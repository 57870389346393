import { check_login } from '../../../constant/check_auth';
import {
  dynamicPageSectionOptimisticUpdate,
  setError,
} from '../../../store/feature/Pages/DynamicPage/dynamicPageSlice';
import { listingLike } from '../../../store/feature/listingSlice';

// like
export const likeListingFromAllListings = ({
  id,
  isLiked,
  router,
  dispatch,
  dynamic_page_sections,
  section_details,
}) => {
  if (check_login(router)) {
    likeDataOptimisticUpdate({
      dispatch,
      isLiked,
      id,
      dynamic_page_sections,
      section_details,
    });
    dispatch(
      listingLike({
        id,
        isLiked,
      })
    )
      .then((res) => {
        if (!res.payload.code) {
          // dispatch(homeCollections({ authKey: auth_key }));
        } else {
          likeDataOptimisticUpdate({
            dispatch,
            id,
            dynamic_page_sections,
            section_details,
            isLiked: !isLiked,
          });
          dispatch(setError([{ msg: res?.payload?.message }]));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

// wish data optimistic  update
const likeDataOptimisticUpdate = ({
  dispatch,
  isLiked,
  id,
  dynamic_page_sections,
  section_details,
}) => {
  dispatch(
    dynamicPageSectionOptimisticUpdate(
      dynamic_page_sections.map((section) => {
        if (section.order_number == section_details?.order_number) {
          return {
            ...section,
            data: {
              ...section.data,
              listings: [
                ...section.data.listings?.map((listing) => {
                  if (listing.id !== id) {
                    return listing;
                  } else {
                    return { ...listing, liked: !isLiked };
                  }
                }),
              ],
            },
          };
        } else {
          return section;
        }
      })
    )
  );
};
