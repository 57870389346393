export const base64ToPDFDownload = ({
  base64Data,
  fileName,
}: {
  base64Data: string;
  fileName: string;
}) => {
  // Step 1: Decode base64 to binary data
  const binaryData = atob(base64Data);

  // Step 2: Create a Blob
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

  // Step 3: Create a URL for the Blob
  const blobUrl = URL.createObjectURL(blob);

  // Step 4: Create a link element
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;

  // Step 5: Click the link to trigger download
  link.click();

  // Cleanup: Revoke the Blob URL to free up resources
  URL.revokeObjectURL(blobUrl);
};
