import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const LayerImage = ({ data, styles }) => {
  return (
    <div className="" style={get_dynamic_styles(styles?.main_styles)}>
      {data?.image && (
        <img
          style={get_dynamic_styles(styles?.image_styles)}
          src={data.image}
          alt="image"
        />
      )}
    </div>
  );
};

export default LayerImage;
