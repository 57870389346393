/* eslint-disable @typescript-eslint/no-var-requires */
import { useRouter } from 'next/dist/client/router';

import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Orders_text } from '../../../constant/Translation/OrdersText';
import { authSelector } from '../../../store/feature/authSlice';
import { get_orders } from '../../../store/feature/orderSlice';
import { slug as SlugChecker } from 'github-slugger';

const OrdersFilter = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const changeFilter = (e) => {
    const separate = e.target.value.split('-');

    if (separate[0] === '0') {
      router.push({
        pathname: '/orders',
        query: { page: router.query.page },
      });
    } else {
      router.push({
        query: {
          order_status: SlugChecker(`${separate[1]}`),
          page: router.query?.page ? router.query?.page : 1,
        },
      });
    }
  };

  // orders filter options
  const options = [
    { value: 'All', label: Orders_text.orderdetail.all, id: 0 },
    {
      value: 'Confirmed',
      label: Orders_text.orderdetail.order_confirmed,
      id: 2,
    },
    {
      value: 'In progress',
      label: Orders_text.orderdetail.order_in_progress,
      id: 3,
    },
    { value: 'Shipped', label: Orders_text.orderdetail.shipped, id: 4 },
    {
      value: 'Delivered',
      label: Orders_text.orderdetail.order_delivered,
      id: 5,
    },

    {
      value: 'Cancel by customer',
      label: Orders_text.orderdetail.cancelled_by_you,
      id: 6,
    },
    {
      value: 'Cancel by admin',
      label: Orders_text.orderdetail.cancelled_by_admin,
      id: 7,
    },
  ];
  return (
    <div>
      <label className="flex justify-center items-center ">
        <span className="text-[#77869E]   text-sm sm:text-lg ltr:mr-4 rtl:ml-4">
          {Orders_text.orderlist.filter_title}
        </span>
        <select
          onChange={(e) => changeFilter(e)}
          className="
                    block
                      w-[150px] sm:w-[250px]
                    mt-1
                    rounded-button
                    bg-white
                    border-transparent
                    focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50
                  "
        >
          {options?.map((item) => {
            return (
              <option
                selected={
                  router.query.order_status === SlugChecker(item.value)
                    ? true
                    : false
                }
                key={Math.random()}
                value={`${item.id}-${item.value}`}
              >
                {item.label}
              </option>
            );
          })}
        </select>
      </label>
    </div>
  );
};

export default OrdersFilter;
