export var Following_text = {
  following: {
    //
    total_listing: 'Total Listing',
    total_listings: 'Total Listings',
    total_followers: 'Total Followers',
    total_follower: 'Total Follower',

    // Need tochange title ... toatla to total
    //   CSV
    following_accounts_title: 'My Following Accounts',
    following_accounts_title: 'My Following Accounts',
    no_following_accounts: 'No Accounts found',
    no_following_accounts: 'No Accounts found',
    no_following_accounts: 'No Accounts found',
    no_following_accounts: 'No Accounts found',
    // total_listing: 'Total Listing',
    // total_listing: 'Total Listing',
    // totla_followers: 'Total Followers',
    // totla_followers: 'Total Followers',
  },
};
