/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import moreImage from '../../../assets/Images/Categories/Menu.png';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { Home_page_text } from '../../../constant/Translation/Home';
import { all_ct_icon } from '../../Shared/Constant/Icons/AllIcons';
import { slug as SlugChecker } from 'github-slugger';

const Categories = ({ categories }) => {
  const [filterCategories, setFilterCategories] = useState();

  useEffect(() => {
    const width = window.innerWidth;
    let calc;
    if (width < 768) {
      calc = 7;
    } else {
      calc = Math.floor((width - 245) / 116);
    }
    let sliceCategories;
    if (categories?.length > calc) {
      sliceCategories = categories?.slice(0, calc - 2);
      sliceCategories.push({
        name: Home_page_text.home.view_all,
        image_path: moreImage,
        id: 'all',
      });
    } else {
      sliceCategories = categories?.slice(0, calc);
    }
    setFilterCategories(sliceCategories);
  }, [categories]);
  return (
    <div className="mt-6 md:mt-10">
      <div className="  flex  flex-wrap justify-center md:justify-start  items-center gap-2 md:gap-4">
        {filterCategories?.map((item) => {
          const query =
            item.id !== 'all'
              ? {
                  slug: item.slug
                    ? item.slug
                    : `${item?.id}-${SlugChecker(item.name)}`,
                }
              : '';

          return (
            <Link
              key={Math.random()}
              href={{
                pathname: `${item.id !== 'all' ? '/lc/[slug]' : '/lc'}`,
                query,
              }}
              passHref
            >
              <a className="    bg-[#ffffff] rounded-card    p-2 flex flex-col  justify-between items-center border border-transparent  shadow-c-sm hover:border-primary hover:bg-primary_light  w-home_category_card  h-home_category_card cursor-pointer">
                <div className=" flex-none w-full h-home_category_image rounded-image overflow-hidden   relative   ">
                  {item.image_path &&
                    (item.id !== 'all' ? (
                      <img
                        src={getThumbnailImage(item.image_path)}
                        alt={item.name}
                        title={item.name}
                        className="h-full w-full object-cover"
                      />
                    ) : (
                      <span className=" w-full  h-full flex items-center justify-center text-primary ">
                        {all_ct_icon}
                      </span>
                    ))}
                </div>
                <p className=" w-full mx-auto   text-primary  text-xs   font-medium   text-center  truncate  text-ellipsis">
                  {item.name}
                </p>
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Categories;
