import React, { useState } from 'react';
import SidePan from '../Shared/Modal/SidePan';
import { Wallet_text } from '../../constant/Translation/wallet';
import AddAmountSteps from './AddAmount/AddAmountSteps';
import { cn } from '../../utils/classNames';
import { useAppDispatch } from '../../hooks/Redux';
import {
  onCloseSidebar,
  onOpenSidebar,
} from '../../store/feature/SidebarSlice';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const AddAmountINWallet2 = ({ styles, data }) => {
  const [sidePanOpen, setSidePanOpen] = useState(false);
  const dispatch = useAppDispatch();

  const onClickButton = (e) => {
    e.stopPropagation();

    dispatch(
      onOpenSidebar({
        sidebar_id: 'amount-sidepan',
        modal_section_styles:
          'fixed  ltr:right-0 rtl:left-0 bottom-0 lg:top-0 lg:my-auto   z-[60]  block flex items-center  justify-center  transform bg-white rounded shadow-lg   max-w-full md:max-w-4xl w-full   rounded-t-[40px]   lg:rounded-card  overflow-hidden',
        modal_inside_section_styles:
          'w-full   max-h-[80VH] md:max-h-full  h-full lg:scrollbar-thin   lg:scrollbar-track-transparent overflow-auto  lg:scrollbar-thumb-transparent  ',
        is_close_by_outer_click: true,

        sidebar_inside_component: (
          <AddAmountSteps
            sidePanOpen={sidePanOpen}
            closeSidebar={() => dispatch(onCloseSidebar())}
          />
        ),
      })
    );
  };

  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className="flex items-center   "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        <button
          className="     rounded-button  flex items-center justify-center  gap-2 "
          type="button"
          onClick={(e) => {
            onClickButton(e);
          }}
          style={get_dynamic_styles(styles?.button_styles)}
        >
          {data?.icon && (
            <img
              className="w-auto object-cover  "
              style={get_dynamic_styles(styles?.icon_styles)}
              src={data?.icon}
              alt="image"
            />
          )}
          {data?.button_title && (
            <p
              className="text-lg font-semibold text-gray-700"
              style={get_dynamic_styles(styles?.text_styles)}
            >
              {get_data_by_current_language({
                data_list: data?.translated_data,
                key_name: 'button_title',
                default_data: data?.button_title,
              })}
            </p>
          )}
        </button>
      </div>
    </div>
  );
};

export default AddAmountINWallet2;
