export const removeKeysFromObject = ({ object_params, key_to_remove }) => {
  // Create a shallow copy of the input object to avoid modifying the original
  const modifiedQueryParams = { ...object_params };

  // Loop through the keys to remove
  for (let i = 0; i < key_to_remove.length; i++) {
    const key = key_to_remove[i];

    // Check if the key exists in the object before attempting to delete
    if (modifiedQueryParams.hasOwnProperty(key)) {
      delete modifiedQueryParams[key];
    }
  }

  return modifiedQueryParams;
};
