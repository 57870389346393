import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import LatestListings from '../../components/home/LatestListings/LatestListings';
import Categories2 from '../../components/home/Categories/Categories2';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Latest_blogs from '../../components/home/BlogsWidget/Latest_blogs';

const HomePageLayout = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(
  //     homeCollections({
  //       authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
  //     })
  //   );
  //   dispatch(
  //     AllPromoBanners({
  //       authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
  //       bodyParam: { placement: 'footer' },
  //     })
  //   );
  // }, [auth_key, dispatch]);

  const {
    collections,
    isFetching,
    isSuccess,
    errorMessage,
    categories,
    promo_banners,
    page_promo_banners,
  } = useSelector(homeSelector);

  return (
    <div className="">
      <div className="  ">
        <Categories2 categories={categories} />
      </div>
      {/* <div>
        <Banner2 banners={promo_banners} />
      </div> */}
      {collections?.map((collection) => {
        const scope_type = collection.scope_type;

        if (scope_type === 4) {
          return (
            <div
              key={Math.random()}
              className="max-w-[1400px] mx-auto px-3 md:px-9  mt-7 md:mt-10 mb-20 "
            >
              <LatestListings products={collection} />
            </div>
          );
        }
      })}

      {TYPE_CONSTANT.GENERAL_CONFIGS?.home_blogs_widget !== false && (
        <div className="max-w-[1400px] mx-auto px-3 md:px-9 ">
          <Latest_blogs />
        </div>
      )}
    </div>
  );
};

export default HomePageLayout;
