export var Invite_text = {
  invite: {
    thanks_note: 'Thanks for sharing!',

    header_title: 'Share',
    invite_friends: 'Invite friends to {value}',
    invite_friends_info:
      'Invite your friends to buy and sell Listing on {value}',
    invite_now: 'Invite Now',
  },
};
