export var EditProfile_text = {
  editprofile: {
    add_image: 'Add Image',

    delete_account: 'Delete User',
    delete_user_desc:
      'All user details and data will be permanently deleted, including its accounts and listings. This action is irreversible and can not be undone.',
    delete_account_title: 'Delete Account',
    delete_account_warning: ' This action not reversible. Please be certain.',
    enter_account_name: 'Enter account name {value} to continue',
    delete_my_account: 'To verify, type "delete my account" below',
    updated: 'Your profile updated successfully',
    enter_valid_phoneno: 'Please Enter Valid Phone number',
    invalid_phoneno: 'Invalid PhoneNumber',
    mobile_number: 'Mobile Number',

    title: 'Edit Profile',
    submit: 'Submit',
    firstname: 'First name',
    lastname: 'Last name',
    email: 'Email',
  },
};
