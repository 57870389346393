/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import OutsideClickHandler from 'react-outside-click-handler';
import Modal from '../../../Shared/Modal/Modal';
import { get_order_details } from '../../../../store/feature/store_orderSlice';
import { useRouter } from 'next/dist/client/router';
import CustomLoading from '../../../Shared/Loading/CustomLoading';
import axios from 'axios';
import { Orders_text } from '../../../../constant/Translation/OrdersText';
import TrackingForm from './TrackingForm/TrackingForm';
import { useDispatch } from 'react-redux';

const OrderTracking = ({ order_details }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showTrackingShipmentForm, setShowTrackingShipmentForm] =
    useState(false);
  const { register, handleSubmit, setValue } = useForm();

  // Submit tracking
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post('/api/a/orders/tracking', {
        id: order_details.id,
        sendData: {
          order: {
            shipment: data,
          },
        },
      })
      .then((res) => {
        dispatch(
          get_order_details({
            id: order_details.id,
            bodyParam: { account_id: router.query.account_id },
          })
        );
        setShowTrackingShipmentForm(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <CustomLoading />}
      {showTrackingShipmentForm && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowTrackingShipmentForm(false);
            }}
          >
            <TrackingForm
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              register={register}
              setForm={setShowTrackingShipmentForm}
              setValue={setValue}
              shipment_details={order_details?.shipment ?? undefined}
            />
          </OutsideClickHandler>
        </Modal>
      )}
      <div className="w-full h-min-[50px] bg-white  shadow-c-sm rounded-card   p-4   sm:p-7    py-5  border-opacity-40">
        <div className="flex  ">
          <p className=" text-lg text-black font-semibold   ">
            {Orders_text?.orderdetail.shipment_details}
          </p>
        </div>
        {order_details?.shipment ? (
          <div className="mt-3">
            {order_details?.shipment?.provider && (
              <div className=" flex justify-start  items-start  py-1  ">
                <p className=" text-sm text-black font-semibold  whitespace-nowrap ">
                  {Orders_text.orderdetail.provider} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {order_details?.shipment?.provider}
                </p>
              </div>
            )}
            {order_details?.shipment?.tracking_url && (
              <a
                href={order_details?.shipment?.tracking_url}
                target="_blank"
                rel="noreferrer"
                className=" flex justify-start  items-start  py-1  "
              >
                <p className=" text-sm text-black font-semibold  whitespace-nowrap ">
                  {Orders_text.orderdetail?.tracking_url} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {order_details?.shipment?.tracking_url}
                </p>
              </a>
            )}
            {order_details?.shipment?.tracking_number && (
              <div className=" flex justify-start  items-start  py-1  ">
                <p className=" text-sm text-black font-semibold  whitespace-nowrap ">
                  {Orders_text.orderdetail?.tracking_number} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                  {order_details?.shipment?.tracking_number}
                </p>
              </div>
            )}
          </div>
        ) : (
          <p className=" mt-3 text-sm text-gray-800 font-medium     break-words ">
            {Orders_text.orderdetail.shipment_data_unavailable_seller_note}
          </p>
        )}
        <div className="mt-4">
          <button
            className={
              order_details?.order_status == 4
                ? 'btn-primary-outline'
                : 'btn-disabled'
            }
            disabled={order_details?.order_status == 4 ? false : true}
            onClick={() => {
              setShowTrackingShipmentForm(true);
            }}
          >
            {order_details?.shipment &&
            Object.keys(order_details.shipment)?.length > 0
              ? Orders_text.orderdetail.edit_shipment_button
              : Orders_text.orderdetail.add_shipment_button}
          </button>
        </div>
      </div>
    </>
  );
};

export default OrderTracking;
