/* eslint-disable react/prop-types */
import React from 'react';
import { Account_text } from '../../../constant/Translation/Account';
import ReadingComposer from '../../Shared/Composer/ReadingComposer';

const DescriptionPart = ({ description }) => {
  return description !== undefined || description === '' ? (
    <div className=" w-full  min-h-[200px] bg-[#FFFFFF] rounded-card    p-[24px] shadow-c-sm">
      <p className="title-h1 ">{Account_text.storedetail.description}</p>
      <div className="  mt-4">
        {description && (
          <ReadingComposer contents={description} isReadOnly={true} />
        )}
      </div>
    </div>
  ) : (
    <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionPart;
