import { useRouter } from 'next/router';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Orders_text } from '../../../../constant/Translation/OrdersText';
import { authSelector } from '../../../../store/feature/authSlice';
import {
  changeOrderStatus,
  changeShipmentStatus,
  get_order_details,
  store_orderSelector,
} from '../../../../store/feature/store_orderSlice';
import { ShipmentStatus, changeStatus } from '../../../Shared/Constant/Status';
import CustomLoading from '../../../Shared/Loading/CustomLoading';
import { General_text } from '../../../../constant/Translation/General';
import OutsideClickHandler from 'react-outside-click-handler';
import { Address_text } from '../../../../constant/Translation/Address';

const ShipmentOrderStatus = ({
  order_details,
  shipment,
  setShowError,
  setError_message,
  setShowSuccess,
  setSuccess_message,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  //
  const {
    isChangeStatusFetching,
    isChangeStatusError,
    changeStatusErrorMessage,
  } = useSelector(store_orderSelector);

  //
  const selectRef = useRef(null);

  const handleResetClick = () => {
    selectRef.current.value = '';
    selectRef.current.selectedIndex = 0;
  };
  //Status change function
  const status_change = (status, order_details) => {
    if (status) {
      if (Number(status) !== 4) {
        dispatch(
          changeShipmentStatus({
            id: order_details.id,
            shipment_id: shipment.id,
            sendData: {
              shipment: {
                status: Number(status),
              },
            },
          })
        ).then((res) => {
          if (!res.payload.code) {
            setShowSuccess(true);
            setSuccess_message(
              Orders_text.orderdetail.status_changed_successfully
            );

            dispatch(
              get_order_details({
                id: order_details.id,
                bodyParam: { account_id: router.query.account_id },
              })
            );
            handleResetClick();
          } else {
            dispatch(
              get_order_details({
                id: order_details.id,
                bodyParam: { account_id: router.query.account_id },
              })
            );
            if (isChangeStatusError) {
              setShowError(true);
              setError_message(changeStatusErrorMessage);
            }
            handleResetClick();
          }
        });
      } else {
        if (Object.keys(shipment?.pickup_address).length === 0) {
          setShowError(true);
          setError_message(
            Address_text.address.alert_message_add_pickup_address
          );
        } else {
          dispatch(
            changeShipmentStatus({
              id: order_details.id,
              shipment_id: shipment.id,
              sendData: {
                shipment: {
                  status: Number(status),
                },
              },
            })
          ).then((res) => {
            if (!res.payload.code) {
              setShowSuccess(true);
              setSuccess_message(
                Orders_text.orderdetail.status_changed_successfully
              );
              dispatch(
                get_order_details({
                  id: order_details.id,
                  bodyParam: { account_id: router.query.account_id },
                })
              );
              handleResetClick();
            } else {
              dispatch(
                get_order_details({
                  id: order_details.id,
                  bodyParam: { account_id: router.query.account_id },
                })
              );
              if (isChangeStatusError) {
                setShowError(true);
                setError_message(changeStatusErrorMessage);
              }
              handleResetClick();
            }
          });
        }
      }
    }
  };

  return (
    <>
      {isChangeStatusFetching && <CustomLoading />}
      {shipment?.next_status.length > 0 && (
        <div className="flex   justify-end">
          <select
            id="all_next_status"
            ref={selectRef}
            className="
                    block
                      max-w-[224px] w-full
                    rounded-button
                    border-primary
                     text-primary
                  "
            onChange={(e) => {
              if (e.target.value !== '') {
                status_change(e.target.value, order_details);
              }
            }}
          >
            <option selected value="">
              {Orders_text.orderdetail.change_order_status}
            </option>
            {shipment?.next_status.length > 0 ? (
              shipment?.next_status.map((status) => {
                return (
                  <>
                    <option key={status} value={status}>
                      {ShipmentStatus(status)}
                    </option>
                  </>
                );
              })
            ) : (
              <option disabled>{Orders_text.orderdetail.no_status}</option>
            )}
          </select>
        </div>
      )}
    </>
  );
};

export default ShipmentOrderStatus;
