/* eslint-disable @typescript-eslint/no-var-requires */
import axios from 'axios';
import { Layer_text } from '../../constant/Translation/LayerText';
import { clearLayerDetailsState } from '../../store/feature/layerSlice';
import { slug as SlugChecker } from 'github-slugger';

export const edit_layer_click = async (
  title,
  slug,
  meta_title,
  meta_description,
  meta_keyword,
  description,
  account_id,
  article_id,
  setErrorMessage,
  setShowError,
  setEditArticleLoading,
  router,
  dispatch
) => {
  setEditArticleLoading(true);

  if (title?.replace(/\s/g, '').length <= 0) {
    setShowError(true);
    setErrorMessage(Layer_text?.layer.title_alert);
    setEditArticleLoading(false);
    return false;
  }
  if (description?.replace(/\s/g, '').length <= 0) {
    setShowError(true);
    setErrorMessage(Layer_text?.layer.desc_alert);
    setEditArticleLoading(false);
    return false;
  }

  try {
    axios
      .patch('/api/c/article', {
        data: {
          layer: {
            title: title,
            content: description,
            type: 'post',
            slug: SlugChecker(title),
          },
        },
        id: article_id,
      })
      .then((res) => {
        setEditArticleLoading(false);
        dispatch(clearLayerDetailsState());
        router.push('/c');
      })
      .catch((error) => {
        setShowError(true);
        setErrorMessage(error.response?.data?.message);
        setEditArticleLoading(false);
      });
  } catch (error) {
    setShowError(true);
    setErrorMessage(error.response?.data?.message);
    setEditArticleLoading(false);
  }
};
