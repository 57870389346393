import React from 'react';
import { Add_product_text } from '../../../../constant/Translation/AddProduct_text';

const Dimension = ({
  height_value,
  setHeightValue,
  width_value,
  length_value,
  setLengthValue,
  setWidthValue,
  size_uom_list,
  setSizeUom,
  size_uom,
}) => {
  return (
    <div className="block relative">
      <span className="text-gray-700  text-lg">
        {Add_product_text.addproduct.dimension}
      </span>
      <div className="  flex items-center justify-start   w-full px-0.5  border-0 border-b-2 border-gray-200 transition  duration-700 focus:ring-0 focus:border-primary gap-4 ">
        {/*  */}
        <input
          value={length_value}
          type="number"
          className="  grow w-full border-0 outline-none  text-input "
          placeholder="L"
          onChange={(e) => {
            if (e.target.value >= 0) {
              setLengthValue(e.target.value);
            }
          }}
          onWheel={(event) => event.currentTarget.blur()}
        />{' '}
        <span className=" text-xs text-gray-400">X</span>
        {/*  */}
        <input
          value={height_value}
          type="number"
          className="  grow w-full border-0 outline-none  text-input "
          placeholder="H"
          onChange={(e) => {
            if (e.target.value >= 0) {
              setHeightValue(e.target.value);
            }
          }}
          onWheel={(event) => event.currentTarget.blur()}
        />
        <span className=" text-xs text-gray-400">X</span>
        {/*  */}
        <input
          value={width_value}
          type="number"
          className="  grow w-full border-0 outline-none  text-input "
          placeholder="W"
          onChange={(e) => {
            if (e.target.value >= 0) {
              setWidthValue(e.target.value);
            }
          }}
          onWheel={(event) => event.currentTarget.blur()}
        />
        <select
          className="   pl-2 pr-8 py-1 border-0  transition  duration-700 focus:ring-0 focus:border-primary text-sm   h-full "
          onChange={(e) => setSizeUom(e.target.value)}
        >
          {size_uom_list?.map((uom) => (
            <option
              key={uom.value}
              value={uom.value}
              selected={size_uom === uom?.value}
            >
              {uom.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Dimension;
