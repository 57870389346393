/* eslint-disable @typescript-eslint/no-var-requires */
import { postStore } from '../../../store/feature/storeSlice';
import tradly from 'tradly';
import axios from 'axios';
import { Add_store_text } from '../../../constant/Translation/AddStore_text';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import { slug as SlugChecker } from 'github-slugger';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

export const create_store_click = (
  files,
  name,
  slug,
  description,
  coordinates,
  full_location,
  preferenceShippingMethod,
  category,
  attributeData,
  setShowError,
  setError_message,
  auth_key,
  dispatch,
  setCreateStoreLoading,
  router,
  accounts_configs,
  account_categories,
  address_state,
  attributes,
  shippingCharge
) => {
  setCreateStoreLoading(true);

  if (files === null || files.length == 0) {
    setShowError(true);
    setError_message(Add_store_text.addstore.add_your_store_photo);
    setCreateStoreLoading(false);
    return false;
  }
  if (name?.replace(/\s/g, '').length <= 0 || name == null) {
    setShowError(true);
    setError_message(Add_store_text.addstore.alert_store_title);
    setCreateStoreLoading(false);
    return false;
  }
  if (accounts_configs.account_address_enabled && coordinates === null) {
    setShowError(true);
    setError_message(Add_store_text.addstore.alert_address_not_found);
    setCreateStoreLoading(false);
    return false;
  }
  if (
    accounts_configs?.enable_shipping_methods_preference &&
    (preferenceShippingMethod === null || preferenceShippingMethod?.length == 0)
  ) {
    setShowError(true);
    setError_message(Add_store_text.addstore.alert_shipping_method_not_found);
    setCreateStoreLoading(false);
    return false;
  }
  if (
    (preferenceShippingMethod?.filter(
      (shippingMethod) => shippingMethod.type === 'pickup'
    ).length > 0 ||
      (preferenceShippingMethod?.filter(
        (shippingMethod) => shippingMethod.type === 'delivery'
      ).length > 0 &&
        accounts_configs?.get_pickup_address_for_delivery === true) ||
      TYPE_CONSTANT?.ADDRESS_CONFIGS?.get_account_pickup_address_always) &&
    address_state === null
  ) {
    setShowError(true);
    setError_message(Add_store_text.addstore.alert_pickup_address_not_found);
    setCreateStoreLoading(false);
    return false;
  }

  // else if (category === null) {
  //   setShowError(true);
  //   setError_message('Select one category');
  //   setCreateStoreLoading(false);
  //   return false;
  // }
  if (attributes?.length > 0) {
    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      if (!attribute.optional) {
        if (
          !attributeData?.filter((at_data) => at_data.id === attribute.id)
            ?.length > 0 ||
          attributeData === null
        ) {
          setShowError(true);
          setError_message(
            dynamic_translation_set(
              Add_store_text.addstore.alert_attribute_required,
              attribute.name
            )
          );
          setCreateStoreLoading(false);
          return false;
          break;
        }
      }
    }
  }

  // Imaage for store
  if (files !== null) {
    axios
      .post('/api/generateS3ImageURL', {
        data: {
          files: [
            {
              name: files.name,
              type: files.type,
            },
          ],
        },
      })
      .then((response) => {
        if (!response.error) {
          const fileURL = response.data.result[0];
          const path = fileURL.signedUrl;
          const ImagePath = fileURL.fileUri;
          fetch(path, {
            method: 'put',
            headers: {
              ContentType: files.type,
            },
            body: files,
          })
            .then((res) => {
              if (res.status) {
                if (attributeData !== null && attributeData?.length !== 0) {
                  const check = attributeData.find((attr) => attr.uploadFile);
                  if (check === undefined) {
                    let storesData = {
                      name: name,

                      web_address: '',
                      images: [ImagePath],
                      attributes: attributeData,
                      type: 'accounts',
                    };
                    if (accounts_configs.account_address_enabled) {
                      storesData['coordinates'] = coordinates;
                    }
                    if (category !== null) {
                      storesData['category_id'] = [category];
                    }
                    if (
                      !description?.replace(/\s/g, '').length <= 0 &&
                      description !== null
                    ) {
                      storesData['description'] = description;
                    }
                    if (
                      accounts_configs?.accounts_show_shipping_charges &&
                      shippingCharge &&
                      shippingCharge > 0
                    ) {
                      storesData['shipping_charges'] = {
                        flat: shippingCharge,
                      };
                    }
                    if (
                      preferenceShippingMethod !== null &&
                      preferenceShippingMethod?.length > 0
                    ) {
                      storesData['shipping_methods'] =
                        preferenceShippingMethod.map((item) => item.value);
                    }
                    if (!slug?.replace(/\s/g, '').length <= 0) {
                      storesData['slug'] = SlugChecker(slug, {
                        remove: undefined,
                        lower: true,
                        strict: true,
                      });
                    } else {
                      storesData['slug'] = SlugChecker(name);
                    }

                    dispatch(
                      postStore({
                        id: '',
                        prams: { account: storesData },
                        authKey: auth_key,
                      })
                    ).then((res) => {
                      if (!res.payload.code) {
                        // set pickup address
                        if (address_state !== null) {
                          axios
                            .post('/api/address/save_address', {
                              addressData: {
                                address: {
                                  ...Object.fromEntries(
                                    Object.entries(address_state).filter(
                                      ([key, value]) => value !== ''
                                    )
                                  ),
                                  type: 'pickup',
                                  current_address: true,
                                },
                              },
                            })
                            .then((res) => {
                              push_data_layer({
                                event: 'create_account',
                                data: {
                                  name: name,
                                },
                              });
                              router.push('/a/my-account');
                              setCreateStoreLoading(false);
                            })
                            .catch((error) => {
                              console.log(error);
                              setCreateStoreLoading(false);
                            });
                        } else {
                          push_data_layer({
                            event: 'create_account',
                            data: {
                              name: name,
                            },
                          });
                          router.push('/a/my-account');
                          setCreateStoreLoading(false);
                        }
                      } else {
                        setCreateStoreLoading(false);
                        setShowError(true);
                        setError_message(res.payload.message);
                      }
                    });
                  } else {
                    axios
                      .post('/api/generateS3ImageURL', {
                        data: {
                          files: [
                            {
                              name: check.values[0].name,
                              type: check.values[0].type,
                            },
                          ],
                        },
                      })
                      .then((response) => {
                        if (!response.error) {
                          const fileURL = response.data.result[0];
                          const path = fileURL.signedUrl;
                          const ImagePath2 = fileURL.fileUri;
                          fetch(path, {
                            method: 'put',
                            headers: {
                              ContentType: check.values[0].type,
                            },
                            body: check.values[0],
                          })
                            .then((res) => {
                              const filter = attributeData.filter(
                                (attr) => !attr.uploadFile
                              );
                              const attributeUpdate = [
                                ...filter,
                                { values: [ImagePath2], id: check.id },
                              ];
                              let storesData = {
                                name: name,
                                web_address: '',
                                images: [ImagePath],
                                attributes: attributeUpdate,
                                type: 'accounts',
                              };
                              if (accounts_configs.account_address_enabled) {
                                storesData['coordinates'] = coordinates;
                              }
                              if (
                                full_location != null ||
                                full_location != undefined
                              ) {
                                storesData['location'] = full_location;
                              }
                              if (
                                accounts_configs?.accounts_show_shipping_charges &&
                                shippingCharge &&
                                shippingCharge > 0
                              ) {
                                storesData['shipping_charges'] = {
                                  flat: shippingCharge,
                                };
                              }
                              if (
                                preferenceShippingMethod !== null &&
                                preferenceShippingMethod?.length > 0
                              ) {
                                storesData['shipping_methods'] =
                                  preferenceShippingMethod.map(
                                    (item) => item.value
                                  );
                              }
                              if (
                                accounts_configs?.accounts_show_shipping_charges &&
                                shippingCharge &&
                                shippingCharge > 0
                              ) {
                                storesData['shipping_charges'] = {
                                  flat: shippingCharge,
                                };
                              }
                              if (
                                preferenceShippingMethod !== null &&
                                preferenceShippingMethod?.length > 0
                              ) {
                                storesData['shipping_methods'] =
                                  preferenceShippingMethod.map(
                                    (item) => item.value
                                  );
                              }
                              if (category !== null) {
                                storesData['category_id'] = [category];
                              }
                              if (
                                !description?.replace(/\s/g, '').length <= 0 &&
                                description !== null
                              ) {
                                storesData['description'] = description;
                              }
                              if (!slug?.replace(/\s/g, '').length <= 0) {
                                storesData['slug'] = SlugChecker(slug);
                              } else {
                                storesData['slug'] = SlugChecker(name);
                              }
                              dispatch(
                                postStore({
                                  id: '',
                                  prams: { account: storesData },
                                  authKey: auth_key,
                                })
                              ).then((res) => {
                                if (!res.payload.code) {
                                  // set pickup address
                                  if (address_state !== null) {
                                    axios
                                      .post('/api/address/save_address', {
                                        addressData: {
                                          address: {
                                            ...Object.fromEntries(
                                              Object.entries(
                                                address_state
                                              ).filter(
                                                ([key, value]) => value !== ''
                                              )
                                            ),
                                            type: 'pickup',
                                            current_address: true,
                                          },
                                        },
                                      })
                                      .then((res) => {
                                        push_data_layer({
                                          event: 'create_account',
                                          data: {
                                            name: name,
                                          },
                                        });
                                        router.push('/a/my-account');
                                        setCreateStoreLoading(false);
                                      })
                                      .catch((error) => {
                                        console.log(error);
                                        setCreateStoreLoading(false);
                                      });
                                  } else {
                                    push_data_layer({
                                      event: 'create_account',
                                      data: {
                                        name: name,
                                      },
                                    });
                                    router.push('/a/my-account');
                                    setCreateStoreLoading(false);
                                  }
                                } else {
                                  setCreateStoreLoading(false);
                                  setShowError(true);
                                  setError_message(res.payload.message);
                                }
                              });
                            })
                            .catch((error) => {
                              setCreateStoreLoading(false);
                              setShowError(true);
                              setError_message(
                                error?.response?.data?.error.message
                              );
                            });
                        } else {
                          setCreateStoreLoading(false);
                          setShowError(true);
                          setError_message(response.error.message);
                        }
                      });
                  }
                } else {
                  let storesData = {
                    name: name,
                    web_address: '',
                    images: [ImagePath],
                    type: 'accounts',
                  };
                  if (accounts_configs.account_address_enabled) {
                    storesData['coordinates'] = coordinates;
                  }
                  if (full_location != null || full_location != undefined) {
                    storesData['location'] = full_location;
                  }
                  if (category !== null) {
                    storesData['category_id'] = [category];
                  }
                  if (
                    accounts_configs?.accounts_show_shipping_charges &&
                    shippingCharge &&
                    shippingCharge > 0
                  ) {
                    storesData['shipping_charges'] = {
                      flat: shippingCharge,
                    };
                  }
                  if (
                    preferenceShippingMethod !== null &&
                    preferenceShippingMethod?.length > 0
                  ) {
                    storesData['shipping_methods'] =
                      preferenceShippingMethod.map((item) => item.value);
                  }
                  if (
                    !description?.replace(/\s/g, '').length <= 0 &&
                    description !== null
                  ) {
                    storesData['description'] = description;
                  }
                  if (!slug?.replace(/\s/g, '').length <= 0) {
                    storesData['slug'] = SlugChecker(slug);
                  } else {
                    storesData['slug'] = SlugChecker(name);
                  }
                  dispatch(
                    postStore({
                      id: '',
                      prams: { account: storesData },
                      authKey: auth_key,
                    })
                  ).then((res) => {
                    if (!res.payload.code) {
                      // set pickup address
                      if (address_state !== null) {
                        axios
                          .post('/api/address/save_address', {
                            addressData: {
                              address: {
                                ...Object.fromEntries(
                                  Object.entries(address_state).filter(
                                    ([key, value]) => value !== ''
                                  )
                                ),
                                type: 'pickup',
                                current_address: true,
                              },
                            },
                          })
                          .then((res) => {
                            push_data_layer({
                              event: 'create_account',
                              data: {
                                name: name,
                              },
                            });
                            router.push('/a/my-account');
                            setCreateStoreLoading(false);
                          })
                          .catch((error) => {
                            console.log(error);
                            setCreateStoreLoading(false);
                          });
                      } else {
                        push_data_layer({
                          event: 'create_account',
                          data: {
                            name: name,
                          },
                        });
                        router.push('/a/my-account');
                        setCreateStoreLoading(false);
                      }
                    } else {
                      setCreateStoreLoading(false);
                      setShowError(true);
                      setError_message(res.payload.message);
                    }
                  });
                }
              }
            })
            .catch((error) => {
              setCreateStoreLoading(false);
              setShowError(true);
              setError_message(error?.response?.data?.error?.message);
            });
        } else {
          setCreateStoreLoading(false);
          setShowError(true);
          setError_message(response.error.message);
        }
      })
      .catch((error) => {
        setCreateStoreLoading(false);
        setShowError(true);
        setError_message(error?.response?.data?.error?.message);
      });
  }
  // no image for store
  else {
    if (attributeData !== null && attributeData?.length !== 0) {
      const check = attributeData.find((attr) => attr.uploadFile);
      if (check === undefined) {
        let storesData = {
          name: name,
          web_address: '',
          attributes: attributeData,
          type: 'accounts',
        };
        if (accounts_configs.account_address_enabled) {
          storesData['coordinates'] = coordinates;
        }
        if (full_location != null || full_location != undefined) {
          storesData['location'] = full_location;
        }
        if (category !== null) {
          storesData['category_id'] = [category];
        }
        if (
          accounts_configs?.accounts_show_shipping_charges &&
          shippingCharge &&
          shippingCharge > 0
        ) {
          storesData['shipping_charges'] = {
            flat: shippingCharge,
          };
        }
        if (
          preferenceShippingMethod !== null &&
          preferenceShippingMethod?.length > 0
        ) {
          storesData['shipping_methods'] = preferenceShippingMethod.map(
            (item) => item.value
          );
        }
        if (
          !description?.replace(/\s/g, '').length <= 0 &&
          description !== null
        ) {
          storesData['description'] = description;
        }
        if (!slug?.replace(/\s/g, '').length <= 0) {
          storesData['slug'] = SlugChecker(slug);
        } else {
          storesData['slug'] = SlugChecker(name);
        }
        dispatch(
          postStore({
            id: '',
            prams: { account: storesData },
            authKey: auth_key,
          })
        ).then((res) => {
          if (!res.payload.code) {
            // set pickup address
            if (address_state !== null) {
              axios
                .post('/api/address/save_address', {
                  addressData: {
                    address: {
                      ...Object.fromEntries(
                        Object.entries(address_state).filter(
                          ([key, value]) => value !== ''
                        )
                      ),
                      type: 'pickup',
                      current_address: true,
                    },
                  },
                })
                .then((res) => {
                  push_data_layer({
                    event: 'create_account',
                    data: {
                      name: name,
                    },
                  });
                  router.push('/a/my-account');
                  setCreateStoreLoading(false);
                })
                .catch((error) => {
                  console.log(error);
                  setCreateStoreLoading(false);
                });
            } else {
              push_data_layer({
                event: 'create_account',
                data: {
                  name: name,
                },
              });
              router.push('/a/my-account');
              setCreateStoreLoading(false);
            }
          } else {
            setCreateStoreLoading(false);
            setShowError(true);
            setError_message(res.payload.message);
          }
        });
      } else {
        axios
          .post('/api/generateS3ImageURL', {
            data: {
              files: [
                {
                  name: check.values[0].name,
                  type: check.values[0].type,
                },
              ],
            },
          })

          .then((response) => {
            if (!response.error) {
              const fileURL = response.data.result[0];
              const path = fileURL.signedUrl;
              const ImagePath2 = fileURL.fileUri;
              fetch(path, {
                method: 'put',
                headers: {
                  ContentType: check.values[0].type,
                },
                body: check.values[0],
              })
                .then((res) => {
                  const filter = attributeData.filter(
                    (attr) => !attr.uploadFile
                  );
                  const attributeUpdate = [
                    ...filter,
                    { values: [ImagePath2], id: check.id },
                  ];
                  let storesData = {
                    name: name,
                    web_address: '',
                    attributes: attributeUpdate,
                    type: 'accounts',
                  };
                  if (accounts_configs.account_address_enabled) {
                    storesData['coordinates'] = coordinates;
                  }
                  if (full_location != null || full_location != undefined) {
                    storesData['location'] = full_location;
                  }
                  if (category !== null) {
                    storesData['category_id'] = [category];
                  }
                  if (
                    accounts_configs?.accounts_show_shipping_charges &&
                    shippingCharge &&
                    shippingCharge > 0
                  ) {
                    storesData['shipping_charges'] = {
                      flat: shippingCharge,
                    };
                  }
                  if (
                    preferenceShippingMethod !== null &&
                    preferenceShippingMethod?.length > 0
                  ) {
                    storesData['shipping_methods'] =
                      preferenceShippingMethod.map((item) => item.value);
                  }
                  if (
                    !description?.replace(/\s/g, '').length <= 0 &&
                    description !== null
                  ) {
                    storesData['description'] = description;
                  }
                  if (!slug?.replace(/\s/g, '').length <= 0) {
                    storesData['slug'] = SlugChecker(slug);
                  } else {
                    storesData['slug'] = SlugChecker(name);
                  }
                  dispatch(
                    postStore({
                      id: '',
                      prams: { account: storesData },
                      authKey: auth_key,
                    })
                  ).then((res) => {
                    if (!res.payload.code) {
                      // set pickup address
                      if (address_state !== null) {
                        axios
                          .post('/api/address/save_address', {
                            addressData: {
                              address: {
                                ...Object.fromEntries(
                                  Object.entries(address_state).filter(
                                    ([key, value]) => value !== ''
                                  )
                                ),
                                type: 'pickup',
                                current_address: true,
                              },
                            },
                          })
                          .then((res) => {
                            push_data_layer({
                              event: 'create_account',
                              data: {
                                name: name,
                              },
                            });
                            router.push('/a/my-account');
                            setCreateStoreLoading(false);
                          })
                          .catch((error) => {
                            console.log(error);
                            setCreateStoreLoading(false);
                          });
                      } else {
                        push_data_layer({
                          event: 'create_account',
                          data: {
                            name: name,
                          },
                        });
                        router.push('/a/my-account');
                        setCreateStoreLoading(false);
                      }
                    } else {
                      setCreateStoreLoading(false);
                      setShowError(true);
                      setError_message(res.payload.message);
                    }
                  });
                })
                .catch((error) => {
                  setCreateStoreLoading(false);
                  setShowError(true);
                  setError_message(error?.response?.data?.error.message);
                });
            } else {
              setCreateStoreLoading(false);
              setShowError(true);
              setError_message(response.error.message);
            }
          });
      }
    } else {
      let storesData = {
        name: name,
        web_address: '',
        type: 'accounts',
      };
      if (accounts_configs.account_address_enabled) {
        storesData['coordinates'] = coordinates;
      }
      if (full_location != null || full_location != undefined) {
        storesData['location'] = full_location;
      }
      if (category !== null) {
        storesData['category_id'] = [category];
      }
      if (
        accounts_configs?.accounts_show_shipping_charges &&
        shippingCharge &&
        shippingCharge > 0
      ) {
        storesData['shipping_charges'] = {
          flat: shippingCharge,
        };
      }
      if (
        preferenceShippingMethod !== null &&
        preferenceShippingMethod?.length > 0
      ) {
        storesData['shipping_methods'] = preferenceShippingMethod.map(
          (item) => item.value
        );
      }
      if (
        !description?.replace(/\s/g, '').length <= 0 &&
        description !== null
      ) {
        storesData['description'] = description;
      }
      if (!slug?.replace(/\s/g, '').length <= 0) {
        storesData['slug'] = SlugChecker(slug);
      } else {
        storesData['slug'] = SlugChecker(name);
      }

      dispatch(
        postStore({
          id: '',
          prams: { account: storesData },
          authKey: auth_key,
        })
      ).then((res) => {
        if (!res.payload.code) {
          // set pickup address
          if (address_state !== null) {
            axios
              .post('/api/address/save_address', {
                addressData: {
                  address: {
                    ...Object.fromEntries(
                      Object.entries(address_state).filter(
                        ([key, value]) => value !== ''
                      )
                    ),
                    type: 'pickup',
                    current_address: true,
                  },
                },
              })
              .then((res) => {
                push_data_layer({
                  event: 'create_account',
                  data: {
                    name: name,
                  },
                });
                router.push('/a/my-account');
                setCreateStoreLoading(false);
              })
              .catch((error) => {
                console.log(error);
                setCreateStoreLoading(false);
              });
          } else {
            push_data_layer({
              event: 'create_account',
              data: {
                name: name,
              },
            });
            router.push('/a/my-account');
            setCreateStoreLoading(false);
          }
        } else {
          setCreateStoreLoading(false);
          setShowError(true);
          setError_message(res.payload.message);
        }
      });
    }
  }
};
