/* eslint-disable react/prop-types */
import React from 'react';
import { Address_text } from '../../../constant/Translation/Address';
import { General_text } from '../../../constant/Translation/General';
import { useEffect } from 'react';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import {
  countriesSelector,
  getCountriesStates,
  getTenantCountriesList,
} from '../../../store/feature/countrySlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/Redux';
import SelectFromList from '../../../UI/FormFields/SelectFromList';

const AddressForm = ({
  setShowPickupAddressForm,
  address_state,
  setAddressState,
}) => {
  let userDetails = JSON.parse(localStorage.getItem('user_details'));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userDetails) {
      setAddressState((prevState) => ({
        ...prevState,
        name: userDetails
          ? `${userDetails?.first_name} ${userDetails?.last_name}`
          : '',
        phone_number: userDetails?.mobile
          ? `${userDetails?.dial_code}${userDetails?.mobile}`
          : '',
      }));
    }

    dispatch(getTenantCountriesList());
  }, [0]);

  //
  const { tenant_countries_list, states } = useAppSelector(countriesSelector);

  // state data load
  useEffect(() => {
    dispatch(
      getCountriesStates({
        country_id: tenant_countries_list?.filter(
          (cn) => cn?.code2 === address_state?.country_code
        )[0]?.id,
      })
    );
  }, [address_state?.country_code, tenant_countries_list]);

  return (
    <form
      className=" w-screen xs:w-[500px] mt-7 mb-7 bg-[#FEFEFE] rounded-card   p-[31px] relative"
      onSubmit={(e) => {
        e.preventDefault();

        if (
          (TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_country == true &&
            !address_state?.country_code) ||
          (TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_state == true &&
            !address_state?.state_code)
        ) {
          return false;
        } else {
          setShowPickupAddressForm(false);
        }
      }}
    >
      <button
        className=" absolute top-0 right-0 mt-5 mr-5 text-primary "
        onClick={() => setShowPickupAddressForm(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <h2 className="text-2xl font-bold">
        {Address_text.address.add_pickup_address}
      </h2>
      <div className="mt-8  w-full">
        <div className="grid grid-cols-1 gap-6">
          <label className="block">
            <span className="text-gray-700">{Address_text.address.name}</span>
            <input
              type="text"
              value={address_state?.name}
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary  
                  "
              placeholder=""
              onChange={(e) =>
                setAddressState((prevState) => ({
                  ...prevState,
                  ['name']: e.target.value,
                }))
              }
              required
            />
          </label>
          <label className="block">
            <span className="text-gray-700">{Address_text.address.phone}</span>
            <input
              type="number"
              value={address_state?.phone_number}
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary  
                  "
              placeholder=""
              onChange={(e) =>
                setAddressState((prevState) => ({
                  ...prevState,
                  ['phone_number']: e.target.value,
                }))
              }
              onWheel={(event) => event.currentTarget.blur()}
              required
            />
          </label>
          {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_house_number && (
            <label className="block">
              <span className="text-gray-700">
                {Address_text.address.house_number}
              </span>
              <input
                type="text"
                value={address_state?.house_number}
                className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary  
                  "
                placeholder={Address_text.address.house_number}
                onChange={(e) =>
                  setAddressState((prevState) => ({
                    ...prevState,
                    ['house_number']: e.target.value,
                  }))
                }
                required
              />
            </label>
          )}
          <label className="block">
            <span className="text-gray-700">
              {Address_text.address.address_one}
            </span>
            <input
              type="text"
              value={address_state?.address_line_1}
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary  
                  "
              placeholder="Address"
              onChange={(e) =>
                setAddressState((prevState) => ({
                  ...prevState,
                  ['address_line_1']: e.target.value,
                }))
              }
              required
            />
          </label>

          {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_address2 != false && (
            <label className="block">
              <span className="text-gray-700">
                {Address_text.address.address_two}
              </span>
              <input
                type="text"
                value={address_state?.address_line_2}
                className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary  
                  "
                placeholder="Address 2"
                onChange={(e) =>
                  setAddressState((prevState) => ({
                    ...prevState,
                    ['address_line_2']: e.target.value,
                  }))
                }
                required
              />
            </label>
          )}
          {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_city && (
            <label className="block">
              <span className="text-gray-700">{Address_text.address.city}</span>
              <input
                type="text"
                value={address_state?.city}
                className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary  
                  "
                placeholder={Address_text.address.city}
                onChange={(e) =>
                  setAddressState((prevState) => ({
                    ...prevState,
                    ['city']: e.target.value,
                  }))
                }
                required
              />
            </label>
          )}
          <div className="grid grid-cols-[100%] sm:grid-cols-[30%,30%,30%] sm:gap-[3.33%] ">
            {/* country */}
            {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_country == true && (
              <SelectFromList
                title={Address_text.address.country}
                current_value={
                  address_state?.country_code ? address_state?.country_code : ''
                }
                drop_down_items={tenant_countries_list?.map((country) => {
                  return {
                    label: country.name,
                    value: country.code2,
                  };
                })}
                styles={{
                  popover_style: '  h-auto max-h-[250px]',
                  title_styles: 'text-gray-700 text-base',
                  component_styles: 'gap-1',
                  field_styles: `  
                    block
                    w-full
                    !rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2 truncate `,
                }}
                set_new_value={(value) => {
                  setAddressState((prevState) => ({
                    ...prevState,
                    ['country_code']: value,
                    ['sate_code']: '',
                    ['country']: tenant_countries_list?.filter(
                      (cn) => cn.code2 == value
                    )[0].name,
                  }));
                }}
                default_option_text={Address_text.address.country}
              />
            )}

            {/*  */}

            {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_state && (
              <SelectFromList
                title={Address_text.address.state}
                current_value={
                  address_state?.state_code ? address_state?.state_code : ''
                }
                drop_down_items={states?.map((st) => {
                  return {
                    label: st.name,
                    value: st.code,
                  };
                })}
                styles={{
                  popover_style: '  h-auto max-h-[250px]',
                  title_styles: 'text-gray-700 text-base',
                  component_styles: 'gap-1',
                  field_styles: `  
                    block
                    w-full
                    !rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary   py-2 truncate `,
                }}
                set_new_value={(value) => {
                  setAddressState((prevState) => ({
                    ...prevState,
                    ['state_code']: value,
                    ['state']: states?.filter((st) => st.code == value)[0].name,
                  }));
                }}
                default_option_text={Address_text.address.state}
              />
            )}
            {TYPE_CONSTANT.ADDRESS_CONFIGS?.address_show_zipcode && (
              <label className="block">
                <span className="text-gray-700">
                  {Address_text.address.zipcode}
                </span>
                <input
                  type="text"
                  value={address_state?.post_code}
                  className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-primary  
                  "
                  placeholder="123456"
                  onChange={(e) =>
                    setAddressState((prevState) => ({
                      ...prevState,
                      ['post_code']: e.target.value,
                    }))
                  }
                  onWheel={(event) => event.currentTarget.blur()}
                  required
                />
              </label>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-center">
        <button
          className=" bg-primary rounded-button px-4 py-2 text-white text-base font-semibold"
          type="submit"
        >
          {General_text.general.add}
        </button>
      </div>
    </form>
  );
};

export default AddressForm;
