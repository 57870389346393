import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { authSelector } from '../../store/feature/authSlice';
import Cookies from 'js-cookie';
import Link from 'next/link';

const Sub_footer2 = ({ data }) => {
  return (
    <div className="w-full bg-white my-20 ">
      <div className=" max-w-[95%] sm:max-w-[80%] md:max-w-[50%] mx-auto px-4 py-4 flex flex-col items-center gap-3">
        <h3 className="h3  text-center">{data?.title}</h3>
        <p className="paragraph text-center">{data?.description}</p>
        <Link href={data?.link ?? '#'}>
          <a
            className=" btn-sm bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary "
            target={data?.link_opening ?? '_blank'}
          >
            {data?.btn_title}
          </a>
        </Link>
      </div>
    </div>
  );
};

export default memo(Sub_footer2);
