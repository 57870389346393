import axios from 'axios';
import { Add_product_text } from '../../../../constant/Translation/AddProduct_text';

// Get Image url function:
const file_uri = async (
  all_files,
  isPrivate,
  setShowError,
  setError_message,
  setEditLoading
) => {
  let all_files_uri = [];
  let upload_files = [];
  let upload_full_files = [];
  for (let i = 0; i < all_files.length; i++) {
    const element = all_files[i];

    // Set in state
    if (element.full_file === null) {
      all_files_uri.push(element.path);
    } else {
      let file_data = { name: element.name, type: element.type };
      if (isPrivate) {
        file_data.private = true;
      }
      upload_files.push(file_data);
      upload_full_files.push(element.full_file);
    }

    // Upload images checking
    if (all_files.length === i + 1 && upload_files.length > 0) {
      try {
        const response = await axios.post('/api/generateS3ImageURL', {
          data: {
            files: upload_files,
          },
        });

        const responseFiles = response.data.result;
        for (let index = 0; index < responseFiles.length; index++) {
          const path = responseFiles[index].signedUrl;
          const fileURI = responseFiles[index].fileUri;

          try {
            const res = await fetch(path, {
              method: 'PUT',
              headers: {
                ContentType: upload_files[index].type,
              },
              body: upload_full_files[index],
            });

            if (res.ok) {
              all_files_uri.push(fileURI);
            }
          } catch (error) {
            setShowError(true);
            setError_message(error?.response?.data?.message);
            setEditLoading(false);
          }
        }
      } catch (error) {
        setShowError(true);
        setError_message(error?.response?.data?.message);
        setEditLoading(false);
      }
    }

    // return all images url
    if (all_files_uri.length == all_files.length) {
      return all_files_uri;
    }
  }
};

// Upload digital products
export const edit_digital_products = async ({
  digitalProductId,
  listingId,
  digitalProducts,
  setShowError,
  setError_message,
  setEditLoading,
  accountId,
  isVariant,
  variantID,
  setShowSuccessMessage,
  isPost,
}) => {
  // destructure
  setEditLoading(true);
  const { account_id, title, description, version_name, content, active } =
    digitalProducts || {};

  //
  if (content?.length == 0) {
    setShowError(true);
    setError_message(Add_product_text.adddigitalproduct.alert_file_select);
    setEditLoading(false);
    return false;
  }
  //
  let digital_file_data = {
    account_id: account_id ?? accountId,
    active: active,
  };

  if (!title?.replace(/\s/g, '').length <= 0) {
    digital_file_data['title'] = title;
  }
  if (!description?.replace(/\s/g, '').length <= 0) {
    digital_file_data['description'] = description;
  }
  if (!version_name?.replace(/\s/g, '').length <= 0) {
    digital_file_data['version_name'] = version_name;
  }
  if (isVariant) {
    digital_file_data.variant_id = variantID;
  }

  const uploaded_files = await file_uri(
    content.map((item) => {
      return {
        full_file: item.full_file ?? null,
        path: item.file_url ?? '',
        name: item.file_name,
        type: item.mime_type,
      };
    }),
    true, //isPrivate
    setShowError,
    setError_message,
    setEditLoading
  );

  if (uploaded_files?.length > 0) {
    digital_file_data.content = content.map((item, index) => {
      return {
        size: item.size,
        mime_type: item.mime_type,
        file_name: item.file_name,
        file_url: uploaded_files[index],
      };
    });

    try {
      const res = await axios({
        method: isPost ? 'post' : 'patch',
        url: '/api/digital_contents',

        data: {
          listing_id: listingId,
          content_id: isPost ? undefined : digitalProductId,
          data: { digital_content: { ...digital_file_data } },
        },
      });

      //   axios.post('/api/digital_contents', {
      //   listing_id: listingId,
      //   content_id: isPost ? undefined : digitalProductId,
      //   data: {
      //     digital_content: { ...digital_file_data },
      //   },
      // });
      if (res) {
        setEditLoading(false);
        !isVariant && setShowSuccessMessage(true);
        return true;
      }
    } catch (error) {
      setShowError(true);
      setError_message(error.response?.data?.message);
      setEditLoading(false);
      return false;
    }
  }
};
