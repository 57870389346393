import React from 'react';

const CategoryGridSkeleton = () => {
  return (
    <div className="   w-full mx-auto overflow-hidden">
      <div className="flex items center gap-4 overflow-x-auto">
        <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5"></div>
        <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5"></div>
        <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5"></div>
        <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5"></div>
        <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5"></div>
        <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5"></div>
        <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5"></div>
        <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5"></div>
        <div className="h-5 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-4/5"></div>
      </div>
    </div>
  );
};

export default CategoryGridSkeleton;
