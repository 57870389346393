/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import {
  angle_down,
  check_icon,
  filter_icon,
  star_icon,
} from './Constant/Icons/AllIcons';
import tradly from 'tradly';
import { useEffect } from 'react';
import { useRouter } from 'next/dist/client/router';
import {
  convertTimeinto12Hrs,
  getDatesArray2,
  priceRange,
} from './Constant/Constant';
import OutsideClickHandler from 'react-outside-click-handler';
import { getDatesArray, getTimeDifference } from './Constant/Constant';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import moment from 'moment';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { fetch_all_categories } from '../../constant/fetch_all_categories';
import { Filter_text } from '../../constant/Translation/Filter';

const Listing_Filter2 = ({
  hidden_category,
  reset_filter,
  hide_sort,
  hide_rating,
}) => {
  const [MARKETPLACE_MODULES, setMARKETPLACE_MODULES] = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSeeAllCategories, setIsSeeAllCategories] = useState(false);
  const [allCategories, setAllCategories] = useState(null);
  const [allAttributes, setAllAttributes] = useState(null);

  const router = useRouter();
  const category_id = router?.query?.category_id;
  const country = router?.query?.country;
  const city = router?.query?.city;
  const sort = router?.query?.sort;
  const rating = router?.query?.rating;
  const attribute_value_id = router?.query?.attribute_value_id;
  const [activeParent, setActiveParent] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedAtValues, setSelectedAtValues] = useState([]);

  const start_at = router?.query?.start_at;
  const end_at = router?.query?.end_at;
  const [range_value, setRange_value] = useState([1]);
  const [changed_value, setChanged_value] = useState(['00:00:00', '23:59:59']);
  const [is_value_changed, setIs_value_changed] = useState(false);

  const [listingsCities, setListingsCities] = useState(null);
  const [listingsCountries, setListingsCountries] = useState(null);
  const [isSeeAllCountries, setIsSeeAllCountries] = useState(false);
  const [isSeeAllCities, setIsSeeAllCities] = useState(false);
  // const [selectedCities, setSelectedCities] = useState([]);
  // const [selectedCountries, setSelectedCountries] = useState([]);

  let dates = getDatesArray2();

  // Fetch Categories , attributes ,locations
  useEffect(() => {
    axios
      .get('/api/categories', { params: { parent: 0, type: 'listings' } })
      .then((res) => {
        setAllCategories(fetch_all_categories(res.data.categories, true));
      });

    // Attributes
    let attribute_params = { type: 'listings' };
    if (category_id) {
      attribute_params['category_id'] = category_id;
    }
    axios.get('/api/attributes', { params: attribute_params }).then((res) => {
      setAllAttributes(res.data.attributes);
    });

    if (category_id) {
      setSelectedCategories(category_id.split(','));
    }
    if (attribute_value_id) {
      setSelectedAtValues(attribute_value_id.split(','));
    }

    // City
    axios
      .get('/api/l/locations', { params: { type: 'city', page: 1 } })
      .then((res) => {
        setListingsCities(res.data.values);
      });
    // Countries
    axios
      .get('/api/l/locations', {
        params: { type: 'country', page: 1 },
      })
      .then((res) => {
        setListingsCountries(res.data.values);
      });
  }, [router.query]);

  // set marketplace module and start at time
  useEffect(() => {
    setMARKETPLACE_MODULES(Number(TYPE_CONSTANT.MARKETPLACE_MODULES));
    if (start_at !== undefined) {
      setChanged_value([
        start_at.split('T')[1].replace('Z', ''),
        end_at.split('T')[1].replace('Z', ''),
      ]);
    }
  }, [0]);

  // Toggle function in arrow icon
  const toggleChildren = (e, id, children) => {
    e.stopPropagation();

    const findParent = activeParent?.find((parentId) => parentId === id);
    let filterActive;
    if (findParent !== undefined) {
      filterActive = activeParent?.filter((parentId) => parentId !== id);
    }

    setActiveParent(
      findParent === undefined ? [...activeParent, id] : [...filterActive]
    );
  };

  // filter by category
  const filter_by_category = (id) => {
    const check = selectedCategories?.find((ct) => ct == id);
    if (check === undefined) {
      setSelectedCategories([...selectedCategories, id]);
      router.push({
        query: {
          ...router.query,
          category_id: [...selectedCategories, id].toString(''),
        },
      });
    } else {
      const filter = selectedCategories?.filter((ct) => ct != id);
      setSelectedCategories(filter);
      if (filter.length > 0) {
        router.push({
          query: {
            ...router.query,
            category_id: [...filter].toString(''),
          },
        });
      } else {
        const queries = { ...router.query };
        delete queries.category_id;
        router.push({
          query: { ...queries },
        });
      }
    }
  };

  // filter by attribute
  const filter_by_attribute_value = (id) => {
    const check = selectedAtValues?.find((at) => at == id);
    if (check === undefined) {
      setSelectedAtValues([...selectedAtValues, id]);
      router.push({
        query: {
          ...router.query,
          attribute_value_id: [...selectedAtValues, id].toString(''),
        },
      });
    } else {
      const filter = selectedAtValues?.filter((at) => at != id);
      setSelectedAtValues(filter);
      if (filter.length > 0) {
        router.push({
          query: {
            ...router.query,
            attribute_value_id: [...filter].toString(''),
          },
        });
      } else {
        const queries = { ...router.query };
        delete queries.attribute_value_id;
        router.push({
          query: { ...queries },
        });
      }
    }
  };

  // filter by country
  const filter_by_country = (value) => {
    if (country == value) {
      const queries = { ...router.query };
      delete queries.country;
      router.push({
        query: { ...queries },
      });
    } else {
      router.push({
        query: {
          ...router.query,
          country: value,
        },
      });
    }
  };
  // filter by City
  const filter_by_city = (value) => {
    if (city == value) {
      if (router.pathname.startsWith(`/l/c/[city]`)) {
        router.push({ pathname: '/l/c', query: {} });
      } else {
        const queries = { ...router.query };
        delete queries.city;
        router.push({
          query: { ...queries },
        });
      }
    } else {
      router.push({
        query: {
          ...router.query,
          city: value,
        },
      });
    }
  };
  // filter by price rang
  const filter_by_price_rang = (value) => {
    if (sort == value) {
      const queries = { ...router.query };
      delete queries.sort;
      router.push({
        query: { ...queries },
      });
    } else {
      router.push({
        query: {
          ...router.query,
          sort: value,
        },
      });
    }
  };

  // filter by rating
  const filter_by_rating = (rating_value) => {
    if (rating == rating_value) {
      const queries = { ...router.query };
      delete queries.rating;
      router.push({
        query: { ...queries },
      });
    } else {
      router.push({
        query: {
          ...router.query,
          rating: rating_value,
        },
      });
    }
  };

  // filter by date
  const filter_by_date = (sdate, edate) => {
    if (sdate == start_at) {
      const queries = { ...router.query };
      delete queries.start_at;
      delete queries.end_at;
      router.push({
        query: { ...queries },
      });
    } else {
      router.push({
        query: {
          ...router.query,
          start_at: sdate,
          end_at: edate,
        },
      });
    }
  };

  // Change time for date
  const change_time = () => {
    if (start_at == undefined) {
      router.push({
        query: {
          ...router.query,
          start_at: `${moment(new Date()).format('YYYY-MM-DD')}T${
            changed_value[0]
          }Z`,
          end_at: `${moment(new Date()).add(1, 'days').format('YYYY-MM-DD')}T${
            changed_value[1]
          }Z`,
        },
      });
    } else {
      router.push({
        query: {
          ...router.query,
          start_at: `${start_at.split('T')[0]}T${changed_value[0]}Z`,
          end_at: `${end_at.split('T')[0]}T${changed_value[1]}Z`,
        },
      });
    }
    setIs_value_changed(false);
  };

  const onSliderChange = (value) => {
    const value1 = Number(value[0]) < 10 ? `0${value[0]}` : value[0];
    const value2 = Number(value[1]) < 10 ? `0${value[0]}` : value[1];
    setChanged_value([`${value1}:00:00`, `${value2}:59:59`]);
    setIs_value_changed(true);
  };

  return (
    <div className="md:w-64 lg:w-72  max-h-[80VH]  overflow-y-auto md:sticky md:top-24">
      <div
        className={[
          'bg-gray-50 rounded-xl border border-gray-200 p-5 relative ',
        ].join(' ')}
      >
        <button
          className=" absolute top-5 ltr:right-5 rtl:left-5 text-base font font-medium text-primary cursor-pointer  z-10"
          onClick={() => {
            reset_filter
              ? (reset_filter(),
                setSelectedAtValues([]),
                setSelectedCategories([]))
              : router.push({
                  query: {
                    page: router.query?.page ? router.query?.page : 1,
                  },
                }),
              setSelectedAtValues([]),
              setSelectedCategories([]);
          }}
        >
          {Filter_text.filter.clear}
        </button>{' '}
        <div
          className={
            'w-full h-full   relative  grid grid-cols-1 ms:grid-cols-2 md:grid-cols-1 gap-5 '
          }
        >
          {/* Categories Part */}
          {!hidden_category && (
            <div>
              <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
                <span className=" cursor-pointer">
                  {Filter_text.filter.category}
                </span>
              </h4>
              {allCategories?.map((item, index, array) => {
                if (array.length > 4) {
                  if (index < 4) {
                    return (
                      <p
                        className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center  "
                        key={Math.random()}
                        // onClick={() => filter_by_category(item.id)}
                      >
                        <span className=" ">{item.name}</span>
                        <input
                          className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                          type="checkbox"
                          checked={
                            selectedCategories?.includes(`${item.id}`)
                              ? true
                              : false
                          }
                          onChange={() => filter_by_category(item.id)}
                        />
                        {/* {selectedCategories?.includes(`${item.id}`) && (
                          
                        )} */}
                      </p>
                    );
                  }
                  if (index === 4) {
                    return (
                      <button
                        className=" text-xs text-primary font-semibold py-[7px]  flex justify-between items-center  cursor-pointer w-full"
                        onClick={() =>
                          setIsSeeAllCategories(!isSeeAllCategories)
                        }
                      >
                        <span>
                          {Filter_text.filter.see_all}{' '}
                          {Filter_text.filter.category}
                        </span>
                        <span
                          className={
                            isSeeAllCategories
                              ? 'transform rotate-180 mr-2'
                              : 'mr-2'
                          }
                        >
                          {angle_down}
                        </span>
                      </button>
                    );
                  }
                } else {
                  return (
                    <p
                      className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center  "
                      key={Math.random()}
                      // onClick={() => filter_by_category(item.id)}
                    >
                      <span className=" ">{item.name}</span>
                      <input
                        className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                        type="checkbox"
                        checked={
                          selectedCategories?.includes(`${item.id}`)
                            ? true
                            : false
                        }
                        onChange={() => filter_by_category(item.id)}
                      />
                      {/* {selectedCategories?.includes(`${item.id}`) && (
                          
                        )} */}
                    </p>
                  );
                }
              })}

              {isSeeAllCategories &&
                allCategories?.map((item, index, array) => {
                  if (array.length > 4) {
                    if (index >= 4) {
                      return (
                        <p
                          className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center  "
                          key={Math.random()}
                          // onClick={() => filter_by_category(item.id)}
                        >
                          <span className=" ">{item.name}</span>
                          <input
                            className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                            type="checkbox"
                            checked={
                              selectedCategories?.includes(`${item.id}`)
                                ? true
                                : false
                            }
                            onChange={() => filter_by_category(item.id)}
                          />
                        </p>
                      );
                    }
                  }
                })}
            </div>
          )}

          {/* Countries */}
          {listingsCountries?.length > 0 && (
            <div>
              <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
                <span className=" cursor-pointer">
                  {Filter_text.filter.country}
                </span>
              </h4>
              {listingsCountries?.map((item, index, array) => {
                if (array.length > 4) {
                  if (index < 4) {
                    return (
                      <p
                        className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                        key={Math.random()}
                        onClick={() => filter_by_country(item)}
                      >
                        <span className=" ">{item}</span>
                        {country === item && (
                          <span className="mr-2">{check_icon}</span>
                        )}
                      </p>
                    );
                  }
                  if (index === 4) {
                    return (
                      <button
                        className=" text-xs text-primary font-semibold py-[7px]  flex justify-between items-center  cursor-pointer w-full"
                        onClick={() => setIsSeeAllCountries(!isSeeAllCountries)}
                      >
                        <span>
                          {Filter_text.filter.see_all}{' '}
                          {Filter_text.filter.country}
                        </span>
                        <span
                          className={
                            isSeeAllCountries
                              ? 'transform rotate-180 mr-2'
                              : 'mr-2'
                          }
                        >
                          {angle_down}
                        </span>
                      </button>
                    );
                  }
                } else {
                  return (
                    <p
                      className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                      key={Math.random()}
                      onClick={() => filter_by_country(item)}
                    >
                      <span className=" ">{item}</span>
                      {country === item && (
                        <span className="mr-2">{check_icon}</span>
                      )}
                    </p>
                  );
                }
              })}

              {isSeeAllCountries &&
                listingsCountries?.map((item, index, array) => {
                  if (array.length > 4) {
                    if (index > 4) {
                      return (
                        <p
                          className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                          key={Math.random()}
                          onClick={() => filter_by_country(item)}
                        >
                          <span className=" ">{item}</span>
                          {country === item && (
                            <span className="mr-2">{check_icon}</span>
                          )}
                        </p>
                      );
                    }
                  }
                })}
            </div>
          )}

          {/* City */}

          {listingsCountries?.length > 0 && (
            <div>
              <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
                <span className=" cursor-pointer">
                  {Filter_text.filter.city}
                </span>
              </h4>
              {listingsCities?.map((item, index, array) => {
                if (array.length > 4) {
                  if (index < 4) {
                    return (
                      <p
                        className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                        key={Math.random()}
                        onClick={() => filter_by_city(item)}
                      >
                        <span className=" ">{item}</span>
                        {city === item && (
                          <span className="mr-2">{check_icon}</span>
                        )}
                      </p>
                    );
                  }
                  if (index === 4) {
                    return (
                      <button
                        className=" text-xs text-primary font-semibold py-[7px]  flex justify-between items-center  cursor-pointer w-full"
                        onClick={() => setIsSeeAllCities(!isSeeAllCities)}
                      >
                        <span>
                          {Filter_text.filter.see_all}{' '}
                          {Filter_text.filter.see_all}
                        </span>
                        <span
                          className={
                            isSeeAllCities
                              ? 'transform rotate-180 mr-2'
                              : 'mr-2'
                          }
                        >
                          {angle_down}
                        </span>
                      </button>
                    );
                  }
                } else {
                  return (
                    <p
                      className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                      key={Math.random()}
                      onClick={() => filter_by_city(item)}
                    >
                      <span className=" ">{item}</span>
                      {city === item && (
                        <span className="mr-2">{check_icon}</span>
                      )}
                    </p>
                  );
                }
              })}

              {isSeeAllCities &&
                listingsCities?.map((item, index, array) => {
                  if (array.length > 4) {
                    if (index > 4) {
                      return (
                        <p
                          className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                          key={Math.random()}
                          onClick={() => filter_by_city(item)}
                        >
                          <span className=" ">{item}</span>
                          {city === item && (
                            <span className="mr-2">{check_icon}</span>
                          )}
                        </p>
                      );
                    }
                  }
                })}
            </div>
          )}
          {/* Sort */}
          {!hide_sort && (
            <div className=" mt-3">
              <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
                <span className=" cursor-pointer">
                  {Filter_text.filter.sort}
                </span>
              </h4>
              {priceRange?.map((item) => {
                return (
                  <p
                    className="  text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center cursor-pointer"
                    key={Math.random()}
                    onClick={() => filter_by_price_rang(item.value)}
                  >
                    <span className=" ">{Filter_text.filter[item.value]}</span>
                    {sort === item.value && (
                      <span className="mr-2">{check_icon}</span>
                    )}
                  </p>
                );
              })}
            </div>
          )}
          {/* Atttributes  */}
          <div className=" mt-3">
            {allAttributes?.map((item) => {
              return (
                item?.show_in_filter && (
                  <ul className="" key={Math.random()}>
                    <li
                      className="text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  cursor-pointer"
                      onClick={(e) => toggleChildren(e, item.id, item.values)}
                    >
                      <span>{item.name}</span>
                      {item?.values?.length > 0 ? (
                        <span
                          className={
                            activeParent.includes(item.id)
                              ? 'transform rotate-180'
                              : ''
                          }
                        >
                          {angle_down}
                        </span>
                      ) : (
                        <input
                          className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                          type="checkbox"
                          checked={
                            selectedAtValues?.includes(`${item.id}`)
                              ? true
                              : false
                          }
                          onChange={() => filter_by_attribute_value(item.id)}
                        />
                      )}
                    </li>
                    {item?.values?.length > 0 &&
                      item?.values?.map((vl) => {
                        return (
                          <li
                            className={
                              activeParent.includes(item.id)
                                ? 'text-[12px] pl-3 text-[#4F4F4F] font-semibold py-[7px]    flex justify-between items-center   transition duration-500  ease-in-out'
                                : 'hidden'
                            }
                            key={Math.random()}
                          >
                            <span className=" ">{vl.name}</span>
                            <input
                              className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                              type="checkbox"
                              checked={
                                selectedAtValues?.includes(`${vl.id}`)
                                  ? true
                                  : false
                              }
                              onChange={() => filter_by_attribute_value(vl.id)}
                            />
                          </li>
                        );
                      })}
                  </ul>
                )
              );
            })}
          </div>
          {/* Ratings Range */}
          {!hide_rating && (
            <div className=" mt-3  pb-[30px]">
              <h4 className=" text-sm text-[#121212] font-bold py-[7px]  flex justify-between items-center  ">
                <span className=" cursor-pointer">
                  {Filter_text.filter.rating}
                </span>
              </h4>

              <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
                <button
                  className=" cursor-pointer flex items-center"
                  onClick={() => filter_by_rating(5)}
                >
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                </button>

                {rating == 5 && <span className="mr-2">{check_icon}</span>}
              </p>
              <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
                <button
                  className=" cursor-pointer flex items-center"
                  onClick={() => filter_by_rating(4)}
                >
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                </button>

                {rating == 4 && <span className="mr-2"> {check_icon}</span>}
              </p>
              <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
                <button
                  className=" cursor-pointer flex items-center"
                  onClick={() => filter_by_rating(3)}
                >
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                </button>

                {rating == 3 && <span className="mr-2">{check_icon}</span>}
              </p>
              <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
                <button
                  className=" cursor-pointer flex items-center"
                  onClick={() => filter_by_rating(2)}
                >
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                </button>

                {rating == 2 && <span className="mr-2">{check_icon}</span>}
              </p>
              <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
                <button
                  className=" cursor-pointer flex items-center"
                  onClick={() => filter_by_rating(1)}
                >
                  <span>{star_icon}</span>
                </button>

                {rating == 1 && <span className="mr-2">{check_icon}</span>}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Listing_Filter2;
