/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import DescriptionPart from '../../components/ListingDetails/DescriptionPart/DescriptionPart';
import ImagePart2 from '../../components/ListingDetails/ImagePart/ImagePart2';
import MainBox from '../../components/ListingDetails/MainBox/MainBox';
import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  clearListingState,
  getListingQNA,
  getListingReviews,
  listingDetails,
  listingLike,
  listingSelector,
} from '../../store/feature/listingSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import AttributeDetails from '../../components/ListingDetails/AttributeDetails/AttributeDetails';
import Head from 'next/head';
import AddressBox from '../../components/ListingDetails/AddressBox/AddressBox';
import Variants from '../../components/ListingDetails/Variants/Variants';
import ProductButtons from '../../components/ListingDetails/ProductButtons/ProductButtons';
import StoreNameBox from '../../components/ListingDetails/StoreNameBox/StoreNameBox';
import RatingBox from '../../components/ListingDetails/RatingBox/RatingBox';
import ReviewBox2 from '../../components/ListingDetails/ReviewBox/ReviewBox2';
import ReactPaginate from 'react-paginate';
import MainBox2 from '../../components/ListingDetails/MainBox/MainBox2';
import ProductButtons2 from '../../components/ListingDetails/ProductButtons/ProductButtons2';
import RatingBox2 from '../../components/ListingDetails/RatingBox/RatingBox2';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import Edit_listing_button from '../../components/Shared/Buttons/Edit_listing_button';
import ShareButtons from '../../components/ListingDetails/ShareButtons/ShareButtons';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import ShippingMethods from '../../components/ListingDetails/ShippingMethods/ShippingMethods';
import RelatedListings from '../../components/ListingDetails/RelatedListing/RelatedListings';
import AccountListings from '../../components/ListingDetails/AccountListings/AccountListings';
import QNA_section from '../../components/ListingDetails/QNA/QNA_section';
import Cookies from 'js-cookie';
import NotFound from '../../components/Shared/Default UI/NotFound';
import { Listing_text } from '../../constant/Translation/Listing';
import { slug as SlugChecker } from 'github-slugger';
import ReportButton from '../../components/ListingDetails/report/ReportButton';
import SuccessPopUp from '../../components/Shared/PopUp/Success';

const ListingDetailsPageLayout = ({}) => {
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  const [selectedVariant, setSelectedVariant] = useState(null);

  const router = useRouter();

  const dispatch = useDispatch();
  const { auth_key, login } = useSelector(authSelector);

  const {
    isSuccess,
    listing_details,
    rating_data,
    errorMessage,
    isError,
    reviews,
    my_review,
    review_page,
    review_total_records,
    isShowSuccess,
    success_message,
  } = useSelector(listingSelector);

  // //
  // useEffect(() => {
  //   if (router?.query.slug && listing_details === null) {
  //     dispatch(
  //       listingDetails({
  //         ...router?.query,
  //       })
  //     );
  //   }
  // }, [router?.query.slug]);

  // review
  useEffect(() => {
    if (listing_details) {
      dispatch(
        getListingReviews({
          authKey: auth_key,
          params: {
            type: 'listings',
            id: listing_details.id,
            page: 1,
          },
        })
      );

      dispatch(
        getListingQNA({
          params: {
            reference_type: 'listings_qa',
            reference_id: listing_details.id,
            page: 1,
            per_page: 10,
            parent: 0,
          },
        })
      );
    }
  }, [listing_details]);

  // useEffect(() => {
  //   const handleRouteChange = (url, { shallow }) => {
  //     dispatch(clearListingDetails());
  //   };

  //   router.events.on('routeChangeStart', handleRouteChange);

  //   // If the component is unmounted, unsubscribe
  //   // from the event with the `off` method:
  //   // return () => {
  //   //   router.events.off('routeChangeStart', handleRouteChange);
  //   // };
  // }, [dispatch, router.events]);

  // Button Handle
  const like = (id, isLiked) => {
    if (login) {
      dispatch(
        listingLike({
          id: id,
          isLiked: isLiked,
          authKey: auth_key,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            listingDetails({
              ...router?.query,
            })
          );
        }
      });
    } else {
      router.push('/sign-in');
    }
  };

  const closePopUP = () => {
    dispatch(clearListingState());
    setShowError(false);
    setError_message('');
  };

  // seo title
  const seoTitle = (text) => {
    if (text) {
      const check = text.includes('{listing_title}');
      if (check) {
        return text.replace('{listing_title}', listing_details?.title);
      }
      return text;
    } else {
      return listing_details?.title;
    }
  };

  // Seo description
  const seoDescription = (text) => {
    if (text) {
      const check = text.includes('{listing_description}');
      if (check) {
        return text.replace(
          '{listing_description}',
          listing_details?.description
        );
      }
      return text;
    } else {
      return listing_details?.description;
    }
  };

  //
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    const totalpage = Math.ceil(review_total_records / 30);
    if (Number(review_total_records) > 30) {
      setPageCount(totalpage);
    }
  }, [review_total_records]);

  //
  const moreReviews = (data) => {
    dispatch(
      getListingReviews({
        authKey: auth_key,
        params: {
          type: 'listings',
          id: listing_details.id,
          page: Number(data.selected) + 1,
          per_page: 30,
        },
      })
    );
  };

  return (
    <div className="max-w-[1400px] mx-auto px-3  md:px-9  w-full flex flex-col mt-4 gap-page_default">
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearListingState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto ">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {/* Success popup */}
      {isShowSuccess && (
        <OutsideClickHandler
          onOutsideClick={() => {
            isShowSuccess && dispatch(clearListingState());
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <SuccessPopUp message={success_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) &&
        JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)) ==
          listing_details?.account_id && (
          <div className="flex items-center justify-end    ">
            <Edit_listing_button listing_details={listing_details} />
          </div>
        )}

      {listing_details !== undefined ? (
        <>
          {' '}
          <div className=" flex flex-col  lg:flex-row  justify-center gap-page_default     ">
            <div className=" flex flex-col gap-page_default  w-full lg:w-[64%]">
              <div>
                <ImagePart2
                  images={
                    selectedVariant
                      ? listing_details?.variants?.filter(
                          (vr) => vr.id == selectedVariant
                        )[0].images.length > 0
                        ? listing_details?.variants?.filter(
                            (vr) => vr.id == selectedVariant
                          )[0].images
                        : listing_details?.images
                      : listing_details?.images
                  }
                  listing_details={listing_details}
                  like={like}
                />
              </div>

              {TYPE_CONSTANT.LISTINGS_CONFIGS?.listing_qa_enabled != false && (
                <div className="   hidden  md:block" id={'qa'}>
                  <QNA_section listing_details={listing_details} />
                </div>
              )}

              {Object.keys(rating_data)?.length > 0 &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.review_and_rating != false && (
                  <div className=" hidden lg:block  ">
                    <RatingBox2 rating_data={rating_data} />
                  </div>
                )}
              {(my_review || reviews?.length > 0) &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.review_and_rating != false && (
                  <div className=" hidden     lg:flex flex-col gap-page_default  ">
                    <ReviewBox2
                      rating_data={rating_data}
                      reviews={reviews}
                      review_page={review_page}
                    />
                    <div className="  pb-12 flex justify-center ">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        }
                        onPageChange={(data) => moreReviews(data)}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        }
                        renderOnZeroPageCount={null}
                        containerClassName=""
                        className="relative z-0 inline-flex flex-wrap justify-center rounded-button shadow-sm -space-x-px "
                        pageClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center text-sm font-medium"
                        pageLinkClassName="px-4 py-2 border"
                        previousClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        nextClassName="relative inline-flex items-center px-2 py-2 r border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        breakLinkClassName="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                        activeLinkClassName="z-10 bg-primary  border-primary text-white relative inline-flex items-center px-4 py-2 border text-md font-semibold"
                        disabledLinkClassName=""
                        prevPageRel="2"
                        forcePage={review_page - 1}
                      />
                    </div>
                  </div>
                )}

              {/* <div className="  ">
							<RelatedEvents />
						</div> */}
            </div>
            <div className="w-full  flex flex-col gap-page_default  lg:w-[35%] ">
              <div>
                <MainBox2
                  listing_details={listing_details}
                  rating_data={rating_data}
                  like={like}
                />
              </div>
              {listing_details?.variants?.length > 0 && (
                <div className=" ">
                  <Variants
                    variants={listing_details.variants}
                    setSelectedVariant={setSelectedVariant}
                    selectedVariant={selectedVariant}
                  />
                </div>
              )}

              {TYPE_CONSTANT.LISTINGS_CONFIGS?.main_buttons != false && (
                <div className="     w-full       bg-transparent z-30 md: ">
                  <ProductButtons2
                    listing_details={listing_details}
                    selectedVariant={selectedVariant}
                  />
                </div>
              )}
              {/* {listing_details?.account && (
              <div className=" ">
                <StoreNameBox account={listing_details?.account} />
              </div>
            )} */}

              {listing_details?.location &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.location_widget != false &&
                Object.keys(listing_details?.location).length > 0 && (
                  <div className=" ">
                    <AddressBox location={listing_details?.location} />
                  </div>
                )}
              {TYPE_CONSTANT.LISTINGS_CONFIGS?.show_description != false &&
                listing_details?.description !== '' && (
                  <div className="  hidden lg:block">
                    <DescriptionPart
                      description={listing_details?.description}
                    />
                  </div>
                )}
              {listing_details?.attributes &&
                listing_details?.attributes.length > 0 &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.listing_attribute_section !=
                  false && (
                  <div className=" ">
                    <AttributeDetails
                      attributes={listing_details?.attributes}
                    />
                  </div>
                )}
              {TYPE_CONSTANT.LISTINGS_CONFIGS?.show_description != false &&
                listing_details?.description !== '' && (
                  <div className="  lg:hidden">
                    <DescriptionPart
                      description={listing_details?.description}
                    />
                  </div>
                )}

              {listing_details?.account?.shipping_methods?.length > 0 &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables !==
                  true &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.shipping_methods != false && (
                  <div className=" ">
                    <ShippingMethods listing_details={listing_details} />
                  </div>
                )}

              {TYPE_CONSTANT.LISTINGS_CONFIGS?.listing_share_section !=
                false && (
                <div className=" ">
                  <ShareButtons
                    listing_details={listing_details}
                    router={router}
                  />
                </div>
              )}

              {Object.keys(rating_data)?.length > 0 &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.review_and_rating != false && (
                  <div className="  lg:hidden  ">
                    <RatingBox2 rating_data={rating_data} />
                  </div>
                )}

              {TYPE_CONSTANT.LISTINGS_CONFIGS?.listing_qa_enabled != false && (
                <div className="    md:hidden" id={'qa'}>
                  <QNA_section listing_details={listing_details} />
                </div>
              )}
              {(my_review || reviews?.length > 0) &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.review_and_rating != false && (
                  <div className="  flex flex-col gap-page_default  lg:hidden ">
                    <ReviewBox2
                      rating_data={rating_data}
                      reviews={reviews}
                      review_page={review_page}
                    />
                    <div className="  pb-12 flex justify-center ">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        }
                        onPageChange={(data) => moreReviews(data)}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        }
                        renderOnZeroPageCount={null}
                        containerClassName=""
                        className="relative z-0 inline-flex flex-wrap justify-center rounded-button shadow-sm -space-x-px "
                        pageClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center text-sm font-medium"
                        pageLinkClassName="px-4 py-2 border"
                        previousClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        nextClassName="relative inline-flex items-center px-2 py-2 r border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        breakLinkClassName="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                        activeLinkClassName="z-10 bg-primary  border-primary text-white relative inline-flex items-center px-4 py-2 border text-md font-semibold"
                        disabledLinkClassName=""
                        prevPageRel="2"
                        forcePage={review_page - 1}
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="  flex flex-col gap-page_default   ">
            {TYPE_CONSTANT.LISTINGS_CONFIGS?.similar_listing_card != false && (
              <div className=" w-full    ">
                <RelatedListings listing_details={listing_details} />
              </div>
            )}
            {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 &&
              TYPE_CONSTANT.LISTINGS_CONFIGS?.account_listings != false && (
                <div className="  w-full    ">
                  <AccountListings
                    account_id={listing_details?.account_id}
                    account={listing_details?.account}
                  />
                </div>
              )}
          </div>
          {listing_details && (
            <div className=" flex items-center justify-between flex-wrap  ">
              <Breadcrumb
                lists={[
                  { name: 'Home', link: '/' },
                  { name: 'Categories', link: '/lc' },
                  {
                    name: listing_details?.categories[0]?.name,
                    isHide: listing_details?.categories[0]?.name ? false : true,
                    link: `/lc/${
                      listing_details?.categories[0]?.slug
                        ? listing_details?.categories[0]?.slug
                        : `${listing_details?.categories[0]?.id}-${SlugChecker(
                            listing_details?.categories[0]?.name
                          )}`
                    }`,
                  },
                  {
                    name: listing_details?.title,
                    link: '',
                  },
                ]}
              />
              <ReportButton listing_details={listing_details} />
            </div>
          )}
        </>
      ) : (
        <NotFound message={Listing_text.product.no_product_found} />
      )}
    </div>
  );
};

export default ListingDetailsPageLayout;
