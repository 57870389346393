/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import favorite from '../../../assets/Images/Home/favourite@3x.png';
import heartIcon from '../../../assets/Images/Home/heartIcon@3x.png';
import { changeDateFormat, getThumbnailImage } from '../Constant/Constant';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const ListingCard6 = ({ item, like }) => {
  return (
    <div className="w-full h-full  relative group">
      <Link
        href={
          item.slug
            ? `/l/${item.slug}`
            : `/l/${item.id}-${item.title.replace(
                /[ /?,#!@#$%^&*=]+/g,
                '-'
              )}?id=true`
        }
      >
        <a>
          <article className="flex flex-col h-full   rounded-md overflow-hidden  border border-[#eaeaea]   shadow-c-sm  hover:shadow-md ">
            <header className="mb-2">
              <div className=" aspect-w-3 aspect-h-2 block relative mb-3 overflow-hidden ">
                <img
                  className="absolute top-0 left-0  align-middle  rounded-t-image"
                  src={getThumbnailImage(item?.images[0])}
                  alt="image"
                />
              </div>

              {TYPE_CONSTANT.LISTINGS_CONFIGS?.price != false && (
                <div className="mb-3 px-3">
                  <ul className="flex flex-wrap text-xs font-medium -m-1">
                    <li className="m-1">
                      <a className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out   items-center flex-wrap gap-2">
                        <span className=" font-semibold">
                          {item.offer_price.formatted}
                        </span>

                        {item?.offer_percent > 0 && (
                          <>
                            <span className="t line-through ">
                              {item.list_price.formatted}
                            </span>
                            <span className=" ">{item?.offer_percent}%</span>
                          </>
                        )}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
              <h3 className=" px-3 text-xl font-bold leading-snug tracking-tight ">
                {item.title}
              </h3>
            </header>
            {item?.description && (
              <p className="px-3 pb-3 text-gray-600 flex-grow">
                {item?.description.length > 60
                  ? item?.description.substring(0, 60) + '...'
                  : item?.description}
              </p>
            )}
          </article>
        </a>
      </Link>
    </div>
  );
};

export default ListingCard6;
