import React from 'react';
import { useState } from 'react';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import { rating_icon_35 } from '../../../Shared/Constant/Icons/AllIcons';
import RatingBox from '../RatingBox/RatingBox';
import { useRouter } from 'next/router';
import {
  getStoreReviews,
  storeSelector,
} from '../../../../store/feature/storeSlice';
import { useDispatch, useSelector } from 'react-redux';
import ReviewBox from '../ReviewBox/ReviewBox';
import ReactPaginate from 'react-paginate';
import { useEffect } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import CustomLoading from '../../../Shared/Loading/CustomLoading';
import { Account_text } from '../../../../constant/Translation/Account';
import AttributeDetails from '../AttributeDetails/AttributeDetails';
import ReadingComposer from '../../../Shared/Composer/ReadingComposer';

const AboutSection = ({ account_details }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  // Review Parts
  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    store_reviews,
    my_store_review,
    review_page,
    review_total_records,
  } = useSelector(storeSelector);
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    const totalpage = Math.ceil(review_total_records / 30);
    if (Number(review_total_records) > 30) {
      setPageCount(totalpage);
    }
  }, [review_total_records]);

  //more review click
  const moreReviews = (data) => {
    dispatch(
      getStoreReviews({
        params: {
          type: 'accounts',
          id: account_details.id,
          page: Number(data.selected) + 1,
          per_page: 30,
        },
      })
    );
  };

  return (
    <>
      {/*About Section  */}
      <div className="py-5 space-y-4">
        {/* Desc */}
        {account_details?.description &&
          TYPE_CONSTANT.ACCOUNTS_CONFIGS.show_description != false && (
            <div className=" w-full  min-h-[200px] bg-[#FFFFFF] rounded-card    p-[24px] shadow-c-sm">
              <h2 className="heading-h2">
                {Account_text.storedetail.description}
              </h2>
              <div className="  mt-4">
                <ReadingComposer contents={account_details?.description} />
              </div>
            </div>
          )}

        <div
          className={[
            ' grid grid-cols-1  gap-5',
            account_details?.attributes &&
              account_details?.attributes.length > 0 &&
              account_details?.shipping_methods?.length > 0 &&
              TYPE_CONSTANT.ACCOUNTS_CONFIGS
                ?.enable_shipping_methods_preference &&
              'md:grid-cols-2',
          ].join(' ')}
        >
          {/* Attributes */}
          {account_details?.attributes &&
            account_details?.attributes.length > 0 && (
              <div className="">
                <AttributeDetails attributes={account_details?.attributes} />
              </div>
            )}
          {/* Shipping Method */}
          {account_details?.shipping_methods?.length > 0 &&
            TYPE_CONSTANT.ACCOUNTS_CONFIGS
              ?.enable_shipping_methods_preference && (
              <div className=" w-full  min-h-[100px] bg-[#FFFFFF] rounded-card    p-[24px] shadow-c-sm">
                <h2 className="heading-h2">
                  {Account_text.storedetail.shipping_method_title}
                </h2>

                <div className="  mt-4">
                  <div className=" mt-3 flex  items-center justify-start  gap-2 flex-wrap ">
                    {account_details?.shipping_methods.map(
                      (item, index, array) => {
                        return (
                          <div className="flex items-center gap-1">
                            <a className=" text-primary ">{item.name}</a>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>

        {/* ratting section */}
        <div className="flex items-cent gap-5 flex-col md:flex-row">
          <div className="bg-white rounded-card   w-full min-h-[66px]  p-4 shadow-c-sm">
            <h2 className="heading-h2">
              {Account_text.storedetail.rate_this_store}
            </h2>
            <p className="mt-2 description-paragraph">
              {Account_text.storedetail.tell_others_think}
            </p>
            <div className="mt-2">
              <p className="flex justify-center items-center gap-2  ">
                {rating_icon_35}
                {rating_icon_35}
                {rating_icon_35}
                {rating_icon_35}
                {rating_icon_35}
              </p>
            </div>
          </div>
          {account_details?.rating_data &&
            Object?.keys(account_details?.rating_data)?.length > 0 && (
              <RatingBox rating_data={account_details?.rating_data} />
            )}
        </div>

        {/* Review Section */}
        {store_reviews && (
          <div className=" ">
            <ReviewBox
              account_details={account_details}
              rating_data={account_details?.rating_data}
              reviews={store_reviews}
              review_page={review_page}
              my_store_review={my_store_review}
            />
            {!pageCount === 0 && (
              <div className="mt-6 flex justify-center ">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                  onPageChange={(data) => moreReviews(data)}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                  renderOnZeroPageCount={null}
                  containerClassName=""
                  className="relative z-0 inline-flex flex-wrap justify-center rounded-button shadow-sm -space-x-px "
                  pageClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center text-sm font-medium"
                  pageLinkClassName="px-4 py-2 border"
                  previousClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  nextClassName="relative inline-flex items-center px-2 py-2 r border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  breakLinkClassName="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                  activeLinkClassName="z-10 bg-primary  border-primary text-white relative inline-flex items-center px-4 py-2 border text-md font-semibold"
                  disabledLinkClassName=""
                  prevPageRel="2"
                  forcePage={review_page - 1}
                />
              </div>
            )}
          </div>
        )}

        {/* Map */}

        {account_details?.coordinates?.latitude && (
          <div className=" relative   min-h-[200px] bg-white rounded-card   w-full  p-4 shadow-c-sm space-y-3">
            <h2 className="heading-h2">
              {Account_text.storedetail.address_title}
            </h2>{' '}
            <p className=" description-paragraph">
              {account_details?.location?.formatted_address
                ? account_details?.location?.formatted_address
                : account_details?.location?.city
                ? `${account_details?.location?.city} ${account_details?.location?.country}`
                : account_details?.location?.country}
            </p>
            <LoadScript
              googleMapsApiKey={
                TYPE_CONSTANT.ADDRESS_CONFIGS?.google_map_api_key
              }
              loadingElement={CustomLoading}
            >
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '300px' }}
                center={{
                  lat: account_details?.coordinates?.latitude,
                  lng: account_details?.coordinates?.longitude,
                }}
                zoom={10}
              >
                <Marker
                  position={{
                    lat: account_details?.coordinates?.latitude,
                    lng: account_details?.coordinates?.longitude,
                  }}
                  clickable
                />
              </GoogleMap>
            </LoadScript>
          </div>
        )}
      </div>
    </>
  );
};

export default AboutSection;
