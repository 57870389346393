/* eslint-disable react/prop-types */
import React, { useEffect, useState, memo } from 'react';
import Image from 'next/image';
import tradly from 'tradly';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper/core';
// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

import axios from 'axios';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { bannersSelector } from '../../../store/feature/Home/bannerSlice';

const Banner2 = ({}) => {
  const [banners, setBanners] = useState(null);
  const router = useRouter();

  // Banner click
  const banner_click = (banner) => {
    if (banner?.type !== 'general') {
      if (banner.type == 'account' && banner.value) {
        router.push(`/a/${banner.value}?id=true`);
      } else if (banner.type == 'listing' && banner.value) {
        router.push(`/l/${banner.value}?id=true`);
      } else if (banner.type == 'external_link' && banner.value) {
        window.open(banner.value);
      }
    }
  };

  //
  const { promo_banners } = useSelector(bannersSelector);

  useEffect(() => {
    window.innerWidth > 850
      ? setBanners(
          promo_banners?.filter(
            (banner) =>
              banner.medium.includes('web') &&
              (banner.placement === '' || banner.placement === 'home')
          )
        )
      : setBanners(
          promo_banners?.filter(
            (banner) =>
              banner.medium.includes('app') &&
              (banner.placement === '' || banner.placement === 'home')
          )
        );
  }, [promo_banners]);

  return (
    <>
      <div className="     h-auto  mx-auto">
        <Swiper
          dir="rtl"
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
        >
          {banners?.map((banner, i) => {
            return (
              (banner.placement == '' || banner.placement == 'home') && (
                <SwiperSlide
                  key={i}
                  className={[
                    ' w-full flex flex-col justify-center items-center ',
                    banner.type !== 'general'
                      ? 'cursor-pointer'
                      : 'cursor-auto',
                    banners?.length > 1 && ' md:mb-10',
                  ].join(' ')}
                >
                  <div
                    className="  relative   "
                    onClick={() => banner_click(banner)}
                  >
                    <img
                      src={banner.image_path}
                      alt="Banner Image"
                      className="w-full object-contain rounded-image   "
                    />
                  </div>
                </SwiperSlide>
              )
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default memo(Banner2);
