import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { cartSelector } from '../../store/feature/cartSlice';
import { get_orders, orderSelector } from '../../store/feature/orderSlice';
import Header3 from '../../components/Header/Header3';
import OrdersItem from '../../components/PaymentCard/OrdersItem';
import PaymentCard from '../../components/PaymentCard/PaymentCard';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const PaymentPageLayout = () => {
  const dispatch = useDispatch();
  const { login, auth_key } = useSelector(authSelector);
  const { order_reference, cart, cart_details } = useSelector(cartSelector);
  const stripePromise = loadStripe(
    TYPE_CONSTANT.PAYMENT_CONFIGS?.stripe_api_publishable_key
  );
  useEffect(() => {
    if (login) {
      console.log('first');
      dispatch(
        get_orders({
          authKey: auth_key,
          bodyParam: {
            order_reference: order_reference,
            page: 1,
          },
        })
      );
    }
  }, [auth_key, dispatch, login, order_reference]);

  const { orders } = useSelector(orderSelector);

  return (
    <div className=" ">
      <Elements stripe={stripePromise}>
        <div className="min-h-[500px]  ">
          <PaymentCard />
        </div>
      </Elements>
    </div>
  );
};

export default PaymentPageLayout;
