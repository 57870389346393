/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import DescriptionPart from '../../components/ListingDetails/DescriptionPart/DescriptionPart';
import ImagePart from '../../components/ListingDetails/ImagePart/ImagePart';
import MainBox from '../../components/ListingDetails/MainBox/MainBox';
import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  clearListingState,
  getListingQNA,
  getListingReviews,
  listingDetails,
  listingLike,
  listingSelector,
} from '../../store/feature/listingSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import AttributeDetails from '../../components/ListingDetails/AttributeDetails/AttributeDetails';
import Head from 'next/head';
import AddressBox from '../../components/ListingDetails/AddressBox/AddressBox';
import Variants from '../../components/ListingDetails/Variants/Variants';
import ProductButtons from '../../components/ListingDetails/ProductButtons/ProductButtons';
import StoreNameBox from '../../components/ListingDetails/StoreNameBox/StoreNameBox';
import RatingBox from '../../components/ListingDetails/RatingBox/RatingBox';
import ReviewBox from '../../components/ListingDetails/ReviewBox/ReviewBox';
import ReactPaginate from 'react-paginate';
import RelatedListings from '../../components/ListingDetails/RelatedListing/RelatedListings';
import AccountListings from '../../components/ListingDetails/AccountListings/AccountListings';
import { check_login } from '../../components/../constant/check_auth';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import Edit_listing_button from '../../components/Shared/Buttons/Edit_listing_button';
import ShareButtons from '../../components/ListingDetails/ShareButtons/ShareButtons';
import Category from '../../components/ListingDetails/categories/Category';
import ShippingMethods from '../../components/ListingDetails/ShippingMethods/ShippingMethods';
import QNA_section from '../../components/ListingDetails/QNA/QNA_section';
import ImagePart3 from '../../components/ListingDetails/ImagePart/ImagePart3';
import ProductButtons3 from '../../components/ListingDetails/ProductButtons/ProductButtons3';

const ListingDetailsPageLayout = ({ slug, close_function }) => {
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  const [selectedVariant, setSelectedVariant] = useState(null);

  const router = useRouter();

  const dispatch = useDispatch();
  const { auth_key, login } = useSelector(authSelector);
  useEffect(() => {
    if (slug) {
      dispatch(
        listingDetails({
          slug,
          id: true,
        })
      );

      //updating state base on new listing fetching
      setSelectedVariant(null);
      setError_message('');
      setShowError(false);
    }
  }, [slug]);

  const {
    isSuccess,
    listing_details,
    rating_data,
    errorMessage,
    isError,
    reviews,
    my_review,
    review_page,
    review_total_records,
    qna,
  } = useSelector(listingSelector);

  useEffect(() => {
    // if (listing_details) {
    //   dispatch(
    //     getListingReviews({
    //       authKey: auth_key,
    //       params: {
    //         type: 'listings',
    //         id: listing_details.id,
    //         page: 1,
    //       },
    //     })
    //   );
    //   dispatch(
    //     getListingQNA({
    //       params: {
    //         reference_type: 'listings_qa',
    //         reference_id: listing_details.id,
    //         page: 1,
    //         per_page: 10,
    //         parent: 0,
    //       },
    //     })
    //   );
    // }
  }, [listing_details]);

  // Button Handle
  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id: id,
          isLiked: isLiked,
          authKey: auth_key,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            listingDetails({
              ...router?.query,
            })
          );
        }
      });
    }
  };

  const closePopUP = () => {
    dispatch(clearListingState());
    setShowError(false);
    setError_message('');
  };

  //
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    const totalpage = Math.ceil(review_total_records / 30);
    if (Number(review_total_records) > 30) {
      setPageCount(totalpage);
    }
  }, [review_total_records]);

  //more review click
  const moreReviews = (data) => {
    dispatch(
      getListingReviews({
        authKey: auth_key,
        params: {
          type: 'listings',
          id: listing_details.id,
          page: Number(data.selected) + 1,
          per_page: 30,
        },
      })
    );
  };

  // Hash anchro tag:
  React.useEffect(() => {
    const path = window.location.hash;
    if (path && path.includes('#') && listing_details) {
      setTimeout(() => {
        const id = path.replace('#', '');
        const el = window.document.getElementById(id);
        if (el) {
          const r = el.getBoundingClientRect();
          window.top.scroll({
            top: pageYOffset + r.top,
            behavior: 'smooth',
          });
        }
      }, 600);
    }
  }, [listing_details]);

  return (
    <>
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearListingState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      <div className=" w-full relative flex flex-col gap-page_default pb-6">
        <div>
          <ImagePart3
            images={
              selectedVariant
                ? listing_details?.variants?.filter(
                    (vr) => vr.id == selectedVariant
                  )[0]?.images?.length > 0
                  ? listing_details?.variants?.filter(
                      (vr) => vr.id == selectedVariant
                    )[0]?.images
                  : listing_details?.images
                : listing_details?.images
            }
          />
        </div>
        <div className="   ">
          <MainBox
            listing_details={listing_details}
            rating_data={rating_data}
            like={like}
          />
        </div>

        {TYPE_CONSTANT.LISTINGS_CONFIGS?.show_description != false &&
          listing_details?.description !== '' && (
            <div className="   ">
              <DescriptionPart description={listing_details?.description} />
            </div>
          )}
        {listing_details?.variants?.length > 0 && (
          <div className="   ">
            <Variants
              variants={listing_details.variants}
              setSelectedVariant={setSelectedVariant}
              selectedVariant={selectedVariant}
              listing_details={listing_details}
            />
          </div>
        )}

        {listing_details?.attributes &&
          listing_details?.attributes.length > 0 && (
            <div className="   ">
              <AttributeDetails attributes={listing_details?.attributes} />
            </div>
          )}

        {TYPE_CONSTANT.LISTINGS_CONFIGS?.listing_share_section != false && (
          <div className="   ">
            <ShareButtons listing_details={listing_details} router={router} />
          </div>
        )}
        {/* <div className="   ">
          <Category listing_details={listing_details} router={router} />
        </div> */}
        {rating_data &&
          Object?.keys(rating_data)?.length > 0 &&
          TYPE_CONSTANT.LISTINGS_CONFIGS?.review_and_rating != false && (
            <div className="   ">
              <RatingBox rating_data={rating_data} />
            </div>
          )}

        {TYPE_CONSTANT.LISTINGS_CONFIGS?.main_buttons != false && (
          <div className=" sticky   bottom-0 left-0 right-0   z-[60]           ">
            <ProductButtons3
              listing_details={listing_details}
              selectedVariant={selectedVariant}
              setError_message={setError_message}
              setShowError={setShowError}
              close_function={close_function}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ListingDetailsPageLayout;
