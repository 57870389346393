/* eslint-disable react/prop-types */
import { useRouter } from 'next/dist/client/router';
import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Address_text } from '../../../../constant/Translation/Address';
import { General_text } from '../../../../constant/Translation/General';
import { Orders_text } from '../../../../constant/Translation/OrdersText';
import { authSelector } from '../../../../store/feature/authSlice';
import {
  changeOrderStatus,
  get_order_details,
  store_orderSelector,
  store_orderSlice,
} from '../../../../store/feature/store_orderSlice';

import { changeDateFormat } from '../../../Shared/Constant/Constant';
import {
  changeStatus,
  currentOrderStatus,
  orderStatus,
} from '../../../Shared/Constant/Status';
import CustomLoading from '../../../Shared/Loading/CustomLoading';
import PricingItems from './PricingItems';

const OrderSummary = ({
  order_details,
  setShowError,
  setError_message,
  setShowSuccess,
  setSuccess_message,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const status_change = (e, order_details) => {
    if (e.target.value != false) {
      if (Number(e.target.value) !== 17) {
        dispatch(
          changeOrderStatus({
            id: order_details.id,
            sendData: {
              order: {
                status: Number(e.target.value),
              },
            },
          })
        ).then((res) => {
          if (!res.payload.code) {
            setShowSuccess(true);
            setSuccess_message(
              Orders_text.orderdetail.status_changed_successfully
            );
            dispatch(
              get_order_details({
                id: order_details.id,
                bodyParam: { account_id: router.query.account_id },
              })
            );
          }
        });
      } else {
        if (Object.keys(order_details?.pickup_address).length === 0) {
          setShowError(true);
          setError_message(
            Address_text.address.alert_message_add_pickup_address
          );
        } else {
          dispatch(
            changeOrderStatus({
              id: order_details.id,
              sendData: {
                order: {
                  status: Number(e.target.value),
                },
              },
            })
          ).then((res) => {
            if (!res.payload.code) {
              setShowSuccess(true);
              setSuccess_message(
                Orders_text.orderdetail.status_changed_successfully
              );
              dispatch(
                get_order_details({
                  id: order_details.id,
                  bodyParam: { account_id: router.query.account_id },
                })
              );
            }
          });
        }
      }
    }
  };
  const { isChangeStatusFetching } = useSelector(store_orderSelector);
  return (
    <div>
      {isChangeStatusFetching && <CustomLoading />}
      <div className="w-full h-min-[300px] bg-white  shadow-c-sm rounded-card   p-4   sm:p-7  border-opacity-40">
        <div className="flex justify-between items-center">
          <p className=" text-lg text-black font-semibold   ">
            {Orders_text.orderdetail.order_summary}
          </p>
          {(order_details?.legacy || order_details?.order_status == 6) && (
            <p className="     rounded-button text-primary font-semibold">
              {currentOrderStatus(order_details?.order_status)}
            </p>
          )}
        </div>
        <div>
          <div className=" flex justify-between items-center py-2  ">
            <p className=" text-sm text-black font-semibold  ">
              {Orders_text.orderdetail.order_created}
            </p>
            <p className=" text-sm text-black font-semibold   text-opacity-70">
              {changeDateFormat(order_details?.created_at, 'DD/MM/YYYY')}
            </p>
          </div>
          <div className=" flex justify-between items-center py-2  ">
            <p className=" text-sm text-black font-semibold  ">
              {Orders_text.orderdetail.order_time}
            </p>
            <p className=" text-sm text-black font-semibold   text-opacity-70">
              {changeDateFormat(order_details?.created_at, 'hh:mm A')}
            </p>
          </div>
          <PricingItems order_details={order_details} />
        </div>
      </div>
      {order_details?.legacy && order_details?.next_status.length > 0 && (
        <div className="w-full h-min-[100px] bg-white  shadow-c-sm rounded-button  p-4   sm:p-7   border-opacity-40 flex justify-between items-center mt-5 gap-3">
          <span className=" text-lg text-primary font-semibold  whitespace-nowrap">
            {Orders_text.orderdetail.change_order_status} :
          </span>

          <select
            className="
                    block
                      w-[50%] sm:max-w-[200px]
                    
                    rounded-button
                    
                    border-primary
                     text-primary
                  "
            onChange={(e) => status_change(e, order_details)}
          >
            <option value={false} selected hidden>
              {General_text.general.select}
            </option>
            {order_details?.next_status.length > 0 ? (
              order_details?.next_status.map((status) => {
                return (
                  <option key={Math.random()} value={status}>
                    {changeStatus(status)}
                  </option>
                );
              })
            ) : (
              <option disabled>{Orders_text.orderdetail.no_status}</option>
            )}
          </select>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
