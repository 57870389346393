import Link from 'next/link';
import React, { memo } from 'react';
import { Layer_text } from '../../../constant/Translation/LayerText';

import BlogsSkeleton from '../../Shared/skeletonUI/BlogsSkeleton';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../../constant/default_styles/HomeDefaultStyles';
import LayersType from '../../Layers/LayersType';

const Latest_pages = ({ pages, styles, extra_data, header_title }) => {
  return pages ? (
    pages?.length > 0 && (
      <div className=" " style={get_dynamic_styles(styles?.main_styles)}>
        <div
          className="w-full   "
          style={get_dynamic_styles_with_default_value(
            styles?.box_styles,
            HomeCardBoxDefaultStyles
          )}
        >
          {extra_data?.is_show_title !== false && (
            <div className="flex justify-between items-center  mb-4 ">
              <h2 className=" heading-h2">
                {header_title ? header_title : Layer_text.layer.latest_pages}
              </h2>
              <Link
                href={{
                  pathname: `/b`,
                }}
                passHref
              >
                <a className=" block text-base text-primary font-semibold cursor-pointer">
                  {Layer_text.layer.view_all}
                </a>
              </Link>
            </div>
          )}
          <LayersType layers={pages} layers_extra_data={extra_data} />
        </div>
      </div>
    )
  ) : (
    <div className=" " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className="w-full   "
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          HomeCardBoxDefaultStyles
        )}
      >
        <BlogsSkeleton />
      </div>
    </div>
  );
};

export default memo(Latest_pages);
