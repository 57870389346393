import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import Banner3 from '../../components/home/Banner/Banner3';
import Banner2 from '../../components/home/Banner/Bannner2';
import Categories from '../../components/home/Categories/Categories';
import LatestListings2 from '../../components/home/LatestListings/LatestListings2';
import StoresForFollow from '../../components/home/Stores/StoresForFollow';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Latest_blogs from '../../components/home/BlogsWidget/Latest_blogs';
import { useRouter } from 'next/router';
import { getCollections } from '../../store/feature/Collections/CollectionsSlice';
import {
  clearErrorState,
  getHomePageSections,
  homePageSelector,
} from '../../store/feature/Pages/Home/homePageSlice';
import Categories6 from '../../components/home/Categories/Categories6';
import LatestListings6 from '../../components/home/LatestListings/LatestListings6';
import StoresForFollow3 from '../../components/home/Stores/StoresForFollow3';
import Latest_blogs2 from '../../components/home/BlogsWidget/Latest_blogs2';
import TestFeature from '../../components/home/TestFeatured/TestFeature';
import WideSearchBox from '../../components/SearchBox/WideSearchBox';
import HomeHeading from '../../components/home/Heading/HomeHeading';
import FeaturedCard2 from '../../components/home/FeaturedCrad/FeaturedCard2';
import Latest_pages from '../../components/home/BlogsWidget/Latest_pages';
import Sub_footer2 from '../../components/Footer/Sub_footer2';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';

const HomePageLayout2 = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    dispatch(getHomePageSections({ sections_data: TYPE_CONSTANT.PAGES?.home }));
  }, []);

  //
  const { isFetching, isSuccess, isError, errors, home_page_sections } =
    useSelector(homePageSelector);

  //Decide UI

  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  // close pop up
  const closePopUP = () => {
    dispatch(clearErrorState());
    setShowError(false);
    setError_message('');
  };

  return (
    <div className=" flex flex-col gap-5 md:gap-8">
      {/* Error handler */}
      {(isError || showError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (isError || showError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearErrorState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="flex flex-col gap-2">
              {errors?.map((err) => {
                return <PopUp message={err?.msg} closePopUP={closePopUP} />;
              })}
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {TYPE_CONSTANT.PAGES?.home?.map((section = {}, index) => {
        const {
          order_number,
          block,
          block_data_key,
          fetch_by,
          data,
          isStatic,
        } = section;

        switch (block) {
          case 'category':
            return (
              <div>
                <Categories6
                  categories={
                    home_page_sections
                      ? home_page_sections.filter(
                          (item) => item.order_number === order_number
                        )[0].data
                      : null
                  }
                />
              </div>
            );

          case 'collection':
            if (fetch_by?.scope_type == 4) {
              return (
                <div>
                  <LatestListings6
                    product_collection={
                      home_page_sections
                        ? home_page_sections.filter(
                            (item) => item.order_number === order_number
                          )[0].data[0]
                        : null
                    }
                    section_details={section}
                  />
                </div>
              );
            } else if (fetch_by?.scope_type == 1) {
              return (
                <div>
                  <StoresForFollow3
                    account_collection={
                      home_page_sections
                        ? home_page_sections.filter(
                            (item) => item.order_number === order_number
                          )[0].data[0]
                        : null
                    }
                    section_details={section}
                  />
                </div>
              );
            } else {
              return <></>;
            }

          case 'layers':
            if (fetch_by?.type === 'page') {
              return (
                <div>
                  <Latest_pages
                    pages={
                      home_page_sections
                        ? home_page_sections.filter(
                            (item) => item.order_number === order_number
                          )[0].data
                        : null
                    }
                  />
                </div>
              );
            } else if (fetch_by?.type === 'blog') {
              return (
                <div>
                  <Latest_blogs2
                    blogs={
                      home_page_sections
                        ? home_page_sections.filter(
                            (item) => item.order_number === order_number
                          )[0].data
                        : null
                    }
                  />
                </div>
              );
            } else {
              return <></>;
            }

          case 'searchblock':
            return (
              <div>
                <WideSearchBox styles={data} />
              </div>
            );
          case 'heading':
            return (
              <div>
                <HomeHeading data={data} />
              </div>
            );
          case 'featured_card':
            return (
              data?.enabled && (
                <div>
                  <FeaturedCard2 data={data} />
                </div>
              )
            );
          case 'sub_footer':
            return (
              data?.enabled && (
                <div>
                  <Sub_footer2 data={data} />
                </div>
              )
            );
          case 'promo_banner':
            return (
              data?.enabled && (
                <div>
                  <Banner2 />
                </div>
              )
            );

          default:
            return <></>;
        }
      })}
    </div>
  );
};

export default HomePageLayout2;
