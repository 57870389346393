import Link from 'next/link';
import React from 'react';
import { Listing_text } from '../../../constant/Translation/Listing';
import { getThumbnailImage, get_relative_time } from '../Constant/Constant';

function ListingCard3({ product }) {
  return (
    <div
      className={`[&:nth-child(-n+12)]:-order-1 group   border-b border-gray-200`}
    >
      <div className={`px-4 py-6  rounded-card`}>
        <div className="sm:flex items-center space-y-3 sm:space-y-0 sm:space-x-5">
          <div className="shrink-0">
            {product.account?.images?.length > 0 ? (
              <img
                src={getThumbnailImage(product.account.images[0])}
                className="w-14 h-14 rounded-full overflow-hidden object-cover"
                width="56"
                height="56"
                alt={product.account.name}
              />
            ) : (
              <img
                src={getThumbnailImage(product.images[0])}
                className="w-14 h-14 rounded-full overflow-hidden object-cover"
                width="56"
                height="56"
                alt={product.title}
              />
            )}
          </div>
          <div className="grow lg:flex items-center justify-between space-y-5 lg:space-x-2 lg:space-y-0">
            <div>
              <div className="flex props-start space-x-2">
                {product?.account && (
                  <Link
                    href={
                      product?.account?.slug
                        ? `/a/${product?.account?.slug}`
                        : `/a/${
                            product?.account
                          }-${product?.account.name.replace(
                            /[ /?,#=]+/g,
                            '-'
                          )}?id=true`
                    }
                  >
                    <a className="text-sm text-gray-800 font-semibold mb-1">
                      {product?.account?.name}
                    </a>
                  </Link>
                )}
              </div>
              <div className="mb-2">
                <Link
                  href={
                    product.slug
                      ? `/l/${product.slug}`
                      : `/l/${product.id}-${product.title.replace(
                          /[ /?,#=]+/g,
                          '-'
                        )}?id=true`
                  }
                >
                  <a className="text-lg text-gray-800 font-bold">
                    {product?.title}
                  </a>
                </Link>
              </div>
              <div className="-m-1">
                <p
                  className={`text-xs text-gray-500 font-medium inline-flex px-2 py-0.5 hover:text-gray-600 rounded-md m-1 whitespace-nowrap transition duration-150 ease-in-out    items-center flex-wrap gap-1 `}
                >
                  <span className="font-semibold">
                    {product.offer_price.formatted}
                  </span>
                  {product?.offer_percent > 0 && (
                    <>
                      <span className="line-through ">
                        {product.list_price.formatted}
                      </span>
                      <span className="text-error  ">
                        {product?.offer_percent}%
                      </span>
                    </>
                  )}
                </p>

                {product?.location?.city && (
                  <a
                    className={`text-xs text-gray-500 font-medium inline-flex px-2 py-0.5 hover:text-gray-600 rounded-md m-1 whitespace-nowrap transition duration-150 ease-in-out  bg-gray-100`}
                  >
                    {product?.location?.city}
                  </a>
                )}
              </div>
            </div>
            <div className="min-w-[120px] flex items-center lg:justify-end space-x-3 lg:space-x-0">
              <div className="lg:hidden group-hover:lg:block">
                <Link
                  href={
                    product.slug
                      ? `/l/${product.slug}`
                      : `/l/${product.id}-${product.title.replace(
                          /[ /?,#=]+/g,
                          '-'
                        )}?id=true`
                  }
                >
                  <a className="btn-sm py-1.5 px-3 text-white  bg-secondary hover:bg-primary group shadow-sm flex items-center justify-center gap-1">
                    <span>{Listing_text.product.apply_now}</span>
                    <span className=" leading-none text-white group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out  ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-5  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </span>
                  </a>
                </Link>
              </div>
              <div className="group-hover:lg:hidden text-sm italic text-gray-500">
                {get_relative_time(product.created_at)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingCard3;
