import React from 'react';

const BannerSkeleton = () => {
  return (
    <div className="   animate-pulse  w-full  ">
      <div className="w-full  h-[200px] bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
    </div>
  );
};

export default BannerSkeleton;
