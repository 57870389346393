/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
import {
  changeDateFormat,
  getThumbnailImage,
} from '../../Shared/Constant/Constant';
import { configsSelector } from '../../../store/feature/configsSlice';
import { authSelector } from '../../../store/feature/authSlice';
import { listingLike } from '../../../store/feature/listingSlice';
import { homeCollections } from '../../../store/feature/homeSlice';

import favorite from '../../../assets/Images/Home/favourite@3x.png';
import heartIcon from '../../../assets/Images/Home/heartIcon@3x.png';
import { check_login } from '../../../constant/check_auth';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { Home_page_text } from '../../../constant/Translation/Home';
import { Listing_text } from '../../../constant/Translation/Listing';
import { express_icon } from '../../Shared/Constant/Icons/AllIcons';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const LatestListings2 = ({ products }) => {
  const { login, auth_key } = useSelector(authSelector);
  const { MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);
  // const { isSuccess } = useSelector(listingSelector);
  const dispatch = useDispatch();
  const router = useRouter();

  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id,
          isLiked,
          authKey: auth_key,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(homeCollections({ authKey: auth_key }));
        }
      });
    }
  };

  return (
    <div className="mt-5 md:mt-8">
      <div className="flex justify-between items-center  ">
        <h2 className=" heading-h2">{products.title}</h2>
        <Link
          href={{
            pathname: `/cp/${products.slug ? products.slug : products.id}`,
          }}
          passHref
        >
          <a className=" block text-base text-primary font-semibold cursor-pointer">
            {Home_page_text.home.view_all}
          </a>
        </Link>
      </div>
      <div className=" mt-4   ">
        <Swiper
          slidesPerView="auto"
          slidesPerGroup={1}
          spaceBetween={16}
          loop={false}
          navigation={false}
        >
          {products?.listings?.map((item) => (
            <SwiperSlide
              className="  overflow-hidden"
              key={Math.random() * 3000000}
              style={{
                width:
                  'calc(var(--bodyStylesMaxWidth)/var(--listingCardPerRow))',
                minHeight: '210px',
                paddingBottom: '5px',
              }}
            >
              <div className=" relative group">
                <Link
                  href={
                    item.slug
                      ? `/l/${item.slug}`
                      : `/l/${item.id}-${item.title.replace(
                          /[ /?,#=]+/g,
                          '-'
                        )}?id=true`
                  }
                >
                  <a
                    className=" block w-full min-h-[210px] bg-[var(--listingCardBackgroundColor)]   rounded-[var(--listingCardBorderRadius)]   overflow-hidden cursor-pointer shadow-listing_card relative transition ease-in-out duration-500 group-hover:bg-[var(--listingCardHoverBackgroundColor)] group-hover:shadow-listing_card_hover"
                    // onClick={() =>
                    //   router.push(
                    //     `/l/${item.id}-${item.title.replace(/[ /?,#=]+/g '-')}`
                    //   )
                    // }
                  >
                    {item.images.length > 0 && (
                      <div className="aspect-h-1 aspect-w-1">
                        <img
                          className={[
                            'w-full h-full  object-cover object-left-top rounded-[var(--listingCardImageBorderRadius)]  overflow-hidden',
                            TYPE_CONSTANT.STYLES_CONFIGS?.listing_card?.hover
                              ?.image_zoom_in &&
                              'transition ease-in-out delay-150  group-hover:-translate-y-2 group-hover:scale-110  duration-300',
                          ].join(' ')}
                          src={getThumbnailImage(item.images[0])}
                          alt={item.title}
                        />
                      </div>
                    )}

                    {/* {MARKETPLACE_MODULES === 2 && (
                    <p className=" mt-2 pl-2 text-[10px] leading-3 text-gray-900  font-medium">
                      {changeDateFormat(item.start_at, 'dddd Do MMM YYYY')}
                    </p>
                  )} */}
                    <div className="mt-2 ltr:pl-2 rtl:pr-2 mb-4">
                      <h3
                        className="   text-[length:var(--listingCardTitleTextSize)] text-[color:var(--listingCardTitleColor)] font-[--listingCardTitleTextFontWeight]
leading-[--listingCardTitleTextLineHeight] "
                      >
                        {item.title.length > 18
                          ? item.title.substring(0, 18) + '..'
                          : item.title}
                      </h3>
                      <p className=" mt-1 flex items-center flex-wrap gap-1.5">
                        <span
                          className="  text-[length:var(--listingCardPriceTextSize)] text-[color:var(--listingCardPriceColor)]  font-[--listingCardPriceTextFontWeight]
leading-[--listingCardPriceTextLineHeight]"
                        >
                          {item.offer_price.formatted}
                        </span>

                        {item?.offer_percent > 0 && (
                          <>
                            <span className="text-[14px] leading-4 font-normal text-gray-600  line-through ">
                              {item.list_price.formatted}
                            </span>
                            <span className="text-[14px] leading-4 font-normal text-error ">
                              {item?.offer_percent}%
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                    {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 && (
                      <div className=" ltr:pl-2 rtl:pr-2   mb-[7px] flex items-center">
                        {item?.account?.images.length > 0 ? (
                          <div className="h-5 w-5 rounded-full overflow-hidden  relative">
                            <img
                              src={getThumbnailImage(item?.account.images[0])}
                              className="h-full w-full object-cover object-left-top"
                              alt="account image"
                            />
                          </div>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        )}

                        <div className=" ltr:ml-1 rtl:mr-1">
                          <p className=" text-[10px]   leading-3 text-[#4F4F4F] font-medium mix-blend-normal">
                            {item?.account?.name.length > 20
                              ? item?.account?.name.substring(0, 18) + '..'
                              : item?.account?.name}
                          </p>
                          <p className="text-[10px] leading-3 text-[#4F4F4F] font-medium   opacity-50">
                            {`${item?.account?.total_followers}  ${Listing_text.product.followers}`}
                          </p>
                        </div>
                      </div>
                    )}
                  </a>
                </Link>
                <div
                  className=" h-[40px] w-[40px]   cursor-pointer  [z-100] absolute top-0  ltr:right-0 rtl:left-0"
                  onClick={() => like(item.id, item.liked)}
                >
                  {item.liked ? (
                    <Image
                      src={favorite}
                      alt="follow button"
                      layout="fill"
                      objectFit="cover"
                      require
                    />
                  ) : (
                    <Image
                      src={heartIcon}
                      alt="follow button"
                      layout="fill"
                      objectFit="cover"
                      require
                    />
                  )}
                </div>
                {TYPE_CONSTANT.SHIPPING_CONFIGS
                  ?.fulfillment_by_platform_enabled && (
                  <div
                    className="  absolute top-0  ltr:left-0 rtl:right-0"
                    onClick={() => like(item.id, item.liked)}
                  >
                    {item?.fulfilled_by_platform &&
                      TYPE_CONSTANT.SHIPPING_CONFIGS
                        .fulfilled_by_platform_icon_path && (
                        <img
                          className="w-auto h-auto max-w-[80px]"
                          src={
                            TYPE_CONSTANT.SHIPPING_CONFIGS
                              .fulfilled_by_platform_icon_path
                          }
                          alt="image"
                        />
                      )}
                    {!item?.fulfilled_by_platform &&
                      TYPE_CONSTANT.SHIPPING_CONFIGS
                        .fulfilled_by_seller_icon_path && (
                        <img
                          className="w-auto h-auto max-w-[80px]"
                          alt="image"
                          src={
                            TYPE_CONSTANT.SHIPPING_CONFIGS
                              .fulfilled_by_seller_icon_path
                          }
                        />
                      )}
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default LatestListings2;
