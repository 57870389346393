import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import {
  addToCart,
  cartList,
  cartSelector,
  clearCartState,
} from '../../../store/feature/cartSlice';
import { authSelector } from '../../../store/feature/authSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../Shared/PopUp/PopUp';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import { listingDetails } from '../../../store/feature/listingSlice';
import ModalBasic from '../../Shared/Modal/ModalBesic';
import axios from 'axios';
import { General_text } from '../../../constant/Translation/General';
import { Listing_text } from '../../../constant/Translation/Listing';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import PopupUI from '../../Shared/PopUp/UI/PopupUI';

const ProductButtons4 = ({
  listing_details,
  selectedVariant,
  setError_message,
  setShowError,
  close_function,
}) => {
  const { login, auth_key } = useSelector(authSelector);
  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(cartSelector);
  const dispatch = useDispatch();
  const router = useRouter();
  const [isBuyNow, setIsBuyNow] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const add_to_Cart = (_isBuyNow) => {
    if (
      TYPE_CONSTANT.LISTINGS_CONFIGS?.enable_stock &&
      listing_details?.stock == 0
    ) {
      setShowError(true);
      setError_message(Listing_text.product.out_of_stock);
      return false;
    }

    if (_isBuyNow) {
      const cartData = {
        cart: {
          listing_id: listing_details?.id,
          quantity: quantity,
        },
      };
      if (selectedVariant !== null) {
        cartData.cart['variant_id'] = selectedVariant;
      }
      dispatch(addToCart({ authKey: auth_key, data: cartData })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'buynow',
            data: {
              name: listing_details?.title,
              slug: listing_details?.slug,
              price: listing_details?.offer_price.amount,
              stock: listing_details?.stock,
              category: {
                id: listing_details?.categories[0]?.id,
                name: listing_details?.categories[0]?.name,
              },
              image: listing_details?.images,
              url: window.location.href,
            },
          });
          setIsBuyNow(false);
          router.push('/checkout');
        } else {
          if (res.payload.code === 480) {
            setForm_open(true);
          } else {
            setShowError(true);
            setError_message(res.payload.message);
          }
        }
      });
    } else {
      const cartData = {
        cart: {
          listing_id: listing_details?.id,
          quantity: quantity,
        },
      };
      if (selectedVariant !== null) {
        cartData.cart['variant_id'] = selectedVariant;
      }
      dispatch(addToCart({ authKey: auth_key, data: cartData })).then((res) => {
        if (!res.payload.code) {
          dispatch(cartList({}));
          push_data_layer({
            event: 'add_cart',
            data: {
              name: listing_details?.title,
              slug: listing_details?.slug,
              price: listing_details?.offer_price.amount,
              stock: listing_details?.stock,
              category: {
                id: listing_details?.categories[0]?.id,
                name: listing_details?.categories[0]?.name,
              },
              image: listing_details?.images,
              url: window.location.href,
            },
          });
          setIsBuyNow(false);
          close_function();
        } else {
          if (res.payload.code === 480) {
            setForm_open(true);
          } else {
            setShowError(true);
            setError_message(res.payload.message);
          }
        }
      });
    }
  };
  const closePopUP = () => {
    setShowError(false);
    setError_message('');
    dispatch(clearCartState());
  };

  const [form_open, setForm_open] = useState(false);
  const clear_cart = () => {
    axios
      .delete('/api/cart/delete_all_cart')
      .then((res) => {
        setForm_open(false);
        add_to_Cart(isBuyNow);
      })
      .catch((error) => {
        setForm_open(false);
      });
  };

  return (
    <>
      {isFetching && <CustomLoading />}
      {listing_details ? (
        <div className=" px-8  w-full  flex justify-between items-center">
          <div className="  min-w-[100px] h-[32px] border border-primary rounded-button  flex justify-between items-center ">
            <button
              onClick={() => setQuantity(quantity > 1 && quantity - 1)}
              className=" flex-none w-[32px] h-[32px] bg-primary  ltr:rounded-l-button rtl:rounded-r-button flex justify-center items-center text-xl leading-6 font-medium text-white"
            >
              -
            </button>
            <span className=" flex-grow w-full h-full text-sm flex justify-center items-center    leading-4 font-medium text-[#4A4A4A]  ">
              {quantity}
            </span>
            <button
              onClick={() => setQuantity(quantity + 1)}
              className=" flex-none w-[32px] h-[32px] bg-primary  ltr:rounded-r-button rtl:rounded-l-button flex justify-center items-center text-xl leading-6 font-medium  text-white "
            >
              +
            </button>
          </div>
          <button
            className=" btn-primary-fill px-8 py-2 text-base font-semibold"
            id="buy_now"
            onClick={() => {
              setIsBuyNow(false);
              login
                ? listing_details?.in_cart
                  ? router.push('/checkout')
                  : listing_details?.variants.length > 0
                  ? selectedVariant === null
                    ? (setShowError(true),
                      setError_message(Listing_text.product.variant_validation))
                    : add_to_Cart(false)
                  : add_to_Cart(false)
                : router.push(`/sign-in?to=${router.asPath}`);
            }}
            type="button"
          >
            {listing_details?.in_cart
              ? Listing_text.product.go_to_cart
              : Listing_text.product.add_to_cart}
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-2  gap-5 md:gap-10">
          <div className=" rounded-button h-[40px] bg-[#3B3269] bg-opacity-[10%] animate-pulse"></div>
          <div className="  rounded-button h-[40px] bg-[#3B3269] bg-opacity-[10%] animate-pulse"></div>
        </div>
      )}

      <ModalBasic
        title="Multi Seller"
        id="multi-seller-serror"
        modalOpen={form_open}
        setModalOpen={setForm_open}
        closeByOutsideClick={true}
      >
        <section className="min-h-[100px] ">
          <PopupUI
            left_button={{
              title: General_text.general.cancel,
              click: (e) => {
                e.stopPropagation();
                setForm_open(false);
              },
            }}
            right_button={{
              title: Listing_text.product.clear_cart,
              click: (e) => {
                clear_cart();
              },
            }}
          >
            <div className="flex flex-col  px-10   py-8  borderer-b border-gray-400">
              <p className="text-base text-center mb-3 ">
                {Listing_text.product.clear_cart_info}
              </p>
            </div>
          </PopupUI>
        </section>
      </ModalBasic>
    </>
  );
};

export default ProductButtons4;
