/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import banner from '../../../assets/Images/store/banner.png';
import { useRouter } from 'next/dist/client/router';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import axios from 'axios';
import { check_login } from '../../../constant/check_auth';
import Image from 'next/image';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import Cookies from 'js-cookie';
import ErrorPopup from '../../Shared/PopUp/ErrorPopup';
import Link from 'next/link';
import { Following_text } from '../../../constant/Translation/Following';
import { Account_text } from '../../../constant/Translation/Account';
import { cart_icon, user_plus } from '../../Shared/Constant/Icons/AllIcons';

const AccountProfile = ({
  account_details,
  setAccount_details,
  setIsDataLoading,
}) => {
  const router = useRouter();

  //
  const main_heder_height =
    document.getElementById('template-main-header')?.offsetHeight ?? 0;
  const margin_top_count = main_heder_height > 0 ? main_heder_height : 105;
  // set draft  value:
  const is_draft = router?.query?.draft
    ? true
    : false || Cookies.get('is_draft') === 'true'
    ? true
    : false;

  // =>>>>>Error state
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // <<<<<<<<==

  const { login, auth_key } = useSelector(authSelector);

  const follow = (id, isFollow) => {
    if (check_login(router)) {
      setIsDataLoading(true);

      axios
        .post('/api/a/follow_account', { id, isFollow })
        .then((res) => {
          const path = router.query?.id
            ? `/api/a/${router.query.slug}?id=${router.query?.id}`
            : `/api/a/${router.query.slug}`;
          axios
            .get(path)
            .then((res) => {
              setAccount_details(res.data.account);
              setIsDataLoading(false);
            })
            .catch((error) => {
              setIsDataLoading(false);
              setShowError(true);
              setErrorMessage(error.response?.data?.message);
            });
        })
        .catch((error) => {
          setIsDataLoading(false);
          setShowError(true);
          setErrorMessage(error.response?.data?.message);
        });
    }
  };

  const block_account = (id, isBlocked) => {
    if (check_login(router)) {
      setIsDataLoading(true);

      axios
        .post('/api/a/block', { id, isBlocked })
        .then((res) => {
          router.push('/');
        })
        .catch((error) => {
          setIsDataLoading(false);
          setShowError(true);
          setErrorMessage(error.response?.data?.message);
        });
    }
  };

  return (
    <div>
      {/* Error Component>>>>> */}
      <ErrorPopup
        showError={showError}
        setShowError={setShowError}
        message={errorMessage}
        functions={() => console.log('error>>>>', errorMessage, '<<<<')}
      />
      {/* <<<<Error Component */}

      <div
        className=" hidden md:block  absolute w-[100%] h-[200px] right-0 top-0 "
        style={{
          marginTop: `${margin_top_count}px`,
        }}
      >
        {is_draft ? (
          TYPE_CONSTANT.DRAFT_STYLES?.styles?.accounts?.background_image ? (
            <img
              className="h-full w-full object-cover object-left-top"
              src={
                TYPE_CONSTANT.DRAFT_STYLES?.styles?.accounts?.background_image
              }
              alt="banner"
            />
          ) : (
            <Image layout="fill" objectFit="cover" src={banner} />
          )
        ) : TYPE_CONSTANT.ACCOUNTS_CONFIGS?.background_image ? (
          <img
            className="h-full w-full object-cover object-left-top"
            src={TYPE_CONSTANT.ACCOUNTS_CONFIGS?.background_image}
            alt="banner"
          />
        ) : (
          <Image src={banner} layout="fill" objectFit="cover" />
        )}
      </div>

      <div className="relative  w-full  min-h-[126px] px-[10px] sm:px-[40px] py-[20px] bg-white rounded-card shadow-c-xsm  md:mt-[120px]">
        <div className="  flex   flex-wrap justify-between items-center  ">
          <div className=" flex items-start md:items-center justify-start gap-3 md:gap-6">
            <div className=" w-[70px] h-[70px] rounded-full overflow-hidden  relative   ">
              {account_details?.images.length > 0 ? (
                <img
                  src={account_details?.images[0]}
                  className="w-[70px] h-[70px] rounded-full  object-cover  object-left-top  "
                  alt="banner"
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-[70px] w-[70px] rounded-full mr-6 text-gray-600 border p-5 border-text-gray-600 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              )}
            </div>
            <div className=" flex flex-col items-start">
              <h1 className=" title-h1">{account_details?.name}</h1>
              <p className=" mt-1.5  description-paragraph">
                @{account_details?.user?.first_name}
              </p>
              <p className=" mt-3 description-paragraph  flex flex-wrap  gap-x-10 gap-y-3">
                {account_details?.location?.city && (
                  <Link
                    href={{
                      pathname: `/l`,
                      query: { city: account_details?.location?.city },
                    }}
                    passHref
                  >
                    <a
                      rel="noreferrer"
                      className="flex  items-center gap-1 group  group-hover:text-primary"
                    >
                      <span className="text-[#6e686e]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      </span>
                      <span className="text-[#6e686e]">
                        {account_details?.location?.city}
                      </span>
                    </a>
                  </Link>
                )}

                {Number(account_details?.total_followers) > 0 && (
                  <div className="flex items-center justify-start gap-1.5">
                    <span className="text-[#6e686e]">{user_plus}</span>
                    <span className="text-[#6e686e]">
                      {Number(account_details?.total_followers) > 1
                        ? Following_text.following.total_followers
                        : Following_text.following.total_follower}
                      :{' '}
                      <span className="text-primary">
                        {account_details?.total_followers}
                      </span>
                    </span>
                  </div>
                )}
                {Number(account_details?.total_listings) > 0 && (
                  <div className="flex items-center justify-start gap-1.5">
                    <span className="text-[#6e686e]">{cart_icon}</span>
                    <p className="text-[#6e686e]">
                      {Number(account_details?.total_listings) > 1
                        ? Following_text.following.total_listings
                        : Following_text.following.total_listing}
                      :{' '}
                      <span className="text-primary">
                        {account_details?.total_listings}
                      </span>
                    </p>
                  </div>
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="mx-auto md:mx-0  mt-4 md:mt-0 flex justify-center   gap-3 flex-wrap ">
              <button
                className={[
                  ' min-w-[145px]  ',
                  account_details.following
                    ? 'btn-primary-fill '
                    : 'btn-primary-outline',
                ].join(' ')}
                onClick={() =>
                  follow(account_details?.id, account_details?.following)
                }
              >
                {account_details?.following
                  ? Account_text.storelist.following
                  : Account_text.storelist.follow}
              </button>
              {!TYPE_CONSTANT.ACCOUNTS_CONFIGS.account_share_button_disable &&
                navigator.share && (
                  <button
                    className="btn-primary-outline"
                    onClick={() => {
                      navigator
                        .share({
                          title: `${account_details?.name}`,
                          url: `${window.location.href}`,
                        })
                        .then(() => {
                          // alert('Thanks for sharing!');
                        })
                        .catch();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6  "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                      />
                    </svg>
                  </button>
                )}
              {!TYPE_CONSTANT.ACCOUNTS_CONFIGS.block_button_disable && (
                <button
                  className="px-3 py-2 btn-red-outline"
                  onClick={() => block_account(account_details?.id, false)}
                >
                  {Account_text.storedetail.block}
                </button>
              )}
            </div>
          </div>
        </div>

        {!account_details?.active && (
          <div className="flex items-start mt-2 text-warning">
            <svg
              className="flex-none fill-current h-4 w-4 text-warning mr-4 mt-0.5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>

            <div className="flex-grow">
              <p className="text-sm">
                {Account_text.storedetail.store_currently_unavailable}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountProfile;
