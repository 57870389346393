import React from 'react';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';

const ShipmentDetails = ({ cart, cart_details, shipments }) => {
  return shipments ? (
    <div className="flex flex-col gap-6 ">
      {shipments?.length > 0 &&
        shipments.map((shipment, index) => {
          const { cart_details } = shipment;
          return (
            <div className="bg-[#FEFEFE] rounded-card  py-6   px-4 md:px-9 ">
              <p className="text-primary text-xl leading-6 font-medium ">
                {Checkout_text.cart.shipment_header_title} {index + 1}
              </p>
              <p className="text-sm font-semibold mt-3 ">
                {shipment?.fulfilled_by_platform
                  ? Checkout_text.cart.items_fullfilled_by_platform
                  : dynamic_translation_set(
                      Checkout_text.cart.items_fullfilled_by_account,
                      cart_details[0]?.listing?.account?.name
                    )}
              </p>

              <div className="mt-3 pb-5 border-b-2 border-gray-300  ">
                {cart_details.map((cartItem) => {
                  return cartItem.variant ? (
                    <div
                      className=" w-full    rounded-card    flex items-start  mb-4"
                      key={cartItem.id}
                    >
                      <div className=" flex-none h-full  relative   ltr:mr-3 rtl:ml-3 mt-1.5 ">
                        {(cartItem.listing?.images?.length > 0 ||
                          cartItem?.variant.images?.length > 0) && (
                          <img
                            src={
                              cartItem?.variant.images?.length > 0
                                ? cartItem?.variant.images[0]
                                : getThumbnailImage(cartItem.listing.images[0])
                            }
                            className="w-[60px] h-[60px]  rounded-image  object-cover"
                            alt="Order Items"
                          />
                        )}
                      </div>
                      <div className="flex-grow">
                        <p className=" text-base text-black font-semibold mt-[2px]">
                          {cartItem?.variant?.title
                            ? cartItem?.variant?.title
                            : cartItem.listing.title}
                        </p>

                        <div className="flex flex-wrap items-center justify-start gap-2">
                          {cartItem?.variant?.variant_values?.map(
                            (vr_value, index) => {
                              return (
                                <p
                                  key={vr_value.variant_type.id}
                                  className=" mt-[8px] text-default_gray text-xs font-medium flex flex-wrap items-center"
                                >
                                  {vr_value.variant_type.name}:
                                  {vr_value.variant_type_value.name}{' '}
                                  {index !== 0 && ','}
                                </p>
                              );
                            }
                          )}
                        </div>

                        <p className=" mt-[8px] text-default_gray text-xs font-medium flex flex-wrap items-center">
                          {Checkout_text.cart.quantity}: {cartItem.quantity}
                        </p>
                        <p className=" mt-[11px] text-primary text-xs font-bold flex flex-wrap items-center    ">
                          {cartItem?.quantity_total_offer_price.formatted}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      className=" w-full    rounded-card    flex items-start  mb-4"
                      key={cartItem.id}
                    >
                      <div className=" flex-none h-full  relative   ltr:mr-3 rtl:ml-3  mt-1.5">
                        {cartItem.listing?.images?.length > 0 && (
                          <img
                            src={getThumbnailImage(cartItem.listing.images[0])}
                            className="w-[60px] h-[60px]  rounded-image  object-cover"
                            alt="Order Items"
                          />
                        )}
                      </div>
                      <div className="flex-grow">
                        <p className=" text-base text-black font-semibold mt-[2px]">
                          {cartItem.listing.title}
                        </p>

                        <p className=" mt-[8px] text-default_gray text-xs font-medium flex flex-wrap items-center">
                          {Checkout_text.cart.quantity}: {cartItem.quantity}
                        </p>
                        <p className=" mt-[11px] text-primary text-xs font-bold flex flex-wrap items-center    ">
                          {cartItem?.quantity_total_offer_price.formatted}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className={[
                  ' flex justify-between items-center mb-2 text-base leading-6 font-semibold text-black pt-2',
                ].join(' ')}
              >
                <span>{Checkout_text.cart.shipping_charge}</span>

                <span className="text-base leading-6 font-semibold text-black">
                  {shipment.shipping_charge?.formatted}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  ) : (
    <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetails;
