/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/dist/client/router';
import { useDispatch, useSelector } from 'react-redux';

import ListingCard from '../../Shared/Cards/ListingCard';
import { configsSelector } from '../../../store/feature/configsSlice';
import { authSelector } from '../../../store/feature/authSlice';
import { listingLike } from '../../../store/feature/search';
import tradly from 'tradly';
import axios from 'axios';
import { check_login } from '../../../constant/check_auth';
import { accountListings } from '../../../store/feature/storeSlice';
import Edit_listing_button from '../../Shared/Buttons/Edit_listing_button';
import DynamicListingCard from '../../Shared/Cards/DynamicListingCard';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { responsive_design_styles } from '../../../utils/ResponsiveDesign';
import { useScreenSize } from '../../../hooks/useScreenSize';

const AccountListingsItem = ({
  Products,
  account_details,
  setIsDataLoading,
}) => {
  const { login, auth_key } = useSelector(authSelector);
  const { MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);
  // const { isSuccess } = useSelector(listingSelector);
  const dispatch = useDispatch();
  const router = useRouter();
  const { width } = useScreenSize();

  // like listing
  const like = (id, isLiked) => {
    if (check_login(router)) {
      setIsDataLoading(true);
      dispatch(
        listingLike({
          id,
          isLiked,
          authKey: auth_key,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            accountListings({
              prams: {
                ...router.query,
                account_id: account_details.id,
                status: 2,
              },
            })
          ).then((res) => {
            if (!res.payload.code) {
              setIsDataLoading(false);
            } else {
              setIsDataLoading(false);
            }
          });
        } else {
          setIsDataLoading(false);
        }
      });
    }
  };

  //
  const listing_card_styles = TYPE_CONSTANT.STYLES_CONFIGS?.listing_card;
  const dynamic_card = TYPE_CONSTANT.STYLES_CONFIGS?.dynamic_listing_card;
  const list_styles = responsive_design_styles({
    width,
    parent_group: dynamic_card?.styles,
    child_group_name: 'list_styles',
  });

  return (
    <div
      className={
        listing_card_styles?.card_type?.type !== 'custom_card'
          ? 'grid  grid-cols-1   xxs:grid-cols-[repeat(auto-fill,minmax(calc(var(--listingCardMinWidth)*var(--listingCardPerRow)/var(--listingCardPerRow)),1fr))] gap-5 justify-center'
          : ' '
      }
      style={
        listing_card_styles?.card_type?.type == 'custom_card'
          ? {
              display: 'grid',
              gridTemplateColumns: `repeat(${list_styles?.gridTemplateColumns}, minmax(0, 1fr))`,
              gap: list_styles?.gap,
            }
          : {}
      }
    >
      {Products?.map((item) => (
        <div key={Math.random()} className=" relative">
          <DynamicListingCard
            product={item}
            like={like}
            MARKETPLACE_MODULES={MARKETPLACE_MODULES}
          />

          <div className="absolute top-0 right-0">
            <Edit_listing_button listing_details={item} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccountListingsItem;
