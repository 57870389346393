export var Home_page_text = {
  home: {
    categories: 'Categories',

    Home: 'Home',
    chats: 'Chat',
    follow: 'Follow',
    invite_message: 'Invite your friends to buy and sell Listing on {value}',
    invite_title: 'Invite friends to {value}',
    more: 'More',
    social_feed: 'Social Feeds',
    view_all: 'View All',
    sell: 'Sell',
    title: 'TradlySocial',
    search: 'Search',
    wish_list: 'Wish List',
    my_orders: 'My Orders',
  },
};
