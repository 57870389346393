/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import OutsideClickHandler from 'react-outside-click-handler';
import Modal from '../../../Shared/Modal/Modal';
import { get_order_details } from '../../../../store/feature/store_orderSlice';
import { useRouter } from 'next/dist/client/router';
import CustomLoading from '../../../Shared/Loading/CustomLoading';
import axios from 'axios';
import { Orders_text } from '../../../../constant/Translation/OrdersText';
import TrackingForm from './TrackingForm/TrackingForm';
import { useDispatch } from 'react-redux';

const OrderTracking2 = ({ order_details }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showTrackingShipmentForm, setShowTrackingShipmentForm] =
    useState(false);
  const { register, handleSubmit, setValue } = useForm();

  // account_shipment
  let account_shipment = order_details?.shipments?.filter(
    (shipment) => shipment?.account?.id == router.query.account_id
  )[0];

  // Submit tracking details
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post('/api/shipments/update_shipment', {
        order_id: order_details.id,
        shipment_id: account_shipment.id,
        data: {
          operation: 'update_tracking',
          shipment: { tracking: data },
        },
      })
      .then((res) => {
        dispatch(
          get_order_details({
            id: order_details.id,
            bodyParam: { account_id: router.query.account_id },
          })
        );
        setShowTrackingShipmentForm(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <CustomLoading />}
      {showTrackingShipmentForm && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowTrackingShipmentForm(false);
            }}
          >
            <TrackingForm
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              register={register}
              setForm={setShowTrackingShipmentForm}
              setValue={setValue}
              shipment_details={account_shipment?.tracking ?? undefined}
            />
          </OutsideClickHandler>
        </Modal>
      )}
      <div className="w-full h-min-[50px] bg-white  shadow-c-sm rounded-card   p-4   sm:p-7    py-5  border-opacity-40">
        <div className="flex  ">
          <p className=" text-lg text-black font-semibold   ">
            {Orders_text?.orderdetail.shipment_details}
          </p>
        </div>
        {account_shipment?.tracking ? (
          <div className="mt-3">
            {account_shipment?.tracking?.provider && (
              <div className=" flex justify-start  items-start  py-1  ">
                <p className=" text-sm text-black font-semibold  whitespace-nowrap ">
                  {Orders_text.orderdetail?.provider} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70   break-all	 ">
                  {account_shipment?.tracking?.provider}
                </p>
              </div>
            )}
            {account_shipment?.tracking?.tracking_url && (
              <a
                href={account_shipment?.shipment?.tracking_url}
                target="_blank"
                rel="noreferrer"
                className=" flex justify-start  items-start  py-1  "
              >
                <p className=" text-sm text-black font-semibold  whitespace-nowrap ">
                  {Orders_text.orderdetail?.tracking_url} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70   break-all	 ">
                  {account_shipment?.tracking?.tracking_url}
                </p>
              </a>
            )}
            {account_shipment?.tracking?.tracking_number && (
              <div className=" flex justify-start  items-start  py-1  ">
                <p className=" text-sm text-black font-semibold  whitespace-nowrap ">
                  {Orders_text.orderdetail?.tracking_number} :
                </p>
                <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70   break-all	 ">
                  {account_shipment?.tracking?.tracking_number}
                </p>
              </div>
            )}
          </div>
        ) : (
          <p className=" mt-3 text-sm text-gray-800 font-medium     break-words ">
            {Orders_text.orderdetail.shipment_data_unavailable_seller_note}
          </p>
        )}
        {order_details?.shipping_method?.channel !== 'sendcloud' && (
          <div className="mt-4 flex items-center justify-start gap-2">
            <button
              className={
                account_shipment?.status == 3
                  ? 'btn-primary-outline'
                  : 'btn-disabled'
              }
              disabled={account_shipment?.status == 3 ? false : true}
              onClick={() => {
                setShowTrackingShipmentForm(true);
              }}
            >
              {account_shipment?.tracking &&
              Object.keys(account_shipment?.tracking)?.length > 0
                ? Orders_text.orderdetail.edit_shipment_button
                : Orders_text.orderdetail.add_shipment_button}
            </button>
            <button className="tooltip text-[#6e686e] hover:text-primary duration-200 transition">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6  "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                />
              </svg>

              <span className="top_left text-primary">
                {Orders_text.orderdetail.add_shipment_note}
              </span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderTracking2;
