/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import moreImage from '../../../assets/Images/Categories/Menu.png';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { Home_page_text } from '../../../constant/Translation/Home';
import { all_ct_icon } from '../../Shared/Constant/Icons/AllIcons';
import CategoriesSkeleton from '../../Shared/skeletonUI/CategoriesSkeleton';
import { slug as SlugChecker } from 'github-slugger';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CategoryCardDefaultStyles,
  CategoryCardImagedDefaultStyles,
  CategoryNameDefaultStyles,
} from '../../../constant/default_styles/CategoryCardDefaultStyles';
import { get_animation_classes } from '../../../constant/animation/getAnimation';
import { get_data_by_current_language } from '../../../utils/GetDataByLanguage';
import { dynamic_text_convert } from '../../../utils/dynamicTextConvert';

const LatestCategories = ({ categories, extra_data, styles }) => {
  const [filterCategories, setFilterCategories] = useState();

  useEffect(() => {
    const width = window.innerWidth;
    let calc;
    if (extra_data?.max_category) {
      calc = extra_data?.max_category;
    }
    let sliceCategories;
    if (categories?.length > calc) {
      sliceCategories = categories?.slice(0, calc);
    } else {
      sliceCategories = categories?.slice(0, calc);
    }
    setFilterCategories(sliceCategories);
  }, [categories]);

  return categories ? (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div style={get_dynamic_styles(styles?.box_styles)}>
        {extra_data?.title && (
          <h2 style={get_dynamic_styles(styles?.title_styles)}>
            {get_data_by_current_language({
              data_list: extra_data?.translated_data,
              key_name: 'extra_data.title',
              default_data: extra_data?.title,
            })}
          </h2>
        )}
        <div
          className="  flex  flex-wrap  "
          style={get_dynamic_styles(styles?.categories_styles)}
        >
          {filterCategories?.map((item) => {
            const query =
              item.id !== 'all'
                ? {
                    slug: item.slug
                      ? item.slug
                      : `${item?.id}-${SlugChecker(item.name)}`,
                  }
                : '';

            return (
              <Link
                key={Math.random()}
                href={{
                  pathname: `${item.id !== 'all' ? '/lc/[slug]' : '/lc'}`,
                  query,
                }}
                passHref
              >
                <a
                  className={` group  flex flex-col  justify-between items-center    cursor-pointer  `}
                  style={{
                    display: styles?.categories_card_styles?.display,
                    gridTemplateColumns: dynamic_text_convert({
                      main_text:
                        styles?.categories_card_styles?.columns_box_percentage,
                      check_by: '-1%',
                      value: '',
                    }),
                    ...get_dynamic_styles(styles?.categories_card_styles),
                  }}
                >
                  <p
                    style={get_dynamic_styles_with_default_value(
                      styles?.categories_name_styles,
                      CategoryNameDefaultStyles
                    )}
                  >
                    {item.name}
                  </p>
                  <img
                    style={get_dynamic_styles(styles?.categories_image_styles)}
                    src={getThumbnailImage(item.image_path)}
                    alt={item.name}
                    title={item.name}
                  />
                </a>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    <div className=" " style={get_dynamic_styles(styles?.main_styles)}>
      <div style={get_dynamic_styles(styles?.box_styles)}>
        <CategoriesSkeleton styles={styles} />
      </div>
    </div>
  );
};

export default memo(LatestCategories);
