/* eslint-disable react/prop-types */
import React, { useEffect, useState, memo } from 'react';
import Image from 'next/image';
import tradly from 'tradly';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { bannersSelector } from '../../../store/feature/Home/bannerSlice';
// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const Banner3 = ({}) => {
  const [banners, setBanners] = useState(null);

  const { promo_banners } = useSelector(bannersSelector);

  useEffect(() => {
    window.innerWidth > 850
      ? setBanners(
          promo_banners?.filter(
            (banner) =>
              banner.medium.includes('web') && banner.placement === 'footer'
          )
        )
      : setBanners(
          promo_banners?.filter(
            (banner) =>
              banner.medium.includes('app') && banner.placement === 'footer'
          )
        );
  }, [promo_banners]);

  const router = useRouter();
  const banner_click = (banner) => {
    if (banner?.type !== 'general') {
      if (banner.type == 'account' && banner.value) {
        router.push(`/a/${banner.value}?id=true`);
      } else if (banner.type == 'listing' && banner.value) {
        router.push(`/l/${banner.value}?id=true`);
      } else if (banner.type == 'external_link' && banner.value) {
        window.open(banner.value);
      }
    }
  };
  return (
    <>
      <div className="     h-auto mb-9 mx-auto">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
        >
          {banners?.map((banner, i) => {
            return (
              <SwiperSlide
                key={i}
                className={[
                  ' w-full flex flex-col justify-center items-center mb-14',
                  banner.type !== 'general' ? 'cursor-pointer' : 'cursor-auto',
                ].join(' ')}
              >
                <div
                  className="  relative   "
                  onClick={() => banner_click(banner)}
                >
                  {banner?.image_path?.split('.')[
                    banner?.image_path.split('.').length - 1
                  ] === 'mp4' ? (
                    <video className="w-full" controls autoPlay muted>
                      <source src={banner?.image_path} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={banner?.image_path}
                      alt="Banner Image"
                      className="w-full  object-contain  rounded-image "
                    />
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default memo(Banner3);

// w-full h-[200px] md:h-[400px]  relative rounded-image overflow-hidden
