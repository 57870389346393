import Link from 'next/link';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Listing_text } from '../../../constant/Translation/Listing';
import { cartList } from '../../../store/feature/cartSlice';
import { clearListingDetails } from '../../../store/feature/listingSlice';
import ListingDetailsPageLayout from '../../../themes/singlecart/ListingDetailsPageLayout';
import { getThumbnailImage, get_relative_time } from '../Constant/Constant';
import SidePan from '../Modal/SidePan';

function ListingCard4({ product }) {
  const [sidePanOpen, setSidePanOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const dispatch = useDispatch();

  return (
    <>
      <div
        className=" w-full flex items-start rounded-card overflow-hidden  bg-white shadow-sm border border-gray-200  cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setSidePanOpen(true);
          setSelectedID(product.id);
        }}
      >
        <div className=" flex-grow   p-4    w-full   h-[144px]  flex flex-col gap-3">
          <p className="text-base text-gray-600 font-normal flex items-center flex-wrap gap-2">
            <span className=" font-semibold">
              {product.offer_price.formatted}
            </span>

            {product?.offer_percent > 0 && (
              <>
                <span className="   line-through ">
                  {product.list_price.formatted}
                </span>
                <span className="text-error  ">{product?.offer_percent}%</span>
              </>
            )}
          </p>

          <p
            className="text-gary-900 text-lg font-normal whitespace-normal text-ellipsis
          "
          >
            {product.title}
          </p>
        </div>
        <div className=" flex-none w-[144px] h-[144px] ">
          {product?.images?.length > 0 && (
            <img
              src={getThumbnailImage(product.images[0])}
              className="w-[144px] h-[144px]  overflow-hidden object-cover"
              width="144"
              height="144"
              alt={product.title}
            />
          )}
        </div>
      </div>
      <SidePan
        id={`listing-details-sidepan-${selectedID}`}
        sidePanOpen={sidePanOpen}
        setSidePanOpen={setSidePanOpen}
        justify_align={'center'}
        modal_width={'max-w-xl w-full'}
        close_function={() => {
          setSelectedID(null);
          dispatch(clearListingDetails());
        }}
      >
        <div className=" relative max-h-[90vh] ">
          <button
            className=" absolute  ltr:right-3 rtl:left-3 w-10 h-10 flex items-center justify-center top-3 p-3 rounded-full shadow-md bg-white  z-20 text-primary"
            onClick={() => {
              setSidePanOpen(false);
              setSelectedID(null);
              dispatch(clearListingDetails());
              dispatch(cartList({}));
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <ListingDetailsPageLayout
            slug={selectedID}
            close_function={() => {
              setSidePanOpen(false);
              setSelectedID(null);
              dispatch(clearListingDetails());
              dispatch(cartList({}));
            }}
          />
        </div>
      </SidePan>
    </>
  );
}

export default ListingCard4;
