/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
import {
  changeDateFormat,
  getThumbnailImage,
} from '../../Shared/Constant/Constant';
import { configsSelector } from '../../../store/feature/configsSlice';
import { authSelector } from '../../../store/feature/authSlice';
import { listingLike } from '../../../store/feature/listingSlice';
import { homeCollections } from '../../../store/feature/homeSlice';

import favorite from '../../../assets/Images/Home/favourite@3x.png';
import heartIcon from '../../../assets/Images/Home/heartIcon@3x.png';
import ListingCard2 from '../../Shared/Cards/ListingCard2';
import { Home_page_text } from '../../../constant/Translation/Home';
import ListingCard6 from '../../Shared/Cards/ListingCard6';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const CollectionListings = ({ products }) => {
  // const { isSuccess } = useSelector(listingSelector);

  return (
    <div className=" ">
      <div className="flex justify-between items-center   ">
        <h1 className="title-h1">{products.title}</h1>
        <Link
          href={{
            pathname: `/cp/${products.slug ? products.slug : products.id}`,
          }}
          passHref
        >
          <a className=" block text-base text-primary font-semibold cursor-pointer">
            {Home_page_text.home.view_all}
          </a>
        </Link>
      </div>
      <div className=" mt-4   ">
        <Swiper
          slidesPerView="auto"
          slidesPerGroup={1}
          spaceBetween={20}
          loop={false}
          navigation={false}
          className="grid  	grid-cols-2  sm:grid-cols-3  md:grid-cols-4 gap-5"
        >
          {products?.listings?.map((item) => (
            <SwiperSlide
              className=" w-[190px] h-[302px]    rounded-card  overflow-hidden"
              key={item.id}
              style={{
                width: '260px',
                minHeight: '210px',
                paddingBottom: '1px',
              }}
            >
              <ListingCard6 item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CollectionListings;
