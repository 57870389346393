import Link from 'next/link';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Listing_text } from '../../../constant/Translation/Listing';
import { cartList } from '../../../store/feature/cartSlice';
import { clearListingDetails } from '../../../store/feature/listingSlice';
import ListingDetailsPageLayout from '../../../themes/restaurant/ListingDetailsPageLayout';
// import ListingDetailsPageLayout from '../../../themes/singlecart/ListingDetailsPageLayout';
import { getThumbnailImage, get_relative_time } from '../Constant/Constant';
import SidePan from '../Modal/SidePan';

function ListingCard5({ product }) {
  const [sidePanOpen, setSidePanOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const dispatch = useDispatch();

  return (
    <>
      <div
        className=" w-full flex flex-col items-start rounded-card overflow-hidden  bg-white shadow-md  cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setSidePanOpen(true);
          setSelectedID(product.id);
        }}
      >
        <div className=" flex-none w-full    h-[185px] ">
          {product?.images?.length > 0 && (
            <img
              src={getThumbnailImage(product.images[0])}
              className="w-full h-full  overflow-hidden object-cover"
              alt={product.title}
            />
          )}
        </div>
        <div className=" flex-grow   p-4    w-full   h-[125px]  flex flex-col gap-4">
          <p className="text-base text-gray-800  font-light flex items-center flex-wrap gap-2">
            <span className="font-medium">{product.offer_price.formatted}</span>

            {product?.offer_percent > 0 && (
              <>
                <span className="   line-through ">
                  {product.list_price.formatted}
                </span>
                <span className="text-error  ">{product?.offer_percent}%</span>
              </>
            )}
          </p>

          <p
            className="text-gary-900 text-lg font-normal whitespace-normal   w-full   truncate
          "
          >
            {product.title}
          </p>
        </div>
      </div>
      <SidePan
        id={`listing-details-sidepan-${selectedID}`}
        sidePanOpen={sidePanOpen}
        setSidePanOpen={setSidePanOpen}
        modal_section_styles={
          'fixed  right-0 left-0 bottom-0 lg:top-0 lg:my-auto  z-[60] overflow-hidden flex items-center  justify-center   rounded-t-[40px]   lg:rounded-card transform '
        }
        modal_inside_section_styles="bg-white rounded shadow-lg overflow-auto  max-w-full lg:max-w-5xl w-full max-h-full  lg:scrollbar-thin   lg:scrollbar-track-transparent  lg:scrollbar-thumb-transparent"
        modal_width={'max-w-xl w-full'}
        close_function={() => {
          setSelectedID(null);
          dispatch(clearListingDetails());
          dispatch(cartList({}));
        }}
      >
        <div className=" relative max-h-[85vh]">
          <button
            className=" fixed  lg:hidden  ltr:right-3 rtl:left-3 w-10 h-10 flex items-center justify-center top-3 p-3 rounded-full shadow-md bg-white  z-40 text-primary"
            onClick={() => {
              setSidePanOpen(false);
              setSelectedID(null);
              dispatch(clearListingDetails());
              dispatch(cartList({}));
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <ListingDetailsPageLayout
            slug={selectedID}
            close_function={() => {
              setSidePanOpen(false);
              setSelectedID(null);
              dispatch(clearListingDetails());
              dispatch(cartList({}));
            }}
          />
        </div>
      </SidePan>
    </>
  );
}

export default ListingCard5;
