import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { commissionsSelector } from '../../../store/feature/Commissions/CommissionsSlice';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';

const CartCommission = ({
  cart_commission,
  setCartCommission,
  fetch_cart_list,
}) => {
  const { commissions } = useSelector(commissionsSelector);

  const [temp_cart_commission, setTempCartCommission] = useState([]);

  // apply_cart_commission
  const apply_cart_commission = async ({ id, amount }) => {
    setCartCommission((prev) => [
      ...prev.filter((old_values) => old_values?.id !== id),
      { id: id, amount: Number(amount) },
    ]);
  };
  return (
    <div className="  w-full mb-6 min-h-[200px] bg-[#FEFEFE] rounded-card   py-6 px-6   flex flex-col gap-4">
      {commissions?.map((commission) => {
        let current_commission_value = temp_cart_commission?.filter(
          (cart_com) => cart_com.id == commission.id
        )[0];
        return (
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1">
              <p className="text-default_gray text-sm leading-6 font-bold  ">
                {commission?.title}
              </p>

              {commission?.description && (
                <button className="tooltip text-default_gray hover:text-info duration-200 transition">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5  "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>

                  {
                    <span className="top_right text-primary">
                      {commission?.description}
                    </span>
                  }
                </button>
              )}
            </div>

            <div className="flex items-center justify-center   gap-2">
              <input
                type="number"
                value={current_commission_value?.amount}
                placeholder={commission?.title}
                className="
                  flex-grow
                    block
                    w-full
                     p-0
                     px-2
                     h-10
                    rounded-input
                    border
                    border-gray-300
                    focus:border-primary  
                    shadow-sm
                   "
                onChange={(e) =>
                  setTempCartCommission((prev) => [
                    ...prev.filter(
                      (old_values) => old_values?.id !== commission.id
                    ),
                    { id: commission.id, amount: e.target?.value },
                  ])
                }
              />
              <button
                className={[
                  'flex-none btn-primary-outline px-2 h-10',
                  current_commission_value?.amount === undefined ||
                  current_commission_value.amount >
                    commission?.commission_data?.max_amount ||
                  current_commission_value?.amount <
                    commission?.commission_data?.min_amount
                    ? 'cursor-not-allowed tooltip'
                    : 'cursor-pointer',
                ].join(' ')}
                disabled={
                  current_commission_value?.amount === undefined ||
                  current_commission_value.amount >
                    commission?.commission_data?.max_amount ||
                  current_commission_value?.amount <
                    commission?.commission_data?.min_amount
                    ? true
                    : false
                }
                onClick={() => {
                  if (
                    current_commission_value?.amount !== undefined &&
                    current_commission_value?.amount <=
                      commission?.commission_data?.max_amount &&
                    current_commission_value?.amount >=
                      commission?.commission_data?.min_amount
                  ) {
                    apply_cart_commission({
                      id: commission.id,
                      amount: current_commission_value?.amount,
                    });
                  }
                }}
              >
                {Checkout_text.cart.apply_commission}

                <span className="top_right text-primary">
                  {(current_commission_value?.amount <
                    commission?.commission_data?.min_amount ||
                    current_commission_value?.amount === undefined) &&
                    dynamic_translation_set(
                      Checkout_text?.cart?.cart_commission_minimum,
                      commission?.commission_data?.min_amount
                    )}
                  {current_commission_value?.amount >
                    commission?.commission_data?.max_amount &&
                    dynamic_translation_set(
                      Checkout_text?.cart?.cart_commission_maximum,
                      commission?.commission_data?.max_amount
                    )}
                </span>
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CartCommission;
