/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { storeSelector } from '../../../../store/feature/storeSlice';
import { stock_text } from '../../../Shared/Constant/TextConstant/addlistingConstant';
import { useEffect } from 'react';
import AllIcons, { camera_icon } from '../../../Shared/Constant/Icons/AllIcons';
import { Add_product_text } from '../../../../constant/Translation/AddProduct_text';
import DigitalProduct from './DigitalProduct/DigitalProduct';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import { validateImageSize } from '../../../../utils/ImageSizeValidate';
import SelectFromList from '../../../Shared/DropDown/SelectFromList';

const AddMatrixVariantsForm = ({
  variantsType,
  variantsObject,
  setVariantsObject,
  addVariantClick,
  setShowError,
  setError_message,
  MARKETPLACE_MODULES,
  btn_title,
  order_type,
}) => {
  const [imagePath, setImagePath] = useState(null);
  const { listing_configs } = useSelector(storeSelector);

  // Click add button
  const clickAddButton = () => {
    if (variantsObject.variant_type === null) {
      setShowError(true);
      setError_message(Add_product_text.addvariant.select_variant_type);
      return false;
    }
    if (variantsObject.variant_type_value === null) {
      setShowError(true);
      setError_message(Add_product_text.addvariant.select_variant_type_value);
      return false;
    }
    // Condition check for content
    if (
      order_type === 'digital' &&
      !variantsObject?.digitalProducts?.content?.length > 0
    ) {
      setShowError(true);
      setError_message(Add_product_text.adddigitalproduct.alert_file_select);
      return false;
    }

    addVariantClick();
  };

  useEffect(() => {
    if (variantsObject?.images) {
      setImagePath({
        id: variantsObject?.images.name,
        path: URL.createObjectURL(variantsObject?.images),
      });
    }
  }, [0]);

  //
  const onChangeVariantType = ({ type, value }) => {
    let vr_data = {
      variant_type_id: type,
      variant_type_value_id: value,
    };
    let old_vr_values =
      variantsObject?.variant_values?.filter(
        (old_vr) => old_vr?.variant_type_id !== type
      ) ?? [];

    setVariantsObject({
      ...variantsObject,
      variant_values: [...old_vr_values, vr_data],
    });
  };

  return (
    <div className=" bg-white w-full px-5 py-7 my-5 grid grid-cols-1 gap-6">
      <div className="block">
        <span className="text-gray-700  text-lg">
          {Add_product_text.addvariant.add_variant_image}
        </span>
        <input
          id="imageButton"
          type="file"
          value={''}
          className=" hidden"
          accept=".png, .jpg ,.svg , .gif, .webp, .jpeg"
          placeholder=""
          onChange={(e) => {
            if (validateImageSize(e.target.files[0])) {
              e.target.files.length > 0 &&
                setImagePath({
                  id: e.target.files[0].name,
                  path: URL.createObjectURL(e.target.files[0]),
                }),
                setVariantsObject({
                  ...variantsObject,
                  images: e.target.files[0],
                });
            } else {
              setShowError(true);
              setError_message(Add_product_text.addproduct.image_size_alert);
              return false;
            }
          }}
        />
        <div>
          {imagePath !== null ? (
            <div className=" relative  w-28 mt-4">
              <img
                alt="image-path"
                className="w-28 h-28 object-cover"
                src={imagePath.path}
              />
              <button
                className=" absolute -top-2 -right-2 text-primary "
                onClick={() => {
                  return (
                    setImagePath(null),
                    setVariantsObject({
                      ...variantsObject,
                      images: null,
                    })
                  );
                }}
                alt="image"
              >
                {AllIcons.close_icon}
              </button>
            </div>
          ) : (
            <button
              className=" w-28  h-28 flex justify-center items-center  mt-3  bg-gray-100 text-sm rounded-button"
              onClick={() => document.getElementById('imageButton').click()}
            >
              <span className="p-3 bg-primary rounded-full">{camera_icon}</span>
            </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        {variantsType?.map((vr_type) => {
          return (
            <SelectFromList
              key={vr_type?.id}
              title={vr_type?.name}
              drop_down_items={vr_type?.values?.map((vl) => ({
                label: vl.name,
                value: vl.id,
              }))}
              title_styles={'text-gray-700  text-lg'}
              field_styles={
                'mt-0   outline-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 transition  duration-700 focus:ring-0 focus:border-primary rounded-none'
              }
              default_option_text={'Select'}
              dropdownBlockStyles={'border-0'}
              set_new_value={(value) => {
                onChangeVariantType({ type: vr_type?.id, value });
              }}
              current_value={
                variantsObject?.variant_values?.filter(
                  (old_vr) => old_vr?.variant_type_id == vr_type?.id
                )?.[0]?.variant_type_value_id
              }
              options_block_styles={'p-0 gap-0'}
              options_style={'px-5 py-2 '}
              options_selected_style={'  bg-primary text-white'}
            />
          );
        })}
      </div>
      <label className="block">
        <span className="text-gray-700  text-lg">
          {Add_product_text.addvariant.name}
        </span>
        <input
          type="text"
          className="
                    mt-0
                    block
                    w-full
                    h-7
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
          placeholder="Enter your title"
          onChange={(e) => {
            setVariantsObject({
              ...variantsObject,
              title: e.target.value,
            });
          }}
          value={variantsObject?.title}
        />
      </label>
      <label className="block">
        <span className="text-gray-700  text-lg">
          {Add_product_text.addvariant.description}
        </span>
        <textarea
          className="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
          rows="3"
          onChange={(e) => {
            setVariantsObject({
              ...variantsObject,
              description: e.target.value,
            });
          }}
          value={variantsObject?.description}
        ></textarea>
      </label>

      <label className="block relative">
        <span className="text-gray-700  text-lg">
          {Add_product_text.addvariant.price}
        </span>
        <input
          type="number"
          className="
                    mt-0
                    block
                    w-full
                    px-0.5
                     border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
          placeholder="1"
          onChange={(e) => {
            setVariantsObject({
              ...variantsObject,
              list_price: e.target.value,
            });
          }}
          value={variantsObject?.list_price}
          onWheel={(event) => event.currentTarget.blur()}
        />
      </label>
      {(TYPE_CONSTANT.LISTINGS_CONFIGS?.enable_stock ||
        listing_configs?.hide_offer_percent) && (
        <div className="grid grid-cols-2 gap-2">
          {listing_configs?.hide_offer_percent && (
            <label className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addvariant.offer}
              </span>
              <input
                type="number"
                className="
                    mt-0
                    block
                    w-full
                    h-7
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                placeholder="1"
                onChange={(e) => {
                  setVariantsObject({
                    ...variantsObject,
                    offer_percent: e.target.value,
                  });
                }}
                value={variantsObject?.offer_percent}
                onWheel={(event) => event.currentTarget.blur()}
              />
            </label>
          )}
          {TYPE_CONSTANT.LISTINGS_CONFIGS?.enable_stock && (
            <label className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addvariant.stock}
              </span>
              <input
                type="number"
                className="
                    mt-0
                    block
                    w-full
                    h-7
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                placeholder="1"
                onChange={(e) => {
                  setVariantsObject({
                    ...variantsObject,
                    stock: e.target.value,
                  });
                }}
                value={variantsObject?.stock}
                onWheel={(event) => event.currentTarget.blur()}
              />
            </label>
          )}
        </div>
      )}
      {order_type === 'digital' && (
        <div className="mt-8">
          <DigitalProduct
            variantsObject={variantsObject}
            setVariantsObject={setVariantsObject}
          />
        </div>
      )}
      <div className=" flex justify-end">
        <button
          className=" btn-primary-outline "
          onClick={() => clickAddButton()}
        >
          {Add_product_text.addvariant.add_variant}
        </button>
      </div>
    </div>
  );
};

export default AddMatrixVariantsForm;
