import React from 'react';
import NotFound from '../../components/Shared/Default UI/NotFound';
import { General_text } from '../../constant/Translation/General';

const NotFoundPageLayout = () => {
  return (
    <div>
      <NotFound message={General_text.general.not_found_message} />
    </div>
  );
};

export default NotFoundPageLayout;
