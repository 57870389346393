import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Shipping_text } from '../../../constant/Translation/Shipping_text';
import { authSelector } from '../../../store/feature/authSlice';
import { cartList, cartSelector } from '../../../store/feature/cartSlice';
import NoCartItem2 from '../NoCartItem/NoCartItem2';
import OrderSummary from '../OrderSummary/OrderSummary';
import OrderSummary2 from '../OrderSummary/OrderSummary2';

const CartBox = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { login, auth_key } = useSelector(authSelector);

  useEffect(() => {
    if (login) {
      dispatch(cartList({}));
    }
  }, [login, router]);

  const {
    cart,
    cart_details,

    isError,
    errorMessage,
    isFetching,
    isCheckoutFetching,
    isSuccess,
  } = useSelector(cartSelector);
  return (
    <div className=" w-full lg:min-h-[200px] lg:border lg:border-gray-200    overflow-y-auto shadow-c-md rounded-card ">
      <div className=" hidden lg:block">
        {cart_details && cart_details?.length > 0 ? (
          <OrderSummary2 cart={cart} cart_details={cart_details} />
        ) : (
          <div className="w-full">
            <NoCartItem2 />
          </div>
        )}
      </div>

      {cart_details && cart_details?.length > 0 && (
        <div className="flex justify-center  lg:mt-6 mb-6">
          <Link href={'/checkout'}>
            <button className=" btn-primary-fill px-8 py-3 text-sm font-medium ">
              {Shipping_text.shipping.checkout} {cart?.grand_total?.formatted}
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default CartBox;
