export var Layer_text = {
  layer: {
    publish: 'Publish',
    edit_layer: 'Edit Article',
    title_alert: 'Title is required',
    desc_alert: 'Description is required',
    blog_not_found: 'Blog not found',
    admin_pages_not_found: 'Pages not found',
    latest_blogs: 'Latest Blogs',
    latest_pages: 'Latest pages',
    view_all: 'View All',
  },
};
