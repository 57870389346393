import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import CartIcon from '../../../assets/Images/Cart/Nocart.png';
import { base_url } from '../../../constant/url';

const Checkout_failure = ({ order_reference, auth_key, payment_method_id }) => {
  return (
    <div className="  min-h-screen    ">
      <div className=" mt-8 w-full  md:w-[600px] min-h-[300px] py-10 bg-white shadow-c-sm  flex flex-col justify-center items-center mx-auto">
        <div>
          <Image
            src={CartIcon}
            width={250}
            height={250}
            alt="No Item"
            objectFit="contain"
          />
        </div>
        <p className="text-base font-semibold text-primary mt-4">
          Sorry!, Your order is not successful.
        </p>
        <div className="flex flex-col xs:flex-row  justify-between items-center gap-5 my-5">
          <Link href="/" passHref={true}>
            <button className="bg-primary rounded-button mt-8 px-4 py-2 text-base text-white font-medium ">
              Back To Home
            </button>
          </Link>

          <button
            onClick={() =>
              window.open(
                `${base_url()}/v1/payments/web/paymentIntent?order_reference=${order_reference}&auth_key=${auth_key}&payment_method_id=${payment_method_id}`
              )
            }
            className="bg-primary rounded-button mt-8 px-4 py-2  text-base text-white font-medium "
          >
            Retry for payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default Checkout_failure;
