import axios from 'axios';
import { isValidPhoneNumber } from 'react-phone-number-input';
import tradly from 'tradly';
import { push_data_layer } from '../../constant/GTMDatalayer';
import { EditProfile_text } from '../../constant/Translation/EditProfile_text';
import { General_text } from '../../constant/Translation/General';
import { UserInfo } from '../../store/feature/authSlice';
import { validate_attribute_data } from '../../utils/AttributeDataValidation';

export const saveChange = async (
  firstName,
  lastName,
  imagePath,
  files,
  setShowError,
  setError_message,
  setLoading,
  userId,
  dispatch,
  setShowSuccess,
  setSuccess_message,
  dialcode,
  number,
  selectedCountryId,
  attributeData,
  attributes
) => {
  setLoading(true);
  if (firstName === null || firstName?.replace(/\s/g, '').length <= 0) {
    setLoading(false);
    setShowError(true);
    setError_message(
      `${EditProfile_text.editprofile.firstname} ${General_text.general.required}`
    );
  }
  if (lastName === null || lastName?.replace(/\s/g, '').length <= 0) {
    setLoading(false);
    setShowError(true);
    setError_message(
      `${EditProfile_text.editprofile.lastname} ${General_text.general.required}`
    );
  }

  if (dialcode && number && dialcode !== '' && number !== '') {
    if (!isValidPhoneNumber(`+${number}`)) {
      setLoading(false);
      setShowError(true);
      setError_message(EditProfile_text.editprofile.enter_valid_phoneno);
      return false;
    }
  }

  if (
    validate_attribute_data({
      attributes,
      attributeData,
      setShowError,
      setError_message,
    }) === false
  ) {
    setLoading(false);
    return false;
  }

  // GET IMAGE URL of Attributes:
  let updated_attributes = attributeData;

  const upload_attributes_files = async (all_attributes) => {
    let all_updated_attributes = [];
    let upload_attributes_files = [];
    let upload_attributes_full_files = [];

    for (let i = 0; i < all_attributes.length; i++) {
      const element = all_attributes[i];

      // Set in state
      if (element.uploadFile) {
        upload_attributes_files.push({
          name: element.values[0].name,
          type: element.values[0].type,
          id: element.id,
        });
        upload_attributes_full_files.push(element.values[0]);
      } else {
        all_updated_attributes.push(element);
      }

      // Upload file checking
      if (
        all_attributes.length === i + 1 &&
        upload_attributes_files.length > 0
      ) {
        try {
          const response = await axios.post('/api/generateS3ImageURL', {
            data: {
              files: upload_attributes_files.map((file) => {
                return { name: file.name, type: file.type };
              }),
            },
          });

          const responseFiles = response.data.result;
          for (let index = 0; index < responseFiles.length; index++) {
            const path = responseFiles[index].signedUrl;
            const fileURI = responseFiles[index].fileUri;

            try {
              const res = await fetch(path, {
                method: 'PUT',
                headers: {
                  ContentType: upload_attributes_files[index].type,
                },
                body: upload_attributes_full_files[index],
              });

              if (res.ok) {
                all_updated_attributes.push({
                  values: [fileURI],
                  id: upload_attributes_files[index].id,
                });
              }
            } catch (error) {
              setShowError(true);
              setError_message(error?.response?.data?.message);
              setEditProductLoading(false);
            }
          }
        } catch (error) {
          setShowError(true);
          setError_message(error?.response?.data?.message);
          setEditProductLoading(false);
        }
      }

      // return all images url
      if (all_updated_attributes.length == all_attributes.length) {
        return all_updated_attributes;
      }
    }
  };
  const check = attributeData.find((attr) => attr.uploadFile);
  if (check) {
    updated_attributes = await upload_attributes_files(attributeData);
  }

  // Main action start
  if (files !== null) {
    axios
      .post('/api/generateS3ImageURL', {
        data: {
          files: [
            {
              name: files.name,
              type: files.type,
            },
          ],
        },
      })
      .then((response) => {
        if (!response.data.error) {
          const fileURL = response.data.result[0];
          const path = fileURL.signedUrl;
          const ImagePath = fileURL.fileUri;
          fetch(path, {
            method: 'put',
            headers: {
              ContentType: files.type,
            },
            body: files,
          }).then((res) => {
            const userData = {
              user: {
                first_name: firstName,
                last_name: lastName,
                profile_pic: ImagePath,
              },
            };

            if (number && number && dialcode !== '' && number !== '') {
              userData.user.mobile = number.slice(dialcode.length);
              userData.user.dial_code = dialcode;
            }
            if (selectedCountryId) {
              userData.user.country_id = selectedCountryId;
            }
            if (updated_attributes?.length > 0) {
              userData.user.attributes = updated_attributes;
            }

            axios
              .post('/api/user/update_user', { userId, userData })
              .then((res) => {
                dispatch(UserInfo({ userId })).then((res) => {
                  if (!res.payload.code) {
                    push_data_layer({
                      event: 'edit_profile',
                      data: {
                        first_name: firstName,
                        last_name: lastName,
                      },
                    });
                    setLoading(false);
                    setShowSuccess(true);
                    setSuccess_message(EditProfile_text.editprofile.updated);
                  } else {
                    setShowError(true);
                    setError_message(response.payload.message);
                    setLoading(false);
                  }
                });
              })
              .catch((error) => {
                alert(error.response.data.message);
              });
          });
        } else {
          setShowError(true);
          setError_message(response.data.error.message);
          setLoading(false);
        }
      });
  } else {
    const userData = {
      user: {
        first_name: firstName,
        last_name: lastName,
      },
    };
    if (selectedCountryId) {
      userData.user.country_id = selectedCountryId;
    }

    if (number && dialcode !== '' && number !== '') {
      userData.user.mobile = number.slice(dialcode.length);
      userData.user.dial_code = dialcode;
    }
    if (imagePath?.path) {
      userData.user['profile_pic'] = imagePath.path;
    } else {
      userData.user['profile_pic'] = '';
    }
    if (updated_attributes?.length > 0) {
      userData.user.attributes = updated_attributes;
    }

    axios
      .post('/api/user/update_user', { userId, userData })
      .then((res) => {
        dispatch(UserInfo({ userId })).then((res) => {
          if (!res.payload.code) {
            push_data_layer({
              event: 'edit_profile',
              data: {
                first_name: firstName,
                last_name: lastName,
              },
            });
            setLoading(false);
            setShowSuccess(true);
            setSuccess_message(EditProfile_text.editprofile.updated);
          } else {
            setShowError(true);
            setError_message(response.payload.message);
            setLoading(false);
          }
        });
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  }
};
