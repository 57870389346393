/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { getThumbnailImage } from '../Shared/Constant/Constant';
import { slug as SlugChecker } from 'github-slugger';

const Categories = ({ allCategories }) => (
  <div>
    <div>
      <div className=" grid grid-cols-[144px,144px]  xs:grid-cols-3 gap-[23px] sm:gap-0  sm:flex sm:flex-wrap justify-center md:justify-start  items-center">
        {allCategories?.map((item) => (
          <Link
            key={Math.random()}
            href={{
              pathname: '/lc/[slug]',
              query: {
                slug: item.slug
                  ? item.slug
                  : `${item?.id}-${SlugChecker(item.name)}`,
              },
            }}
            passHref
          >
            <a className="   sm:mr-4  sm:mb-6  bg-[#ffffff] rounded-card   p-2 flex flex-col  justify-between items-center border border-transparent  shadow-c-sm hover:border-primary hover:shadow-sm  w-[144px] h-[144px]   cursor-pointer gap-3">
              <div className=" w-full h-[120px] rounded-image overflow-hidden  relative">
                <img
                  src={getThumbnailImage(item.image_path)}
                  alt={item.name}
                  title={item.name}
                  className=" h-full w-full object-cover"
                />
              </div>
              <p className=" w-full mx-auto min-h-[5px] text-primary  text-xs   font-medium flex justify-center items-center text-center whitespace-pre-wrap">
                {item.name}
              </p>
            </a>
          </Link>
        ))}
      </div>
    </div>
  </div>
);

export default Categories;
