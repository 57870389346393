/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';

import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import Head from 'next/head';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import axios from 'axios';
import DescriptionPart from '../../components/Layers/DescriptionPart';
import Image from 'next/image';
import default_image from '../../assets/Images/success/default.jpg';

import { Layer_text } from '../../constant/Translation/LayerText';
import { getThumbnailImage } from '../../components/Shared/Constant/Constant';

const AdminPageDetailsLayout = ({}) => {
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [article_details, setArticle_details] = useState();

  const router = useRouter();

  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .get('/api/c/article_details', {
        params: { slug: router?.query?.slug },
      })
      .then((res) => {
        setArticle_details(res.data.layer);
      })
      .catch((error) => {
        setShowError(true);
        setError_message(error.response?.data?.message);
      });
  }, [router?.query.slug]);

  const closePopUP = () => {
    setShowError(false);
    setError_message('');
  };

  return (
    <>
      {showError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showError && (setShowError(false), setError_message(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp message={error_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      <div className=" w-full lg:w-4/6 mx-auto">
        {article_details?.cover_image_path && (
          <div className="w-full  h-[300px] rounded-t-card   relative  overflow-hidden">
            <img
              className="h-full w-full object-cover object-left-top rounded-sm"
              src={article_details?.cover_image_path}
              alt="image"
            />
          </div>
        )}
        {article_details?.content !== '' && (
          <div className="  w-full  min-h-[200px] bg-[#FFFFFF] rounded-b-card    p-[24px] shadow-c-sm">
            <h2 className="h2">{article_details?.title}</h2>
            <DescriptionPart description={article_details?.content} />
          </div>
        )}
      </div>
    </>
  );
};

export default AdminPageDetailsLayout;
