export var Filter_text = {
  filter: {
    to: 'To',
    go: 'Go',
    price: 'Price',

    see_all: 'See ALl',
    all: 'All',
    time: 'Time',
    done: 'Done',
    country: 'Country',
    city: 'Cities',
    sort: 'Sort',
    relevance: 'Relevance',
    price_low_to_high: 'Price low to high',
    price_high_to_low: 'Price high to low',
    newest_first: 'Newest first',
    today: 'Today',

    distance: 'Distance',
    attributes: 'Attributes',
    select_attributes: 'Select Attributes',
    no_attributes: 'No Attributes available.',
    rating: 'Rating',
    title: 'Filters',
    category: 'Category',
    clear: 'Clear',
    apply: 'Apply',
  },
};
