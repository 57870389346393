import axios from 'axios';
import { get_orders } from '../../store/feature/orderSlice';

export const pawa_pay_checkup = async ({
  router,
  setIsLoading,
  dispatch,
  setOrderStatus,
}) => {
  setIsLoading(true);
  axios
    .post('/api/web_payments/check_pawa_pay', {
      depositId: router.query.depositId,
      order_reference: router.query.order_reference,
    })
    .then((res) => {
      setIsLoading(false);
      dispatch(
        get_orders({
          bodyParam: {
            page: 1,
            per_page: 30,
            order_reference: router.query.order_reference,
          },
        })
      );
      setOrderStatus('success');
    })
    .catch((err) => {
      setIsLoading(false);
    });
};
