import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountAttribute,
  clearAttributes,
  clearStoreState,
  storeSelector,
} from '../../../store/feature/storeSlice';
import PopUp from '../../Shared/PopUp/PopUp';
import { authSelector } from '../../../store/feature/authSlice';
import { useRouter } from 'next/dist/client/router';
import { add_product_click } from './addProduct2';
import SchedulePart from './schedule/SchedulePart';
import VariantsPart from './Variants/VariantsPart';
import { configsSelector } from '../../../store/feature/configsSlice';
import axios from 'axios';
import Attributes from './Attributes';
import { fetch_all_categories } from '../../../constant/fetch_all_categories';
import { ReactSortable } from 'react-sortablejs';
import ModalBasic from '../../Shared/Modal/ModalBesic';
import CropImage from '../../Shared/Image/CropImage';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { Add_product_text } from '../../../constant/Translation/AddProduct_text';
import { camera_icon } from '../../Shared/Constant/Icons/AllIcons';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import SearchAddress from '../../Shared/SearchAddress';
import SelectDropDown from '../../Shared/SelectDropDown';
import DigitalProduct from './DigitalProduct/DigitalProduct';
import OrderTypeSelection from './OrderTypeSelectioon';
import moment from 'moment';
import { validateImageSize } from '../../../utils/ImageSizeValidate';
import SwitchBox from '../../../UI/FormFields/Switch';
import Composer2 from '../../Shared/Composer/Composer2';
import Dimension from './FormItems/Dimension';
import { getCookie } from 'cookies-next';
import Timezone from './Timezone';

const AddProductForm = () => {
  const [title, setTitle] = useState('');
  const [order_type, setOrderType] = useState(
    TYPE_CONSTANT.ADD_LISTINGS_TYPES?.[0] ?? 'listings'
  );

  const [meta_title, setMetaTitle] = useState('');
  const [meta_keyword, setMetaKeyword] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState(' ');
  const [meta_description, setMetaDescription] = useState(' ');
  const [price, setPrice] = useState(0);
  const [goal_price, setGoalPrice] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [offerPercent, setOfferPercent] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [attributeData, setAttributeData] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [addressSearchKey, setAddressSearchKey] = useState('');
  const [full_location, setFullLocation] = useState(null);
  const [tags, setTags] = useState('');
  const [all_images, setAll_images] = useState([]);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [addProductLoading, setAddProductLoading] = useState(false);
  const [schedulesArray, setSchedulesArray] = useState(null);
  const [variantsArray, setVariantsArray] = useState([]);
  const [variantsType, setVariantsType] = useState(null);
  const [allCategories, setAllCategories] = useState(null);
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [seller_protection_fee_enabled, setSellerProtectionFeeEnabled] =
    useState(false);
  const [weight_uom, setWeightUom] = useState('kilogram');
  const [weight_uom_list, setWeightUomList] = useState([
    { label: 'kg', value: 'kilogram' },
  ]);
  const [size_uom, setSizeUom] = useState('centimeter');
  const [size_uom_list, setSizeUomList] = useState([
    { label: 'cm', value: 'centimeter' },
  ]);
  const [weight_value, setWeightValue] = useState('centimeter');
  const [height_value, setHeightValue] = useState(null);
  const [width_value, setWidthValue] = useState(null);
  const [length_value, setLengthValue] = useState(null);
  const [showVariantForm, setShowVariantForm] = useState(false);
  const [time_zone, setTimezone] = useState(null);

  //
  const [digitalProducts, setDigitalProducts] = useState({
    active: true,
    account_id: router?.query?.account_id,
    title: '',
    description: '',
    version_name: '',
    content: [],
  });

  const { auth_key } = useSelector(authSelector);
  const { genral_configs, MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);

  const {
    listing_configs,
    isError,
    errorMessage,
    currencies,
    listing_categories,
    attributes,
  } = useSelector(storeSelector);

  // effects
  useEffect(() => {
    axios.get('/api/variant').then((res) => {
      setVariantsType(res.data.variant_types);
    });

    return () => {
      dispatch(clearAttributes());
      setAllCategories(null);
      setCurrency(null);
    };
  }, []);

  useEffect(() => {
    if (listing_categories?.length > 0) {
      setAllCategories(fetch_all_categories(listing_categories));
    }
    if (currencies !== null) {
      setCurrency(currencies[0].id);
    }
  }, [listing_categories, currencies]);

  const dispatch = useDispatch();
  const router = useRouter();
  const accountId =
    router.query.account_id ||
    getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`);

  // Use Effect functions

  //Image Upload func:
  const imageButtonClick = () => {
    if (
      all_images.length !== parseInt(listing_configs.listing_pictures_count)
    ) {
      document.getElementById('imageButtonInput').click();
    } else {
      setShowError(true);
      setError_message("You can't add more photo");
    }
  };

  // Image
  const [imageFormOpen, setImageFormOpen] = useState(false);
  const [image_data, setImageData] = useState(null);

  // Image change handler
  const image_change_handler = (e) => {
    e.stopPropagation();
    setImageFormOpen(true);
    if (e.target.files && e.target.files.length > 0) {
      setImageData(e.target.files[0]);
    }
  };
  // image upload
  const imageUpload = async (data) => {
    if (validateImageSize(data)) {
      setAll_images([
        {
          id: data.id,
          path: data.path,
          name: data.name.replace(/\s/g, '-'),
          type: data.type,
          full_file: data.base64,
        },
        ...all_images,
      ]);
    } else {
      setShowError(true);
      setError_message(Add_product_text.addproduct.image_size_alert);
      setAddProductLoading(false);
      return false;
    }
  };

  // delete image
  const imageDelete = async (id) => {
    const all_images_filter = all_images.filter((image) => image.id !== id);
    setAll_images(all_images_filter);
  };

  // pop up close
  const closePopUP = () => {
    dispatch(clearStoreState());
    setShowError(false);
    setError_message('');
  };

  // on select category
  const onSelectCategory = (item) => {
    setSelectedCategory(item);
    setAttributeData(null);
    dispatch(
      accountAttribute({
        prams: {
          category_id: item.value,
          type: 'listings',
        },
      })
    );
  };

  return (
    <>
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearStoreState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      <div className=" w-full pt-2   flex flex-col  c-lg:flex-row items-center   justify-center    c-lg:items-start   gap-8 md:gap-2 ">
        <div className=" w-full  c-lg:w-[60%] mb-20  ">
          {/* Add listing form */}{' '}
          <div className=" bg-white p-4 md:p-6  grid grid-cols-1 gap-6 rounded-t-card shadow-c-sm">
            {/*  */}
            <h1 className="title-h1 text-start">
              {Add_product_text.addproduct.header_title}
            </h1>
            {/* Order type */}{' '}
            {TYPE_CONSTANT.THEME === 0 && (
              <OrderTypeSelection
                order_type={order_type}
                set_order_type={setOrderType}
              />
            )}
            {/* Product Photo */}
            <div className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addproduct.add_product_photo}
              </span>
              <input
                id="imageButtonInput"
                type="file"
                value={''}
                className=" hidden"
                accept=".png ,.jpg ,.svg , .gif, .webp, .jpeg"
                placeholder=""
                onChange={(e) => {
                  image_change_handler(e);
                }}
                multiple={false}
              />
              <div
                className={[
                  'mt-2  flex flex-row   flex-wrap ',
                  all_images?.length > 0 ? 'gap-4' : 'gap-0',
                ].join(' ')}
              >
                <ReactSortable
                  list={all_images}
                  setList={setAll_images}
                  animation={150}
                  group="cards"
                  // onChange={(order, sortable, evt) => {}}
                  // onEnd={(evt) => {}}
                  className="flex   items-center flex-wrap gap-4"
                >
                  {all_images.length !== 0 &&
                    all_images?.map((singleImage) => {
                      return (
                        <div
                          key={singleImage.id}
                          className=" relative w-28 cursor-move"
                        >
                          <img
                            src={singleImage.path}
                            alt="store image"
                            className="w-28 h-28  object-cover  object-left-top   shadow-c-xsm"
                          />
                          <button
                            className=" absolute -top-2 -right-2 text-primary "
                            onClick={() => imageDelete(singleImage.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                </ReactSortable>

                <button
                  className=" w-28  h-28 flex justify-center items-center     bg-gray-100 text-sm rounded-button"
                  onClick={() => imageButtonClick()}
                >
                  <span className="p-3 bg-primary rounded-full">
                    {camera_icon}
                  </span>
                </button>
              </div>
              {listing_configs?.listing_pictures_count && (
                <p className=" text-sm mt-2 font-normal text-default_gray">
                  {dynamic_translation_set(
                    Add_product_text.addproduct.alert_max_image_upload,
                    listing_configs?.listing_pictures_count
                  )}
                </p>
              )}
            </div>
            {/* Title */}
            <label className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addproduct.product_title}
              </span>
              <input
                type="text"
                className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                placeholder=""
                onChange={(e) => setTitle(e.target.value)}
              />
            </label>
            {/* Slug */}
            {listing_configs?.enable_slug && (
              <label className="block">
                <span className="text-gray-700  text-lg">
                  {Add_product_text.addproduct.listing_slug}
                </span>
                <input
                  type="text"
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder=""
                  onChange={(e) => setSlug(e.target.value)}
                />
              </label>
            )}
            {/* Description */}
            {listing_configs?.show_description != false && (
              <div className="block">
                <span className="text-gray-700  text-lg">
                  {Add_product_text.addproduct.description}
                </span>
                {listing_configs?.listing_enable_formatted_text_description !=
                false ? (
                  <Composer2
                    contents={description}
                    setContents={setDescription}
                    mainClass={' border-b-2 border-gray-200 '}
                  />
                ) : (
                  <textarea
                    value={description}
                    className="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                    rows="4"
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                )}
              </div>
            )}
            {/* Meta title */}
            {listing_configs?.meta_title && (
              <label className="block">
                <span className="text-gray-700  text-lg">
                  {Add_product_text.addproduct.listing_meta_title}
                </span>
                <input
                  type="text"
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder="Set meta title for seo "
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </label>
            )}
            {/* Meta Description */}
            {listing_configs?.meta_description && (
              <label className="block">
                <span className="text-gray-700  text-lg">
                  {Add_product_text.addproduct.listing_meta_description}
                </span>
                <textarea
                  type="text"
                  row={7}
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder="Set meta description for listing"
                  onChange={(e) => setMetaDescription(e.target.value)}
                />
              </label>
            )}
            {/* Meta keywords */}
            {listing_configs?.meta_keyword && (
              <label className="block">
                <span className="text-gray-700  text-lg">
                  {Add_product_text.addproduct.listing_meta_keyword}
                </span>
                <input
                  type="text"
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder="Set listing meta tags for seo"
                  onChange={(e) => setMetaKeyword(e.target.value)}
                />
              </label>
            )}
            {/* Address */}
            {listing_configs?.listing_address_enabled && (
              <label className="block ">
                <span className="text-gray-700  text-lg">
                  {' '}
                  {Add_product_text.addproduct.address}
                </span>
                <SearchAddress
                  setCoordinates={setCoordinates}
                  addressSearchKey={addressSearchKey}
                  setAddressSearchKey={setAddressSearchKey}
                  full_location={full_location}
                  setFullLocation={setFullLocation}
                />
              </label>
            )}
            {/*  */}
            <div className=" grid grid-cols-1 gap-6  md:grid-cols-2  md:justify-between">
              {/* Price */}
              {order_type !== 'donation' && (
                <label className="block relative">
                  <span className="text-gray-700  text-lg">
                    {Add_product_text.addproduct.price}
                  </span>
                  {/*  */}
                  <div className=" relative mt-0    w-full px-0.5 border-0 border-b-2 border-gray-200 transition  duration-700  focus:ring-0 focus:border-primary flex items-center ">
                    <select
                      className="   pl-2 pr-8 py-1 border-0  transition  duration-700 focus:ring-0 focus:border-primary text-sm   h-full "
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      {currencies?.map((currency) => (
                        <option key={currency.id} value={currency.id}>
                          {currency.code}
                        </option>
                      ))}
                    </select>
                    <input
                      value={price}
                      type="number"
                      className="  grow w-full border-0 outline-none  text-input "
                      placeholder="1"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setPrice(e.target.value);
                        }
                      }}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </div>
                </label>
              )}
              {/* Goal Price*/}
              {order_type === 'donation' && (
                <div className="block relative">
                  <span className="text-gray-700  text-lg">
                    {Add_product_text.addproduct.goal_price}
                  </span>
                  <div className=" relative mt-0    w-full px-0.5 border-0 border-b-2 border-gray-200 transition  duration-700  focus:ring-0 focus:border-primary flex items-center ">
                    <select
                      className="   pl-2 pr-8 py-1 border-0  transition  duration-700 focus:ring-0 focus:border-primary text-sm   h-full "
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      {currencies?.map((currency) => (
                        <option key={currency.id} value={currency.id}>
                          {currency.code}
                        </option>
                      ))}
                    </select>
                    <input
                      value={goal_price}
                      type="number"
                      className="  grow w-full border-0 outline-none  text-input "
                      placeholder="1"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setGoalPrice(e.target.value);
                        }
                      }}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </div>
                </div>
              )}
              {/* Offer percente */}
              {listing_configs.hide_offer_percent &&
                order_type !== 'donation' && (
                  <label className="block">
                    <span className="text-gray-700  text-lg">
                      {Add_product_text.addproduct.offer_percent}
                    </span>
                    <input
                      value={offerPercent}
                      type="number"
                      className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                      placeholder="1"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setOfferPercent(e.target.value);
                        }
                      }}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </label>
                )}

              {/* Weight */}
              {listing_configs.listing_enable_weight &&
                order_type == 'listings' && (
                  <label className="block relative">
                    <span className="text-gray-700  text-lg">
                      {Add_product_text.addproduct.weight}
                    </span>
                    <div className=" relative mt-0    w-full px-0.5 border-0 border-b-2 border-gray-200 transition  duration-700  focus:ring-0 focus:border-primary flex items-center ">
                      <input
                        value={weight_value}
                        type="number"
                        className="  grow w-full border-0 outline-none  text-input "
                        placeholder="1"
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setWeightValue(e.target.value);
                          }
                        }}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                      <select
                        className="   pl-2 pr-8 py-1 border-0  transition  duration-700 focus:ring-0 focus:border-primary text-sm   h-full "
                        onChange={(e) => setWeightUom(e.target.value)}
                      >
                        {weight_uom_list?.map((uom) => (
                          <option key={uom.value} value={uom.value}>
                            {uom.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </label>
                )}
              {/* Dimention */}
              {listing_configs.listing_enable_dimensions &&
                order_type == 'listings' && (
                  <Dimension
                    height_value={height_value}
                    setHeightValue={setHeightValue}
                    width_value={width_value}
                    length_value={length_value}
                    setLengthValue={setLengthValue}
                    setWidthValue={setWidthValue}
                    size_uom_list={size_uom_list}
                    size_uom={size_uom}
                    setSizeUom={setSizeUom}
                  />
                )}

              {/* Shipping charge */}
              {listing_configs.show_shipping_charges &&
                order_type == 'listings' && (
                  <label className="block">
                    <span className="text-gray-700  text-lg">
                      {Add_product_text.addproduct.shipping_charges}
                    </span>
                    <input
                      value={shippingCharge}
                      type="number"
                      className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                      placeholder="1"
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setShippingCharge(e.target.value);
                        }
                      }}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </label>
                )}

              {/* Stock */}
              {listing_configs.enable_stock && (
                <label className="block">
                  <span className="text-gray-700  text-lg">
                    {Add_product_text.addproduct.stock}
                  </span>
                  <input
                    value={quantity}
                    type="number"
                    className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                    placeholder="1"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setQuantity(e.target.value);
                      }
                    }}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                </label>
              )}
              {order_type === 'events' && (
                <Timezone time_zone={time_zone} setTimeZone={setTimezone} />
              )}
              {/* Start date */}
              {order_type === 'donation' && (
                <label className="block">
                  <span className="text-gray-700  text-lg">Start Date</span>
                  <input
                    type="date"
                    className="
                    mt-0
                    block
                    w-full
                    px-0.5
                 border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                    onChange={(e) =>
                      setStartAt(
                        moment(e.target.value).format('YYYY-MM-DD HH:mm:ss')
                      )
                    }
                  />
                </label>
              )}

              {/* End  date*/}
              {order_type === 'donation' && (
                <label className="block">
                  <span className="text-gray-700  text-lg">End Date</span>
                  <input
                    type="date"
                    className="
                    mt-0
                    block
                    w-full
                    px-0.5
                 border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                    onChange={(e) =>
                      setEndAt(
                        moment(e.target.value).format('YYYY-MM-DD HH:mm:ss')
                      )
                    }
                  />
                </label>
              )}
            </div>
            {/* Tags  (this tag config is confusing) */}
            {listing_configs?.listing_hide_tags && (
              <label className="block">
                <span className="text-gray-700  text-lg">
                  {Add_product_text.addproduct.tag_your_product}
                </span>
                <input
                  type="text"
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder={
                    Add_product_text.addproduct.multi_write_value_info
                  }
                  onChange={(e) => setTags(e.target.value)}
                />
              </label>
            )}
            {/*  seller_protection_fee */}
            {listing_configs?.listing_seller_protection_fee_enabled &&
              order_type == 'listings' && (
                <SwitchBox
                  title={Add_product_text.addproduct.seller_protection_fee}
                  current_value={seller_protection_fee_enabled}
                  set_new_value={(vl) => setSellerProtectionFeeEnabled(vl)}
                  tooltip={
                    Add_product_text.addproduct.seller_protection_fee_info
                  }
                />
              )}
            {/* Category  */}
            <label className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addproduct.category}
              </span>
              <SelectDropDown
                drop_down_values={allCategories?.map((item) => {
                  return { label: item.name, value: item.id };
                })}
                setSelectedItem={(item) => {
                  if (item) {
                    onSelectCategory(item);
                  } else {
                    setSelectedCategory(null);
                    setAttributeData(null);
                    dispatch(clearAttributes());
                  }
                }}
                selectedItem={selectedCategory}
              />
            </label>
            {/* Attributes */}
            <div className="  flex flex-col gap-6">
              <Attributes
                attributeData={attributeData}
                setAttributeData={setAttributeData}
                setShowError={setShowError}
                setErrormessage={setError_message}
              />
            </div>
          </div>
          {/* Variants part */}
          {variantsType?.length > 0 && listing_configs?.show_variants && (
            <>
              <div className="w-full    ">
                <VariantsPart
                  variantsArray={variantsArray}
                  setVariantsArray={setVariantsArray}
                  currency={currency}
                  order_type={order_type}
                  showVariantForm={showVariantForm}
                  setShowVariantForm={setShowVariantForm}
                />
              </div>
            </>
          )}
          {/* Schedule & Digital products */}
          {(MARKETPLACE_MODULES == 2 ||
            order_type === 'events' ||
            order_type === 'appointments' ||
            order_type === 'digital') && (
            <div className=" my-5  flex c-lg:hidden   ltr:c-lg:ml-5 rtl:c-lg:mr-5 w-full c-lg:w-[30%] flex-col gap-6 ">
              {(MARKETPLACE_MODULES == 2 ||
                order_type === 'events' ||
                order_type === 'appointments') && (
                <div className=" ">
                  <SchedulePart
                    schedulesArray={schedulesArray}
                    setSchedulesArray={setSchedulesArray}
                  />
                </div>
              )}
              {order_type === 'digital' && (
                <div>
                  <DigitalProduct
                    digitalProducts={digitalProducts}
                    setDigitalProducts={setDigitalProducts}
                  />
                </div>
              )}
            </div>
          )}
          {/* Main Button part */}
          <div className="  relative w-full  rounded-b-card h-20 bg-white  ">
            <div className="h-full   flex   justify-center  items-center ">
              <button
                type="submit"
                className="  min-w-5/6  md:min-w-48 h-12 btn-primary-fill gap-2  "
                id="Add-listing"
                onClick={() =>
                  add_product_click(
                    all_images?.map((image) => {
                      return { name: image.name, type: image.type };
                    }) || [], //this is files value , this is mapped from all_images state

                    all_images, //this is fullFile value, this is mapped from all_images state
                    title,
                    order_type,
                    slug,
                    meta_title,
                    meta_description,
                    meta_keyword,
                    description,
                    price,
                    goal_price,
                    shippingCharge,
                    offerPercent,
                    quantity,
                    coordinates,
                    full_location,
                    selectedCategory ? selectedCategory.value : null,
                    attributeData,
                    currency,
                    setShowError,
                    setError_message,
                    dispatch,
                    router,
                    listing_configs,
                    auth_key,
                    accountId,
                    setAddProductLoading,
                    schedulesArray,
                    variantsArray,
                    attributes,
                    digitalProducts,
                    setDigitalProducts,
                    startAt,
                    endAt,
                    weight_uom,
                    weight_value,
                    seller_protection_fee_enabled,
                    tags,
                    height_value,
                    width_value,
                    size_uom,
                    length_value,
                    showVariantForm,
                    time_zone
                  )
                }
                disabled={addProductLoading ? true : false}
              >
                {addProductLoading && (
                  <svg
                    className="animate-spin -ml-1  h-5 w-5  "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                {Add_product_text.addproduct.add_product}
              </button>
            </div>
          </div>
        </div>

        {/* Schedule part & Digital products part*/}
        {(MARKETPLACE_MODULES == 2 ||
          order_type === 'events' ||
          order_type === 'appointments' ||
          order_type === 'digital') && (
          <div className="hidden  c-lg:flex  ltr:c-lg:ml-5 rtl:c-lg:mr-5 w-full c-lg:w-[30%]   flex-col gap-6">
            {(MARKETPLACE_MODULES == 2 ||
              order_type === 'events' ||
              order_type === 'appointments') && (
              <div className=" ">
                <SchedulePart
                  schedulesArray={schedulesArray}
                  setSchedulesArray={setSchedulesArray}
                />
              </div>
            )}

            {order_type === 'digital' && (
              <div>
                <DigitalProduct
                  digitalProducts={digitalProducts}
                  setDigitalProducts={setDigitalProducts}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/* Image Select Crop functionality*/}
      <ModalBasic
        title="Image Edit"
        id="image-edit"
        modalOpen={imageFormOpen}
        setModalOpen={setImageFormOpen}
        main_div_width={400}
        main_div_height={500}
        closeByOutsideClick={false}
      >
        <CropImage
          imageUpload={imageUpload}
          image_data={image_data}
          setImageData={setImageData}
          imageFormOpen={imageFormOpen}
          setImageFormOpen={setImageFormOpen}
        />
      </ModalBasic>
    </>
  );
};

export default AddProductForm;
