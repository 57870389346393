/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Address_text } from '../../../../../constant/Translation/Address';
import { General_text } from '../../../../../constant/Translation/General';
import { Orders_text } from '../../../../../constant/Translation/OrdersText';
import { TYPE_CONSTANT } from '../../../../../constant/Web_constant';

const TrackingForm = ({
  onSubmit,
  handleSubmit,
  register,
  setForm,
  setValue,
  shipment_details,
}) => {
  useEffect(() => {
    if (shipment_details) {
      Object.entries(shipment_details).forEach(([key, value]) => {
        {
          setValue(key, shipment_details[key] ?? '');
        }
      });
    }
  }, [shipment_details]);
  return (
    <form
      className=" w-screen xs:w-[500px] mt-7 mb-7 bg-[#FEFEFE] rounded-card   p-[31px] relative"
      onSubmit={handleSubmit(onSubmit)}
    >
      <button
        className=" absolute top-0 ltr:right-0 rtl:left-0 mt-5  ltr:mr-5 rtl:ml-5 text-primary "
        onClick={() => setForm(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <h2 className="text-2xl font-bold">
        {shipment_details
          ? Orders_text.orderdetail.edit_shipment_button
          : Orders_text.orderdetail.add_shipment_button}
      </h2>
      <div className="mt-6  w-full">
        <div className="grid grid-cols-1 gap-6">
          <label className="block">
            <span className="text-gray-700">
              {Orders_text.orderdetail.provider}
            </span>
            <input
              type="text"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
              placeholder=""
              {...register('provider', {
                required: true,
              })}
            />
          </label>
          <label className="block">
            <span className="text-gray-700">
              {Orders_text.orderdetail.tracking_url}
            </span>
            <input
              type="text"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
              placeholder=""
              {...register('tracking_url', {
                required: true,
              })}
            />
          </label>
          <label className="block">
            <span className="text-gray-700">
              {Orders_text.orderdetail.tracking_number}
            </span>
            <input
              type="text"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
              placeholder=""
              {...register('tracking_number', {})}
            />
          </label>
        </div>
      </div>
      <div className="mt-6 flex justify-center">
        <button className=" btn-primary-fill" type="submit">
          {shipment_details
            ? General_text.general.edit
            : General_text.general.save}
        </button>
      </div>
    </form>
  );
};

export default TrackingForm;
