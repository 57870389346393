/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import Link from 'next/link';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

// Import Swiper styles
import 'swiper/css/bundle';

import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';

import axios from 'axios';
import { check_login } from '../../../constant/check_auth';
import { Home_page_text } from '../../../constant/Translation/Home';
import AccountCollectionsSkeleton from '../../Shared/skeletonUI/AccountsCollectionsSkeleton';
import {
  homePageSectionOptimisticUpdate,
  homePageSelector,
  setError,
} from '../../../store/feature/Pages/Home/homePageSlice';
import { HomeCardBoxDefaultStyles } from '../../../constant/default_styles/HomeDefaultStyles';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import DynamicAccountsCarousel from '../../AllAccounts/Accounts/DynamicAccountsCarousel';
import { get_data_by_current_language } from '../../../utils/GetDataByLanguage';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const StoresForFollow3 = ({
  account_collection,
  section_details,
  styles,
  extra_data,
}) => {
  const { home_page_sections } = useSelector(homePageSelector);

  const dispatch = useDispatch();
  const router = useRouter();

  //follow button handler
  const follow = (id, isFollow) => {
    if (check_login(router)) {
      followDataOptimisticUpdate({ isFollow, id });

      axios
        .post('/api/a/follow_account', { id, isFollow })
        .then((res) => {
          if (res.data) {
            // dispatch(getHomePageSection({ sections_data: [section_details] }));
          }
        })
        .catch((error) => {
          followDataOptimisticUpdate({ isFollow: !isFollow, id });
          dispatch(setError([{ msg: error?.response?.data?.message }]));
        });
    }
  };

  // follow data optimistic  update
  const followDataOptimisticUpdate = ({ isFollow, id }) => {
    dispatch(
      homePageSectionOptimisticUpdate(
        home_page_sections.map((section) => {
          if (section.order_number == section_details?.order_number) {
            return {
              ...section,
              data: [
                {
                  ...section.data[0],
                  accounts: [
                    ...section.data[0].accounts?.map((acc) => {
                      if (acc.id !== id) {
                        return acc;
                      } else {
                        return { ...acc, following: !isFollow };
                      }
                    }),
                  ],
                },
              ],
            };
          } else {
            return section;
          }
        })
      )
    );
  };

  return account_collection ? (
    <div className=" " style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          HomeCardBoxDefaultStyles
        )}
      >
        {/* header */}

        {extra_data?.is_show_header !== false && (
          <div className="flex justify-between items-center mb-4  ">
            <h2 className="heading-h2">
              {extra_data?.title
                ? get_data_by_current_language({
                    data_list: extra_data?.translated_data,
                    key_name: 'extra_data.title',
                    default_data: extra_data?.title,
                  })
                : account_collection.title}
            </h2>
            <Link
              href={{
                pathname: `/ac/${
                  account_collection.slug
                    ? account_collection.slug
                    : account_collection.id
                }`,
              }}
              passHref
            >
              <a className=" block text-base text-primary font-semibold cursor-pointer">
                {Home_page_text.home.view_all}
              </a>
            </Link>
          </div>
        )}

        <div className=" ">
          <DynamicAccountsCarousel
            accounts={account_collection?.accounts}
            account_follow={follow}
          />
        </div>
      </div>
    </div>
  ) : (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          HomeCardBoxDefaultStyles
        )}
      >
        <AccountCollectionsSkeleton />
      </div>
    </div>
  );
};

export default memo(StoresForFollow3);
