import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { memo } from 'react';
import { Layer_text } from '../../../constant/Translation/LayerText';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import default_image from '../../../assets/Images/success/default.jpg';
import Image from 'next/image';
import BlogsSkeleton from '../../Shared/skeletonUI/BlogsSkeleton';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const Latest_blogs2 = ({ blogs, styles }) => {
  return blogs ? (
    blogs?.length > 0 && (
      <div className=" " style={get_dynamic_styles(styles?.main_styles)}>
        <div className="flex justify-between items-center  #ffff  ">
          <h2 className=" heading-h2">{Layer_text.layer.latest_blogs}</h2>
          <Link
            href={{
              pathname: `/b`,
            }}
            passHref
          >
            <a className=" block text-base text-primary font-semibold cursor-pointer">
              {Layer_text.layer.view_all}
            </a>
          </Link>
        </div>
        <div className=" mt-4   ">
          <Swiper
            slidesPerView="auto"
            slidesPerGroup={1}
            spaceBetween={16}
            loop={false}
            navigation={false}
          >
            {blogs?.map((item) => (
              <SwiperSlide
                className="max-w-[300px]  min-h-[159px]  pb-2  rounded-card   overflow-hidden"
                key={Math.random() * 3000000}
                style={{
                  width:
                    'calc(var(--listingCardMinWidth)*var(--listingCardPerRow)/var(--listingCardPerRow))',
                }}
              >
                <div className=" relative">
                  <Link href={`/b/${item.slug}`}>
                    <a className=" block w-full  min-h-[159px] bg-[var(--listingCardBackgroundColor)]   rounded-[var(--listingCardBorderRadius)] overflow-hidden cursor-pointer  shadow-listing_card pb-  transition ease-in-out duration-500 group-hover:bg-[var(--listingCardHoverBackgroundColor)] group-hover:shadow-listing_card_hover">
                      <div
                        className={[
                          'max-w-[300px] h-[159px] relative  object-cover object-left-top rounded-[var(--listingCardImageBorderRadius)]  overflow-hidden',
                          TYPE_CONSTANT.STYLES_CONFIGS?.listing_card?.hover
                            ?.image_zoom_in &&
                            'transition ease-in-out delay-150  group-hover:-translate-y-2 group-hover:scale-110  duration-300',
                        ].join(' ')}
                      >
                        {item?.cover_image_path ? (
                          <img
                            className=" h-full w-full  object-cover"
                            src={getThumbnailImage(item?.cover_image_path)}
                            alt="cover image"
                          />
                        ) : (
                          <Image
                            src={default_image}
                            layout="responsive"
                            objectFit="cover"
                          />
                        )}
                      </div>
                      <div className="mt-3 ltr:pl-2 rtl:pr-2 mb-4">
                        <p className="  text-[length:var(--listingCardTitleTextSize)] text-[color:var(--listingCardTitleColor)]   font-[--listingCardTitleTextFontWeight] leading-[--listingCardTitleTextLineHeight]  truncate  ">
                          {item.title}
                        </p>
                      </div>
                    </a>
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    )
  ) : (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <BlogsSkeleton />
    </div>
  );
};

export default memo(Latest_blogs2);
