import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from 'react-big-calendar';
import { useRouter } from 'next/router';
import {
  get_orders_by_calender,
  store_orderSelector,
} from '../../../../store/feature/store_orderSlice';
import { filter_id } from '../../../Shared/Constant/Status';
import { useAppDispatch, useAppSelector } from '../../../../hooks/Redux';
import { getMonthRange } from '../../../../utils/getMonthRange';

const mLocalizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  });

/**
 * We are defaulting the localizer here because we are using this same
 * example on the main 'About' page in Storybook
 */
export default function StoreOrdersCalenderView({
  localizer = mLocalizer,
  showDemoLink = true,
  orders,
  ...props
}) {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const accountId = router.query.account_id;
  const [monthRange, setMonthRange] = useState(getMonthRange(new Date()));

  //
  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: new Date(),
      views: Object.keys(Views).map((k) => Views[k]),
    }),
    []
  );

  //
  useEffect(() => {
    const params = {
      ...router?.query,
      schedule_start_at: monthRange.startDate.split('T')[0],
      schedule_end_at: monthRange.endDate.split('T')[0],
    };

    if (params.order_status) {
      params.order_status = filter_id(router.query.order_status);
    }
    dispatch(
      get_orders_by_calender({
        bodyParam: {
          account_id: accountId,
          ...params,
        },
      })
    );
  }, [router.query.order_status, monthRange]);

  //

  const { isOrderByCalenderFetching, orders_list_by_calender } =
    useAppSelector(store_orderSelector);

  return (
    <div className=" min-h-[600px] h-[80dvh]" {...props}>
      <Calendar
        components={components}
        defaultDate={defaultDate}
        events={
          isOrderByCalenderFetching
            ? []
            : orders_list_by_calender
                ?.map((order) =>
                  order?.order_details?.map((item) => ({
                    id: `${order.reference_number}-${item.id}`,
                    title: item?.listing?.title,
                    start: new Date(item?.schedule_start_at),
                    end: new Date(item?.schedule_end_at),
                  }))
                )
                .flat()
        }
        localizer={localizer}
        max={max}
        showMultiDayTimes
        step={60}
        views={views}
        onNavigate={(date) => {
          const range = getMonthRange(date);
          const { startDate: sd, endDate: ed } = range;
          if (monthRange.startDate !== sd || monthRange.endDate !== sd) {
            setMonthRange(range);
          }
        }}
        onSelectEvent={(event) => {
          router.push(`/orders/${event.id.split('-')[0]}`);
        }}
      />
    </div>
  );
}
StoreOrdersCalenderView.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
  showDemoLink: PropTypes.bool,
};
