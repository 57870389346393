/* eslint-disable @typescript-eslint/no-var-requires */
import axios from 'axios';
import { getCookie } from 'cookies-next';
import { Feedback_text } from '../../constant/Translation/FeedbackText';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

export const submit_feedback = async (
  event,
  files,
  fullFile,
  feedback_data,
  category,
  setShowError,
  setError_message,
  setSubmitLoading,
  setSuccessSubmit,
  device_info,
  setSelectedCategory,
  setFeedbackFields,
  setAll_images,
  fieldsref
) => {
  setSubmitLoading(true);
  if (category === null || category == undefined) {
    setShowError(true);
    setError_message(Feedback_text.feedback.category_error);
    setSubmitLoading(false);
    return false;
  }
  if (
    feedback_data?.title == undefined ||
    feedback_data?.title?.replace(/\s/g, '').length <= 0
  ) {
    setShowError(true);
    setError_message(Feedback_text.feedback.title_error);
    setSubmitLoading(false);
    return false;
  }

  // Get Image url function:
  const images_uri = async (all_images) => {
    let all_images_uri = [];
    let upload_images = [];
    let upload_images_files = [];
    for (let i = 0; i < all_images.length; i++) {
      const element = all_images[i];

      // Set in state
      if (element.full_file === null) {
        all_images_uri.push(element.path);
      } else {
        upload_images.push({ name: element.name, type: element.type });
        upload_images_files.push(element.full_file);
      }

      // Upload images checking
      if (all_images.length === i + 1 && upload_images.length > 0) {
        try {
          const response = await axios.post('/api/generateS3ImageURL', {
            data: {
              files: upload_images,
            },
          });

          const responseFiles = response.data.result;
          for (let index = 0; index < responseFiles.length; index++) {
            const path = responseFiles[index].signedUrl;
            const fileURI = responseFiles[index].fileUri;

            try {
              const res = await fetch(path, {
                method: 'PUT',
                headers: {
                  ContentType: upload_images[index].type,
                },
                body: upload_images_files[index],
              });

              if (res.ok) {
                all_images_uri.push(fileURI);
              }
            } catch (error) {
              setShowError(true);
              // console.log('>>>>>>>>>', error);
              setError_message(error?.response?.data?.message);
              setSubmitLoading(false);
            }
          }
        } catch (error) {
          // console.log('>>>>>>>>>', error);
          setShowError(true);
          setError_message(error?.response?.data?.message);
          setSubmitLoading(false);
        }
      }

      // return all images url
      if (all_images_uri.length == all_images.length) {
        return all_images_uri;
      }
    }
  };

  try {
    const images_response =
      fullFile.length > 0 ? await images_uri(fullFile) : [];

    const feedBackData = {
      category: category?.value,
      type: category?.value,
      title: feedback_data?.title,
      device_info: {
        device_name: device_info?.browser.name,
        device_manufacturer: device_info?.browser.name,
        device_model: device_info?.os.name,
        os_version: device_info?.os.version,
        language: getCookie(`${TYPE_CONSTANT.DOMAIN}_default_language`),
        client_type: 3,
      },
    };
    if (feedback_data?.mood) {
      feedBackData['mood'] = feedback_data?.mood;
    }
    if (feedback_data?.order_reference) {
      feedBackData['order_reference'] = feedback_data?.order_reference;
    }
    if (feedback_data?.email) {
      feedBackData['email'] = feedback_data?.email;
    }
    if (feedback_data?.description) {
      feedBackData['description'] = feedback_data?.description;
    }
    if (images_response?.length > 0) {
      feedBackData['attachments'] = images_response;
    }

    // post listing function

    axios
      .post('/api/feedback/create', {
        data: feedBackData,
      })
      .then((res) => {
        setSuccessSubmit(true);
        setSubmitLoading(false);
        setSelectedCategory(null);
        setFeedbackFields({});
        setAll_images([]);
        fieldsref.current.value = '';

        // 👇️ clear all input values in the form
      })
      .catch((error) => {
        setShowError(true);
        setError_message(error?.response?.data.message);
        setSubmitLoading(false);
      });
  } catch (error) {
    setShowError(true);
    setError_message(error.response?.data?.message);
    setSubmitLoading(false);
  }
};
