/* eslint-disable react/prop-types */
import React from 'react';
import { Listing_text } from '../../../constant/Translation/Listing';
import { rating_icon } from '../../Shared/Constant/Icons/AllIcons';
import AverageRating from './average_rating';

const RatingBox2 = ({ rating_data }) => {
  return (
    <div className=" bg-white rounded-card   w-full min-h-[66px] px-4   grid       lg:grid-cols-[45%,55%] xl:grid-cols-2  items-center justify-center ">
      <div className="flex items-center gap-5">
        <h2 className="heading-h2">
          {rating_data?.review_count} {Listing_text.product.reviews}
        </h2>
        <AverageRating rating_value={Number(rating_data.rating_average)} />
      </div>
    </div>
  );
};

export default RatingBox2;
