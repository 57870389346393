/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import {
  addToCart,
  deleteCart,
  cartList,
  cartSelector,
  clearCartState,
} from '../../../store/feature/cartSlice';
import PopUp from '../../Shared/PopUp/PopUp';
import OutsideClickHandler from 'react-outside-click-handler';
import { configsSelector } from '../../../store/feature/configsSlice';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { Checkout_text } from '../../../constant/Translation/Cart_text';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';

const CartItemBox2 = ({ cart, cart_details }) => {
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  const { MARKETPLACE_MODULES, listings_configs } =
    useSelector(configsSelector);

  const dispatch = useDispatch();
  const { login, auth_key } = useSelector(authSelector);
  const { isError, isSuccess, errorMessage, currencies } =
    useSelector(cartSelector);

  const updateCartQuantity = (listing, quantity, increase) => {
    let cartData;
    if (increase) {
      if (quantity < listing.max_quantity) {
        if (
          (quantity === listing.stock || quantity > listing.stock) &&
          listings_configs?.enable_stock
        ) {
          setShowError(true);
          setError_message(Checkout_text.cart.alert_stock_available);

          return false;
        } else {
          cartData = {
            cart: {
              listing_id: listing.id,
              quantity: quantity + 1,
            },
          };
        }
      } else {
        setShowError(true);
        setError_message(
          `${Checkout_text.cart.max_quantity}: ${listing.max_quantity} `
        );
        return false;
      }
    } else {
      if (quantity > 1) {
        cartData = {
          cart: {
            listing_id: listing.id,
            quantity: quantity - 1,
          },
        };
      } else {
        delete_cart(listing.id);
        return false;
      }
    }
    dispatch(addToCart({ authKey: auth_key, data: cartData })).then((res) => {
      if (!res.payload.code) {
        dispatch(cartList({}));
      }
    });
  };

  const delete_cart = (id) => {
    dispatch(
      deleteCart({
        authKey: auth_key,
        data: {
          cart: {
            listing_id: [id],
          },
        },
      })
    ).then((res) => {
      if (!res.payload.code) {
        dispatch(cartList({}));
      }
    });
  };
  const closePopUP = () => {
    dispatch(clearCartState());
    setShowError(false);
    setError_message('');
  };

  return cart_details ? (
    <>
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearCartState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {cart_details.map((cartItem) => {
        return cartItem.variant ? (
          <div
            className=" w-full   flex items-start  gap-2  mb-4"
            key={cartItem.id}
          >
            <div className="flex-grow">
              {listings_configs?.enable_stock && (
                <p className=" text-xs  font-semibold leading-6 text-primary">
                  {dynamic_translation_set(
                    Checkout_text.cart.in_stock,
                    cartItem?.variant.stock
                  )}
                </p>
              )}
              <p className=" text-base text-black font-medium  ">
                {cartItem.listing.title}
              </p>

              <div className="flex flex-wrap items-center justify-start">
                {cartItem?.variant?.variant_values?.map((vr_value, index) => {
                  return (
                    <p
                      key={vr_value.variant_type.id}
                      className=" mt-[8px] text-default_gray text-xs font-medium flex flex-wrap items-center"
                    >
                      {vr_value.variant_type.name}:
                      {vr_value.variant_type_value.name} {index !== 0 && ','}
                    </p>
                  );
                })}
              </div>

              {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 &&
                cartItem?.listing?.account?.name && (
                  <p className=" mt-[8px] text-default_gray text-xs font-medium flex flex-wrap items-center">
                    {Checkout_text.cart.by} {cartItem.listing?.account?.name}
                  </p>
                )}
              <p className=" mt-[11px] text-default_gray   font-medium flex flex-wrap items-center text-xs     ">
                {cartItem?.variant?.list_price.formatted}
              </p>
            </div>
            <div className="  min-w-[60px] h-[20px]   flex justify-between items-center  mt-1">
              <button
                onClick={() =>
                  updateCartQuantity(cartItem.listing, cartItem.quantity, false)
                }
                className=" w-[20px] h-[20px] bg-primary  ltr:rounded-l-button rtl:rounded-r-button flex justify-center items-center text-xl leading-6 font-medium text-white"
              >
                -
              </button>
              <span className=" flex-grow text-sm leading-4 font-medium text-[#4A4A4A]  flex justify-center items-center ">
                {cartItem.quantity}
              </span>
              <button
                onClick={() =>
                  updateCartQuantity(cartItem.listing, cartItem.quantity, true)
                }
                className="w-[20px] h-[20px] bg-primary  ltr:rounded-r-button rtl:rounded-l-button flex justify-center items-center text-xl leading-6 font-medium  text-white "
              >
                +
              </button>
            </div>
          </div>
        ) : (
          <div
            className=" w-full     flex items-start  gap-2 mb-4"
            key={cartItem.id}
          >
            <div className="flex-grow">
              {listings_configs?.enable_stock && (
                <p className=" text-xs  font-medium leading-6 text-primary">
                  {dynamic_translation_set(
                    Checkout_text.cart.in_stock,
                    cartItem.listing.stock
                  )}
                </p>
              )}
              <p className=" text-base text-black font-medium  ">
                {cartItem.listing.title}
              </p>
              {TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 &&
                cartItem?.listing?.account?.name && (
                  <p className=" mt-[8px] text-default_gray text-xs font-medium flex flex-wrap items-center">
                    {Checkout_text.cart.by} {cartItem.listing?.account?.name}
                  </p>
                )}
              <p className=" mt-[11px] text-default_gray   font-medium flex flex-wrap items-center text-xs ">
                {cartItem.listing.list_price.formatted}
              </p>
            </div>
            <div className="   min-w-[60px] h-[20px] border border-primary rounded-button  flex justify-between items-center mt-1">
              <button
                onClick={() =>
                  updateCartQuantity(cartItem.listing, cartItem.quantity, false)
                }
                className=" w-[20px] h-[20px] bg-primary  ltr:rounded-l-button rtl:rounded-r-button flex justify-center items-center text-xl leading-6 font-medium text-white"
              >
                -
              </button>
              <span className=" flex-grow text-sm leading-4 font-medium text-[#4A4A4A]  flex justify-center items-center ">
                {cartItem.quantity}
              </span>
              <button
                onClick={() =>
                  updateCartQuantity(cartItem.listing, cartItem.quantity, true)
                }
                className="w-[20px] h-[20px] bg-primary  ltr:rounded-r-button rtl:rounded-l-button flex justify-center items-center text-xl leading-6 font-medium  text-white "
              >
                +
              </button>
            </div>
          </div>
        );
      })}
    </>
  ) : (
    <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItemBox2;
