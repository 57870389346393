/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import favorite from '../../../assets/Images/Home/favourite@3x.png';
import heartIcon from '../../../assets/Images/Home/heartIcon@3x.png';
import {
  changeDateFormat,
  getThumbnailImage,
  getThumbnailImage2,
} from '../Constant/Constant';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { Listing_text } from '../../../constant/Translation/Listing';
import ALink from '../../../constant/ALink';
import { express_icon } from '../Constant/Icons/AllIcons';

const ListingCard = ({ item, like, embed }) => {
  const router = useRouter();
  const MARKETPLACE_MODULES = TYPE_CONSTANT.MARKETPLACE_MODULES;
  return (
    <div className="  relative group">
      <ALink
        href={
          item.slug
            ? `/l/${item.slug}`
            : `/l/${item.id}-${item.title.replace(
                /[ /?,#!@#$%^&*=]+/g,
                '-'
              )}?id=true`
        }
        isEmbed={embed}
      >
        <a className=" block w-full  min-h-[210px] bg-[var(--listingCardBackgroundColor)]   rounded-[var(--listingCardBorderRadius)] overflow-hidden cursor-pointer  shadow-listing_card pb-3  transition ease-in-out duration-500 group-hover:bg-[var(--listingCardHoverBackgroundColor)] group-hover:shadow-listing_card_hover">
          {item?.images?.length > 0 && (
            <div className="aspect-w-1 aspect-h-1">
              <img
                className={[
                  'w-full h-full object-cover object-left-top rounded-[var(--listingCardImageBorderRadius)]  overflow-hidden',
                  TYPE_CONSTANT.STYLES_CONFIGS?.listing_card?.hover
                    ?.image_zoom_in &&
                    'transition ease-in-out delay-150  group-hover:-translate-y-2 group-hover:scale-110  duration-300',
                ].join(' ')}
                src={getThumbnailImage(item?.images[0])}
                onError={(e) => {
                  e.target.src = item?.images[0];
                }}
                alt={item.title}
              />
            </div>
          )}

          {/* {MARKETPLACE_MODULES === 2 && (
          <p className=" mt-2 pl-2 text-[10px] leading-3 text-gray-900  font-medium">
            {changeDateFormat(item.start_at, 'dddd Do MMM YYYY')}
          </p>
        )} */}
          <div className="mt-2 ltr:pl-2 rtl:pr-2">
            <h3
              className="  text-[length:var(--listingCardTitleTextSize)] text-[color:var(--listingCardTitleColor)]  font-[--listingCardTitleTextFontWeight]
leading-[--listingCardTitleTextLineHeight]"
            >
              {item.title.length > 18
                ? item.title.substring(0, 18) + '..'
                : item.title}
            </h3>
            <p className="  text-[length:var(--listingCardPriceTextSize)] text-[color:var(--listingCardPriceColor)]   font-[--listingCardPriceTextFontWeight] leading-[--listingCardPriceTextLineHeight] mt-1 flex items-center flex-wrap gap-2">
              <span className=" font-semibold">
                {MARKETPLACE_MODULES == 3
                  ? `${Listing_text.product.by} ${
                      item.account?.name.length > 20
                        ? item.account?.name.substring(0, 18) + '..'
                        : item.account?.name
                    }`
                  : item.offer_price.formatted}
              </span>

              {MARKETPLACE_MODULES !== 3 && item?.offer_percent > 0 && (
                <>
                  <span className="  line-through ">
                    {item.list_price.formatted}
                  </span>
                  <span className="text-error  ">{item?.offer_percent}%</span>
                </>
              )}
            </p>
          </div>
        </a>
      </ALink>

      {!embed && (
        <div
          className=" h-[40px] w-[40px]  absolute ltr:right-0 rtl:left-0 top-0 cursor-pointer    "
          onClick={() => like(item.id, item.liked)}
        >
          {item.liked ? (
            <Image
              src={favorite}
              alt="follow button"
              layout="fill"
              objectFit="cover"
              require
            />
          ) : (
            <Image
              src={heartIcon}
              alt="follow button"
              layout="fill"
              objectFit="cover"
              require
            />
          )}
        </div>
      )}

      {TYPE_CONSTANT.SHIPPING_CONFIGS.fulfillment_by_platform_enabled && (
        <div
          className="  absolute top-0  ltr:left-0 rtl:right-0"
          onClick={() => like(item.id, item.liked)}
        >
          {item?.fulfilled_by_platform &&
            TYPE_CONSTANT.SHIPPING_CONFIGS.fulfilled_by_platform_icon_path && (
              <img
                className="w-auto h-auto max-w-[80px]"
                alt="image"
                src={
                  TYPE_CONSTANT.SHIPPING_CONFIGS.fulfilled_by_platform_icon_path
                }
              />
            )}
          {!item?.fulfilled_by_platform &&
            TYPE_CONSTANT.SHIPPING_CONFIGS.fulfilled_by_seller_icon_path && (
              <img
                className="w-auto h-auto max-w-[80px]"
                alt="image"
                src={
                  TYPE_CONSTANT.SHIPPING_CONFIGS.fulfilled_by_seller_icon_path
                }
              />
            )}
        </div>
      )}
    </div>
  );
};

export default ListingCard;
