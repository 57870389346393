import React, { useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactSortable } from 'react-sortablejs';
import { Feedback_text } from '../../constant/Translation/FeedbackText';
import { camera_icon } from '../Shared/Constant/Icons/AllIcons';
import CustomLoading from '../Shared/Loading/CustomLoading';
import PopUp from '../Shared/PopUp/PopUp';
import SuccessPopUp from '../Shared/PopUp/Success';
import SelectDropDown from '../Shared/SelectDropDown';
import { submit_feedback } from './Submit_feedback';
import { UAParser } from 'ua-parser-js';
import { useRouter } from 'next/router';
import { General_text } from '../../constant/Translation/General';
import { validateImageSize } from '../../utils/ImageSizeValidate';

const FeedbackForm = ({ categories }) => {
  const router = useRouter();
  const [is_loading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [feedback_fields, setFeedbackFields] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  //
  useEffect(() => {
    if (router?.query?.type == 'orders') {
      categories?.filter((item) => item.type == router?.query?.type)?.length >
        0 &&
        setSelectedCategory({
          label: categories?.filter(
            (item) => item.type == router?.query?.type
          )[0].name,
          value: categories?.filter(
            (item) => item.type == router?.query?.type
          )[0].type,
        });

      if (router?.query?.reference_number) {
        setFeedbackFields((prevState) => ({
          ...prevState,
          ['order_reference']: router?.query?.reference_number,
        }));
      }
    }
  }, [router, categories]);

  //
  let fieldsref = useRef(null);

  // pop up close
  const closePopUP = () => {
    setShowError(false);
    setError_message('');
  };
  const closeSuccessPopUP = () => {
    setShowError(false);
    setError_message('');
    setSubmitSuccess(false);
    window.location.reload();
  };

  //Image Upload func:
  const imageButtonClick = () => {
    if (all_images.length !== 4) {
      document.getElementById('imageButtonInput').click();
    } else {
      setShowError(true);
      setError_message(Feedback_text.feedback?.max_attachment_message);
    }
  };

  // Image
  const [all_images, setAll_images] = useState([]);
  const [image_data, setImageData] = useState(null);
  // image upload
  const imageUpload = async (data) => {
    setAll_images([
      {
        id: data.id,
        path: data.path,
        name: data.name,
        type: data.type,
        full_file: data.base64,
      },
      ...all_images,
    ]);
  };

  // delete image
  const imageDelete = async (id) => {
    const all_images_filter = all_images.filter((image) => image.id !== id);
    setAll_images(all_images_filter);
  };

  const moods = [
    { mood: 1, icon: '😠' },
    { mood: 2, icon: '😟' },
    { mood: 3, icon: '😐' },
    { mood: 4, icon: '😊' },
    { mood: 5, icon: '😍' },
  ];

  console.log(selectedCategory);

  return (
    categories && (
      <div>
        {showError && (
          <OutsideClickHandler
            onOutsideClick={() => {
              showError && (setShowError(false), setError_message(''));
            }}
          >
            <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
              <div className="">
                <PopUp message={error_message} closePopUP={closePopUP} />
              </div>
            </div>
          </OutsideClickHandler>
        )}
        {submitSuccess && (
          <OutsideClickHandler
            onOutsideClick={() => {
              submitSuccess && setSubmitSuccess(false),
                window.location.reload();
            }}
          >
            <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
              <div className="">
                <SuccessPopUp
                  message={Feedback_text.feedback.sent_successfully}
                  closePopUP={closeSuccessPopUP}
                />
              </div>
            </div>
          </OutsideClickHandler>
        )}
        {is_loading && <CustomLoading />}

        <div className=" w-full pt-2  pb-[100px] flex flex-col  c-lg:flex-row items-center   justify-center    c-lg:items-start   gap-8 md:gap-2 ">
          <div className=" p-5 bg-white shadow-md rounded-md  max-w-xl w-full">
            <h3 className=" font-semibold text-primary text-2xl mb-4 text-center">
              {Feedback_text.feedback.header_title}
            </h3>
            <div className="flex flex-col gap-3">
              <label className="block">
                <span className="text-gray-700  font-bold">
                  {Feedback_text.feedback.categories}
                </span>
                <SelectDropDown
                  drop_down_values={categories?.map((item) => {
                    return { label: item.name, value: item.type };
                  })}
                  setSelectedItem={(item) => {
                    if (item) {
                      setSelectedCategory(item);
                    } else {
                      setSelectedCategory(null);
                    }
                  }}
                  selectedItem={selectedCategory}
                />
              </label>

              {/* Ref */}
              {selectedCategory?.value == 'orders' && (
                <label className="block">
                  <span className="text-gray-700  font-bold">
                    {Feedback_text.feedback.order_ref}
                  </span>
                  <input
                    value={feedback_fields?.order_reference}
                    type="text"
                    className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                    placeholder="1234"
                    onChange={(e) => {
                      setFeedbackFields((prevState) => ({
                        ...prevState,
                        ['order_reference']: e.target.value,
                      }));
                    }}
                  />
                </label>
              )}
              {/* mood */}
              {selectedCategory?.value == 'general_feedback' && (
                <div className="block">
                  <span className="text-gray-700  font-bold">
                    {Feedback_text.feedback.reaction_title}
                  </span>
                  <div className="flex items-center gap-3 mt-1">
                    {moods.map((item) => {
                      return (
                        <div className="block">
                          <button
                            onClick={() =>
                              setFeedbackFields((prevState) => ({
                                ...prevState,
                                ['mood']: item.mood,
                              }))
                            }
                            className={[
                              ' relative text-xl p-1.5  rounded-full  border border-transparent hover:border-primary hover:shadow-md transition duration-300',
                              feedback_fields?.mood == item.mood &&
                                'border-primary shadow-md',
                            ].join(' ')}
                          >
                            {item.icon}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {/*  */}

              <label className="block">
                <span className="text-gray-700  font-bold">
                  {Feedback_text.feedback.title}
                </span>
                <input
                  value={feedback_fields?.title}
                  type="text"
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  onChange={(e) => {
                    setFeedbackFields((prevState) => ({
                      ...prevState,
                      ['title']: e.target.value,
                    }));
                  }}
                />
              </label>

              {/*  */}

              <label className="block">
                <span className="text-gray-700  font-bold">
                  {Feedback_text.feedback.contactInfo_title}
                </span>
                <input
                  value={feedback_fields?.email}
                  type="text"
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder={Feedback_text.feedback.email_mobileNo}
                  onChange={(e) => {
                    setFeedbackFields((prevState) => ({
                      ...prevState,
                      ['email']: e.target.value,
                    }));
                  }}
                />
              </label>

              {/*  */}

              <label className="block">
                <span className="text-gray-700  font-bold">
                  {Feedback_text.feedback.additional_information}
                </span>
                <textarea
                  value={feedback_fields?.description}
                  type="text"
                  className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                  placeholder={Feedback_text.feedback.additional_information}
                  onChange={(e) => {
                    setFeedbackFields((prevState) => ({
                      ...prevState,
                      ['description']: e.target.value,
                    }));
                  }}
                />
              </label>

              {localStorage.getItem('login') && (
                <div className="block">
                  <span className="text-gray-700  font-bold">
                    {Feedback_text.feedback.attachment}
                  </span>
                  <input
                    id="imageButtonInput"
                    type="file"
                    value={''}
                    className=" hidden"
                    accept=".png , .jpg"
                    placeholder=""
                    onChange={(e) => {
                      e.stopPropagation();

                      if (validateImageSize(e.target.files[0])) {
                        if (e.target.files && e.target.files.length > 0) {
                          imageUpload({
                            id: e.target.files[0].lastModified,
                            path: URL.createObjectURL(e.target.files[0]),
                            name: e.target.files[0].name,
                            type: e.target.files[0].type,
                            base64: e.target.files[0],
                          });
                        }
                      } else {
                        setShowError(true);
                        setError_message(General_text.general.image_size_alert);
                      }
                    }}
                    multiple={false}
                  />
                  <div className="mt-1 flex flex-col   gap-2">
                    <ReactSortable
                      list={all_images}
                      setList={setAll_images}
                      animation={150}
                      group="cards"
                      // onChange={(order, sortable, evt) => {}}
                      // onEnd={(evt) => {}}
                      className="flex   items-center flex-wrap gap-4"
                    >
                      {all_images.length !== 0 &&
                        all_images?.map((singleImage) => {
                          return (
                            <div
                              key={singleImage.id}
                              className=" relative w-[100px] cursor-move"
                            >
                              <img
                                src={singleImage.path}
                                alt="store image"
                                className="w-[100px] h-[100px]  object-cover  object-left-top   shadow-c-xsm"
                              />
                              <button
                                className=" absolute -top-2 -right-2 text-primary "
                                onClick={() => imageDelete(singleImage.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </button>
                            </div>
                          );
                        })}
                    </ReactSortable>

                    <button
                      type="button"
                      className=" w-[100px]  h-[100px] flex justify-center items-center     bg-gray-100 text-sm rounded-button"
                      onClick={() => imageButtonClick()}
                    >
                      <span className="p-[15px] bg-primary rounded-full">
                        {camera_icon}
                      </span>
                    </button>
                  </div>

                  <p className=" text-sm mt-2 font-normal text-default_gray">
                    {Feedback_text.feedback.images_information}
                  </p>
                </div>
              )}
            </div>

            <div className="flex items-center justify-center my-3">
              <button
                className="max-w-lg px-3 py-2 bg-primary border border-primary hover:bg-transparent text-white hover:text-primary rounded-button transition duration-300 flex items-center justify-center gap-2  "
                type="submit"
                onClick={(event) =>
                  !submitLoading &&
                  submit_feedback(
                    event,
                    all_images?.map((image) => {
                      return { name: image.name, type: image.type };
                    }) || [], //this is files value , this is mapped from all_images state

                    all_images, //this is fullFile value, this is mapped from all_images state

                    feedback_fields,
                    selectedCategory,
                    setShowError,
                    setError_message,
                    setSubmitLoading,
                    setSubmitSuccess,
                    UAParser(),
                    setSelectedCategory,
                    setFeedbackFields,
                    setAll_images,
                    fieldsref
                  )
                }
              >
                {submitLoading && (
                  <svg
                    className="animate-spin -ml-1  h-5 w-5  "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                {Feedback_text.feedback.submit_feedback}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default FeedbackForm;
