//hover_animation
export const hover_animation = {
  group_scale_animation: ' scale-100 hover:scale-105 duration-300',
  bg_color_animation: ' bg-opacity-0 hover:bg-opacity-100 duration-300',
};

// group_hover_animation
export const group_hover_animation = {
  scale_animation: '  scale-100 group-hover:scale-105 duration-300',
  bg_color_animation: '  bg-opacity-0 group-hover:bg-opacity-100 duration-300',
};
