export var Feedback_text = {
  feedback: {
    categories: 'Categories',
    order_ref: 'Order reference',

    additional_information: 'Additional informations',
    attachment: 'Attachment',
    attachments: 'Attachments',
    category_error: 'Category should not be empty',
    contactInfo_title: 'Can we contact you for more input?',
    contact_email_invalid: 'Email address entered is invalid.',
    contact_email_invalid: 'Email address entered is invalid.',
    contact_info_email_placeholder: 'Email address',
    contact_info_email_placeholder: 'Email address',
    contact_info_phone_placeholder: 'Phone number',
    contact_info_phone_placeholder: 'Phone number',
    contact_info_placeholder: 'Email address or Phone number',
    email_mobileNo: 'Email or Phone number',
    emoji_title: 'What do you think of our app?',
    header_title: 'Feedback',
    images_error: 'Maximum of only 4 photos can be attached',
    images_information: 'Max. 4 photos',
    max_attachment_message: 'More than 4 files cannot be selected',
    rating_error: 'Rating should not be empty',
    reaction_title: 'What do you think of our app',
    sending_feedback: 'Sending feedback',
    sent_successfully: 'Feedback sent successfully',
    submit_feedback: 'Submit Feedback',
    tell_us_about_it: 'Tell us about it',
    title: 'What would you like to share with us?',
    title_error: 'Title should not be empty',
    upload_attachment: 'Uploading attachment',
    feedback_submit_success: 'Feedback submitted successfully!',
  },
};
