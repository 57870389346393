import React from 'react';
import SelectFromList from '../../../UI/FormFields/SelectFromList';

const Timezone = ({ time_zone, setTimeZone }) => {
  const timeZones = [
    {
      label: 'Pacific/Midway',
      value: 'Pacific/Midway',
    },
    { label: 'US/Samoa', value: 'US/Samoa' },
    { label: 'US/Hawaii', value: 'US/Hawaii' },
    { label: 'US/Alaska', value: 'US/Alaska' },
    { label: 'US/Pacific', value: 'US/Pacific' },
    {
      label: 'America/Tijuana',
      value: 'America/Tijuana',
    },
    { label: 'US/Arizona', value: 'US/Arizona' },
    { label: 'US/Mountain', value: 'US/Mountain' },
    {
      label: 'America/Chihuahua',
      value: 'America/Chihuahua',
    },
    {
      label: 'America/Mazatlan',
      value: 'America/Mazatlan',
    },
    {
      label: 'America/Mexico_City',
      value: 'America/Mexico_City',
    },
    {
      label: 'America/Monterrey',
      value: 'America/Monterrey',
    },
    {
      label: 'Canada/Saskatchewan',
      value: 'Canada/Saskatchewan',
    },
    { label: 'US/Central', value: 'US/Central' },
    { label: 'US/Eastern', value: 'US/Eastern' },
    {
      label: 'US/East-Indiana',
      value: 'US/East-Indiana',
    },
    {
      label: 'America/Bogota',
      value: 'America/Bogota',
    },
    { label: 'America/Lima', value: 'America/Lima' },
    {
      label: 'America/Caracas',
      value: 'America/Caracas',
    },
    {
      label: 'Canada/Atlantic',
      value: 'Canada/Atlantic',
    },
    {
      label: 'America/La_Paz',
      value: 'America/La_Paz',
    },
    {
      label: 'America/Santiago',
      value: 'America/Santiago',
    },
    {
      label: 'Canada/Newfoundland',
      value: 'Canada/Newfoundland',
    },
    {
      label: 'America/Buenos_Aires',
      value: 'America/Buenos_Aires',
    },
    { label: 'Greenland', value: 'Greenland' },
    {
      label: 'Atlantic/Stanley',
      value: 'Atlantic/Stanley',
    },
    {
      label: 'Atlantic/Azores',
      value: 'Atlantic/Azores',
    },
    {
      label: 'Atlantic/Cape_Verde',
      value: 'Atlantic/Cape_Verde',
    },
    {
      label: 'Africa/Casablanca',
      value: 'Africa/Casablanca',
    },
    {
      label: 'Europe/Dublin',
      value: 'Europe/Dublin',
    },
    {
      label: 'Europe/Lisbon',
      value: 'Europe/Lisbon',
    },
    {
      label: 'Europe/London',
      value: 'Europe/London',
    },
    {
      label: 'Africa/Monrovia',
      value: 'Africa/Monrovia',
    },
    {
      label: 'Europe/Amsterdam',
      value: 'Europe/Amsterdam',
    },
    {
      label: 'Europe/Belgrade',
      value: 'Europe/Belgrade',
    },
    {
      label: 'Europe/Berlin',
      value: 'Europe/Berlin',
    },
    {
      label: 'Europe/Bratislava',
      value: 'Europe/Bratislava',
    },
    {
      label: 'Europe/Brussels',
      value: 'Europe/Brussels',
    },
    {
      label: 'Europe/Budapest',
      value: 'Europe/Budapest',
    },
    {
      label: 'Europe/Copenhagen',
      value: 'Europe/Copenhagen',
    },
    {
      label: 'Europe/Ljubljana',
      value: 'Europe/Ljubljana',
    },
    {
      label: 'Europe/Madrid',
      value: 'Europe/Madrid',
    },
    { label: 'Europe/Paris', value: 'Europe/Paris' },
    {
      label: 'Europe/Prague',
      value: 'Europe/Prague',
    },
    { label: 'Europe/Rome', value: 'Europe/Rome' },
    {
      label: 'Europe/Sarajevo',
      value: 'Europe/Sarajevo',
    },
    {
      label: 'Europe/Skopje',
      value: 'Europe/Skopje',
    },
    {
      label: 'Europe/Stockholm',
      value: 'Europe/Stockholm',
    },
    {
      label: 'Europe/Vienna',
      value: 'Europe/Vienna',
    },
    {
      label: 'Europe/Warsaw',
      value: 'Europe/Warsaw',
    },
    {
      label: 'Europe/Zagreb',
      value: 'Europe/Zagreb',
    },
    {
      label: 'Europe/Athens',
      value: 'Europe/Athens',
    },
    {
      label: 'Europe/Bucharest',
      value: 'Europe/Bucharest',
    },
    { label: 'Africa/Cairo', value: 'Africa/Cairo' },
    {
      label: 'Africa/Harare',
      value: 'Africa/Harare',
    },
    {
      label: 'Europe/Helsinki',
      value: 'Europe/Helsinki',
    },
    {
      label: 'Europe/Istanbul',
      value: 'Europe/Istanbul',
    },
    {
      label: 'Asia/Jerusalem',
      value: 'Asia/Jerusalem',
    },
    { label: 'Europe/Kiev', value: 'Europe/Kiev' },
    { label: 'Europe/Minsk', value: 'Europe/Minsk' },
    { label: 'Europe/Riga', value: 'Europe/Riga' },
    { label: 'Europe/Sofia', value: 'Europe/Sofia' },
    {
      label: 'Europe/Tallinn',
      value: 'Europe/Tallinn',
    },
    {
      label: 'Europe/Vilnius',
      value: 'Europe/Vilnius',
    },
    { label: 'Asia/Baghdad', value: 'Asia/Baghdad' },
    { label: 'Asia/Kuwait', value: 'Asia/Kuwait' },
    {
      label: 'Africa/Nairobi',
      value: 'Africa/Nairobi',
    },
    { label: 'Asia/Riyadh', value: 'Asia/Riyadh' },
    { label: 'Asia/Tehran', value: 'Asia/Tehran' },
    {
      label: 'Europe/Moscow',
      value: 'Europe/Moscow',
    },
    { label: 'Asia/Baku', value: 'Asia/Baku' },
    {
      label: 'Europe/Volgograd',
      value: 'Europe/Volgograd',
    },
    { label: 'Asia/Muscat', value: 'Asia/Muscat' },
    { label: 'Asia/Tbilisi', value: 'Asia/Tbilisi' },
    { label: 'Asia/Yerevan', value: 'Asia/Yerevan' },
    { label: 'Asia/Kabul', value: 'Asia/Kabul' },
    { label: 'Asia/Karachi', value: 'Asia/Karachi' },
    {
      label: 'Asia/Tashkent',
      value: 'Asia/Tashkent',
    },
    { label: 'Asia/Kolkata', value: 'Asia/Kolkata' },
    {
      label: 'Asia/Kathmandu',
      value: 'Asia/Kathmandu',
    },
    {
      label: 'Asia/Yekaterinburg',
      value: 'Asia/Yekaterinburg',
    },
    { label: 'Asia/Almaty', value: 'Asia/Almaty' },
    { label: 'Asia/Dhaka', value: 'Asia/Dhaka' },
    {
      label: 'Asia/Novosibirsk',
      value: 'Asia/Novosibirsk',
    },
    { label: 'Asia/Bangkok', value: 'Asia/Bangkok' },
    { label: 'Asia/Jakarta', value: 'Asia/Jakarta' },
    {
      label: 'Asia/Krasnoyarsk',
      value: 'Asia/Krasnoyarsk',
    },
    {
      label: 'Asia/Chongqing',
      value: 'Asia/Chongqing',
    },
    {
      label: 'Asia/Hong_Kong',
      value: 'Asia/Hong_Kong',
    },
    {
      label: 'Asia/Kuala_Lumpur',
      value: 'Asia/Kuala_Lumpur',
    },
    {
      label: 'Australia/Perth',
      value: 'Australia/Perth',
    },
    {
      label: 'Asia/Singapore',
      value: 'Asia/Singapore',
    },
    { label: 'Asia/Taipei', value: 'Asia/Taipei' },
    {
      label: 'Asia/Ulaanbaatar',
      value: 'Asia/Ulaanbaatar',
    },
    { label: 'Asia/Urumqi', value: 'Asia/Urumqi' },
    { label: 'Asia/Irkutsk', value: 'Asia/Irkutsk' },
    { label: 'Asia/Seoul', value: 'Asia/Seoul' },
    { label: 'Asia/Tokyo', value: 'Asia/Tokyo' },
    {
      label: 'Australia/Adelaide',
      value: 'Australia/Adelaide',
    },
    {
      label: 'Australia/Darwin',
      value: 'Australia/Darwin',
    },
    { label: 'Asia/Yakutsk', value: 'Asia/Yakutsk' },
    {
      label: 'Australia/Brisbane',
      value: 'Australia/Brisbane',
    },
    {
      label: 'Australia/Canberra',
      value: 'Australia/Canberra',
    },
    { label: 'Pacific/Guam', value: 'Pacific/Guam' },
    {
      label: 'Australia/Hobart',
      value: 'Australia/Hobart',
    },
    {
      label: 'Australia/Melbourne',
      value: 'Australia/Melbourne',
    },
    {
      label: 'Pacific/Port_Moresby',
      value: 'Pacific/Port_Moresby',
    },
    {
      label: 'Australia/Sydney',
      value: 'Australia/Sydney',
    },
    {
      label: 'Asia/Vladivostok',
      value: 'Asia/Vladivostok',
    },
    { label: 'Asia/Magadan', value: 'Asia/Magadan' },
    {
      label: 'Pacific/Auckland',
      value: 'Pacific/Auckland',
    },
    { label: 'Pacific/Fiji', value: 'Pacific/Fiji' },
  ];

  return (
    <>
      <SelectFromList
        drop_down_items={timeZones}
        current_value={time_zone}
        set_new_value={(value) => setTimeZone(value)}
        dropdownBlockStyles={'border-0 max-h-[300px]'}
        title={'Timezone'}
        styles={{
          popover_style: '  h-auto max-h-[250px]',
          title_styles: 'text-gray-700 text-base',
          field_styles: `  
                     mt-0
                    block
                    w-full
                    px-0.5
                    py-1.5
                     border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary outline-none outline-transparent rounded-none `,
        }}
        default_option_text={'Select Timezone'}
      />
    </>
  );
};

export default Timezone;
