import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CartBox from '../../components/Cart/BasketCard/CartBox';
import Banner3 from '../../components/home/Banner/Banner3';
import Banner2 from '../../components/home/Banner/Bannner2';
import Categories from '../../components/home/Categories/Categories';
import Categories3 from '../../components/home/Categories/Categories3';
import LatestListings2 from '../../components/home/LatestListings/LatestListings2';
import LatestListings4 from '../../components/home/LatestListings/LatestListings4';
import StoresForFollow from '../../components/home/Stores/StoresForFollow';
import ListingCard4 from '../../components/Shared/Cards/ListingCard4';
import { authSelector } from '../../store/feature/authSlice';
import {
  cartList,
  cartSelector,
  getCurrencies,
} from '../../store/feature/cartSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';

const HomepageLayout = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(homeCollections({}));
  //   dispatch(
  //     AllPromoBanners({
  //       bodyParam: { placement: 'footer' },
  //     })
  //   );

  //   dispatch(cartList());
  // }, []);

  const {
    collections,
    isFetching,
    isSuccess,
    errorMessage,
    categories,
    promo_banners,
    page_promo_banners,
  } = useSelector(homeSelector);

  const { cart, cart_details } = useSelector(cartSelector);

  const { general_configs, MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);
  return (
    <div className=" ">
      <div className="w-full">
        <Banner2 banners={promo_banners} />
      </div>
      <div className="max-w-7xl px-3 md:px-9 mx-auto  py-5 md:py-5 lg:flex flex-col  lg:flex-row   gap-4  mt-10">
        <div className=" lg:flex-grow w-full md:flex    md:flex-row  gap-4">
          <div className=" md:flex-none  w-full md:w-auto  h-auto md:self-start sticky  py-1 md:py-0 bg-white md:bg-transparent   top-0 md:top-2  ">
            <Categories3 categories={categories} />
          </div>

          <div className="flex-grow w-full flex flex-col gap-4">
            <LatestListings4 />
          </div>
        </div>
        <div className="flex-none  max-w-xs  lg:w-[310px]  lg:self-start fixed  bottom-5 right-5 lg:right-0  lg:sticky lg:top-2  ">
          <CartBox />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default HomepageLayout;
