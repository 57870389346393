export function getMonthRange(date) {
  const year = date.getFullYear();
  const month = date.getMonth();

  // Get the first day of the month
  const startDate = new Date(year, month, 1);

  // Get the last day of the month
  const endDate = new Date(year, month + 1, 0);

  // Format dates in ISO 8601 format
  const isoStartDate = startDate.toISOString();
  const isoEndDate = endDate.toISOString();

  return { startDate: isoStartDate, endDate: isoEndDate };
}
