/* eslint-disable react/prop-types */
import React from 'react';
import ReadingComposer from '../Shared/Composer/ReadingComposer';

const DescriptionPart = ({ description }) => {
  return description !== undefined || description === '' ? (
    <div className="  mt-4 pb-5">
      {description && (
        <ReadingComposer
          contents={description}
          mainClass={` prose prose-sm sm:prose lg:prose-lg xl:prose-xl m-0 focus:outline-none   prose-a:text-primary  prose-a:border-0 hover:prose-a:underline prose-img:m-0`}
        />
      )}
    </div>
  ) : (
    <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionPart;
