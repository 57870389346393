import { useRouter } from 'next/dist/client/router';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import EditProfile from '../../components/EditProfile/EditProfile';
import ProfileLayout1 from '../common_layouts/ProfileLayout1';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const EditProfilePageLayout = () => {
  const { login } = useSelector(authSelector);
  const router = useRouter();

  return (
    <div className="flex  flex-col  justify-center w-full max-w-3xl mx-auto">
      <div className="w-full flex justify-center">
        <EditProfile />
      </div>
    </div>
  );
};

export default EditProfilePageLayout;
