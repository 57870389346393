import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const LayerTItle = ({ data, styles }) => {
  return (
    <div className="   " style={get_dynamic_styles(styles?.main_styles)}>
      <h2 style={get_dynamic_styles(styles?.title_styles)}>{data?.title}</h2>
    </div>
  );
};

export default LayerTItle;
