/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  changeDateFormat,
  getThumbnailImage,
  getThumbnailImage2,
} from '../Constant/Constant';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import ModalBasic from '../Modal/ModalBesic';
import PaymentCard from '../../PaymentCard/PaymentCard';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import DirectCheckout from '../../PaymentCard/DirectCheckout';

const FundingListingCard = ({ item, like }) => {
  const router = useRouter();
  const MARKETPLACE_MODULES = TYPE_CONSTANT.MARKETPLACE_MODULES;
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  // const stripePromise = loadStripe(
  //   TYPE_CONSTANT.PAYMENT_CONFIGS?.stripe_api_publishable_key
  // );

  return (
    <div className="  relative group">
      <Link
        href={
          item.slug
            ? `/l/${item.slug}`
            : `/l/${item.id}-${item.title.replace(
                /[ /?,#!@#$%^&*=]+/g,
                '-'
              )}?id=true`
        }
        key={item.id}
      >
        <a className="  w-full    px-4  mb-1 flex  items-start justify-start border border-transparent group-hover:border-gray-300  rounded-image p-4 cursor-pointer">
          <img
            src={getThumbnailImage(item.images[0])}
            alt="image "
            className="  h-[150px]  md:h-[500px] w-[150px] md:w-[500px] rounded-image "
          />

          <div className="   md:h-[500px]  ltr:pl-3 ltr:sm:pl-7 rtl:pr-3 rtl:sm:pr-7 flex flex-col  flex-grow">
            <h4 className=" whitespace-pre-wrap text-base md:text-3xl font-semibold">
              {item?.title}
              {/* .length > 18
                ? item.title.substring(0, 18) + '..'
                : item.title */}
            </h4>
            <p className=" whitespace-pre-wrap mt-3 mb-0 text-second text-base md:text-2xl   text-primary">
              By {item?.account?.name}
              {/* .length > 20 ? item.account?.name.substring(0, 18) + '..' :
              item.account?.name */}
            </p>

            <div className="mt-6 mb-4 w-[100%] h-[25px] border-[0.5px]  border-primary   rounded-card     flex   items-center justify-start">
              <div
                className={`h-full bg-primary rounded-card   w-4/6 text-center flex items-center justify-center`}
              >
                <p className="text-[15px] font-normal text-white">80% Funded</p>
              </div>
            </div>
            <button
              className="block w-full  px-4 py-2  md:py-3 bg-primary rounded-button text-white  text-sm md:text-base font-medium  mt-auto  "
              // aria-controls="{item.title+item.id}"
              // onClick={(e) => {
              //   e.stopPropagation();
              //   setFeedbackModalOpen(true);
              // }}
            >
              DONATE
            </button>
          </div>
        </a>
      </Link>

      {/* <ModalBasic
        id="{item.title+item.id}"
        modalOpen={feedbackModalOpen}
        setModalOpen={setFeedbackModalOpen}
        title={`Donate for ${item.title}`}
      >
        
        <div className="px-5 py-4">
          <Elements stripe={stripePromise}>
            <DirectCheckout />
          </Elements>
        </div>
      </ModalBasic> */}
    </div>
  );
};

export default FundingListingCard;
