import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { getThumbnailImage } from '../../components/Shared/Constant/Constant';
import Home_filter from '../../components/home/Filter/Home_filter';
import Expert_directory_accounts from '../../components/home/Stores/Expert_directory_accounts';
import { useRouter } from 'next/router';
import axios from 'axios';
import Sub_footer from '../../components/Footer/Sub_footer';
import Latest_blogs from '../../components/home/BlogsWidget/Latest_blogs';
import Banner2 from '../../components/home/Banner/Bannner2';
import LatestListings5 from '../../components/home/LatestListings/LatestListings5';
import Categories4 from '../../components/home/Categories/Categories4';

const HomePageLayout = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  //
  // useEffect(() => {
  //   dispatch(
  //     homeCollections({
  //       authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
  //     })
  //   );
  //   dispatch(
  //     AllPromoBanners({
  //       authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
  //       bodyParam: { medium: 'web' },
  //     })
  //   );
  // }, [0]);

  //

  // Data from store
  const { categories, promo_banners } = useSelector(homeSelector);

  return (
    <>
      <div className="mt-5">
        <section className=" max-w-[1140px] mx-auto px-4 ">
          <Banner2 banners={promo_banners} />
        </section>

        <section className="mt-6 md:mt-12  ">
          <div
            className=" bg-white sticky z-20  "
            style={{
              top: `calc(${
                TYPE_CONSTANT.GENERAL_CONFIGS?.logo_height || 50
              }px + 28px) `,
            }}
          >
            <div className=" max-w-[1140px] mx-auto px-4 ">
              <Categories4 categories={categories} />
            </div>
          </div>
          <div className=" max-w-[1140px] mx-auto px-4  mt-8">
            <LatestListings5 />
          </div>
        </section>
      </div>
    </>
  );
};

export default HomePageLayout;
