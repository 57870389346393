/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import {
  addToCart,
  cartList,
  cartSelector,
  clearCartState,
} from '../../../store/feature/cartSlice';
import { authSelector } from '../../../store/feature/authSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../Shared/PopUp/PopUp';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import {
  getAllListings,
  listingDetails,
} from '../../../store/feature/listingSlice';
import { Listing_text } from '../../../constant/Translation/Listing';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const SimpleProductButtons = ({
  listing_details,
  selectedVariant,
  setError_message,
  setShowError,
}) => {
  const { login, auth_key } = useSelector(authSelector);
  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(cartSelector);
  const dispatch = useDispatch();
  const router = useRouter();
  const add_to_Cart = (isBuyNow) => {
    if (
      TYPE_CONSTANT.LISTINGS_CONFIGS?.enable_stock &&
      listing_details?.stock == 0
    ) {
      setShowError(true);
      setError_message(Listing_text.product.out_of_stock);
      return false;
    }

    //
    if (isBuyNow) {
      const cartData = {
        cart: {
          listing_id: listing_details?.id,
          quantity: 1,
        },
      };
      if (selectedVariant !== null) {
        cartData.cart['variant_id'] = selectedVariant;
      }
      dispatch(addToCart({ authKey: auth_key, data: cartData })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'buynow',
            data: {
              name: listing_details?.title,
              slug: listing_details?.slug,
              price: listing_details?.offer_price.amount,
              stock: listing_details?.stock,
              category: {
                id: listing_details?.categories[0]?.id,
                name: listing_details?.categories[0]?.name,
              },
              image: listing_details?.images,
              url: window.location.href,
            },
          });
          router.push('/checkout');
        } else {
          setShowError(true);
          setError_message(res.payload.message);
        }
      });
    } else {
      const cartData = {
        cart: {
          listing_id: listing_details?.id,
          quantity: 1,
        },
      };
      if (selectedVariant !== null) {
        cartData.cart['variant_id'] = selectedVariant;
      }
      dispatch(addToCart({ authKey: auth_key, data: cartData })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'add_cart',
            data: {
              name: listing_details?.title,
              slug: listing_details?.slug,
              price: listing_details?.offer_price.amount,
              stock: listing_details?.stock,
              category: {
                id: listing_details?.categories[0]?.id,
                name: listing_details?.categories[0]?.name,
              },
              image: listing_details?.images,
              url: window.location.href,
            },
          });
          dispatch(cartList({}));
          dispatch(
            getAllListings({
              prams: {
                page: 1,
                per_page: 10,
                status: 2,
                include: 'attributes',
              },
              authKey: auth_key,
            })
          );
        } else {
          setShowError(true);
          setError_message(res.payload.message);
        }
      });
    }
  };
  const closePopUP = () => {
    setShowError(false);
    setError_message('');
    dispatch(clearCartState());
  };
  return (
    <>
      {isFetching && <CustomLoading />}

      <div className=" w-full  flex  flex-col gap-5">
        <button
          className="   w-full  h-[44px] md:h-[52px]  flex justify-center items-center border border-primary rounded-button box-border  "
          id="add_to_cart"
          onClick={() =>
            login
              ? listing_details?.in_cart
                ? router.push('/checkout')
                : listing_details?.variants.length > 0
                ? selectedVariant === null
                  ? (setShowError(true),
                    setError_message(Listing_text.product.variant_validation))
                  : add_to_Cart(false)
                : add_to_Cart(false)
              : router.push(`/sign-in?to=${router.asPath}`)
          }
        >
          <span className=" text-base  sm:text-xl text-primary font-semibold ml-3">
            {listing_details?.in_cart
              ? Listing_text.product.go_to_cart
              : Listing_text.product.add_to_cart}
          </span>
        </button>
        <button
          className="  w-full  h-[44px]  md:h-[52px] bg-primary rounded-button flex justify-center items-center "
          id="buy_now"
          // onClick={add_to_Cart}
          onClick={() =>
            login
              ? listing_details?.variants.length > 0
                ? selectedVariant === null
                  ? (setShowError(true),
                    setError_message(Listing_text.product.variant_validation))
                  : add_to_Cart(true)
                : add_to_Cart(true)
              : router.push(`/sign-in?to=${router.asPath}`)
          }
        >
          <span className=" text-base sm:text-xl text-white font-semibold  ">
            {Listing_text.product.buy_now}
          </span>
        </button>
      </div>
    </>
  );
};

export default SimpleProductButtons;
