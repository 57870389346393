import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ALink from '../../constant/ALink';
import CustomLink from '../../constant/CustomLisnk';
import { Listing_text } from '../../constant/Translation/Listing';

const ListingCitiesPageLayout = () => {
  const [listingsCities, setListingsCities] = useState(null);

  // City
  useEffect(() => {
    axios
      .get('/api/l/locations', { params: { type: 'city', page: 1 } })
      .then((res) => {
        setListingsCities(res.data.values);
      });
  }, []);

  return (
    <div className="max-w-3xl md:pt-5 mx-auto  px-3 md:px-5">
      <h4 className="h4 mb-10 text-center ">
        {Listing_text.productlist.cities_page_title}
      </h4>
      <div className=" flex items-center justify-center flex-wrap gap-2">
        {listingsCities?.map((item) => {
          return (
            <CustomLink
              href={`/l/c/${item}`}
              className="btn-primary-fill px-2 py-1.5   sm:whitespace-nowrap   "
            >
              {item}
            </CustomLink>
          );
        })}
      </div>
    </div>
  );
};

export default ListingCitiesPageLayout;
