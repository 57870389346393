import React, { useEffect, useMemo, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from '@react-google-maps/api';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import CustomLoading from '../Shared/Loading/CustomLoading';
import DynamicListingCard from '../Shared/Cards/DynamicListingCard';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { removeKeysFromObject } from '../../utils/keyRemoveFromObject';
import { useRouter } from 'next/router';
import { useAppDispatch } from '../../hooks/Redux';
import { getDynamicPageSection } from '../../store/feature/Pages/DynamicPage/dynamicPageSlice';

const ListingsInMap = ({
  section_details,
  all_listings_data,
  styles,
  AllListingFilter,
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const { fetch_by } = section_details || {};

  //
  let coordinates_listings = useMemo(() => {
    return all_listings_data
      ? all_listings_data?.listings?.filter(
          (listing) => listing.coordinates?.latitude != undefined
        )
      : null;
  }, [all_listings_data]);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };
  const [selected_marker, setSelected_marker] = useState(
    coordinates_listings?.length > 0
      ? coordinates_listings?.[0].coordinates.latitude
      : null
  );
  const [isInfoWindowOpen, stIsInfoWindowOpen] = useState(false);

  //get more listings by page
  const moreListings = (current_page) => {
    dispatch(
      getDynamicPageSection({
        sections_data: [
          {
            ...section_details,
            fetch_by: {
              ...fetch_by,
              ...AllListingFilter,
              ...router.query,
              page: current_page,
            },
          },
        ],
      })
    );
  };

  // fetch listings base on category
  useEffect(() => {
    const filter_query_params = removeKeysFromObject({
      object_params: router.query,
      key_to_remove: ['draft', 'iframe', 'page_slug'],
    });

    //
    if (
      Object.keys(AllListingFilter)?.length > 0 ||
      Object.keys(filter_query_params)?.length > 0
    ) {
      dispatch(
        getDynamicPageSection({
          sections_data: [
            {
              ...section_details,
              extra_data: {
                ...section_details.extra_data,
                fetching_type: 'fetching_by_effect',
              },
              fetch_by: {
                ...fetch_by,
                ...AllListingFilter,
                ...filter_query_params,
              },
            },
          ],
        })
      );
    }
  }, [AllListingFilter]);

  //
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:
      TYPE_CONSTANT.ADDRESS_CONFIGS?.google_map_api_key ??
      'AIzaSyBr3Q8BS3eMq4dA0u0wrkUtW_VWgYLbtB0',
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={get_dynamic_styles(styles?.main_styles)}
      center={{
        lat: coordinates_listings?.[0]?.coordinates?.latitude,
        lng: coordinates_listings?.[0]?.coordinates?.longitude,
      }}
      zoom={10}
    >
      {coordinates_listings?.map((item) => {
        return (
          item?.coordinates?.latitude && (
            <>
              <Marker
                key={item.id}
                position={{
                  lat: item?.coordinates?.latitude,
                  lng: item?.coordinates?.longitude,
                }}
                clickable
                onClick={() => {
                  setSelected_marker(item?.coordinates?.latitude);
                  stIsInfoWindowOpen(true);
                }}
              >
                {isInfoWindowOpen &&
                  selected_marker == item.coordinates.latitude && (
                    <InfoWindow
                      position={{
                        lat: item.coordinates.latitude,
                        lng: item.coordinates.longitude,
                      }}
                      onCloseClick={() => {
                        setSelected_marker(null);
                        stIsInfoWindowOpen(null);
                      }}
                    >
                      <div className=" p-0 relative">
                        <DynamicListingCard
                          product={item}
                          is_hide_like_btn={true}
                        />
                      </div>
                    </InfoWindow>
                  )}
              </Marker>
            </>
          )
        );
      })}
    </GoogleMap>
  ) : (
    <CustomLoading />
  );
};

export default ListingsInMap;
