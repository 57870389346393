export const layer_details_default_data = {
  schema: [
    {
      columns: [
        [
          {
            data: {
              enabled: true,
            },
            block: 'layer_image',
            styles: {
              main_styles: {
                top: '0px',
                left: '0px',
                right: '0px',
                width: '100%',
                bottom: '0px',
                height: '',
                z_index: '',
                position: 'static',
                max_width: '100%',
                min_width: '',
                background: '#FFFFFF',
                box_shadow: '',
                margin_top: '0px',
                max_height: '',
                min_height: '',
                margin_left: 'px',
                padding_top: '8px',
                margin_right: 'px',
                padding_left: '8px',
                border_radius: '0px',
                margin_bottom: '0px',
                padding_right: '8px',
                padding_bottom: '8px',
                background_image_url: '',
                top_left_border_radius: '0px',
                top_right_border_radius: '0px',
                bottom_left_border_radius: '0px',
                bottom_right_border_radius: '0px',
              },
              image_styles: {
                width: '100%',
                height: '',
                max_width: '100%',
                min_width: '',
                margin_top: '0px',
                max_height: '',
                min_height: '',
                object_fit: 'cover',
                margin_left: 'px',
                padding_top: '0px',
                margin_right: 'px',
                padding_left: '0px',
                border_radius: '0px',
                margin_bottom: '0px',
                padding_right: '0px',
                padding_bottom: '0px',
                top_left_border_radius: '0px',
                top_right_border_radius: '0px',
                bottom_left_border_radius: '0px',
                bottom_right_border_radius: '0px',
              },
            },
            fetch_by: null,
            isStatic: true,
            block_code: 1,
            block_name: 'Layer Image',
            extra_data: {},
            order_number: 'row-1-col-0-1',
            block_data_key: 'listings',
          },
          {
            data: {
              enabled: true,
            },
            block: 'layer_title',
            styles: {
              main_styles: {
                top: '0px',
                left: '0px',
                right: '0px',
                width: '100%',
                bottom: '0px',
                height: '',
                z_index: '',
                position: 'static',
                max_width: '100%',
                min_width: '',
                background: '#FFFFFF',
                box_shadow: '',
                margin_top: '0px',
                max_height: '',
                min_height: '',
                margin_left: 'px',
                padding_top: '16px',
                margin_right: 'px',
                padding_left: '8px',
                border_radius: '0px',
                margin_bottom: '0px',
                padding_right: '8px',
                padding_bottom: '16px',
                background_image_url: '',
                top_left_border_radius: '0px',
                top_right_border_radius: '0px',
                bottom_left_border_radius: '0px',
                bottom_right_border_radius: '0px',
              },
              title_styles: {
                text_size: '50px',
                text_align: 'center',
                text_color: '#000000',
                font_family: 'primary_font',
                font_weight: '600',
                line_height: '54px',
              },
            },
            fetch_by: null,
            isStatic: true,
            block_code: 1,
            block_name: 'Layer Title',
            extra_data: {},
            order_number: 'row-1-col-0-3',
            block_data_key: 'layer_details',
          },
          {
            data: {
              enabled: true,
            },
            block: 'layer_description_block',
            styles: {
              box_styles: {
                top: '0px',
                left: '0px',
                right: '0px',
                width: '100%',
                bottom: '0px',
                height: '',
                z_index: '',
                position: 'static',
                max_width: '100%',
                min_width: '',
                background: '#FFFFFF',
                box_shadow: '',
                margin_top: '0px',
                max_height: '',
                min_height: '',
                margin_left: 'px',
                padding_top: '8px',
                margin_right: 'px',
                padding_left: '8px',
                border_radius: '0px',
                margin_bottom: '0px',
                padding_right: '8px',
                padding_bottom: '8px',
                background_image_url: '',
                top_left_border_radius: '0px',
                top_right_border_radius: '0px',
                bottom_left_border_radius: '0px',
                bottom_right_border_radius: '0px',
              },
              main_styles: {
                top: '0px',
                left: '0px',
                right: '0px',
                width: '100%',
                bottom: '0px',
                height: '',
                z_index: '',
                position: 'static',
                max_width: '100%',
                min_width: '',
                background: '#FFFFFF',
                box_shadow: '',
                margin_top: '0px',
                max_height: '',
                min_height: '',
                margin_left: 'px',
                padding_top: '8px',
                margin_right: 'px',
                padding_left: '8px',
                border_radius: '0px',
                margin_bottom: '0px',
                padding_right: '8px',
                padding_bottom: '8px',
                background_image_url: '',
                top_left_border_radius: '0px',
                top_right_border_radius: '0px',
                bottom_left_border_radius: '0px',
                bottom_right_border_radius: '0px',
              },
            },
            fetch_by: null,
            isStatic: true,
            block_code: 1,
            block_name: 'Layer Description',
            extra_data: {},
            order_number: 'row-1-col-0-2',
            block_data_key: '',
          },
        ],
      ],
      row_styles: {
        top: '0px',
        left: '0px',
        right: '0px',
        width: '100%',
        bottom: '0px',
        height: '',
        display: 'grid',
        row_gap: '24px',
        z_index: '',
        position: 'static',
        flex_wrap: 'nowrap',
        max_width: '100%',
        min_width: '',
        background: '#ffffffff',
        column_gap: '24px',
        margin_top: '0px',
        max_height: '',
        min_height: '',
        align_items: 'start',
        margin_left: 'px',
        padding_top: '0px',
        margin_right: 'px',
        padding_left: '0px',
        margin_bottom: '0px',
        padding_right: '0px',
        flex_direction: 'column',
        padding_bottom: '0px',
        justify_content: 'start',
        background_image_url: '',
        columns_box_percentage: '100%',
      },
      column_1_styles: {
        top: '0px',
        left: '0px',
        right: '0px',
        width: '100%',
        bottom: '0px',
        height: '',
        display: 'flex',
        row_gap: '0px',
        z_index: '',
        position: 'static',
        flex_wrap: 'nowrap',
        max_width: '100%',
        min_width: '',
        background: '',
        box_shadow: '0px 0px 0px 0px #ffffff',
        column_gap: '0px',
        margin_top: '0px',
        max_height: '',
        min_height: '',
        align_items: 'start',
        margin_left: 'px',
        padding_top: '0px',
        margin_right: 'px',
        padding_left: '0px',
        border_radius: '0px',
        margin_bottom: '0px',
        padding_right: '0px',
        flex_direction: 'column',
        padding_bottom: '0px',
        justify_content: 'start',
        background_image_url: '',
        top_left_border_radius: '0px',
        top_right_border_radius: '0px',
        bottom_left_border_radius: '0px',
        bottom_right_border_radius: '0px',
      },
      current_editor_version: '1.1.3',
    },
  ],
  styles: {
    box_styles: {
      gap: '24px',
      width: '100%',
      height: '',
      max_width: '900px',
      min_width: '',
      background: '',
      margin_top: '16px',
      max_height: '',
      min_height: '',
      margin_left: '0px',
      padding_top: '0px',
      margin_right: '0px',
      padding_left: '16px',
      margin_bottom: '16px',
      padding_right: '16px',
      padding_bottom: '16px',
      background_image_url: '',
    },
    main_styles: {
      width: '100%',
      height: '',
      max_width: '100%',
      min_width: '',
      background: '#FFFFFF',
      margin_top: '0px',
      max_height: '',
      min_height: '',
      margin_left: 'px',
      padding_top: '0px',
      margin_right: 'px',
      padding_left: '0px',
      margin_bottom: '0px',
      padding_right: '0px',
      padding_bottom: '0px',
      background_image_url: '',
    },
  },
};
