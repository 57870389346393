/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import { get_all_following_accounts } from '../../../store/feature/storeSlice';
import { useRouter } from 'next/dist/client/router';
import AccountCard from '../../Shared/Cards/AccountCard';
import { check_login } from '../../../constant/check_auth';
import axios from 'axios';
import DynamicAccountCard from '../../Shared/Cards/DynamicAccountCard';

// * Swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import SwiperCore, { Navigation, Pagination } from 'swiper';
SwiperCore.use([Navigation, Pagination]);
//*

const DynamicAccountsCarousel = ({
  accounts,
  setIsDataLoading,
  account_follow,
  isHideFollowButton = false,
}) => {
  const { login, auth_key } = useSelector(authSelector);
  const router = useRouter();
  const dispatch = useDispatch();
  const follow = (id, isFollow) => {
    if (account_follow) {
      account_follow(id, isFollow);
    } else {
      if (check_login(router)) {
        setIsDataLoading(true);
        axios
          .post('/api/a/follow_account', { id, isFollow })
          .then((res) => {
            setIsDataLoading(false);
            dispatch(
              get_all_following_accounts({
                bodyParam: {
                  page: router.query?.page ? router.query?.page : 1,
                },
              })
            );
          })
          .catch((error) => {
            setIsDataLoading(false);
            alert(error.response.data.message);
          });
      }
    }
  };

  return (
    <>
      <Swiper
        slidesPerView="auto"
        slidesPerGroup={1}
        spaceBetween={16}
        loop={false}
        navigation={false}
      >
        {accounts?.map((item) => (
          <SwiperSlide
            className="  overflow-hidden"
            key={item?.id}
            style={{
              width:
                'calc(var(--accountCardMinWidth)*var(--accountCardPerRow)/var(--accountCardPerRow))',
              minHeight: '100px',
              paddingBottom: '5px',
            }}
          >
            <DynamicAccountCard item={item} follow={follow} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default DynamicAccountsCarousel;
