/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import DescriptionPart from '../../components/ListingDetails/DescriptionPart/DescriptionPart';
import ImagePart from '../../components/ListingDetails/ImagePart/ImagePart';
import MainBox from '../../components/ListingDetails/MainBox/MainBox';
import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  clearListingState,
  getListingQNA,
  getListingReviews,
  listingDetails,
  listingLike,
  listingSelector,
} from '../../store/feature/listingSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import AttributeDetails from '../../components/ListingDetails/AttributeDetails/AttributeDetails';
import Variants from '../../components/ListingDetails/Variants/Variants';
import RatingBox from '../../components/ListingDetails/RatingBox/RatingBox';
import { check_login } from '../../constant/check_auth';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import ShareButtons from '../../components/ListingDetails/ShareButtons/ShareButtons';
import ImagePart3 from '../../components/ListingDetails/ImagePart/ImagePart3';
import ProductButtons4 from '../../components/ListingDetails/ProductButtons/ProductButtons4';

const ListingDetailsPageLayout = ({ slug, close_function }) => {
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  const [selectedVariant, setSelectedVariant] = useState(null);

  const router = useRouter();

  const dispatch = useDispatch();
  const { auth_key, login } = useSelector(authSelector);
  useEffect(() => {
    if (slug) {
      dispatch(
        listingDetails({
          slug,
          id: true,
        })
      );

      //updating state base on new listing fetching
      setSelectedVariant(null);
      setError_message('');
      setShowError(false);
    }
  }, [slug]);

  const {
    isSuccess,
    listing_details,
    rating_data,
    errorMessage,
    isError,
    reviews,
    my_review,
    review_page,
    review_total_records,
    qna,
  } = useSelector(listingSelector);

  useEffect(() => {
    // if (listing_details?.id) {
    //   dispatch(
    //     getListingReviews({
    //       authKey: auth_key,
    //       params: {
    //         type: 'listings',
    //         id: listing_details.id,
    //         page: 1,
    //       },
    //     })
    //   );
    //   dispatch(
    //     getListingQNA({
    //       params: {
    //         reference_type: 'listings_qa',
    //         reference_id: listing_details.id,
    //         page: 1,
    //         per_page: 10,
    //         parent: 0,
    //       },
    //     })
    //   );
    // }
  }, [listing_details]);

  // Button Handle
  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id: id,
          isLiked: isLiked,
          authKey: auth_key,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            listingDetails({
              slug,
              id: true,
            })
          );
        }
      });
    }
  };

  const closePopUP = () => {
    dispatch(clearListingState());
    setShowError(false);
    setError_message('');
  };

  //
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    const totalpage = Math.ceil(review_total_records / 30);
    if (Number(review_total_records) > 30) {
      setPageCount(totalpage);
    }
  }, [review_total_records]);

  // Hash anchro tag:
  React.useEffect(() => {
    const path = window.location.hash;
    if (path && path.includes('#') && listing_details) {
      setTimeout(() => {
        const id = path.replace('#', '');
        const el = window.document.getElementById(id);
        if (el) {
          const r = el.getBoundingClientRect();
          window.top.scroll({
            top: pageYOffset + r.top,
            behavior: 'smooth',
          });
        }
      }, 600);
    }
  }, [listing_details]);

  return (
    <div className={' w-full flex flex-col gap-page_default p-page_default'}>
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearListingState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      <div className=" grid grid-cols-1  lg:grid-cols-5   gap-page_default">
        <div className="w-full   lg:col-span-3 flex flex-col gap-page_default">
          <div>
            <ImagePart3
              images={
                selectedVariant
                  ? listing_details?.variants?.filter(
                      (vr) => vr.id == selectedVariant
                    )[0]?.images.length > 0
                    ? listing_details?.variants?.filter(
                        (vr) => vr.id == selectedVariant
                      )[0]?.images
                    : listing_details?.images
                  : listing_details?.images
              }
            />
          </div>
          {TYPE_CONSTANT.LISTINGS_CONFIGS?.show_description != false &&
            listing_details?.description !== '' && (
              <div className="  hidden lg:block">
                <DescriptionPart description={listing_details?.description} />
              </div>
            )}
        </div>
        <div className=" lg:col-span-2   flex flex-col gap-page_default pb-40 lg:pb-0">
          <div>
            <MainBox
              listing_details={listing_details}
              rating_data={rating_data}
              like={like}
            />
          </div>
          {TYPE_CONSTANT.LISTINGS_CONFIGS?.show_description != false &&
            listing_details?.description !== '' && (
              <div className="     lg:hidden">
                <DescriptionPart description={listing_details?.description} />
              </div>
            )}
          {listing_details?.variants?.length > 0 && (
            <div className=" ">
              <Variants
                variants={listing_details.variants}
                setSelectedVariant={setSelectedVariant}
                selectedVariant={selectedVariant}
                listing_details={listing_details}
              />
            </div>
          )}

          {TYPE_CONSTANT.LISTINGS_CONFIGS?.main_buttons != false && (
            <div className=" fixed lg:relative  bottom-0 lg:top-0 w-full p-3   left-0 right-0 bg-white lg:shadow-c-sm      z-[60] lg:z-30 sm:    ">
              <ProductButtons4
                listing_details={listing_details}
                selectedVariant={selectedVariant}
                setError_message={setError_message}
                setShowError={setShowError}
                close_function={() => {
                  close_function();
                }}
              />
            </div>
          )}

          {listing_details?.attributes &&
            listing_details?.attributes.length > 0 && (
              <div className=" ">
                <AttributeDetails attributes={listing_details?.attributes} />
              </div>
            )}

          {TYPE_CONSTANT.LISTINGS_CONFIGS?.show_description != false &&
            listing_details?.description !== '' && (
              <div className="  md:hidden">
                <DescriptionPart description={listing_details?.description} />
              </div>
            )}

          {TYPE_CONSTANT.LISTINGS_CONFIGS?.listing_share_section != false && (
            <div className=" ">
              <ShareButtons listing_details={listing_details} router={router} />
            </div>
          )}

          {rating_data &&
            Object?.keys(rating_data)?.length > 0 &&
            TYPE_CONSTANT.LISTINGS_CONFIGS?.review_and_rating != false && (
              <div className=" ">
                <RatingBox rating_data={rating_data} />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ListingDetailsPageLayout;
