import Link from 'next/link';
import Image from 'next/image';
import React, { useEffect } from 'react';
import Add_Head from '../../components/Shared/Header/Head';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import Footer from '../../components/Footer/Footer';
import Header4 from '../../components/Header/Header4';
import CustomListings from '../../components/home/Listing/CustomListings';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Sub_footer from '../../components/Footer/Sub_footer';
import { Home_page_text } from '../../constant/Translation/Home';
import Latest_blogs from '../../components/home/BlogsWidget/Latest_blogs';
import { bannersSelector } from '../../store/feature/Home/bannerSlice';
import { slug as SlugChecker } from 'github-slugger';

const CustomHomePageLayout = ({ pageTitle, pageDescription }) => {
  const dispatch = useDispatch();
  const { auth_key } = useSelector(authSelector);

  // useEffect(() => {
  //   dispatch(
  //     homeCollections({
  //       authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
  //     })
  //   );
  //   dispatch(
  //     AllPromoBanners({
  //       authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
  //       bodyParam: { medium: 'web' },
  //     })
  //   );
  // }, [auth_key, dispatch]);

  const { collections, isFetching, isSuccess, errorMessage, categories } =
    useSelector(homeSelector);
  const { promo_banners } = useSelector(bannersSelector);

  const { general_configs, MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);
  return (
    <>
      <div className=" ">
        <div className="grid md:grid-cols-2 items-center">
          <div className="flex flex-col gap-4">
            <h2 className="text-[32px] text-black font-bold pr-4">
              {general_configs?.header_text}
            </h2>
            <p className=" mt-2 text-[16px] text-black font-normal w-5/6">
              {general_configs?.header_description}
            </p>
            {/* <Link href="#">
              <a className=" mt-2 text-[16px]   text-primary font-bold">
                View all Apps
              </a>
            </Link> */}
          </div>
          <div className=" relative   aspect-w-4 aspect-h-2  ">
            {promo_banners?.length > 0 && (
              <img
                alt="image"
                src={promo_banners[0]?.image_path}
                className="  absolute  max-w-[100%]  top-[50%] ltr:left-[50%] rtl:-right-[50%]  -translate-x-1/2 -translate-y-1/2 rounded-image shadow-sm "
              />
            )}
          </div>
        </div>
        <div className="grid  md:grid-cols-4  mt-14">
          <div>
            <ul className="pt-5">
              <li className="pb-2">
                <p className="text-2xl block  ltr:text-left rtl:text-right w-full   text-primary">
                  {Home_page_text.home.categories}
                </p>
              </li>
              {categories?.length > 0 &&
                categories.map((item, index) => {
                  return (
                    <li className="py-2 " key={index}>
                      <Link
                        href={{
                          pathname: `/lc/[slug]`,
                          query: {
                            slug: item.slug
                              ? item.slug
                              : `${item?.id}-${SlugChecker(item.name)}`,
                          },
                        }}
                      >
                        <a className="text-sm block  ltr:text-left rtl:text-right w-full  ">
                          {item.name.length > 20
                            ? item.name.substring(0, 19) + '.'
                            : item.name}
                        </a>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="col-span-3 pt-5">
            {collections?.map((collection) => {
              const scope_type = collection.scope_type;

              if (scope_type === 4) {
                return (
                  <div key={Math.random()}>
                    <CustomListings products={collection} />
                  </div>
                );
              }
            })}
          </div>
        </div>
        {general_configs?.home_blogs_widget !== false && (
          <div>
            <Latest_blogs />
          </div>
        )}
      </div>
    </>
  );
};

export default CustomHomePageLayout;
