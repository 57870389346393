import React, { useEffect, useState } from 'react';
import ListingsList from '../Listings/ListingsList';
import { likeListingFromAllListings } from './actions/likeFromAllListings';
import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from '../../hooks/Redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListingDynamicSkeleton from '../Shared/skeletonUI/Listing/ListingDynamicSkeleton';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { dynamicPageSelector } from '../../store/feature/Pages/DynamicPage/dynamicPageSlice';

const ScrollTypeListings = ({
  all_listings_data,
  section_details,
  moreListings,
}) => {
  //
  const { fetch_by } = section_details || {};
  const { dynamic_page_sections } = useAppSelector(dynamicPageSelector);

  return (
    <InfiniteScroll
      dataLength={all_listings_data?.listings?.length}
      next={() => moreListings(Number(all_listings_data?.page) + 1)}
      hasMore={
        all_listings_data?.listings.length === all_listings_data?.total_records
          ? false
          : true
      }
      loader={
        <ListingDynamicSkeleton
          listings_type={'list'}
          listing_card_type={
            TYPE_CONSTANT.STYLES_CONFIGS?.listing_card?.card_type?.type
          }
          per_page={fetch_by?.per_page}
        />
      }
    >
      <ListingsList
        Products={all_listings_data?.listings}
        like_listing={(id, isLiked) =>
          likeListingFromAllListings({
            id,
            isLiked,
            router,
            dispatch,
            dynamic_page_sections,
            section_details,
          })
        }
      />
    </InfiniteScroll>
  );
};

export default ScrollTypeListings;
