import React from 'react';
import storeIcon from '../../../assets/Images/store/store.png';
import Image from 'next/image';
import { useRouter } from 'next/dist/client/router';
import { Add_store_text } from '../../../constant/Translation/AddStore_text';

const NoStore = () => {
  const router = useRouter();
  return (
    <div className=" flex flex-col justify-center    items-center  mt-10 ">
      <h2 className="heading-h2">
        {' '}
        {Add_store_text.addstore.donot_have_store}
      </h2>
      <button
        className=" px-6 py-2 bg-primary rounded-button text-white text-base mt-7"
        onClick={() => router.push('/a/create-account')}
      >
        {Add_store_text.addstore.add_store}
      </button>
      <div className=" w-[220px] h-[220px] relative mt-10">
        <Image
          src={storeIcon}
          layout="fill"
          objectFit="contain"
          alt="store Icon"
        />
      </div>
    </div>
  );
};

export default NoStore;
