/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import {
  get_all_accounts,
  get_all_blocked_accounts,
  storeSelector,
} from '../../../store/feature/storeSlice';
import { useRouter } from 'next/dist/client/router';
import AccountCard from '../../Shared/Cards/AccountCard';
import { check_login } from '../../../constant/check_auth';
import axios from 'axios';
import ErrorPopup from '../../Shared/PopUp/ErrorPopup';
import BlockAccountCard from '../../Shared/Cards/BlockAccountCard';

const BlockedAccounts = ({ accounts, setIsDataLoading }) => {
  const { login, auth_key } = useSelector(authSelector);
  const router = useRouter();
  const dispatch = useDispatch();

  // =>>>>>Error state
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // <<<<<<<<==

  const block_account = (id, isBlocked) => {
    if (check_login(router)) {
      setIsDataLoading(true);

      axios
        .post('/api/a/block', { id, isBlocked })
        .then((res) => {
          setIsDataLoading(false);
          dispatch(
            get_all_blocked_accounts({
              bodyParam: {
                page: router.query?.page ? router.query?.page : 1,
                // type: 'accounts',
                per_page: 30,
              },
            })
          );
        })
        .catch((error) => {
          setIsDataLoading(false);
          setShowError(true);
          setErrorMessage(error.response?.data?.message);
        });
    }
  };

  return (
    <>
      {' '}
      {/* Error Component>>>>> */}
      <ErrorPopup
        showError={showError}
        setShowError={setShowError}
        message={errorMessage}
        functions={() => console.log('error>>>>', errorMessage, '<<<<')}
      />
      {/* <<<<Error Component */}{' '}
      <div className="  grid grid-cols-2   gap-4  ms:gap-0  ms:grid-cols-[190px,190px] justify-around   xs:flex  xs:flex-wrap   xs:justify-center md:justify-center">
        {accounts?.map((item) => {
          return (
            <div
              className=" relative w-[190px]  ms:mb-5  ms:mr-4 "
              key={item.id}
            >
              <BlockAccountCard item={item} block={block_account} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BlockedAccounts;
