import axios from 'axios';
import tradly from 'tradly';
import { Image_upload } from '../../../../constant/functions';
import { push_data_layer } from '../../../../constant/GTMDatalayer';
import { Add_product_text } from '../../../../constant/Translation/AddProduct_text';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import { myAccountListingDetails } from '../../../../store/feature/storeSlice';
import { edit_digital_products } from '../DigitalProduct/edit_digital_product';

export const editVariantButton = async (
  variantsObject,
  setShowVariantForm,
  setIsEditVariant,
  setEditVariantData,
  setError_message,
  setShowError,
  auth_key,
  variantId,
  productId,
  setShowSuccessMessage,
  setEditVariantLoading,
  setVariantsObject,
  accountId,
  order_type,
  clearVariant
) => {
  setEditVariantLoading(true);

  // Condition check for content
  if (
    order_type === 'digital' &&
    !variantsObject?.digitalProducts?.content?.length > 0
  ) {
    setShowError(true);
    setError_message(Add_product_text.adddigitalproduct.alert_file_select);
    setEditVariantLoading(false);
    return false;
  }

  let images_uri = variantsObject?.images
    ? await Image_upload(
        [
          {
            path: variantsObject?.images?.name ? null : variantsObject?.images,
            full_file: variantsObject?.images.name
              ? variantsObject?.images
              : null,
          },
        ],
        setShowError,
        setError_message
      )
    : undefined;

  try {
    let variant_data;
    if (images_uri?.length > 0) {
      variant_data = {
        active: true,
        title: variantsObject.title || ' ',
        description: variantsObject.description || ' ',
        list_price: variantsObject.list_price || 0,
        offer_percent: variantsObject.offer_percent || 0,
        stock: variantsObject.stock || 0,
        images: images_uri,
        variant_values: variantsObject?.variant_values,
      };
    } else {
      variant_data = {
        active: true,
        title: variantsObject.title || ' ',
        description: variantsObject.description || ' ',
        list_price: variantsObject.list_price || 0,
        offer_percent: variantsObject.offer_percent || 0,
        stock: variantsObject.stock || 0,
        variant_values: variantsObject?.variant_values,
      };
    }

    const res = await axios.post('/api/variant/edit_variant', {
      productId,
      variantId,
      data: {
        variant: { ...variant_data },
      },
    });
    if (res) {
      if (variantsObject?.digitalProducts && order_type === 'digital') {
        edit_digital_products({
          digitalProductId: variantsObject?.digitalProducts?.id,
          listingId: productId,
          setShowError,
          setError_message,
          setShowSuccessMessage,
          isVariant: true,
          variantID: variantId,
          setEditLoading: setEditVariantLoading,
          accountId: accountId,
          digitalProducts: variantsObject?.digitalProducts,
          isPost: variantsObject?.digitalProducts?.id ? false : true,
        }).then((res) => {
          if (res) {
            push_data_layer({
              event: 'edit_variant',
              data: {},
            });
            setShowVariantForm(false);
            setIsEditVariant(false);
            setEditVariantData(null);
            setShowSuccessMessage(true);
            setEditVariantLoading(false);
            clearVariant();
          }
        });
      } else {
        push_data_layer({
          event: 'edit_variant',
          data: {},
        });
        setShowVariantForm(false);
        setIsEditVariant(false);
        setEditVariantData(null);
        setShowSuccessMessage(true);
        setEditVariantLoading(false);
        clearVariant();
      }
    }
  } catch (error) {
    setShowError(true);
    setError_message(error?.response?.data?.message ?? '');
    setEditVariantLoading(false);
  }
};

// DELETE VARIANT
export const deleteVariant = (variantID, productId, auth_key, dispatch) => {
  axios
    .post('/api/variant/delete_variant', { variantID, productId })
    .then((res) => {
      dispatch(myAccountListingDetails({ slug: productId, id: true }));
    });
};

// Add New VaRIANT
export const addNewVariant = async (
  variantsObject,
  setShowVariantForm,
  setIsEditVariant,
  setEditVariantData,
  setError_message,
  setShowError,
  auth_key,
  variantId,
  productId,
  setShowSuccessMessage,
  setAddVariantLoading,
  setVariantsObject,
  accountId,
  order_type,
  clearVariant
) => {
  setAddVariantLoading(true);
  let images_uri = variantsObject?.images
    ? await Image_upload(
        [
          {
            path: variantsObject?.images?.name ? null : variantsObject?.images,
            full_file: variantsObject?.images.name
              ? variantsObject?.images
              : null,
          },
        ],
        setShowError,
        setError_message
      )
    : undefined;

  try {
    let variant_data;
    if (images_uri?.length > 0) {
      variant_data = {
        active: true,
        title: variantsObject.title || ' ',
        description: variantsObject.description || ' ',
        list_price: variantsObject.list_price || 0,
        offer_percent: variantsObject.offer_percent || 0,
        stock: variantsObject.stock || 0,
        images: images_uri,
        variant_values: variantsObject.variant_values,
      };
    } else {
      variant_data = {
        active: true,
        title: variantsObject.title || ' ',
        description: variantsObject.description || ' ',
        list_price: variantsObject.list_price || 0,
        offer_percent: variantsObject.offer_percent || 0,
        stock: variantsObject.stock || 0,
        variant_values: variantsObject.variant_values,
      };
    }

    const res = await axios.post('/api/variant/edit_variant', {
      productId,
      variantId,
      data: {
        variant: { ...variant_data },
      },
    });
    if (res) {
      if (variantsObject?.digitalProducts && order_type === 'digital') {
        edit_digital_products({
          listingId: productId,
          setShowError,
          setError_message,
          setShowSuccessMessage,
          isVariant: true,
          variantID: res.data.variant.id,
          setEditLoading: setAddVariantLoading,
          accountId: accountId,
          digitalProducts: variantsObject?.digitalProducts,
          isPost: true,
        }).then((res) => {
          if (res) {
            push_data_layer({
              event: 'edit_variant',
              data: {},
            });
            setShowVariantForm(false);
            setIsEditVariant(false);
            setEditVariantData(null);
            setShowSuccessMessage(true);
            setAddVariantLoading(false);
            clearVariant();
          }
        });
      } else {
        push_data_layer({
          event: 'edit_variant',
          data: {},
        });
        setShowVariantForm(false);
        setIsEditVariant(false);
        setEditVariantData(null);
        setShowSuccessMessage(true);
        setAddVariantLoading(false);
        clearVariant();
      }
    }
  } catch (error) {
    setShowError(true);
    setError_message(error.response?.data?.message);
    setAddVariantLoading(false);
  }
};
