/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import Link from 'next/link';

// Import Swiper styles
import 'swiper/css/bundle';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { getThumbnailImage } from '../../Shared/Constant/Constant';

import { TYPE_CONSTANT } from '../../../constant/Web_constant';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);
import Search_filter from '../Filter/Search_filter';
import ReactPaginate from 'react-paginate';
import Modal from '../../Shared/Modal/Modal';
import Home_filter from '../Filter/Home_filter';
import OutsideClickHandler from 'react-outside-click-handler';
import { Account_text } from '../../../constant/Translation/Account';
import ReadingComposer from '../../Shared/Composer/ReadingComposer';

const Expert_directory_accounts = ({
  accounts,
  total_records,
  moreAccounts,
  pageCount,
  page,
}) => {
  const [isFilter_open, setIsfilter_open] = useState(null);

  return (
    <div className="">
      <div className="flex justify-between items-center mb-5  md:mb-8">
        <Search_filter />
      </div>
      <div className="mb-3 flex items-center justify-between">
        <p className="paragraph">
          {`${total_records} ${Account_text.storelist.stores_found} `}
          {/*- Page $ {page} of ${pageCount == 0 ? 1 : pageCount} */}
        </p>

        <button
          className="lg:hidden"
          onClick={() => setIsfilter_open(!isFilter_open)}
        >
          <svg
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM1 6H15C15.2652 6 15.5196 5.89464 15.7071 5.70711C15.8946 5.51957 16 5.26522 16 5C16 4.73478 15.8946 4.48043 15.7071 4.29289C15.5196 4.10536 15.2652 4 15 4H1C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6ZM19 8H1C0.734784 8 0.48043 8.10536 0.292893 8.29289C0.105357 8.48043 0 8.73478 0 9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10H19C19.2652 10 19.5196 9.89464 19.7071 9.70711C19.8946 9.51957 20 9.26522 20 9C20 8.73478 19.8946 8.48043 19.7071 8.29289C19.5196 8.10536 19.2652 8 19 8ZM15 12H1C0.734784 12 0.48043 12.1054 0.292893 12.2929C0.105357 12.4804 0 12.7348 0 13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13C16 12.7348 15.8946 12.4804 15.7071 12.2929C15.5196 12.1054 15.2652 12 15 12Z"
              fill="#121212"
            />
          </svg>
        </button>

        {isFilter_open && (
          <Modal>
            <OutsideClickHandler
              onOutsideClick={() => {
                setIsfilter_open(false);
              }}
            >
              <div className="relative w-screen xs:w-[450px] sm:w-[600px] h-[80vh] overflow-auto p-10 bg-white">
                <Home_filter />
              </div>
            </OutsideClickHandler>
          </Modal>
        )}
      </div>
      <div className="grid     gap-6  ">
        {accounts?.length > 0 ? (
          accounts?.map((item, index) => {
            return (
              <Link
                href={
                  item?.slug
                    ? `/a/${item?.slug}`
                    : `/a/${item?.id}-${item?.name.replace(
                        /[ /?,#=]+/g,
                        '-'
                      )}?id=true`
                }
                key={index}
              >
                <a className=" h-[193px]    max-w-full      flex  items-center justify-start border border-[#241c1526] transition duration-300 hover:shadow-md  rounded-image   cursor-pointer p-3 md:pl-6 md:gap-3">
                  <div className="flex-none min-w-[70px]  max-w-[144px]   w-full  h-[144px]   relative overflow-hidden flex justify-start rounded-image">
                    <img
                      src={
                        item.images.length > 0 &&
                        getThumbnailImage(item.images[0])
                      }
                      alt="image"
                      className=" max-w-[100%]   absolute rounded-image  top-[50%] left-[50%]  -translate-x-1/2 -translate-y-1/2"
                    />
                  </div>
                  <div className=" flex-grow flex-shrink  pl-3    md:pr-6  max-w-[50ch] md:max-w-[70ch] xl:max-w-[90ch]">
                    <div className="">
                      <h6 className="  h6">
                        {item.name.length > 18
                          ? item.name.substring(0, 18) + '..'
                          : item.name}
                      </h6>
                      {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 && (
                        <p className=" mt-2 mb-0  truncate text-primary paragraph">
                          {Account_text.storelist.by}{' '}
                          {item?.user?.first_name.length > 20
                            ? item?.user?.first_name.substring(0, 18) + '..'
                            : item?.user?.first_name}
                        </p>
                      )}
                      <p className=" mt-2 mb-0 caption md:body">
                        <ReadingComposer
                          contents={
                            item?.description.length > 100
                              ? item?.description.substring(0, 100) + '..'
                              : item?.description
                          }
                          isReadOnly={true}
                        />
                      </p>
                    </div>
                  </div>
                </a>
              </Link>
            );
          })
        ) : (
          <div className=" h-[193px] w-[500px] ">
            <span className="   md:ml-2">
              {Account_text.storelist.no_stores_found}
            </span>
          </div>
        )}
      </div>

      <div className="mt-6 flex justify-center">
        {accounts !== null && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            }
            onPageChange={(data) => moreAccounts(data)}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel={
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            }
            renderOnZeroPageCount={null}
            containerClassName=""
            className="relative z-0 inline-flex flex-wrap justify-center rounded-button shadow-sm -space-x-px "
            pageClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center text-sm font-medium"
            pageLinkClassName="px-4 py-2 border"
            previousClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            nextClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            breakLinkClassName="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
            activeLinkClassName="z-10 bg-primary  border-primary text-white relative inline-flex items-center px-4 py-2 border text-md font-semibold"
            disabledLinkClassName=""
            prevPageRel="2"
            forcePage={page - 1}
          />
        )}
      </div>
    </div>
  );
};

export default Expert_directory_accounts;
