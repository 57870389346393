import React from 'react';
import { Orders_text } from '../../../constant/Translation/OrdersText';
import {
  calender_icon2,
  list_icon,
} from '../../Shared/Constant/Icons/AllIcons';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import OrdersFilter from './OrdersFilter/OrdersFilter';

const OrdersPageHeader = ({ isShowCalender, setIsShowCalender }) => {
  return (
    <div className=" grid  grid-cols-[25%,75%]   sm:grid-cols-[35%,65%] xl:grid-cols-[50%,50%] 2xl:grid-cols-[50%,50%]  mb-11 items-center">
      <h2 className="  text-xl sm:text-3xl   font-bold text-[#042C5C] leading-10">
        {Orders_text.orderlist.orders_title}
      </h2>
      <div className=" flex  flex-wrap justify-end gap-8">
        {TYPE_CONSTANT.ORDERS_CONFIGS?.show_orders_view_type && (
          <div className=" hidden md:flex  gap-4">
            <button
              className={[
                'p-2',
                !isShowCalender && 'bg-primary  text-white rounded-button',
              ].join(' ')}
              onClick={() => setIsShowCalender(!isShowCalender)}
            >
              {list_icon}
            </button>
            <button
              className={[
                'p-2',
                isShowCalender && 'bg-primary  text-white rounded-button',
              ].join(' ')}
              onClick={() => setIsShowCalender(true)}
            >
              {calender_icon2}
            </button>
          </div>
        )}
        <OrdersFilter />
      </div>
      <div></div>
      {TYPE_CONSTANT.ORDERS_CONFIGS?.show_orders_view_type && (
        <div className=" md:hidden flex gap-4 justify-end py-2">
          <button
            className={[
              'p-2',
              !isShowCalender && 'bg-primary  text-white rounded-button',
            ].join(' ')}
            onClick={() => setIsShowCalender(!isShowCalender)}
          >
            {list_icon}
          </button>
          <button
            className={[
              'p-2',
              isShowCalender && 'bg-primary  text-white rounded-button',
            ].join(' ')}
            onClick={() => setIsShowCalender(true)}
          >
            {calender_icon2}
          </button>
        </div>
      )}
    </div>
  );
};

export default OrdersPageHeader;
