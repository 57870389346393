import axios from 'axios';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { authSelector } from '../../../store/feature/authSlice';

const Add_post_button = ({ title }) => {
  const { login, user_details } = useSelector(authSelector);
  const router = useRouter();

  const [isAccount, setIsAccount] = useState(
    Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`)
  );
  const [accountId, setAccountID] = useState(
    Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)
  );
  useEffect(() => {
    axios
      .get('/api/a/my_account', {
        params: {
          page: 1,
          type: 'accounts',
          user_id: user_details.id,
        },
      })
      .then((res) => {
        if (res?.data?.accounts?.length > 0) {
          setIsAccount(true);
          setAccountID(res?.data?.accounts[0].id);
        } else {
          setIsAccount(false);
        }
      });
  }, [router, user_details]);
  return (
    <div className="w-full flex items-center justify-end">
      <button
        className="btn-sm bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary "
        onClick={() => {
          if (login) {
            if (isAccount) {
              router.push({
                pathname: '/c/add_post',
                query: { account_id: accountId },
              });
            } else {
              router.push('/a/create-account');
            }
          } else {
            router.push(`/sign-in?to=${router.asPath}`);
          }
        }}
      >
        {title}
      </button>
    </div>
  );
};

export default Add_post_button;
