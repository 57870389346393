import { useRouter } from 'next/dist/client/router';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import EditProfile from '../../components/EditProfile/EditProfile';
import SubscriptionsList from '../../components/Subscription/SubscriptionsList';
import SubscriptionDetails from '../../components/Subscription/SubscriptionDetails';

const SubscriptionPageLayout = () => {
  const { login } = useSelector(authSelector);
  const router = useRouter();

  return (
    <div className="  w-full space-y-10">
      <SubscriptionsList />
      <SubscriptionDetails subscription_id={8} />
    </div>
  );
};

export default SubscriptionPageLayout;
