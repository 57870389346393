/* eslint-disable react/prop-types */
import React from 'react';
import { Account_text } from '../../../constant/Translation/Account';
import { rating_icon } from '../../Shared/Constant/Icons/AllIcons';

const RatingBox = ({ rating_data }) => {
  return (
    <div className="bg-white rounded-card   w-full min-h-[66px]  p-4 shadow-c-sm">
      <h2 className="heading-h2">
        {Account_text.storedetail.ratings_and_reviews}
      </h2>
      <div className="   grid       lg:grid-cols-[45%,55%] xl:grid-cols-2  items-center justify-center  ">
        <div className="flex flex-col justify-end items-center">
          <p className="  description-paragraph font-semibold text-6xl  my-3">
            {rating_data.rating_average}
          </p>
          <p className=" description-caption  ">
            {rating_data.rating_count} {Account_text.storedetail.ratings}
          </p>
        </div>
        <div className="mt-5 lg:mt-0">
          {/* rating 1 */}
          <div className="flex items-center gap-3">
            <p className="flex justify-end items-center gap-1 w-20">
              {rating_icon}
              {rating_icon}
              {rating_icon}
              {rating_icon}
              {rating_icon}
            </p>
            <p className=" relative block w-28 bg-gray-300 h-2   rounded-full ">
              <span
                className={` absolute top-0 left-0  bg-primary h-2 w-[${
                  (rating_data.rating_count_data.rating_5 /
                    (rating_data.rating_count > 0
                      ? rating_data.rating_count
                      : 1)) *
                  (rating_data.rating_count > 0 &&
                  rating_data.rating_count_data.rating_5 > 0
                    ? 100
                    : 5)
                }%] rounded-full `}
                // style={{width:'10%'}}
              />
            </p>
            <p className="ml-5">{rating_data.rating_count_data.rating_5}</p>
          </div>
          {/* rating 4 */}
          <div className="flex items-center gap-3">
            <p className="flex justify-end items-center gap-1 w-20">
              {rating_icon}
              {rating_icon}
              {rating_icon}
              {rating_icon}
            </p>
            <p className=" relative block w-28 bg-gray-300 h-2   rounded-full ">
              <span
                className={` absolute top-0  bg-primary h-2 w-[${
                  (rating_data.rating_count_data.rating_4 /
                    (rating_data.rating_count > 0
                      ? rating_data.rating_count
                      : 1)) *
                  (rating_data.rating_count > 0 &&
                  rating_data.rating_count_data.rating_4 > 0
                    ? 100
                    : 5)
                }%] rounded-full `}
              />
            </p>
            <p className="ml-5">{rating_data.rating_count_data.rating_4}</p>
          </div>
          {/* rating 3 */}
          <div className="flex items-center gap-3">
            <p className="flex justify-end items-center gap-1 w-20">
              {rating_icon}
              {rating_icon}
              {rating_icon}
            </p>
            <p className=" relative block w-28 bg-gray-300 h-2   rounded-full ">
              <span
                className={` absolute top-0  bg-primary h-2 w-[${
                  (rating_data.rating_count_data.rating_3 /
                    (rating_data.rating_count > 0
                      ? rating_data.rating_count
                      : 1)) *
                  (rating_data.rating_count > 0 &&
                  rating_data.rating_count_data.rating_3 > 0
                    ? 100
                    : 5)
                }%] rounded-full `}
              />
            </p>
            <p className="ml-5">{rating_data.rating_count_data.rating_3}</p>
          </div>
          {/* rating 2 */}
          <div className="flex items-center gap-3">
            <p className="flex items-center justify-end gap-1 w-20">
              {rating_icon}
              {rating_icon}
            </p>
            <p className=" relative block w-28 bg-gray-300 h-2   rounded-full ">
              <span
                className={` absolute top-0  bg-primary h-2 w-[${
                  (rating_data.rating_count_data.rating_2 /
                    (rating_data.rating_count > 0
                      ? rating_data.rating_count
                      : 1)) *
                  (rating_data.rating_count > 0 &&
                  rating_data.rating_count_data.rating_2 > 0
                    ? 100
                    : 5)
                }%] rounded-full `}
              />
            </p>
            <p className="ml-5">{rating_data.rating_count_data.rating_2}</p>
          </div>
          {/* rating 1 */}
          <div className="flex items-center gap-3">
            <p className="flex items-center gap-1 justify-end w-20">
              {rating_icon}
            </p>
            <p className=" relative block w-28 bg-gray-300 h-2   rounded-full ">
              <span
                className={` absolute top-0  bg-primary h-2 w-[${
                  (rating_data.rating_count_data.rating_1 /
                    (rating_data.rating_count > 0
                      ? rating_data.rating_count
                      : 1)) *
                  (rating_data.rating_count > 0 &&
                  rating_data.rating_count_data.rating_1 > 0
                    ? 100
                    : 5)
                }%] rounded-full `}
              />
            </p>
            <p className="ml-5">{rating_data.rating_count_data.rating_1}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingBox;
