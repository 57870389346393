import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const BlogsSkeleton = () => {
  return (
    <div className="    w-full mx-auto overflow-hidden">
      <div className="flex  justify-between">
        <div className="h-8 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-28"></div>
        <div className="h-8 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-28"></div>
      </div>
      <div className="animate-pulse mt-4 flex space-x-4">
        <Swiper
          slidesPerView={'auto'}
          slidesPerGroup={1}
          spaceBetween={16}
          loop={false}
          navigation={false}
          style={{ paddingBottom: '24px' }}
        >
          <SwiperSlide
            style={{
              width: '300px',
              minHeight: '159px',
            }}
          >
            <div className="w-full min-h-full bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
          </SwiperSlide>

          <SwiperSlide
            style={{
              width: '300px',
              minHeight: '159px',
            }}
          >
            <div className="w-full min-h-full bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
          </SwiperSlide>

          <SwiperSlide
            style={{
              width: '300px',
              minHeight: '159px',
            }}
          >
            <div className="w-full min-h-full bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
          </SwiperSlide>

          <SwiperSlide
            style={{
              width: '300px',
              minHeight: '159px',
            }}
          >
            <div className="w-full min-h-full bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
          </SwiperSlide>
          <SwiperSlide
            style={{
              width: '300px',
              minHeight: '159px',
            }}
          >
            <div className="w-full min-h-full bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
          </SwiperSlide>
          <SwiperSlide
            style={{
              width: '300px',
              minHeight: '159px',
            }}
          >
            <div className="w-full min-h-full bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
          </SwiperSlide>
          <SwiperSlide
            style={{
              width: '300px',
              minHeight: '159px',
            }}
          >
            <div className="w-full min-h-full bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default BlogsSkeleton;
