import Link from 'next/link';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import axios from 'axios';
import { useRouter } from 'next/router';
import CrowdFundingListing from '../../components/home/Listing/CrowdFundingListings';
import {
  categoryListings,
  categorySelector,
} from '../../store/feature/categorySlice';
import FundingListingCard from '../../components/Shared/Cards/FundingListingCard';
import { Home_page_text } from '../../constant/Translation/Home';
import { bannersSelector } from '../../store/feature/Home/bannerSlice';
// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const HomePageLayout = ({ pageTitle, pageDescription }) => {
  const router = useRouter();

  const ct_id = router.query.category_id && router.query.category_id;

  const dispatch = useDispatch();
  const { auth_key } = useSelector(authSelector);

  // useEffect(() => {
  //   dispatch(
  //     homeCollections({
  //       authKey: localStorage.getItem('auth_key'),
  //     })
  //   );
  //   dispatch(
  //     AllPromoBanners({
  //       authKey: localStorage.getItem('auth_key'),
  //       bodyParam: { medium: 'web' },
  //     })
  //   );
  // }, [auth_key, dispatch]);

  //category listings
  useEffect(() => {
    if (ct_id) {
      dispatch(
        categoryListings({
          prams: { ...router.query, page: 1 },
          authKey: auth_key,
        })
      );
    }
  }, [router, ct_id]);

  const { collections, isFetching, isSuccess, errorMessage, categories } =
    useSelector(homeSelector);

  const { general_configs, MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);
  const { promo_banners } = useSelector(bannersSelector);

  // category listings
  const { category_listings } = useSelector(categorySelector);
  return (
    <>
      <div className=" ">
        <div className="grid grid-cols-1 md:grid-cols-2   gap-3">
          <div className="flex flex-col gap-4">
            <h2 className=" text-center ltr:md:text-left rtl:md:text-right text-[32px] text-black font-bold pr-4">
              {general_configs?.header_text}
            </h2>
            <p className=" mt-2 text-center ltr:md:text-left rtl:md:text-right text-[16px] text-black font-normal md:w-5/6">
              {general_configs?.header_description}
            </p>
            {/* <Link href="#">
              <a className=" mt-2 text-[16px]   text-primary font-bold">
                View all Apps
              </a>
            </Link> */}
          </div>

          <div className=" relative   aspect-w-4 aspect-h-2  ">
            {promo_banners?.length > 0 && (
              <img
                alt="image"
                src={promo_banners[0]?.image_path}
                className="  absolute  max-w-[100%]  top-[50%] ltr:left-[50%] rtl:-right-[50%]  -translate-x-1/2 -translate-y-1/2 rounded-image shadow-sm "
              />
            )}
          </div>
        </div>
        <div className=" mt-12">
          {categories !== null && (
            <div className="border-b border-gray-300 pb-4 mb-12  overflow-x-auto ">
              <ul className="flex   justify-start font-medium  ">
                <li className="px-3 mx-2 my-1 min-w-[100px] text-center">
                  <Link href="/" passHref>
                    <a
                      className={
                        !ct_id && router.pathname === '/'
                          ? 'text-blue-600'
                          : 'text-gray-800 hover:underline"'
                      }
                    >
                      {Home_page_text.home.view_all}
                    </a>
                  </Link>
                </li>
                {categories.map((ct) => {
                  return (
                    <li
                      className="px-3 mx-2 my-1 min-w-[100px] text-center"
                      key={ct.id}
                    >
                      <Link
                        href={{
                          pathname: '/',
                          query: { category_id: ct.id },
                        }}
                        passHref
                      >
                        <a
                          className={
                            router.query.category_id == ct.id
                              ? 'block text-blue-600  whitespace-normal'
                              : 'block text-gray-800 hover:underline whitespace-normal"'
                          }
                        >
                          {ct.name}
                        </a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="col-span-3 pt-5">
            {collections?.map((collection) => {
              const scope_type = collection.scope_type;

              if (scope_type === 4 && !ct_id) {
                return (
                  <div key={Math.random()}>
                    <CrowdFundingListing products={collection} />
                  </div>
                );
              }
            })}
          </div>
          {ct_id && (
            <div>
              {category_listings?.length > 0 ? (
                <div className="mt-5 flex flex-col gap-5">
                  {category_listings?.map((item, index) => {
                    return <FundingListingCard item={item} key={item.id} />;
                  })}
                </div>
              ) : (
                <div className=" w-full h-[200px] mt-5 flex justify-center items-start">
                  <div
                    className="w-full    md:w-5/6 bg-yellow-500    text-white px-4 py-3 rounded-card  relative grid grid-cols-[5%,80%]"
                    role="alert"
                  >
                    <div className="flex items-center justify-center w-6 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                        />
                      </svg>
                    </div>
                    <div className="ml-5">
                      <span className="   md:ml-2">
                        No listings found under this category.
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HomePageLayout;
