/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import { storeSelector } from '../../../../store/feature/storeSlice';
import { configsSelector } from '../../../../store/feature/configsSlice';
import { stock_text } from '../../../Shared/Constant/TextConstant/addlistingConstant';
import { Add_product_text } from '../../../../constant/Translation/AddProduct_text';
import {
  digitalContentSelector,
  getVariantDigitalContent,
} from '../../../../store/feature/digital content/digitalContentSlice';
import DigitalProduct from './DigitalProduct/DigitalProduct';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import { validateImageSize } from '../../../../utils/ImageSizeValidate';
import SelectFromList from '../../../Shared/DropDown/SelectFromList';

const EditVariantsMatrixForm = ({
  editVariantData,
  variantsType,
  variantsObject,
  setVariantsObject,
  EditVariantClick,
  setShowError,
  setError_message,
  editvariantLoading,
  productId,
  order_type,
}) => {
  const [imagePath, setImagePath] = useState(null);
  const [files, setFiles] = useState(null);
  const dispatch = useDispatch();

  const { currencies, listing_configs } = useSelector(storeSelector);

  const { variant_digital_content } = useSelector(digitalContentSelector);
  const clickAddButton = () => {
    if (variantsObject.variant_type === null) {
      setShowError(true);
      setError_message(Add_product_text.addvariant.select_variant_type);
      return false;
    }
    if (variantsObject.variant_type_value === null) {
      setShowError(true);
      setError_message(Add_product_text.addvariant.select_variant_type_value);
      return false;
    }

    EditVariantClick();
  };

  useEffect(() => {
    if (editVariantData.images?.length > 0) {
      setImagePath({
        id: 1,
        path: editVariantData.images[0],
      });
    }

    dispatch(
      getVariantDigitalContent({
        listing_id: productId,
        variant_id: editVariantData.id,
      })
    );
  }, [editVariantData]);

  useEffect(() => {
    setVariantsObject((prev) => ({
      ...prev,
      digitalProducts: { ...variant_digital_content },
    }));
  }, [variant_digital_content]);

  const onChangeVariantType = ({ type, value }) => {
    let vr_data = {
      variant_type_id: type,
      variant_type_value_id: value,
    };
    let old_vr_values =
      variantsObject?.variant_values?.filter(
        (old_vr) => old_vr?.variant_type_id !== type
      ) ?? [];

    setVariantsObject({
      ...variantsObject,
      variant_values: [...old_vr_values, vr_data],
    });
  };

  return (
    <div className=" bg-white w-full px-[20px] py-[30px] mt-4 grid grid-cols-1 gap-6">
      <h2 className=" text-start  heading-h2 mb-4">
        {`${Add_product_text.addvariant.edit_variant}  ${editVariantData.title}  `}
      </h2>
      <div className="block">
        <span className="text-gray-700  text-lg">
          {Add_product_text.addvariant.add_variant_image}
        </span>
        <input
          id="imageButton"
          type="file"
          value={''}
          className=" hidden"
          accept=".png, .jpg ,.svg , .gif, .webp, .jpeg"
          placeholder=""
          onChange={(e) => {
            if (validateImageSize(e.target.files[0])) {
              e.target.files.length > 0 &&
                (setImagePath({
                  id: e.target.files[0].name,
                  path: URL.createObjectURL(e.target.files[0]),
                }),
                setFiles(e.target.files[0])),
                setVariantsObject({
                  ...variantsObject,
                  images: e.target.files[0],
                });
            } else {
              setShowError(true);
              setError_message(Add_product_text.addproduct.image_size_alert);
              return false;
            }
          }}
        />
        <div>
          {imagePath !== null ? (
            <div className=" relative w-28 mt-4">
              <img
                className="w-28 h-28 object-cover"
                alt="image"
                src={imagePath.path}
              />
              <button
                className=" absolute -top-2 -right-2 text-primary "
                onClick={() => {
                  return (
                    setImagePath(null),
                    setFiles(null),
                    setVariantsObject({
                      ...variantsObject,
                      images: null,
                    })
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
          ) : (
            <button
              className=" w-28  h-28 flex justify-center items-center  mt-3  bg-gray-100 text-sm rounded-button "
              onClick={() => document.getElementById('imageButton').click()}
            >
              {Add_product_text.addproduct.add_image}
            </button>
          )}
        </div>
      </div>

      {/*  */}
      <div className="grid grid-cols-2 gap-2">
        {variantsType?.map((vr_type) => {
          return (
            <SelectFromList
              key={vr_type?.id}
              title={vr_type?.name}
              drop_down_items={vr_type?.values?.map((vl) => ({
                label: vl.name,
                value: vl.id,
              }))}
              title_styles={'text-gray-700  text-lg'}
              field_styles={
                'mt-0   outline-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 transition  duration-700 focus:ring-0 focus:border-primary rounded-none'
              }
              default_option_text={'Select'}
              dropdownBlockStyles={'border-0'}
              set_new_value={(value) => {
                onChangeVariantType({ type: vr_type?.id, value });
              }}
              current_value={
                variantsObject?.variant_values?.filter(
                  (old_vr) => old_vr?.variant_type_id == vr_type?.id
                )?.[0]?.variant_type_value_id
              }
              options_block_styles={'p-0 gap-0'}
              options_style={'px-5 py-2 '}
              options_selected_style={'  bg-primary text-white'}
            />
          );
        })}
      </div>

      <label className="block">
        <span className="text-gray-700  text-lg">
          {Add_product_text.addvariant.name}
        </span>
        <input
          value={variantsObject?.title}
          type="text"
          className="
                    mt-0
                    block
                    w-full
                    h-[27px]
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
          placeholder="Enter your title"
          onChange={(e) => {
            setVariantsObject({
              ...variantsObject,
              title: e.target.value,
            });
          }}
        />
      </label>
      <label className="block">
        <span className="text-gray-700  text-lg">
          {Add_product_text.addvariant.description}
        </span>
        <textarea
          value={variantsObject.description}
          className="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
          rows="3"
          onChange={(e) => {
            setVariantsObject({
              ...variantsObject,
              description: e.target.value,
            });
          }}
        ></textarea>
      </label>

      <label className="block relative">
        <span className="text-gray-700  text-lg">
          {Add_product_text.addvariant.price}
        </span>
        <input
          value={variantsObject.list_price}
          type="number"
          className="
                    mt-0
                    block
                    w-full
                    px-0.5
                     border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
          placeholder="1"
          onChange={(e) => {
            setVariantsObject({
              ...variantsObject,
              list_price: e.target.value,
            });
          }}
          onWheel={(event) => event.currentTarget.blur()}
        />
      </label>
      {(TYPE_CONSTANT.LISTINGS_CONFIGS?.enable_stock ||
        listing_configs?.hide_offer_percent) && (
        <div className="grid grid-cols-2 gap-2">
          {listing_configs?.hide_offer_percent && (
            <label className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addvariant.offer}
              </span>
              <input
                value={variantsObject.offer_percent}
                type="number"
                className="
                    mt-0
                    block
                    w-full
                    h-[27px]
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                placeholder="1"
                onChange={(e) => {
                  setVariantsObject({
                    ...variantsObject,
                    offer_percent: e.target.value,
                  });
                }}
                onWheel={(event) => event.currentTarget.blur()}
              />
            </label>
          )}
          {TYPE_CONSTANT.LISTINGS_CONFIGS?.enable_stock && (
            <label className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addvariant.ticket_limit}
              </span>
              <input
                value={variantsObject.stock}
                type="number"
                className="
                    mt-0
                    block
                    w-full
                    h-[27px]
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                placeholder="1"
                onChange={(e) => {
                  setVariantsObject({
                    ...variantsObject,
                    stock: e.target.value,
                  });
                }}
                onWheel={(event) => event.currentTarget.blur()}
              />
            </label>
          )}
        </div>
      )}
      {variantsObject?.digitalProducts && order_type === 'digital' && (
        <div className="mt-8">
          <DigitalProduct
            variantsObject={variantsObject}
            setVariantsObject={setVariantsObject}
          />
        </div>
      )}

      <div className=" flex justify-end">
        <button
          className="btn-primary-fill gap-2  "
          id="edit-variant"
          onClick={() => clickAddButton()}
          disabled={editvariantLoading}
        >
          {editvariantLoading && (
            <svg
              className="animate-spin -ml-1  h-5 w-5 "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {Add_product_text.addvariant.edit_variant}
        </button>
      </div>
    </div>
  );
};

export default EditVariantsMatrixForm;
