import React from 'react';
import { useState } from 'react';
import { slug as SlugChecker } from 'github-slugger';
import { formatBytes } from '../../../../constant/functions';
import { Add_product_text } from '../../../../constant/Translation/AddProduct_text';
import { General_text } from '../../../../constant/Translation/General';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import {
  delete_icon,
  file_icon,
} from '../../../Shared/Constant/Icons/AllIcons';

const DigitalProduct = ({ digitalProducts = {}, setDigitalProducts }) => {
  //destructure

  const { account_id, title, description, version_name, content, active } =
    digitalProducts;

  // File Upload part
  const handleFileClick = () => {
    document.getElementById('select-digital-product-file').click();
  };

  //

  const selectFile = (e) => {
    setDigitalProducts((prev) => ({
      ...prev,
      content:
        content?.length > 0
          ? [
              ...content,
              {
                upload_file: true,
                full_file: e.target.files[0],
                size: formatBytes(e.target.files[0].size),
                mime_type: e.target.files[0].type,
                file_name: e.target.files[0].name.replace(/\s/g, '-'),
              },
            ]
          : [
              {
                upload_file: true,
                full_file: e.target.files[0],
                size: formatBytes(e.target.files[0].size),
                mime_type: e.target.files[0].type,
                file_name: e.target.files[0].name.replace(/\s/g, '-'),
              },
            ],
    }));
  };

  // deleteContent
  const deleteContent = (item) => {
    setDigitalProducts((prev) => ({
      ...prev,
      content: content.filter(
        (it) => it.file_name != item.file_name && it.size != item.size
      ),
    }));
  };

  return (
    <>
      <div className="w-full bg-white shadow-c-sm p-4  min-h-min flex flex-col gap-6 ">
        <h3 className=" font-semibold text-primary text-xl  text-start">
          {Add_product_text.adddigitalproduct.digital_product_title}
        </h3>

        {/* Upoad digital product file  */}
        <div className="flex   justify-start">
          <button
            className="btn-primary-outline"
            onClick={() => handleFileClick()}
          >
            {Add_product_text.adddigitalproduct.add_digital_product_btn_title}
          </button>

          <input
            type="file"
            value={''}
            id={'select-digital-product-file'}
            className="h-0  opacity-0  w-0"
            multiple={false}
            onChange={selectFile}
          />
        </div>
        {/* Contents list */}
        {content?.length > 0 && (
          <div className="flex flex-col gap-4 ">
            {content?.map((item) => {
              return (
                <div className="w-full flex items-center justify-start gap-3 rounded-card shadow-sm border border-primary p-2">
                  <span className=" flex -none text-primary">{file_icon}</span>
                  <p className=" flex-grow text-base text-gray-600 font-medium">
                    {item.file_name}
                  </p>
                  <button
                    className="text-primary hover:text-error  flex-none "
                    onClick={() => deleteContent(item)}
                  >
                    {delete_icon}
                  </button>
                </div>
              );
            })}
          </div>
        )}

        {/* Input fields */}
        <div className="flex flex-col gap-4">
          {TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content?.title !== false && (
            <label className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.adddigitalproduct.title}
              </span>
              <input
                type="text"
                value={title}
                className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                onChange={(e) =>
                  setDigitalProducts((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }))
                }
                placeholder=""
              />
            </label>
          )}
          {TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content?.version !==
            false && (
            <label className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.adddigitalproduct.Version}
              </span>
              <input
                type="text"
                value={version_name}
                className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                onChange={(e) =>
                  setDigitalProducts((prev) => ({
                    ...prev,
                    version_name: e.target.value,
                  }))
                }
                placeholder=""
              />
            </label>
          )}
          {TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content?.description !==
            false && (
            <label className="block">
              <span className="text-gray-700  text-lg">
                {Add_product_text.adddigitalproduct.description}
              </span>

              <textarea
                value={description}
                className="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
                rows="3"
                onChange={(e) =>
                  setDigitalProducts((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              ></textarea>
            </label>
          )}
        </div>
      </div>
    </>
  );
};

export default DigitalProduct;
