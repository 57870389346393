/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { useRouter } from 'next/dist/client/router';
import Cookies from 'js-cookie';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { Menubar_text } from '../../../constant/Translation/Heading';

let banner = '/banner.png';

const StoreProfile = ({ my_stores }) => {
  const router = useRouter();
  const is_draft = router?.query?.draft
    ? true
    : false || Cookies.get('is_draft') === 'true'
    ? true
    : false;

  return (
    <div>
      <div className=" hidden md:block  absolute w-[100%] h-[200px] right-0 top-0 mt-[64px] md:mt-[120px]">
        <img
          src={
            is_draft
              ? TYPE_CONSTANT.DRAFT_STYLES?.styles?.accounts?.background_image
                ? `${TYPE_CONSTANT.DRAFT_STYLES?.styles?.accounts?.background_image}`
                : banner
              : TYPE_CONSTANT.ACCOUNTS_CONFIGS?.background_image
              ? `${TYPE_CONSTANT.ACCOUNTS_CONFIGS?.background_image}`
              : banner
          }
          alt="banner"
          className="w-full h-full object-cover"
        />
      </div>
      <div className=" relative  w-full  min-h-[126px] px-[10px] sm:px-[40px] py-[20px] bg-white flex flex-col md:flex-row justify-between md:items-center rounded-card   shadow-c-xsm  md:mt-[110px]">
        <div className=" flex items-center">
          <div className=" w-[70px] h-[70px] rounded-full overflow-hidden  relative   ltr:mr-6 rtl:ml-6 ">
            {my_stores[0]?.images[0] ? (
              <img
                src={my_stores[0]?.images[0]}
                className="h-full w-full object-cover object-left-top"
                alt="banner"
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-[70px] w-[70px] rounded-full ltr:mr-6 rtl:ml-6 text-gray-600 border p-5 border-text-gray-600 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            )}
          </div>
          <div className=" flex flex-col items-start">
            <p className=" font-semibold text-base text-black">
              {my_stores[0].name}
            </p>
            <p className=" text-default_gray text-sm">
              @{my_stores[0].user.first_name}
            </p>
          </div>
        </div>
        <div className=" mt-4 md:mt-0 flex flex-row justify-between   md:flex-col flex-wrap ">
          <button
            className="  min-w-[145px] px-1.5 h-[30px] flex justify-center items-center bg-primary rounded-button mb-2 text-white"
            onClick={() =>
              router.push({
                pathname: '/a/edit-account',
                query: { slug: my_stores[0].id, id: true },
              })
            }
          >
            {Menubar_text.more.edit_account}
          </button>
          <button
            onClick={() =>
              router.push({
                pathname: '/a/orders',
                query: { account_id: my_stores[0].id },
              })
            }
            className=" min-w-[145px] px-1.5 h-[30px] flex justify-center items-center bg-primary rounded-button text-white"
          >
            {Menubar_text.more.my_store_orders}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoreProfile;
