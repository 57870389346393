import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { useRouter } from 'next/router';
import {
  clearErrorState,
  getHomePageSections,
  homePageSelector,
} from '../../store/feature/Pages/Home/homePageSlice';
import Categories6 from '../../components/home/Categories/Categories6';
import LatestListings6 from '../../components/home/LatestListings/LatestListings6';
import StoresForFollow3 from '../../components/home/Stores/StoresForFollow3';
import WideSearchBox from '../../components/SearchBox/WideSearchBox';
import HeadingBlock from '../../components/StaticBlocks/HeadingBlock';
import FeaturedCard2 from '../../components/home/FeaturedCrad/FeaturedCard2';
import Latest_pages from '../../components/home/BlogsWidget/Latest_pages';
import TitleDescriptionButton from '../../components/StaticBlocks/TitleDescriptionButton';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import VideoBlock from '../../components/StaticBlocks/VideoBlock';
import QuoteBlock from './QuoteBlock';
import TrustedBlock from './TrustedBlock';
import LatestListings7 from '../../components/home/LatestListings/LatestListings7';
import BlankPageBanner from '../../components/home/Banner/BlankPageBanner';
import DescriptionBlock from '../../components/StaticBlocks/DescriptionBlock ';
import ImageBlock from '../../components/StaticBlocks/ImageBlock';
import StoresListView from '../../components/home/Stores/StoresListView';
import WideSearchWithLocation from '../../components/SearchBox/WideSearchWithLocation';
import DividerBlock from '../../components/StaticBlocks/DividerBlock';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import {
  HomeBoxDefaultStyles,
  HomeDefaultColumnStyles,
  HomeMainDefaultStyles,
} from '../../constant/default_styles/HomeDefaultStyles';
import { useScreenSize } from '../../hooks/useScreenSize';
import CardBlock from '../../components/StaticBlocks/CardBlock';
import AboutUs from '../../components/StaticBlocks/AboutUs';
import LeftImageFeaturedCard from '../../components/home/FeaturedCrad/LeftImageFeaturedCard';
import ListCategories from '../../components/home/Categories/ListCategories';
import GridButtonTypeCategories from '../../components/home/Categories/GridButtonTypeCategories';
import Paragraph from '../../components/StaticBlocks/Paragraph';
import CustomCta from '../../components/StaticBlocks/CustomCta';
import GridTabTypeCategories from '../../components/home/Categories/GridTabTypeCategories';
import CarouselCardBlock from '../../components/StaticBlocks/CarouselCardBlock';
import EmptyBlock from '../../components/StaticBlocks/EmptyBlock';
import ImageDestinationBlock from '../../components/StaticBlocks/ImageDestinationBlock';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../utils/ResponsiveDesign';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import CardBlockImageFirst from '../../components/StaticBlocks/CardBlockImageFirst';
import DynamicListingFilter from '../../components/Shared/DynamicListingFilter';
import {
  editor_versions_list,
  latest_editor_version,
} from '../../constant/Version';
import ListingCities from '../../components/StaticBlocks/ListingCities';
import { Layer_text } from '../../constant/Translation/LayerText';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import OPPSetup from '../../components/Opp/OPPSetup';
import StripeSetup from '../../components/Stripe/StripeSetup';
import { home_page_component_decide } from './BlockDecide/HomePageBlokcDecide';
import AnimatedTextBlock from '../../components/StaticBlocks/AnimatedTextBlock';

const HomePageLayout = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { width, height } = useScreenSize();

  //
  const { isFetching, isSuccess, isError, errors, home_page_sections } =
    useSelector(homePageSelector);

  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  // close pop up
  const closePopUP = () => {
    dispatch(clearErrorState());
    setShowError(false);
    setError_message('');
  };

  //select blocks
  const [oldBlocks, setOldBlocks] = useState([]);
  const [NewBlocks, setNewBlocks] = useState({});
  const [AllListingFilter, setAllListingsFilter] = useState({});
  const [AllAccountFilter, setAllAccountFilter] = useState({});

  useEffect(() => {
    if (Array.isArray(TYPE_CONSTANT.PAGES?.home)) {
      setOldBlocks(TYPE_CONSTANT.PAGES?.home);
    } else {
      setNewBlocks(TYPE_CONSTANT.PAGES?.home);
    }
  }, [TYPE_CONSTANT.PAGES?.home]);

  // data fetching
  useEffect(() => {
    if (oldBlocks?.length > 0) {
      dispatch(
        getHomePageSections({ sections_data: TYPE_CONSTANT.PAGES?.home })
      );
    }
    if (NewBlocks?.schema?.length > 0) {
      let all_items = NewBlocks?.schema.map(
        ({ row_styles, columns, ...column_styles }) => {
          const all_columns_items = columns?.map((column) => column);
          const flattenedArray = all_columns_items.flat();
          // Remove empty inner arrays from the flattened array.
          const filteredArray = flattenedArray.filter(
            (element) => element.length !== 0
          );
          return filteredArray;
        }
      );
      const flattenedArray = all_items.flat();

      // Remove empty inner arrays from the flattened array.
      const filteredArray = flattenedArray.filter(
        (element) => element.length !== 0
      );

      dispatch(getHomePageSections({ sections_data: filteredArray }));
    }
  }, [oldBlocks, NewBlocks]);

  return (
    <>
      {/* Error handler */}
      {(isError || showError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (isError || showError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearErrorState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="flex flex-col gap-2">
              {errors?.map((err) => {
                return <PopUp message={err?.msg} closePopUP={closePopUP} />;
              })}
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {/* Old method without col and rows */}

      <div className="flex flex-col  ">
        {oldBlocks?.map((section = {}, index) => {
          const isTakeFullScreen =
            section?.styles?.main_styles?.is_take_full_screen ?? false;
          return (
            home_page_component_decide({
              ...section,
              home_page_sections,
              AllListingFilter,
              setAllListingsFilter,
            }) && (
              <div
                className={[
                  !isTakeFullScreen &&
                    'max-w-[var(--bodyStylesMaxWidth)] mx-auto  px-[var(--bodyStylesPaddingX)] w-full',
                ].join(' ')}
              >
                {home_page_component_decide({
                  ...section,
                  home_page_sections,
                  AllListingFilter,
                  setAllListingsFilter,
                  AllAccountFilter,
                  setAllAccountFilter,
                })}
              </div>
            )
          );
        })}
      </div>

      {/* New blocks */}
      {/* Rows */}

      <div
        style={get_dynamic_styles_with_default_value(
          responsive_design_styles({
            width,
            parent_group: NewBlocks?.styles,
            child_group_name: 'main_styles',
          }),
          HomeMainDefaultStyles
        )}
      >
        {' '}
        <div
          className="flex flex-col  "
          style={get_dynamic_styles_with_default_value(
            responsive_design_styles({
              width,
              parent_group: NewBlocks?.styles,
              child_group_name: 'box_styles',
            }),
            HomeBoxDefaultStyles
          )}
        >
          {NewBlocks?.schema?.map((row) => {
            const {
              columns,
              current_editor_version = '',
              ...column_styles
            } = row || {};
            const row_styles = responsive_row_styles({
              parent_group: row,
              width,
            });
            return (
              <div
                className={``}
                style={
                  current_editor_version == latest_editor_version
                    ? {
                        display: row_styles?.display,
                        gridTemplateColumns: dynamic_text_convert({
                          main_text: row_styles?.columns_box_percentage,
                          check_by: '-1%',
                          value: '',
                        }),
                        ...get_dynamic_styles(row_styles),
                      }
                    : {
                        display:
                          row_styles?.isShowInMobile === false
                            ? 'none'
                            : 'grid',
                        gap: row_styles?.gap_between_columns ?? '24px',
                        gridTemplateColumns:
                          width > 768
                            ? row_styles?.columns_box_percentage
                              ? row_styles?.columns_box_percentage
                              : `repeat(${
                                  row.columns?.length ?? 1
                                }, minmax(0, 1fr))`
                            : row?.['768_row_styles']
                            ? row_styles?.columns_box_percentage
                            : '100%',
                        ...get_dynamic_styles(row_styles),
                      }
                }
              >
                {/* Columns */}
                {columns?.map((column, index) => {
                  const current_column_style = responsive_column_styles({
                    parent_group: column_styles,
                    index,
                    width,
                  });

                  return (
                    <div
                      style={{
                        display: current_column_style?.display
                          ? current_column_style?.display
                          : 'flex',
                        ...get_dynamic_styles_with_default_value(
                          current_column_style,
                          HomeDefaultColumnStyles
                        ),
                      }}
                    >
                      {column?.map((item) => {
                        return (
                          home_page_component_decide({
                            ...item,
                            home_page_sections,
                            AllListingFilter,
                            setAllListingsFilter,
                          }) &&
                          (editor_versions_list?.includes(
                            current_editor_version
                          ) ? (
                            home_page_component_decide({
                              ...item,
                              home_page_sections,
                              AllListingFilter,
                              setAllListingsFilter,
                              AllAccountFilter,
                              setAllAccountFilter,
                            })
                          ) : (
                            <div>
                              {home_page_component_decide({
                                ...item,
                                home_page_sections,
                                AllListingFilter,
                                setAllListingsFilter,
                                AllAccountFilter,
                                setAllAccountFilter,
                              })}
                            </div>
                          ))
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HomePageLayout;
