/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { useRouter } from 'next/dist/client/router';
import { changeDateFormat } from '../Shared/Constant/Constant';
import { transaction_type } from './type';
import { Sales_text } from '../../constant/Translation/Sales';

const TransactionsList = ({ list, account_id }) => {
  const router = useRouter();
  return (
    <div>
      <div className=" grid  grid-cols-[60%,40%]  xs:grid-cols-[40%,30%,30%]   c-lg:grid-cols-[40%,20%,20%,20%] px-2">
        <p className=" text-lg text-[#000000] text-opacity-40">
          {Sales_text.sales.history}
        </p>
        <p className="hidden c-lg:block text-lg text-[#000000] text-opacity-40 text-center">
          {Sales_text.sales.date}
        </p>
        <p className=" text-lg text-[#000000] text-opacity-40 text-center">
          {Sales_text.sales.amount}
        </p>
        <p className=" hidden xs:block text-lg text-[#000000] text-opacity-40 text-center">
          {Sales_text.sales.status}
        </p>
      </div>
      {list ? (
        <div className=" mt-8">
          {list?.map((item) => {
            return (
              <Link
                href={{
                  pathname: `/a/orders/${item?.order?.reference_number}`,
                  query: { account_id: account_id },
                }}
                key={Math.random()}
              >
                <a>
                  <div
                    key={Math.random()}
                    // onClick={() => {
                    //   router.push({
                    //     pathname: `/a/orders/${item.id}`,
                    //     query: { account_id: accountId },
                    //   });
                    // }}
                  >
                    <div className=" grid  grid-cols-[60%,40%] xs:grid-cols-[40%,30%,30%]   c-lg:grid-cols-[40%,20%,20%,20%] items-center bg-white shadow-c-sm rounded-card   mb-3 py-3 px-2 cursor-pointer">
                      <div className=" ltr:pl-4 rtl:pr-4 ">
                        <div>
                          <p className=" text-base md:text-lg font-semibold text-primary break-words	">
                            #{item.transaction_number}
                          </p>

                          <p className="  text-sm text-[#77869E] leading-4 flex flex-col mt-1">
                            <span className=" whitespace-pre-wrap">
                              {` Order Ref :  ${item?.order?.reference_number}`}
                            </span>
                            <span>
                              {changeDateFormat(
                                item.created_at,
                                'DD/MM/YYYY, h:mm a'
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className=" hidden c-lg:flex justify-center items-center">
                        <p className=" text-sm text[#000000] text-opacity-50 leading-4 text-center">
                          {changeDateFormat(item.created_at, ' Do MMM  YY')}
                        </p>
                      </div>
                      <div className="flex justify-center items-center">
                        <p
                          className={[
                            ' text-center text-primary    flex justify-center items-center',
                            item.amount.amount >= 0
                              ? 'text-primry'
                              : 'text-red-500',
                          ].join(' ')}
                        >
                          <span className="text-lg  ">
                            {item.amount.formatted}
                          </span>
                        </p>
                      </div>
                      <div className="hidden xs:flex justify-center items-center">
                        <button className=" px-6 py-1 rounded-button   text-primary break-words">
                          {transaction_type(item.type)}
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </Link>
            );
          })}
        </div>
      ) : (
        <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full h-full mx-auto  mt-8">
          <div className="animate-pulse flex space-x-4 mt-5">
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-[90%]"></div>
                <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionsList;
