import React from 'react';
import { Listing_text } from '../../../constant/Translation/Listing';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { getThumbnailImage } from '../../Shared/Constant/Constant';

const MainBox4 = ({ listing_details }) => {
  return (
    <div className="w-full min-h-min rounded-card">
      {listing_details ? (
        <div className="relative bg-gray-50 rounded-card border border-gray-200 p-5">
          <div className="text-center mb-4">
            {listing_details?.account?.images?.length > 0 ? (
              <img
                src={getThumbnailImage(listing_details?.account.images[0])}
                className="w-20 h-20 inline-flex rounded-full overflow-hidden object-cover"
                width="72"
                height="72"
                alt={listing_details?.account.name}
              />
            ) : (
              <img
                src={getThumbnailImage(listing_details?.images[0])}
                className="w-20 h-20 rounded-full overflow-hidden object-cover"
                width="72"
                height="72"
                alt={listing_details?.title}
              />
            )}
            <p className="text-lg font-bold text-gray-800">
              {listing_details?.account?.name}
            </p>
          </div>

          <div className="flex justify-center md:justify-start mb-5">
            <ul className="inline-flex flex-col space-y-2">
              <li className="flex items-center">
                <svg
                  className="shrink-0 fill-gray-400 mr-3"
                  width="14"
                  height="14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.707 4.293a1 1 0 0 0-1.414 1.414L10.586 8H2V2h3a1 1 0 1 0 0-2H2a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h8.586l-2.293 2.293a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4Z" />
                </svg>
                <span className="text-sm text-gray-600">24 August, 2024</span>
              </li>
              {listing_details?.location?.formatted_address &&
                TYPE_CONSTANT.LISTINGS_CONFIGS?.location_widget != false && (
                  <li className="flex items-center">
                    <svg
                      className="shrink-0 fill-gray-400 mr-3"
                      width="14"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="7" cy="7" r="2" />
                      <path d="M6.3 15.7c-.1-.1-4.2-3.7-4.2-3.8C.7 10.7 0 8.9 0 7c0-3.9 3.1-7 7-7s7 3.1 7 7c0 1.9-.7 3.7-2.1 5-.1.1-4.1 3.7-4.2 3.8-.4.3-1 .3-1.4-.1Zm-2.7-5 3.4 3 3.4-3c1-1 1.6-2.2 1.6-3.6 0-2.8-2.2-5-5-5S2 4.2 2 7c0 1.4.6 2.7 1.6 3.7 0-.1 0-.1 0 0Z" />
                    </svg>
                    <span className="text-sm text-gray-600">
                      {listing_details?.location?.formatted_address}
                    </span>
                  </li>
                )}
              {TYPE_CONSTANT.LISTINGS_CONFIGS?.main_buttons != false && (
                <li className="flex items-center">
                  <svg
                    className="shrink-0 fill-gray-400 mr-3"
                    width="16"
                    height="12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15 0H1C.4 0 0 .4 0 1v10c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1Zm-1 10H2V2h12v8Z" />
                    <circle cx="8" cy="6" r="2" />
                  </svg>
                  <span className="text-sm text-gray-600">
                    {listing_details?.offer_price?.formatted}
                  </span>
                </li>
              )}
            </ul>
          </div>

          <div className="max-w-xs mx-auto mb-5">
            <a
              className="btn w-full text-white bg-secondary hover:bg-primary group shadow-sm"
              href="#0"
            >
              {Listing_text.product.apply_now}
              <span className="tracking-none text-white group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                  />
                </svg>
              </span>
            </a>
          </div>

          <div className="text-center">
            <a
              className="text-sm text-primary font-medium hover:underline"
              href="#0"
            >
              {Listing_text.product.visit_website}
            </a>
          </div>
        </div>
      ) : (
        <div className="relative bg-gray-50 rounded-card border border-gray-200 p-5">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-4 py-1">
              <div className="h-10 w-10 mx-auto bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
              <div className="space-y-2">
                <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
                <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainBox4;
