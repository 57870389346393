import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { useRouter } from 'next/router';
import axios from 'axios';
import Link from 'next/link';
import ReactPaginate from 'react-paginate';
import { Home_page_text } from '../../constant/Translation/Home';
import Latest_blogs from '../../components/home/BlogsWidget/Latest_blogs';
import { bannersSelector } from '../../store/feature/Home/bannerSlice';
import Composer2 from '../../components/Shared/Composer/Composer2';
import ReadingComposer from '../../components/Shared/Composer/ReadingComposer';

const HomePageLayout = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { auth_key } = useSelector(authSelector);
  const [listings, setListings] = useState(null);
  const [page, setPage] = useState(1);
  const [total_records, setTotal_records] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const category_id = router?.query?.category_id;

  // //
  // useEffect(() => {
  //   dispatch(
  //     homeCollections({
  //       authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
  //     })
  //   );
  //   dispatch(
  //     AllPromoBanners({
  //       authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
  //       bodyParam: { medium: 'web' },
  //     })
  //   );
  // }, [auth_key, dispatch]);

  //
  const queries = router.query;

  // fetch listings
  useEffect(() => {
    const params = router?.query?.page
      ? {
          ...router.query,
          type: 'listings',
          status: 2,
          includes: 'attributes',
          per_page: 10,
        }
      : {
          ...router.query,
          page: 1,
          type: 'listings',
          status: 2,
          includes: 'attributes',
          per_page: 10,
        };
    axios
      .get('/api/l', {
        params: params,
      })
      .then((res) => {
        setListings(res.data.listings);
        setPage(res.data.page);
        setTotal_records(res.data.total_records);

        const total_page = Math.ceil(res.data.total_records / 10);
        if (Number(res.data.total_records) > 10) {
          setPageCount(total_page);
        }
      });
  }, [router]);

  //
  const moreListings = (data) => {
    router.push({
      query: {
        ...router.query,
        page: Number(data.selected) + 1,
      },
    });
  };

  // Data from store
  const { collections, categories } = useSelector(homeSelector);
  const { promo_banners } = useSelector(bannersSelector);

  const { general_configs } = useSelector(configsSelector);
  return (
    <>
      <div className="mt-5">
        <div className="pb-12 grid md:grid-cols-2 items-center">
          <div className="flex flex-col gap-4">
            <h2 className="text-[32px] text-black font-bold  ltr:pr-4 rtl:pl-4">
              {general_configs?.header_text}
            </h2>
            <p className=" mt-2 text-[16px] text-black font-normal w-5/6  ltr:pr-4 rtl:pl-4">
              {general_configs?.header_description}
            </p>
            {/* <Link href="#">
              <a className=" mt-2 text-[16px]   text-primary font-bold">
                View all Apps
              </a>
            </Link> */}
          </div>
          <div className=" relative   aspect-w-4 aspect-h-2  ">
            {promo_banners?.length > 0 && (
              <img
                alt="image"
                src={promo_banners[0]?.image_path}
                className="  absolute  max-w-[100%]  top-[50%] ltr:left-[50%] rtl:-right-[50%]  -translate-x-1/2 -translate-y-1/2 rounded-image shadow-sm "
              />
            )}
          </div>
        </div>

        {/* Filters */}
        <div className="mb-6">
          {categories !== null && (
            <div className="border-b border-gray-300 pb-4 mb-12">
              <ul className="flex flex-wrap justify-center md:justify-start font-medium -mx-5 -my-1">
                <li className="mx-5 my-1">
                  <Link href="/" passHref>
                    <a
                      className={
                        !category_id && router.pathname === '/'
                          ? 'text-primary'
                          : 'text-gray-800 hover:underline"'
                      }
                    >
                      {Home_page_text.home.view_all}
                    </a>
                  </Link>
                </li>
                {categories.map((ct) => {
                  return (
                    <li className="mx-5 my-1" key={ct.id}>
                      <Link
                        href={{ path: `/`, query: { category_id: ct.id } }}
                        passHref
                      >
                        <a
                          className={
                            category_id == ct.id
                              ? 'text-primary'
                              : 'text-gray-800 hover:underline"'
                          }
                        >
                          {ct.name}
                        </a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>

        {/* Connected Apps   */}
        {listings && (
          <section className="pb-6 ">
            {listings.length > 0 ? (
              <div className="grid grid-cols-12 gap-6">
                {listings?.map((item, index) => {
                  return (
                    <a
                      className="col-span-full xl:col-span-6 2xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200"
                      key={index}
                    >
                      {/* Card content */}
                      <div className="flex flex-col h-full p-5">
                        <div className="flex-grow">
                          <header className="flex items-center mb-4 gap-3">
                            <img
                              src={item?.images[0]}
                              className="w-10 h-10 rounded-full object-cover  object-left-top  "
                              alt="image"
                            />

                            <h3 className="text-lg text-gray-800 font-semibold">
                              {item.title}
                            </h3>
                          </header>
                          <div className="">
                            <ReadingComposer
                              contents={
                                item?.description.length > 300
                                  ? item.description.substring(0, 300) + '...'
                                  : item.description?.description
                              }
                            />
                          </div>
                        </div>
                        {/* Card footer */}
                        <footer className="mt-4">
                          <div className="flex flex-wrap justify-between items-center">
                            {/* Left side */}
                            <div className="flex space-x-3"></div>
                            {/* Right side */}
                            <div className="m-1.5">
                              <Link
                                href={
                                  item.slug
                                    ? `/l/${item.slug}`
                                    : `/l/${item.id}-${item.title.replace(
                                        /[ /?,#=]+/g,
                                        '-'
                                      )}?id=true`
                                }
                              >
                                <a className="btn-sm w-full text-gray-200 bg-gray-900 hover:bg-gray-800 ">
                                  Configure
                                </a>
                              </Link>
                            </div>
                          </div>
                        </footer>
                      </div>
                    </a>
                  );
                })}
              </div>
            ) : (
              <div className="">
                <p className="paragraph w-full mx-auto text-center">
                  Listings not found
                </p>
              </div>
            )}
          </section>
        )}

        <section>
          {pageCount > 1 && (
            <div className="mt-6 flex justify-center">
              {listings && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                  onPageChange={(data) => moreListings(data)}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                  renderOnZeroPageCount={null}
                  containerClassName=""
                  className="relative z-0 inline-flex flex-wrap justify-center rounded-button shadow-sm -space-x-px "
                  pageClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center text-sm font-medium"
                  pageLinkClassName="px-4 py-2 border"
                  previousClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  nextClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  breakLinkClassName="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                  activeLinkClassName="z-10 bg-primary  border-primary text-white relative inline-flex items-center px-4 py-2 border text-md font-semibold"
                  disabledLinkClassName=""
                  prevPageRel="2"
                  forcePage={page - 1}
                />
              )}
            </div>
          )}
        </section>

        {general_configs?.home_blogs_widget !== false && (
          <div>
            <Latest_blogs />
          </div>
        )}
      </div>
    </>
  );
};

export default HomePageLayout;
