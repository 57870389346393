import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import default_image from '../../../assets/Images/success/default.jpg';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { getThumbnailImage } from '../../Shared/Constant/Constant';

const LayerWIthTitle = ({ item }) => {
  return (
    <Link href={`/b/${item.slug}`}>
      <a className=" block w-full  min-h-[159px] bg-[var(--listingCardBackgroundColor)]   rounded-[var(--listingCardBorderRadius)] overflow-hidden cursor-pointer  shadow-listing_card pb-  transition ease-in-out duration-500 group-hover:bg-[var(--listingCardHoverBackgroundColor)] group-hover:shadow-listing_card_hover">
        <div
          className={[
            ' aspect-w-1 h-[159px] relative  object-cover object-left-top rounded-[var(--listingCardImageBorderRadius)]  overflow-hidden',
            TYPE_CONSTANT.STYLES_CONFIGS?.listing_card?.hover?.image_zoom_in &&
              'transition ease-in-out delay-150  group-hover:-translate-y-2 group-hover:scale-110  duration-300',
          ].join(' ')}
        >
          {item?.cover_image_path ? (
            <img
              className=" h-full w-full  object-cover"
              src={getThumbnailImage(item?.cover_image_path)}
              alt="cover image"
            />
          ) : (
            <Image src={default_image} layout="responsive" objectFit="cover" />
          )}
        </div>
        <div className="mt-3 ltr:pl-2 rtl:pr-2 mb-4">
          <p className="  text-[length:var(--listingCardTitleTextSize)] text-[color:var(--listingCardTitleColor)]   font-[--listingCardTitleTextFontWeight] leading-[--listingCardTitleTextLineHeight]  truncate  ">
            {item.title}
          </p>
        </div>
      </a>
    </Link>
  );
};

export default LayerWIthTitle;
