import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import Banner from '../../components/home/Banner/Banner';
import Banner3 from '../../components/home/Banner/Banner3';
import Banner2 from '../../components/home/Banner/Bannner2';
import Categories from '../../components/home/Categories/Categories';
import LatestListings2 from '../../components/home/LatestListings/LatestListings2';
import StoresForFollow from '../../components/home/Stores/StoresForFollow';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Latest_blogs from '../../components/home/BlogsWidget/Latest_blogs';
import ListingCard3 from '../../components/Shared/Cards/ListingCard3';
import { Listing_text } from '../../constant/Translation/Listing';
import HeroBanner from '../../components/home/Banner/HeroBanner';
import LatestListings3 from '../../components/home/LatestListings/LatestListings3';

const HomePageLayout = () => {
  return (
    <div className="">
      <div>
        <HeroBanner />
      </div>

      <div>
        <LatestListings3 />
      </div>
    </div>
  );
};

export default HomePageLayout;
