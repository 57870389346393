import React, { useEffect, useState } from 'react';
import ListingsList from '../Listings/ListingsList';
import { likeListingFromAllListings } from './actions/likeFromAllListings';
import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from '../../hooks/Redux';
import Pagination from '../Shared/Pagination/Pagination';
import { dynamicPageSelector } from '../../store/feature/Pages/DynamicPage/dynamicPageSlice';

const PaginationTypeListings = ({
  all_listings_data,
  section_details,
  moreListings,
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const { dynamic_page_sections } = useAppSelector(dynamicPageSelector);

  //
  const { fetch_by } = section_details || {};

  //  Count and set total page
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    const totalPage = Math.ceil(
      all_listings_data?.total_records / fetch_by?.per_page
    );
    setPageCount(totalPage);

    return () => {
      setPageCount(0);
    };
  }, [all_listings_data?.total_records]);

  //

  return (
    <div className=" w-full h-full flex flex-col gap-2 justify-between ">
      <ListingsList
        Products={all_listings_data?.listings}
        like_listing={(id, isLiked) =>
          likeListingFromAllListings({
            id,
            isLiked,
            router,
            dispatch,
            dynamic_page_sections,
            section_details,
          })
        }
      />

      <div className=" flex justify-center py-12">
        {all_listings_data !== null && pageCount > 1 && (
          <Pagination
            pageCount={pageCount}
            current_page={all_listings_data?.page}
            moreData={(pageNumber) => moreListings(pageNumber)}
          />
        )}
      </div>
    </div>
  );
};

export default PaginationTypeListings;
