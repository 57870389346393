import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { Home_page_text } from '../../../constant/Translation/Home';

const Categories3 = ({ categories }) => {
  const router = useRouter();
  return (
    <div className=" w-full md:w-[200px] md:max-h-[90vh]   p-4  flex flex-row md:flex-col gap-3 md:gap-2   overflow-x-auto  md:overflow-y-auto  ">
      <Link
        href={{
          pathname: '/',
        }}
      >
        <a>
          <span
            className={[
              ' hidden md:block w-full whitespace-nowrap md:whitespace-normal text-ellipsis font-normal text-sm ',
              !router.query?.category_id && '   md:underline',
            ].join(' ')}
          >
            {Home_page_text.home.view_all}
          </span>

          <span
            className={[
              ' md:hidden w-full whitespace-nowrap md:whitespace-normal text-ellipsis font-normal text-sm btn-primary-outline px-2 py-1',
              !router.query?.category_id && '   btn-primary-fill  px-2 py-1',
            ].join(' ')}
          >
            {Home_page_text.home.view_all}
          </span>
        </a>
      </Link>
      {categories?.map((ct) => {
        return (
          <Link
            href={{
              pathname: '/',
              query: {
                category_id: ct.id,
              },
            }}
          >
            <a>
              <span
                className={[
                  ' hidden md:block w-full whitespace-nowrap md:whitespace-normal text-ellipsis font-normal text-sm ',
                  router.query?.category_id == ct.id && '   md:underline',
                ].join(' ')}
              >
                {ct.name}
              </span>

              <span
                className={[
                  ' md:hidden w-full whitespace-nowrap md:whitespace-normal text-ellipsis font-normal text-sm btn-primary-outline px-2 py-1',
                  router.query?.category_id == ct.id &&
                    '   btn-primary-fill  px-2 py-1',
                ].join(' ')}
              >
                {ct.name}
              </span>
            </a>
          </Link>
        );
      })}
    </div>
  );
};

export default Categories3;
