import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { Home_page_text } from '../../../constant/Translation/Home';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { slug as SlugChecker } from 'github-slugger';
import CategoryListSkeleton from '../../Shared/skeletonUI/CategoryListSkeleton';

const ListCategories = ({
  categories,
  styles,
  extra_data,
  setAllListingsFilter,
  AllListingFilter,
  AllAccountFilter,
  setAllAccountFilter,
  fetch_by,
}) => {
  const router = useRouter();
  const current_filter =
    fetch_by?.type === 'listings' ? AllListingFilter : AllAccountFilter;

  const listings_by_category = ({ category_id }) => {
    if (category_id !== '') {
      setAllListingsFilter({ category_id: category_id });
    } else {
      setAllListingsFilter({ page: 1 });
    }
  };
  const accounts_by_category = ({ category_id }) => {
    if (category_id !== '') {
      setAllAccountFilter({ category_id: category_id });
    } else {
      setAllAccountFilter({ page: 1 });
    }
  };

  return (
    <div className=" w-full  " style={get_dynamic_styles(styles?.main_styles)}>
      <div style={get_dynamic_styles(styles?.box_styles)}>
        {categories !== null ? (
          <div
            className="w-full  flex  flex-col  "
            style={get_dynamic_styles(styles?.categories_styles)}
          >
            {extra_data?.is_connected_with_home_filter ? (
              //       {/* button type */}
              <>
                <button
                  onClick={() =>
                    fetch_by?.type == 'listings'
                      ? listings_by_category({
                          category_id: '',
                        })
                      : accounts_by_category({
                          category_id: '',
                        })
                  }
                  className={[
                    ' cursor-pointer w-full whitespace-nowrap md:whitespace-normal text-ellipsis font-normal text-sm text-start ',
                    !current_filter?.category_id && '   md:underline',
                  ].join(' ')}
                >
                  {Home_page_text.home.view_all}
                </button>

                {categories?.map((ct) => {
                  return (
                    <button
                      onClick={() =>
                        fetch_by?.type == 'listings'
                          ? listings_by_category({
                              category_id: ct.id,
                            })
                          : accounts_by_category({
                              category_id: ct.id,
                            })
                      }
                      className={[
                        ' cursor-pointer w-full whitespace-nowrap md:whitespace-normal text-ellipsis font-normal text-sm text-start ',
                        current_filter?.category_id == ct.id &&
                          '   md:underline',
                      ].join(' ')}
                    >
                      {ct.name}
                    </button>
                  );
                })}
              </>
            ) : (
              //       {/* link type */}
              <>
                <Link href={fetch_by?.type == 'listings' ? '/lc/' : '/a'}>
                  <a
                    className={[
                      ' cursor-pointer w-full whitespace-nowrap md:whitespace-normal text-ellipsis font-normal text-sm text-start ',
                      !current_filter?.category_id && '   md:underline',
                    ].join(' ')}
                  >
                    {Home_page_text.home.view_all}
                  </a>
                </Link>

                {categories?.map((ct) => {
                  const query = extra_data?.is_connected_with_home_filter
                    ? { category_id: ct.id }
                    : fetch_by?.type == 'listings'
                    ? {
                        slug: ct.slug
                          ? ct.slug
                          : `${ct?.id}-${SlugChecker(ct.name)}`,
                      }
                    : { category_id: ct.id };
                  return (
                    <Link
                      href={{
                        pathname:
                          fetch_by?.type == 'listings' ? '/lc/[slug]' : '/a',
                        query: query,
                      }}
                    >
                      <a
                        className={[
                          ' cursor-pointer w-full whitespace-nowrap md:whitespace-normal text-ellipsis font-normal text-sm  text-start',
                          current_filter?.category_id == ct.id &&
                            '   md:underline',
                        ].join(' ')}
                      >
                        {ct.name}
                      </a>
                    </Link>
                  );
                })}
              </>
            )}
          </div>
        ) : (
          <CategoryListSkeleton />
        )}
      </div>
    </div>
  );
};

export default ListCategories;
