import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import ReadingComposer from '../Shared/Composer/ReadingComposer';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';

const LayerDescription = ({ data, styles }) => {
  return (
    <div className="   " style={get_dynamic_styles(styles?.main_styles)}>
      <div style={get_dynamic_styles(styles?.box_styles)}>
        <h2 style={get_dynamic_styles(styles?.title_styles)}>{data?.title}</h2>
        <ReadingComposer
          contents={data?.description}
          dependents={data?.description}
          mainClass={` prose prose-sm sm:prose lg:prose-lg xl:prose-xl m-0 focus:outline-none   prose-a:text-primary  prose-a:border-0 hover:prose-a:underline prose-img:m-0`}
        />
      </div>
    </div>
  );
};

export default LayerDescription;
