import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Add_address_button from '../../components/MyAddresses/Add_address_button';
import PickupAddress from '../../components/MyAddresses/PickupAddress';
import ShippingAddresses from '../../components/MyAddresses/ShippingAddresses';
import {
  addressSelector,
  getPickupAddress,
  getShippingAddress,
} from '../../store/feature/addressSlice';

const AddressesPageLayout = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  //Effect
  useEffect(() => {
    dispatch(getShippingAddress({ bodyParam: { type: 'shipping' } }));
    //dispatch(getPickupAddress({ bodyParam: { type: 'pickup' } }));
  }, [router]);

  const { shipping_addresses, pickup_addresses } = useSelector(addressSelector);

  return (
    <div className="w-full flex flex-col justify-center items-center gap-5 mt-2  md:mt-5">
      <Add_address_button />
      <ShippingAddresses addresses={shipping_addresses} />
    </div>
  );
};

export default AddressesPageLayout;
