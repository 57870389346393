import React, { useEffect, useState } from 'react';
import KYCProfile from './KYCProfile';
import KYCBankDetails from './BankDetails';
import KYCDocument from './KYCDocument';
import Paragraph from '../../UI/Text/Paragraph/Paragraph';
import {
  BANK_DETAILS_FORMAT,
  DOCUMENT_DETAILS_FORMAT,
  KYC_PROFILE_DETAILS_FORMAT,
} from './types/KYCTypes';
import PrimaryButton from '../../UI/Buttons/PrimaryButton';
import SecondaryButton from '../../UI/Buttons/SecondaryButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  KYCSelector,
  clearKYCSubmission,
  getKYCDetails,
  kycFullFOrmSubmission,
} from '../../store/feature/KYC/kycSlice';
import { changeDateFormatFromDate } from '../../utils/date';
import { delete_item_from_local_storage } from '../../utils/localStorage';
import { local_storage_keys } from '../Localstorage/LocalStorageKeys';
import { useAppDispatch } from '../../hooks/Redux';
import { getAllCountriesList } from '../../store/feature/countrySlice';
import { useRouter } from 'next/router';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const KYCSteps = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const { isSubmissionLoading, isSubmissionSuccess, isError } =
    useSelector(KYCSelector);
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';

  // Profile details
  const [profile_details, setProfileDetails] =
    useState<KYC_PROFILE_DETAILS_FORMAT>({
      birth_date: null,
      nationality_country_code: null,
      residence_country_code: null,
    });

  // bank details
  const [bank_details, setBankDetails] = useState<BANK_DETAILS_FORMAT>({
    owner_name: null,
    iban: null,
    address_1: null,
    address_2: null,
    city: null,
    state: null,
    country: null,
    postal_code: null,
  });

  //document details
  const [document_details, setDocumentDetails] =
    useState<DOCUMENT_DETAILS_FORMAT>({
      file: null,
      file_url: null,
      file_name: null,
      base_64: null,
    });

  // oncChange Handler

  // Steps
  const [steps, setSteps] = useState({
    step_1: true,
    step_2: false,
    final_step: false,
  });

  // Function to go to the next step
  const goToNextStep = () => {
    const currentStep = Object.keys(steps).find((step) => steps[step]);

    if (currentStep) {
      const currentIndex = Object.keys(steps).indexOf(currentStep);
      const nextIndex = (currentIndex + 1) % Object.keys(steps).length;
      const nextStep = Object.keys(steps)[nextIndex];

      setSteps((prevSteps) => ({
        ...prevSteps,
        [currentStep]: false,
        [nextStep]: true,
      }));
    }
  };

  // Function to go to the previous step
  const goToPrevStep = () => {
    const currentStep = Object.keys(steps).find((step) => steps[step]);
    if (currentStep) {
      const currentIndex = Object.keys(steps).indexOf(currentStep);
      const prevIndex =
        (currentIndex - 1 + Object.keys(steps).length) %
        Object.keys(steps).length;
      const prevStep = Object.keys(steps)[prevIndex];

      setSteps((prevSteps) => ({
        ...prevSteps,
        [currentStep]: false,
        [prevStep]: true,
      }));
    }
  };

  //form_submission_handler
  const form_submission_handler = () => {
    const personal_details = {
      birth_date: changeDateFormatFromDate(
        profile_details?.birth_date,
        'YYYY-MM-DD'
      ),
      nationality_country_code: profile_details?.nationality_country_code,
      residence_country_code: profile_details?.residence_country_code,
    };

    const document = {
      file_body: document_details?.base_64,
    };

    dispatch(
      // @ts-ignore
      kycFullFOrmSubmission({
        personal_details,
        document,
      })
    );
  };

  // effect
  useEffect(() => {
    if (isSubmissionSuccess) {
      dispatch(
        // @ts-ignore
        getKYCDetails({ currency: currency })
      );
      dispatch(clearKYCSubmission());
      delete_item_from_local_storage({
        key_name: 'bank_details',
      });
      delete_item_from_local_storage({
        key_name: 'profile_details',
      });
    }
  }, [isSubmissionSuccess]);

  useEffect(() => {
    // @ts-ignore
    dispatch(getAllCountriesList({}));
  }, [0]);

  return (
    <div className="max-w-lg  mx-auto py-6 px-4 space-y-14">
      {/* Form note */}
      <Paragraph styles="text-sm italic text-center">
        Identity confirmation by Payment service provider. Mangopay, our trusted
        payment provider, uses this form to collect information and confirm your
        identity, know as KYC Check.
      </Paragraph>
      {/* Form fields */}
      {steps?.step_1 && (
        <KYCProfile
          profile_details={profile_details}
          setProfileDetails={setProfileDetails}
          group_name="profile_details"
        />
      )}

      {/* Bank Details */}
      {steps?.step_2 && (
        <KYCBankDetails
          bank_details={bank_details}
          setBankDetails={setBankDetails}
          group_name="bank_details"
        />
      )}

      {/* Kyc document */}
      {steps?.final_step && (
        <KYCDocument
          kyc_document={document_details}
          setDocumentDetails={setDocumentDetails}
          group_name="document_details"
        />
      )}

      {/* Step changing button */}
      <div className="flex items-center justify-between">
        <SecondaryButton
          onClick={goToPrevStep}
          className=" px-10"
          isDisabled={steps?.step_1 ? true : false}
        >
          Back
        </SecondaryButton>
        {steps?.final_step ? (
          <PrimaryButton
            onClick={form_submission_handler}
            isDisabled={isSubmissionLoading}
            isLoading={isSubmissionLoading}
            className=" px-10"
          >
            Submit
          </PrimaryButton>
        ) : (
          <PrimaryButton
            onClick={goToNextStep}
            className=" px-10"
            isDisabled={steps?.final_step ? true : false}
          >
            Next
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default KYCSteps;
