import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { push_data_layer } from '../../constant/GTMDatalayer';
import { Address_text } from '../../constant/Translation/Address';
import {
  addressSelector,
  clearErrorState,
  getPickupAddress,
  getShippingAddress,
  save_address,
} from '../../store/feature/addressSlice';
import CustomLoading from '../Shared/Loading/CustomLoading';
import Modal from '../Shared/Modal/Modal';
import PopUp from '../Shared/PopUp/PopUp';
import AddressForm from './AddressForm';

const Add_address_button = () => {
  const [address_form, setAddressForm] = useState(false);
  const { register, handleSubmit, setValue, control } = useForm();
  const [type, setType] = useState('shipping');
  const dispatch = useDispatch();
  const { isFetching, isError, errorMessage } = useSelector(addressSelector);

  // save address
  const onSubmit = (data) => {
    dispatch(
      save_address({
        id: undefined,
        addressData: {
          address: {
            ...Object.fromEntries(
              Object.entries(data).filter(([key, value]) => value !== '')
            ),
            type: type,
          },
        },
      })
    ).then((res) => {
      if (!res.payload.code) {
        dispatch(
          getPickupAddress({
            bodyParam: { type: 'pickup' },
          })
        );
        dispatch(
          getShippingAddress({
            bodyParam: { type: 'shipping' },
          })
        );
        push_data_layer({
          event: 'add_address',
          data: {},
        });
        setAddressForm(false);
      }
    });
  };

  //
  const closePopUP = () => {
    dispatch(clearErrorState());
  };

  return (
    <>
      {isFetching && <CustomLoading />}
      {isError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            isError && dispatch(clearErrorState());
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp message={errorMessage} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      <div className="w-full flex  justify-end">
        {address_form && (
          <Modal>
            <OutsideClickHandler
              onOutsideClick={() => {
                setAddressForm(false);
              }}
            >
              <AddressForm
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                register={register}
                setShowAddressForm={setAddressForm}
                isNewAddress={true}
                setValue={setValue}
                type={type}
                setType={setType}
                control={control}
              />
            </OutsideClickHandler>
          </Modal>
        )}
        <button
          onClick={() => setAddressForm(!address_form)}
          className="btn-sm bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary flex items-center gap-2  "
        >
          {Address_text.address.add_address}
        </button>
      </div>
    </>
  );
};

export default Add_address_button;
