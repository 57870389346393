import axios from 'axios';
import tradly from 'tradly';
import { push_data_layer } from '../../../../constant/GTMDatalayer';

export const changeSchedule = (
  schedulesObject,
  setSchedulesObject,
  setError_message,
  setShowError,
  setEditScheduleLoading,
  setEditScheduleData,
  setIsEditSchedule,
  schedulesArray,
  editScheduleIndex,
  productId,
  auth_key,
  setShowSuccessMessage,
  setEditScheduleIndex,
  setIsScheduleFormOpen
) => {
  setEditScheduleLoading(true);

  const filter = schedulesArray.filter(
    (item, index) => index !== editScheduleIndex
  );

  axios
    .post('/api/schedules/create_schedule', {
      id: productId,
      data: { schedules: [...filter, schedulesObject] },
    })
    .then((res) => {
      setIsEditSchedule(false), setEditScheduleData(null);
      setIsScheduleFormOpen(false);
      setEditScheduleIndex(null);
      setSchedulesObject({
        start_date: null,
        start_time: null,
        end_time: null,
        schedule_type: 2,
        repeat_days: null,
        active: true,
      });
      push_data_layer({
        event: 'edit_schedule',
        data: {},
      });
      setEditScheduleLoading(false);
      setShowSuccessMessage(true);
    })
    .catch((error) => {
      setShowError(true);
      setError_message(error.response.data.message);
      setEditScheduleLoading(false);
    });
};

export const addNewSchedule = (
  schedulesObject,
  setSchedulesObject,
  setError_message,
  setShowError,
  setEditScheduleLoading,
  setEditScheduleData,
  setIsEditSchedule,
  schedulesArray,
  editScheduleIndex,
  productId,
  auth_key,
  setShowSuccessMessage,
  setEditScheduleIndex,
  setIsScheduleFormOpen
) => {
  setEditScheduleLoading(true);

  //
  function removeId(obj) {
    // Check if the object has an id key and delete it
    if (obj.hasOwnProperty('id')) {
      delete obj.id;
    }
    return obj;
  }

  const data = {
    schedules: [...schedulesArray.map(removeId), removeId(schedulesObject)],
  };

  axios
    .post('/api/schedules/create_schedule', {
      id: productId,
      data: data,
    })
    .then((res) => {
      setIsScheduleFormOpen(false);
      setSchedulesObject({
        start_date: null,
        start_time: null,
        end_time: null,
        schedule_type: 2,
        repeat_days: null,
        active: true,
      });
      push_data_layer({
        event: 'edit_schedule',
        data: {},
      });
      setEditScheduleLoading(false);
      setShowSuccessMessage(true);
    })
    .catch((error) => {
      setShowError(true);
      setError_message(error.response.data.message);
      setEditScheduleLoading(false);
    });
};

export const deleteSchedule = (
  setError_message,
  setShowError,
  setEditScheduleLoading,
  schedulesArray,
  productId,
  auth_key,
  setShowSuccessMessage,
  selectScheduleIndex
) => {
  setEditScheduleLoading(true);

  const filter = schedulesArray.map((item, index) =>
    index !== selectScheduleIndex ? item : { ...item, archived: true }
  );
  if (filter.length > 0) {
    axios
      .post('/api/schedules/create_schedule', {
        id: productId,
        data: { schedules: filter },
      })
      .then((res) => {
        setEditScheduleLoading(false);
        setShowSuccessMessage(true);
      })
      .catch((error) => {
        setShowError(true);
        setError_message(error.response.data.message);
        setEditScheduleLoading(false);
      });
  } else {
    setShowError(true);
    setError_message("You can't delete all schedules");
    setEditScheduleLoading(false);
  }
};
