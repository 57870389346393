import React, { useEffect, useState } from 'react';
import AddressBox from '../../components/OrderDetails/AddressBox/AddressBox';
import CustomerDetails from '../../components/OrderDetails/CustomerDetails/CustomerDetails';
import OrderSummary from '../../components/OrderDetails/OrderSummary/OrderSummary';
import TotalAmountBox from '../../components/OrderDetails/TotalBox/TotalAmountBox';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  get_order_details,
  orderSelector,
} from '../../store/feature/orderSlice';
import CustomLoading from '../../components/Shared/Loading/CustomLoading';
import PopUp from '../../components/Shared/PopUp/PopUp';
import OutsideClickHandler from 'react-outside-click-handler';
import SuccessPopUp from '../../components/Shared/PopUp/Success';
import ItemsSummary from '../../components/AddReview/ItemsSummary/ItemsSummary';
import ReviewBox from '../../components/AddReview/ReviewBox/ReviewBox';
import { add_review } from '../../components/AddReview/send_review';
import Review_status from '../../components/AddReview/ReviewBox/review_status';
import { Review_text } from '../../constant/Translation/Review';

const AddReviewPageLayout = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [selected_product, setSelected_product] = useState(null);
  const [selected_product_review_status, setSelected_product_review_status] =
    useState(false);
  const [rating_value, setRating_value] = useState(5);
  const [rating_title, setRating_title] = useState('Excellent Service');
  const [rating_description, setRating_description] = useState(null);
  const [imagePath, setImagePath] = useState([]);
  const [files, setFiles] = useState([]);
  const [fullFile, setFullFile] = useState([]);

  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [success_message, setSuccess_message] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const { auth_key } = useSelector(authSelector);

  useEffect(() => {
    if (auth_key) {
      dispatch(get_order_details({ authKey: auth_key, id: router.query.id }));
    }
  }, [auth_key, router.query.id, showSuccess]);
  const { order_details } = useSelector(orderSelector);

  useEffect(() => {
    setSelected_product(order_details?.order_details[0].listing.id);
    setSelected_product_review_status(
      order_details?.order_details[0].listing.review_status
    );
  }, [order_details]);

  const closePopUP = () => {
    setShowError(false);
    setError_message('');
    setShowSuccess(false);
    setSuccess_message('');
    dispatch(get_order_details({ authKey: auth_key, id: router.query.id }));
  };

  // send review
  const send_review = () => {
    if (selected_product === null) {
      setShowError(true);
      setError_message(Review_text.addreview.select_one);
      return false;
    }
    add_review(
      selected_product,
      'listings',
      rating_value,
      rating_title,
      rating_description,
      imagePath,
      files,
      fullFile,
      setShowError,
      setError_message,
      setShowSuccess,
      setSuccess_message,
      setIsLoading
    );
  };

  return (
    <div className="w-full">
      {showError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showError && (setShowError(false), setError_message(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp message={error_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {showSuccess && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showSuccess && (setShowSuccess(false), setSuccess_message(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <SuccessPopUp message={success_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {/* <CustomLoading/> */}
      <div className=" flex gap-2">
        <h2 className=" text-xl sm:text-3xl font-semibold text-black">
          {Review_text.addreview.order}
        </h2>
        <h2 className="  text-xl sm:text-3xl font-semibold text-primary  ">
          {`# ${order_details?.reference_number}`}
        </h2>
        <button
          className=""
          onClick={() => {
            navigator.clipboard.writeText(order_details?.reference_number);
            alert(
              'Copied the order ref number: ' + order_details?.reference_number
            );
          }}
        >
          <svg
            width="20"
            height="22"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 18H5.5C4.70435 18 3.94129 17.6839 3.37868 17.1213C2.81607 16.5587 2.5 15.7956 2.5 15V5C2.5 4.73478 2.39464 4.48043 2.20711 4.29289C2.01957 4.10536 1.76522 4 1.5 4C1.23478 4 0.98043 4.10536 0.792893 4.29289C0.605357 4.48043 0.5 4.73478 0.5 5V15C0.5 16.3261 1.02678 17.5979 1.96447 18.5355C2.90215 19.4732 4.17392 20 5.5 20H13.5C13.7652 20 14.0196 19.8946 14.2071 19.7071C14.3946 19.5196 14.5 19.2652 14.5 19C14.5 18.7348 14.3946 18.4804 14.2071 18.2929C14.0196 18.1054 13.7652 18 13.5 18ZM18.5 6.94C18.4896 6.84813 18.4695 6.75763 18.44 6.67V6.58C18.3919 6.47718 18.3278 6.38267 18.25 6.3L12.25 0.3C12.1673 0.222216 12.0728 0.158081 11.97 0.11H11.88L11.56 0H7.5C6.70435 0 5.94129 0.316071 5.37868 0.87868C4.81607 1.44129 4.5 2.20435 4.5 3V13C4.5 13.7956 4.81607 14.5587 5.37868 15.1213C5.94129 15.6839 6.70435 16 7.5 16H15.5C16.2956 16 17.0587 15.6839 17.6213 15.1213C18.1839 14.5587 18.5 13.7956 18.5 13V7C18.5 7 18.5 7 18.5 6.94ZM12.5 3.41L15.09 6H13.5C13.2348 6 12.9804 5.89464 12.7929 5.70711C12.6054 5.51957 12.5 5.26522 12.5 5V3.41ZM16.5 13C16.5 13.2652 16.3946 13.5196 16.2071 13.7071C16.0196 13.8946 15.7652 14 15.5 14H7.5C7.23478 14 6.98043 13.8946 6.79289 13.7071C6.60536 13.5196 6.5 13.2652 6.5 13V3C6.5 2.73478 6.60536 2.48043 6.79289 2.29289C6.98043 2.10536 7.23478 2 7.5 2H10.5V5C10.5 5.79565 10.8161 6.55871 11.3787 7.12132C11.9413 7.68393 12.7044 8 13.5 8H16.5V13Z"
              fill="var(--primary_color)"
            />
          </svg>
        </button>
      </div>
      <div className=" grid   grid-cols-[100%]  lg:grid-cols-[40%,60%]   mt-6">
        <div>
          <div>
            <ItemsSummary
              order_details={order_details}
              selected_product={selected_product}
              setSelected_product={setSelected_product}
              setSelected_product_review_status={
                setSelected_product_review_status
              }
            />
          </div>
        </div>
        <div className="    xl:ml-10">
          {selected_product_review_status ? (
            <Review_status />
          ) : (
            <ReviewBox
              rating_value={rating_value}
              setRating_value={setRating_value}
              rating_title={rating_title}
              setRating_title={setRating_title}
              rating_description={rating_description}
              setRating_description={setRating_description}
              imagePath={imagePath}
              setImagePath={setImagePath}
              files={files}
              setFiles={setFiles}
              fullFile={fullFile}
              setFullFile={setFullFile}
              send_review={send_review}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddReviewPageLayout;
