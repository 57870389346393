import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { General_text } from '../../../constant/Translation/General';
import { Listing_text } from '../../../constant/Translation/Listing';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { authSelector } from '../../../store/feature/authSlice';
import {
  listingSelector,
  reportListing,
} from '../../../store/feature/listingSlice';
import ModalBasic from '../../Shared/Modal/ModalBesic';
import PopupUI from '../../Shared/PopUp/UI/PopupUI';
import axios from 'axios';
import { Account_text } from '../../../constant/Translation/Account';

const AccountReportButton = ({
  account_details,
  setShowError,
  setErrorMessage,
  setShowSuccess,
  setSuccessMessage,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { login } = useSelector(authSelector);

  //
  const [isOwner, setIsOwner] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  //
  const [form_open, setForm_open] = useState(false);
  const [reason_text, setReasonText] = useState('');

  //effect for findout account user
  useEffect(() => {
    if (
      Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`) &&
      JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_isStoreAccount`))
    ) {
      if (
        Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) &&
        JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)) ==
          account_details?.id
      ) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  }, [account_details]);

  //post_report
  const post_report = (e) => {
    setIsLoading(true);
    axios
      .post('/api/report/account', {
        id: account_details?.id,
        data: { reason: reason_text },
      })
      .then((res) => {
        e.stopPropagation();
        setForm_open(false);
        setReasonText('');
        setIsLoading(false);
        setShowSuccess(true);
        setSuccessMessage(Account_text.storedetail?.report_success);
      })
      .catch((error) => {
        e.stopPropagation();
        setForm_open(false);
        setReasonText('');
        setIsLoading(false);
        setShowError(true);
        setErrorMessage(error.response?.data?.message);
      });
  };

  return (
    <>
      {/* */}
      {account_details && !isOwner && (
        <button
          className="text-lg text-primary  underline"
          onClick={(e) => {
            if (login) {
              e.stopPropagation();
              setForm_open(true);
            } else {
              router.push(`/sign-in?to=${router.asPath}`);
            }
          }}
        >
          {Account_text.storedetail.report}
        </button>
      )}

      <ModalBasic
        title="report-section"
        id="qna-section-form"
        modalOpen={form_open}
        setModalOpen={setForm_open}
        closeByOutsideClick={false}
        close_func={() => {
          setReasonText('');
        }}
      >
        <section className="min-h-min  ">
          <PopupUI
            title={Account_text.storedetail.report}
            left_button={{
              title: General_text.general.cancel,
              click: (e) => {
                e.stopPropagation();
                setForm_open(false);
                setReasonText('');
              },
            }}
            right_button={{
              title: General_text.general.submit,
              click: (e) => {
                reason_text?.replace(/\s/g, '').length > 1 && post_report(e);
              },
              disabled:
                reason_text?.replace(/\s/g, '').length < 1 ? true : false,
              extra_styles:
                reason_text?.replace(/\s/g, '').length < 1
                  ? ' cursor-not-allowed '
                  : ' cursor-pointer',
              icon: isLoading ? (
                <svg
                  className="animate-spin   h-5 w-5  "
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                ''
              ),
            }}
          >
            <div className="px-10 py-6">
              <textarea
                className="w-full rounded-input border border-primary focus:border-primary "
                name="qna-form"
                id=""
                cols="3"
                rows="5"
                value={reason_text}
                placeholder={Account_text.storedetail?.report_reason}
                onChange={(e) => setReasonText(e.target.value)}
              ></textarea>
            </div>
          </PopupUI>
        </section>
      </ModalBasic>
    </>
  );
};

export default AccountReportButton;
