/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import {
  angle_down,
  check_icon,
  filter_icon,
  star_icon,
} from './Constant/Icons/AllIcons';
import tradly from 'tradly';
import { useEffect } from 'react';
import { useRouter } from 'next/dist/client/router';
import {
  convertTimeinto12Hrs,
  getDatesArray2,
  priceRange,
} from './Constant/Constant';
import OutsideClickHandler from 'react-outside-click-handler';
import { getDatesArray, getTimeDifference } from './Constant/Constant';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import moment from 'moment';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { fetch_all_categories } from '../../constant/fetch_all_categories';
import { Filter_text } from '../../constant/Translation/Filter';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const DynamicListingFilter = ({
  data,
  reset_filter,
  styles,
  setAllListingsFilter,
  AllListingFilter,
}) => {
  const [MARKETPLACE_MODULES, setMARKETPLACE_MODULES] = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSeeAllCategories, setIsSeeAllCategories] = useState(false);
  const [allCategories, setAllCategories] = useState(null);
  const [allAttributes, setAllAttributes] = useState(null);

  const router = useRouter();
  const category_id = AllListingFilter?.category_id?.toString();
  const country = AllListingFilter?.country;
  const city = AllListingFilter?.city;
  const sort = AllListingFilter?.sort;
  const rating = AllListingFilter?.rating;
  const attribute_value_id = AllListingFilter?.attribute_value_id;
  const [activeParent, setActiveParent] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedAtValues, setSelectedAtValues] = useState([]);

  const start_at = AllListingFilter?.start_at;
  const end_at = AllListingFilter?.end_at;
  const [range_value, setRange_value] = useState([1]);
  const [changed_value, setChanged_value] = useState(['00:00:00', '23:59:59']);
  const [is_value_changed, setIs_value_changed] = useState(false);

  const [listingsCities, setListingsCities] = useState(null);
  const [listingsCountries, setListingsCountries] = useState(null);
  const [isSeeAllCountries, setIsSeeAllCountries] = useState(false);
  const [isSeeAllCities, setIsSeeAllCities] = useState(false);
  // const [selectedCities, setSelectedCities] = useState([]);
  // const [selectedCountries, setSelectedCountries] = useState([]);

  let dates = getDatesArray2();

  // Fetch Categories , attributes ,locations
  useEffect(() => {
    axios
      .get('/api/categories', { params: { parent: 0, type: 'listings' } })
      .then((res) => {
        setAllCategories(fetch_all_categories(res.data.categories, true));
      });

    // Attributes
    let attribute_params = { type: 'listings' };
    if (category_id) {
      attribute_params['category_id'] = category_id;
    }
    axios.get('/api/attributes', { params: attribute_params }).then((res) => {
      setAllAttributes(res.data.attributes);
    });

    if (category_id) {
      setSelectedCategories(category_id.split(','));
    }
    if (attribute_value_id) {
      setSelectedAtValues(attribute_value_id.split(','));
    }

    // City
    axios
      .get('/api/l/locations', { params: { type: 'city', page: 1 } })
      .then((res) => {
        setListingsCities(res.data.values);
      });
    // Countries
    axios
      .get('/api/l/locations', {
        params: { type: 'country', page: 1 },
      })
      .then((res) => {
        setListingsCountries(res.data.values);
      });
  }, [AllListingFilter]);

  // set marketplace module and start at time
  useEffect(() => {
    setMARKETPLACE_MODULES(Number(TYPE_CONSTANT.MARKETPLACE_MODULES));
    if (start_at !== undefined) {
      setChanged_value([
        start_at.split('T')[1].replace('Z', ''),
        end_at.split('T')[1].replace('Z', ''),
      ]);
    }
  }, [AllListingFilter]);

  // Toggle function in arrow icon
  const toggleChildren = (e, id, children) => {
    e.stopPropagation();

    const findParent = activeParent?.find((parentId) => parentId === id);
    let filterActive;
    if (findParent !== undefined) {
      filterActive = activeParent?.filter((parentId) => parentId !== id);
    }

    setActiveParent(
      findParent === undefined ? [...activeParent, id] : [...filterActive]
    );
  };

  // filter by category
  const filter_by_category = (id) => {
    const check = selectedCategories?.find((ct) => ct == id);
    if (check === undefined) {
      setSelectedCategories([...selectedCategories, id]);
      setAllListingsFilter((old_items) => ({
        ...old_items,
        category_id: [...selectedCategories, id].toString(''),
      }));
    } else {
      const filter = selectedCategories?.filter((ct) => ct != id);
      setSelectedCategories(filter);
      if (filter.length > 0) {
        setAllListingsFilter((old_items) => ({
          ...old_items,
          category_id: [...filter].toString(''),
        }));
      } else {
        const queries = {
          ...AllListingFilter,
          page: AllListingFilter?.page ?? 1,
        };
        delete queries.category_id;
        setAllListingsFilter(queries);
      }
    }
  };

  // filter by attribute
  const filter_by_attribute_value = (id) => {
    const check = selectedAtValues?.find((at) => at == id);
    if (check === undefined) {
      setSelectedAtValues([...selectedAtValues, id]);
      setAllListingsFilter((old_items) => ({
        ...old_items,
        attribute_value_id: [...selectedAtValues, id].toString(''),
      }));
    } else {
      const filter = selectedAtValues?.filter((at) => at != id);
      setSelectedAtValues(filter);
      if (filter.length > 0) {
        setAllListingsFilter((old_items) => ({
          ...old_items,
          attribute_value_id: [...filter].toString(''),
        }));
      } else {
        const queries = {
          ...AllListingFilter,
          page: AllListingFilter?.page ?? 1,
        };
        delete queries.attribute_value_id;
        setAllListingsFilter(queries);
      }
    }
  };

  // filter by country
  const filter_by_country = (value) => {
    if (country == value) {
      const queries = {
        ...AllListingFilter,
        page: AllListingFilter?.page ?? 1,
      };
      delete queries.country;
      setAllListingsFilter(queries);
    } else {
      setAllListingsFilter((old_items) => ({
        ...old_items,
        country: value,
      }));
    }
  };
  // filter by City
  const filter_by_city = (value) => {
    if (city == value) {
      if (router.pathname.startsWith(`/l/c/[city]`)) {
        router.push({ pathname: '/l/c', query: {} });
      } else {
        const queries = {
          ...AllListingFilter,
          page: AllListingFilter?.page ?? 1,
        };
        delete queries.city;
        setAllListingsFilter(queries);
      }
    } else {
      setAllListingsFilter((old_items) => ({
        ...old_items,
        city: value,
      }));
    }
  };
  // filter by price rang
  const filter_by_price_rang = (value) => {
    if (sort == value) {
      const queries = {
        ...AllListingFilter,
        page: AllListingFilter?.page ?? 1,
      };
      delete queries.sort;
      setAllListingsFilter(queries);
    } else {
      setAllListingsFilter((old_items) => ({
        ...old_items,
        sort: value,
      }));
    }
  };

  // filter by rating
  const filter_by_rating = (rating_value) => {
    if (rating == rating_value) {
      const queries = {
        ...AllListingFilter,
        page: AllListingFilter?.page ?? 1,
      };
      delete queries.rating;
      setAllListingsFilter(queries);
    } else {
      setAllListingsFilter((old_items) => ({
        ...old_items,
        rating: rating_value,
      }));
    }
  };

  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div style={get_dynamic_styles(styles?.box_styles)}>
        <button
          style={get_dynamic_styles(styles?.clear_button_style)}
          onClick={() => {
            reset_filter
              ? (reset_filter(),
                setSelectedAtValues([]),
                setSelectedCategories([]))
              : setAllListingsFilter({
                  page: AllListingFilter?.page ? AllListingFilter?.page : 1,
                });
            setSelectedAtValues([]), setSelectedCategories([]);
          }}
        >
          {Filter_text.filter.clear}
        </button>
        <div
          className={'w-full h-full   flex '}
          style={get_dynamic_styles(styles?.filter_component_style)}
        >
          {/* Categories Part */}
          {data?.hidden_category !== false && (
            <div className="w-full">
              <h4
                className="flex"
                style={get_dynamic_styles(styles?.filter_title_style)}
              >
                <span className=" cursor-pointer">
                  {Filter_text.filter.category}
                </span>
              </h4>
              {allCategories?.map((item, index, array) => {
                if (array.length > 4) {
                  if (index < 4) {
                    return (
                      <p
                        className="flex"
                        style={get_dynamic_styles(styles?.filter_menu_style)}
                        key={Math.random()}
                        // onClick={() => filter_by_category(item.id)}
                      >
                        <span className=" ">{item.name}</span>
                        <input
                          className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                          type="checkbox"
                          checked={
                            selectedCategories?.includes(`${item.id}`)
                              ? true
                              : false
                          }
                          onChange={() => filter_by_category(item.id)}
                        />
                        {/* {selectedCategories?.includes(`${item.id}`) && (
                          
                        )} */}
                      </p>
                    );
                  }
                  if (index === 4) {
                    return (
                      <button
                        className=" flex    !font-semibold  !w-full"
                        style={get_dynamic_styles(styles?.filter_menu_style)}
                        onClick={() =>
                          setIsSeeAllCategories(!isSeeAllCategories)
                        }
                      >
                        <span>
                          {Filter_text.filter.see_all}{' '}
                          {Filter_text.filter.category}
                        </span>
                        <span
                          className={
                            isSeeAllCategories
                              ? 'transform rotate-180 mr-2'
                              : 'mr-2'
                          }
                        >
                          {angle_down}
                        </span>
                      </button>
                    );
                  }
                } else {
                  return (
                    <p
                      className="flex"
                      style={get_dynamic_styles(styles?.filter_menu_style)}
                      key={Math.random()}
                    >
                      <span className=" ">{item.name}</span>
                      <input
                        className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                        type="checkbox"
                        checked={
                          selectedCategories?.includes(`${item.id}`)
                            ? true
                            : false
                        }
                        onChange={() => filter_by_category(item.id)}
                      />
                      {/* {selectedCategories?.includes(`${item.id}`) && (
                          
                        )} */}
                    </p>
                  );
                }
              })}

              {isSeeAllCategories &&
                allCategories?.map((item, index, array) => {
                  if (array.length > 4) {
                    if (index >= 4) {
                      return (
                        <p
                          className="flex"
                          style={get_dynamic_styles(styles?.filter_menu_style)}
                          key={Math.random()}
                        >
                          <span className=" ">{item.name}</span>
                          <input
                            className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                            type="checkbox"
                            checked={
                              selectedCategories?.includes(`${item.id}`)
                                ? true
                                : false
                            }
                            onChange={() => filter_by_category(item.id)}
                          />
                        </p>
                      );
                    }
                  }
                })}
            </div>
          )}

          {/* Countries */}
          {data?.hidden_countries !== false &&
            listingsCountries?.length > 0 && (
              <div className="w-full">
                <h4
                  className="flex"
                  style={get_dynamic_styles(styles?.filter_title_style)}
                >
                  <span className=" cursor-pointer">
                    {Filter_text.filter.country}
                  </span>
                </h4>
                {listingsCountries?.map((item, index, array) => {
                  if (array.length > 4) {
                    if (index < 4) {
                      return (
                        <p
                          className="flex"
                          style={get_dynamic_styles(styles?.filter_menu_style)}
                          key={Math.random()}
                          onClick={() => filter_by_country(item)}
                        >
                          <span className=" ">{item}</span>
                          <input
                            className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                            type="radio"
                            checked={country === item ? true : false}
                          />
                        </p>
                      );
                    }
                    if (index === 4) {
                      return (
                        <button
                          className="flex   text-primary font-semibold   cursor-pointer w-full"
                          style={get_dynamic_styles(styles?.filter_menu_style)}
                          onClick={() =>
                            setIsSeeAllCountries(!isSeeAllCountries)
                          }
                        >
                          <span>
                            {Filter_text.filter.see_all}{' '}
                            {Filter_text.filter.country}
                          </span>
                          <span
                            className={
                              isSeeAllCountries
                                ? 'transform rotate-180 mr-2'
                                : 'mr-2'
                            }
                          >
                            {angle_down}
                          </span>
                        </button>
                      );
                    }
                  } else {
                    return (
                      <p
                        className="flex  cursor-pointer"
                        style={get_dynamic_styles(styles?.filter_menu_style)}
                        key={Math.random()}
                        onClick={() => filter_by_country(item)}
                      >
                        <span className=" ">{item}</span>
                        <input
                          className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                          type="radio"
                          checked={country === item ? true : false}
                        />
                      </p>
                    );
                  }
                })}

                {isSeeAllCountries &&
                  listingsCountries?.map((item, index, array) => {
                    if (array.length > 4) {
                      if (index > 4) {
                        return (
                          <p
                            className=" flex  cursor-pointer"
                            style={get_dynamic_styles(
                              styles?.filter_menu_style
                            )}
                            key={Math.random()}
                            onClick={() => filter_by_country(item)}
                          >
                            <span className=" ">{item}</span>
                            <input
                              className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                              type="radio"
                              checked={country === item ? true : false}
                            />
                          </p>
                        );
                      }
                    }
                  })}
              </div>
            )}

          {/* City */}

          {data?.hidden_cities !== false && listingsCountries?.length > 0 && (
            <div className="w-full">
              <h4
                className="flex"
                style={get_dynamic_styles(styles?.filter_title_style)}
              >
                <span className=" cursor-pointer">
                  {Filter_text.filter.city}
                </span>
              </h4>
              {listingsCities?.map((item, index, array) => {
                if (array.length > 4) {
                  if (index < 4) {
                    return (
                      <p
                        className="  flex  cursor-pointer"
                        style={get_dynamic_styles(styles?.filter_menu_style)}
                        key={Math.random()}
                        onClick={() => filter_by_city(item)}
                      >
                        <span className=" ">{item}</span>
                        <input
                          className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                          type="radio"
                          checked={city === item ? true : false}
                        />
                      </p>
                    );
                  }
                  if (index === 4) {
                    return (
                      <button
                        className=" flex text-primary font-semibold cursor-pointer w-full"
                        style={get_dynamic_styles(styles?.filter_menu_style)}
                        onClick={() => setIsSeeAllCities(!isSeeAllCities)}
                      >
                        <span>
                          {Filter_text.filter.see_all}{' '}
                          {Filter_text.filter.see_all}
                        </span>
                        <span
                          className={
                            isSeeAllCities
                              ? 'transform rotate-180 mr-2'
                              : 'mr-2'
                          }
                        >
                          {angle_down}
                        </span>
                      </button>
                    );
                  }
                } else {
                  return (
                    <p
                      className=" flex  cursor-pointer"
                      style={get_dynamic_styles(styles?.filter_menu_style)}
                      key={Math.random()}
                      onClick={() => filter_by_city(item)}
                    >
                      <span className=" ">{item}</span>
                      <input
                        className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                        type="radio"
                        checked={city === item ? true : false}
                      />
                    </p>
                  );
                }
              })}

              {isSeeAllCities &&
                listingsCities?.map((item, index, array) => {
                  if (array.length > 4) {
                    if (index > 4) {
                      return (
                        <p
                          className=" flex  cursor-pointer"
                          style={get_dynamic_styles(styles?.filter_menu_style)}
                          key={Math.random()}
                          onClick={() => filter_by_city(item)}
                        >
                          <span className=" ">{item}</span>
                          <input
                            className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                            type="radio"
                            checked={city === item ? true : false}
                          />
                        </p>
                      );
                    }
                  }
                })}
            </div>
          )}
          {/* Sort */}
          {data?.hide_sort !== false && (
            <div className=" w-full">
              <h4
                className="flex"
                style={get_dynamic_styles(styles?.filter_title_style)}
              >
                <span className=" cursor-pointer">
                  {Filter_text.filter.sort}
                </span>
              </h4>
              {priceRange?.map((item) => {
                return (
                  <p
                    className=" flex cursor-pointer"
                    style={get_dynamic_styles(styles?.filter_menu_style)}
                    key={Math.random()}
                    onClick={() => filter_by_price_rang(item.value)}
                  >
                    <span className=" ">{Filter_text.filter[item.value]}</span>
                    <input
                      className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                      type="radio"
                      checked={sort === item.value ? true : false}
                    />
                  </p>
                );
              })}
            </div>
          )}
          {/* Atttributes  */}
          {data?.hidden_attributes !== false && (
            <div className=" w-full">
              {allAttributes?.map((item) => {
                return (
                  item?.show_in_filter && (
                    <ul className="" key={Math.random()}>
                      <li
                        className=" flex  cursor-pointer"
                        style={get_dynamic_styles(styles?.filter_title_style)}
                        onClick={(e) => toggleChildren(e, item.id, item.values)}
                      >
                        <span>{item.name}</span>
                        {item?.values?.length > 0 ? (
                          <span
                            className={
                              activeParent.includes(item.id)
                                ? 'transform rotate-180'
                                : ''
                            }
                          >
                            {angle_down}
                          </span>
                        ) : (
                          <input
                            className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                            type="checkbox"
                            checked={
                              selectedAtValues?.includes(`${item.id}`)
                                ? true
                                : false
                            }
                            onChange={() => filter_by_attribute_value(item.id)}
                          />
                        )}
                      </li>
                      {item?.values?.length > 0 &&
                        item?.values?.map((vl) => {
                          return (
                            <li
                              className={
                                activeParent.includes(item.id)
                                  ? ' flex  transition duration-500  ease-in-out'
                                  : 'hidden'
                              }
                              style={get_dynamic_styles(
                                styles?.filter_menu_style
                              )}
                              key={Math.random()}
                            >
                              <span className=" ">{vl.name}</span>
                              <input
                                className=" form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                                type="checkbox"
                                checked={
                                  selectedAtValues?.includes(`${vl.id}`)
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  filter_by_attribute_value(vl.id)
                                }
                              />
                            </li>
                          );
                        })}
                    </ul>
                  )
                );
              })}
            </div>
          )}
          {/* Ratings Range */}
          {data?.hide_rating !== false && (
            <div className=" w-full">
              <h4
                className="flex"
                style={get_dynamic_styles(styles?.filter_title_style)}
              >
                <span className=" cursor-pointer">
                  {Filter_text.filter.rating}
                </span>
              </h4>

              <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
                <button
                  className=" cursor-pointer flex items-center"
                  onClick={() => filter_by_rating(5)}
                >
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                </button>

                <input
                  className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                  type="radio"
                  checked={rating == 5 ? true : false}
                  onClick={() => filter_by_rating(5)}
                />
              </p>
              <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
                <button
                  className=" cursor-pointer flex items-center"
                  onClick={() => filter_by_rating(4)}
                >
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                </button>

                <input
                  className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                  type="radio"
                  checked={rating == 4 ? true : false}
                  onClick={() => filter_by_rating(4)}
                />
              </p>
              <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
                <button
                  className=" cursor-pointer flex items-center"
                  onClick={() => filter_by_rating(3)}
                >
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                </button>

                <input
                  className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                  type="radio"
                  checked={rating == 3 ? true : false}
                  onClick={() => filter_by_rating(3)}
                />
              </p>
              <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
                <button
                  className=" cursor-pointer flex items-center"
                  onClick={() => filter_by_rating(2)}
                >
                  <span>{star_icon}</span>
                  <span>{star_icon}</span>
                </button>

                <input
                  className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                  type="radio"
                  checked={rating == 2 ? true : false}
                  onClick={() => filter_by_rating(2)}
                />
              </p>
              <p className=" text-[12px] text-[#4F4F4F] font-semibold py-[7px]  flex justify-between items-center ">
                <button
                  className=" cursor-pointer flex items-center"
                  onClick={() => filter_by_rating(1)}
                >
                  <span>{star_icon}</span>
                </button>

                <input
                  className=" form-check-radio  h-3 w-3 border border-gray-300   bg-white checked:bg-primary checked:border-primary focus:outline-none focus:ring-primary checked:hover:bg-primary  checked:focus:ring-primary checked:focus:bg-primary  transition duration-200   align-top bg-no-repeat bg-center bg-contain   mr-2 cursor-pointer"
                  type="radio"
                  checked={rating == 1 ? true : false}
                  onClick={() => filter_by_rating(1)}
                />
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DynamicListingFilter;
