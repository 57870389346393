import Link from 'next/link';
import React from 'react';
import { Listing_text } from '../../../constant/Translation/Listing';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const ShippingMethods = ({ listing_details, router }) => {
  return listing_details ? (
    listing_details?.account && (
      <div className=" w-full min-h-[105px]  shadow-c-sm rounded-card  p-4 bg-white relative">
        <h2 className="heading-h2">{Listing_text.product.shipping_options}</h2>
        <div className=" mt-4 flex  items-center justify-start  gap-4 flex-wrap ">
          {listing_details?.account?.shipping_methods.map((item, index) => {
            return <p className="   text-primary  ">{item.name}</p>;
          })}
        </div>

        {TYPE_CONSTANT.SHIPPING_CONFIGS.fulfillment_by_platform_enabled && (
          <div
            className="  absolute top-3  ltr:right-2 rtl:left-2"
            onClick={() => like(item.id, item.liked)}
          >
            {listing_details?.fulfilled_by_platform &&
              TYPE_CONSTANT.SHIPPING_CONFIGS
                .fulfilled_by_platform_icon_path && (
                <div className="flex items-center justify-start gap-2">
                  <img
                    alt="image"
                    className="w-auto h-auto max-w-[80px]"
                    src={
                      TYPE_CONSTANT.SHIPPING_CONFIGS
                        .fulfilled_by_platform_icon_path
                    }
                  />
                  {TYPE_CONSTANT.SHIPPING_CONFIGS
                    ?.fulfilled_by_platform_note && (
                    <button className="tooltip text-[#6e686e] hover:text-info duration-200 transition">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_right text-primary">
                        {
                          TYPE_CONSTANT.SHIPPING_CONFIGS
                            ?.fulfilled_by_platform_note
                        }
                      </span>
                    </button>
                  )}
                </div>
              )}
            {!listing_details?.fulfilled_by_platform &&
              TYPE_CONSTANT.SHIPPING_CONFIGS.fulfilled_by_seller_icon_path && (
                <div>
                  <img
                    alt="image"
                    className="w-auto h-auto max-w-[80px]"
                    src={
                      TYPE_CONSTANT.SHIPPING_CONFIGS
                        .fulfilled_by_seller_icon_path
                    }
                  />
                  {TYPE_CONSTANT.SHIPPING_CONFIGS?.fulfilled_by_seller_note && (
                    <button className="tooltip text-[#6e686e] hover:text-info duration-200 transition">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6  "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>

                      <span className="top_right text-primary">
                        {
                          TYPE_CONSTANT.SHIPPING_CONFIGS
                            ?.fulfilled_by_seller_note
                        }
                      </span>
                    </button>
                  )}
                </div>
              )}
          </div>
        )}
      </div>
    )
  ) : (
    <div>
      <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full  mx-auto overflow-hidden">
        <div className="flex  justify-between">
          <div className="h-3 bg-[#3B3269] bg-opacity-[20%] rounded-full   w-28"></div>
        </div>
        <div className="animate-pulse mt-4  ">
          <div className="w-full  min-h-[10px] flex  items-center justify-center xs:justify-start  gap-5 flex-wrap">
            <div className="h-8 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-28"></div>
            <div className="h-8 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-28"></div>
            <div className="h-8 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-28"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingMethods;
