/* eslint-disable react/prop-types */
import React from 'react';
import productIcon from '../../../assets/Images/store/product.png';
import Image from 'next/image';
import { useRouter } from 'next/dist/client/router';
import { Listing_text } from '../../../constant/Translation/Listing';
import { Menubar_text } from '../../../constant/Translation/Heading';
import Add_listing_button from '../../Shared/Buttons/Add_listing_button';

const NoProducts = ({ my_stores }) => {
  const router = useRouter();
  return (
    <div className=" flex flex-col justify-center    items-center  mt-10 ">
      <h2 className="heading-h2">
        {Listing_text.productlist.no_products_found}
      </h2>

      <Add_listing_button
        title={Menubar_text.more.add_new_listing}
        btn_styles={
          ' px-6 py-2 bg-primary rounded-button text-white text-base mt-7'
        }
      />
      <div className=" w-[220px] h-[220px] relative mt-10">
        <Image
          src={productIcon}
          layout="fill"
          objectFit="contain"
          alt="store Icon"
        />
      </div>
    </div>
  );
};

export default NoProducts;
