import React from 'react';

import { Swiper, SwiperSlide as div } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);
import tradly from 'tradly';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import ListingCard from '../../Shared/Cards/ListingCard';
import { listingLike } from '../../../store/feature/listingSlice';
import Link from 'next/link';
import { check_login } from '../../../constant/check_auth';
import axios from 'axios';
import { Listing_text } from '../../../constant/Translation/Listing';
import ListingCard6 from '../../Shared/Cards/ListingCard6';
import ListingListSkeleton from '../../Shared/skeletonUI/Listing/ListingsListSkeleton';
import ListingsList from '../../Listings/ListingsList';

const AccountListingsList = ({ account_id, account }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [account_listings, setAccount_listings] = useState(null);
  useEffect(() => {
    if (account_id) {
      axios
        .get('/api/l', {
          params: { per_page: 8, account_id: account_id, status: 2 },
        })
        .then((res) => {
          setAccount_listings(res.data.listings);
        });
    }
  }, [account_id]);

  // Button Handle
  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id: id,
          isLiked: isLiked,
        })
      ).then((res) => {
        if (!res.payload.code) {
          axios
            .get('/api/l', {
              params: {
                page: 1,
                per_page: 30,
                account_id: account_id,
                status: 2,
              },
            })
            .then((res) => {
              setAccount_listings(res.data.listings);
            });
        }
      });
    }
  };

  return (
    <>
      {account_listings !== null ? (
        account_listings?.length > 0 && (
          <div className=" w-[100%] ms:w-full min-h-min p-4 bg-[#FFFFFF] rounded-card   shadow-c-sm">
            <div className=" flex justify-between items-center">
              <h2 className="heading-h2 ">
                {Listing_text.product.more_listing_from_account_title}
              </h2>
              <Link
                href={
                  account?.slug
                    ? `/a/${account?.slug}`
                    : `/a/${account?.id}-${account?.name.replace(
                        /[ /?,#=]+/g,
                        '-'
                      )}?id=true`
                }
              >
                <a className="  btn-primary-fill">
                  {Listing_text.product.see_all}
                </a>
              </Link>
            </div>
            <div className="mt-4  ">
              <ListingsList Products={account_listings} like_listing={like} />
            </div>
          </div>
        )
      ) : (
        <div className=" w-[100%] ms:w-full min-h-min p-4 bg-[#FFFFFF] rounded-card   shadow-c-sm">
          {' '}
          <ListingListSkeleton per_page={10} />
        </div>
      )}
    </>
  );
};

export default AccountListingsList;
