import React from 'react';
import LayersList from './LayersList';
import LayersCarousel from './LayersCarousel';

const LayersType = ({ layers, layers_extra_data }) => {
  let layers_type_card;

  // bse on "layers_type"
  switch (layers_extra_data?.layers_type) {
    case 'list':
      layers_type_card = (
        <LayersList layers={layers} layers_extra_data={layers_extra_data} />
      );
      break;
    case 'carousel':
      layers_type_card = (
        <LayersCarousel layers={layers} layers_extra_data={layers_extra_data} />
      );
      break;

    default:
      layers_type_card = (
        <LayersCarousel layers={layers} layers_extra_data={layers_extra_data} />
      );

      break;
  }

  return layers_type_card;
};

export default LayersType;
