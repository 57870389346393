/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import icon from '../../../../assets/Images/Home/pexels-photo-789812 1.png';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../../store/feature/authSlice';
import tradly from 'tradly';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../../Shared/PopUp/PopUp';
import {
  myAccountListingDetails,
  storeSelector,
} from '../../../../store/feature/storeSlice';
import EditVariantsForm from './EditVariantsForm';
import {
  addNewVariant,
  deleteVariant,
  editVariantButton,
} from './variantActions';
import { useRouter } from 'next/dist/client/router';
import { getThumbnailImage } from '../../../Shared/Constant/Constant';
import Modal from '../../../Shared/Modal/Modal';
import VariantSuccess from './VariantSuccess/VariantSuccess';
import AddVariantsForm from './AddVariantsForm';
import axios from 'axios';
import { stock_card_text } from '../../../Shared/Constant/TextConstant/addlistingConstant';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import { Add_product_text } from '../../../../constant/Translation/AddProduct_text';
import { Listing_text } from '../../../../constant/Translation/Listing';
import { dynamic_translation_set } from '../../../../constant/Translation/translation_set';
import AllIcons from '../../../../components2/Shared/Constant/Icons/AllIcons';
import AddVariantsMatrixForm from './AddVariantsMatrixForm';
import { close_icon } from '../../../Shared/Constant/Icons/AllIcons';
import EditVariantsMatrixForm from './EditVariantsMatrixForm';

const EditVariantsPart = ({ order_type }) => {
  const {
    listing_configs,
    isError,
    errorMessage,
    currencies,
    listing_categories,
    my_account_listing_details,
  } = useSelector(storeSelector);
  const { auth_key } = useSelector(authSelector);

  const dispatch = useDispatch();

  const router = useRouter();
  const accountId = router.query.account_id;
  const productId = router.query.product_id;

  const [showVariantForm, setShowVariantForm] = useState(false);
  const [isEditVariant, setIsEditVariant] = useState(false);
  const [editVariantData, setEditVariantData] = useState(null);
  const [editvariantLoading, setEditVariantLoading] = useState(false);
  const [addvariantLoading, setAddVariantLoading] = useState(false);

  const [variantsType, setVariantsType] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [variantsObject, setVariantsObject] = useState({
    variant_values: null,
    images: null,
    title: null,
    description: null,
    list_price: null,
    offer_percent: 0,
    stock: 0,
  });

  //
  const EditVariantClick = () => {
    const variantId = editVariantData.id;
    editVariantButton(
      variantsObject,
      setShowVariantForm,
      setIsEditVariant,
      setEditVariantData,
      setError_message,
      setShowError,
      auth_key,
      variantId,
      productId,
      setShowSuccessMessage,
      setEditVariantLoading,
      setVariantsObject,
      accountId,
      order_type,
      clearVariant
    );
  };

  //
  const addVariantClick = () => {
    const variantId = '';
    addNewVariant(
      variantsObject,
      setShowVariantForm,
      setIsEditVariant,
      setEditVariantData,
      setError_message,
      setShowError,
      auth_key,
      variantId,
      productId,
      setShowSuccessMessage,
      setAddVariantLoading,
      setVariantsObject,
      accountId,
      order_type,
      clearVariant
    );
  };

  useEffect(() => {
    axios.get('/api/variant').then((res) => {
      setVariantsType(res.data.variant_types);
    });
  }, [auth_key]);

  const closePopUP = () => {
    setShowError(false);
    setError_message('');
  };

  //
  const clearVariant = () => {
    setVariantsObject({
      variant_values: null,
      images: null,
      title: null,
      description: null,
      list_price: null,
      offer_percent: 0,
      stock: 0,
    });
  };

  //
  const openEditVariantForm = (variant) => {
    setIsEditVariant(true);
    setShowVariantForm(true);
    setEditVariantData(variant);
    setVariantsObject({
      variant_values: variant.variant_values?.map((vr_type) => ({
        variant_type_id: vr_type?.variant_type_id,
        variant_type_value_id: vr_type?.variant_type_value_id,
      })),
      images: variant.images[0] || undefined,
      title: variant.title,
      description: variant.description,
      list_price: Number(variant.list_price.amount),
      offer_percent: Number(variant.offer_percent),
      stock: Number(variant.stock),
    });
  };

  return (
    variantsType?.length > 0 && (
      <>
        {showError && (
          <OutsideClickHandler
            onOutsideClick={() => {
              showError && (setShowError(false), setError_message(''));
            }}
          >
            <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
              <div className="">
                <PopUp message={error_message} closePopUP={closePopUP} />
              </div>
            </div>
          </OutsideClickHandler>
        )}

        {showSuccessMessage && (
          <Modal>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowSuccessMessage(false);
                dispatch(
                  myAccountListingDetails({ slug: productId, id: true })
                );
              }}
            >
              <VariantSuccess
                message={Add_product_text.addvariant.updated}
                setShowSuccessMessage={setShowSuccessMessage}
                dispatch={dispatch}
                productId={productId}
                auth_key={auth_key}
              />
            </OutsideClickHandler>
          </Modal>
        )}

        {listing_configs?.show_variants && (
          <div className="bg-white p-5  rounded-card   shadow-c-sm">
            <h2 className=" text-start heading-h2 mb-4">
              {Add_product_text.addvariant.edit_variant}
            </h2>
            {my_account_listing_details?.variants?.length > 0 ? (
              <div>
                {my_account_listing_details?.variants.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className={
                        'w-full min-h-[90px] relative rounded-card   overflow-hidden  flex items-center my-4 shadow-c-sm  border border-transparent cursor-pointer gap-4'
                      }
                    >
                      <div className="h-28 w-28 relative">
                        {item.images?.length > 0 ? (
                          <img
                            alt="image"
                            className="w-full h-full  object-cover"
                            src={getThumbnailImage(item.images[0])}
                          />
                        ) : (
                          AllIcons.default_image
                        )}
                      </div>
                      <div className="  w-4/6">
                        <p className=" text-sm text-primary font-normal">
                          {dynamic_translation_set(
                            Listing_text.product.stock_text,
                            item.stock
                          )}
                        </p>
                        <p className="text-gray-700 font-semibold">
                          {item.title}
                        </p>
                        <p className=" flex items-center  ">
                          {item.offer_price.formatted}
                        </p>
                        <p className=" flex items-center  text-xs ">
                          <span className=" line-through text-default_gray font-normal">
                            {item.list_price.formatted}
                          </span>
                          <span className=" font-medium ml-2">
                            -{item.offer_percent}%
                          </span>
                        </p>
                      </div>
                      <div className="flex-none flex items-center justify-center gap-4 px-2">
                        <button
                          onClick={() => {
                            deleteVariant(
                              item.id,
                              productId,
                              auth_key,
                              dispatch
                            );
                          }}
                        >
                          {AllIcons.delete_icon}
                        </button>
                        <button
                          className=" "
                          onClick={() => {
                            openEditVariantForm(item);
                          }}
                        >
                          {AllIcons.edit_icon}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className=" w-full  flex flex-col  md:flex-row  justify-center items-center gap-3">
                <div
                  className="   w-full max-w-[350px] bg-yellow-500    text-white   py-3 rounded-card  relative grid grid-cols-[20%,80%]"
                  role="alert"
                >
                  <div className="flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                  </div>
                  <div>
                    <span className="  ltr:ml-2 rtl:mr-2">
                      {Add_product_text.addvariant.no_variants_available_alert}
                    </span>
                  </div>
                </div>
                <button
                  className="border  border-primary rounded-button text-primary text-center px-6 py-1 "
                  onClick={() => setShowVariantForm(!showVariantForm)}
                >
                  {Add_product_text.addvariant.add_variant}
                </button>
              </div>
            )}
            {!showVariantForm &&
              my_account_listing_details?.variants?.length > 0 && (
                <div className="flex items-center justify-center">
                  <button
                    className="border  border-primary rounded-button text-primary text-center px-6 py-1 mt-9"
                    onClick={() => setShowVariantForm(true)}
                  >
                    {Add_product_text.addvariant.add_variant}
                  </button>
                </div>
              )}
          </div>
        )}
        {showVariantForm && isEditVariant && editVariantData !== null && (
          <div className="  bg-white w-full min-h-[300px] mt-4 relative">
            <button
              className="absolute top  ltr:right-0 rtl:left-0 text-primary font-semibold text-xl mt-5 ltr:mr-5 rtl:ml-5"
              onClick={() => {
                setShowVariantForm(false),
                  setIsEditVariant(false),
                  setEditVariantData(null);
                clearVariant();
              }}
            >
              {close_icon}
            </button>
            <EditVariantsMatrixForm
              key={editVariantData?.id}
              editVariantData={editVariantData}
              variantsType={variantsType}
              variantsObject={variantsObject}
              setVariantsObject={setVariantsObject}
              EditVariantClick={EditVariantClick}
              setError_message={setError_message}
              setShowError={setShowError}
              editvariantLoading={editvariantLoading}
              productId={productId}
              order_type={order_type}
            />
          </div>
        )}
        {showVariantForm && !isEditVariant && (
          <div className="  bg-white w-full min-h-[300px] mt-4 relative">
            <button
              className="absolute top  ltr:right-0 rtl:left-0 text-primary font-semibold text-xl mt-5 ltr:mr-5 rtl:ml-5"
              onClick={() => {
                setShowVariantForm(false);
                setAddVariantLoading(false);
                setIsEditVariant(false);
                clearVariant();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <AddVariantsMatrixForm
              variantsType={variantsType}
              variantsObject={variantsObject}
              setVariantsObject={setVariantsObject}
              addVariantClick={addVariantClick}
              setShowError={setShowError}
              setError_message={setError_message}
              addvariantLoading={addvariantLoading}
              order_type={order_type}
            />
          </div>
        )}
      </>
    )
  );
};

export default EditVariantsPart;
