import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import PopUp from '../Shared/PopUp/PopUp';
import { Layer_text } from '../../constant/Translation/LayerText';
import { edit_layer_click } from './edit_layer_press';
import axios from 'axios';
import { layerSelector } from '../../store/feature/layerSlice';
import ReadingComposer from '../Shared/Composer/ReadingComposer';
import Composer2 from '../Shared/Composer/Composer2';

const EditLayerForm = () => {
  const dispatch = useDispatch();
  const { layer_details } = useSelector(layerSelector);
  const router = useRouter();
  const account_id = router.query.account_id;
  const article_slug = router.query.article_slug;

  //
  const [title, setTitle] = useState(layer_details?.title);
  const [meta_title, setMetaTitle] = useState('');
  const [meta_keyword, setMetaKeyword] = useState('');
  const [slug, setSlug] = useState(layer_details?.slug);
  const [description, setDescription] = useState(``);
  const [meta_description, setMetaDescription] = useState(' ');
  const [article_details, setArticle_details] = useState(
    layer_details?.content
  );
  const [article_id, setArticle_id] = useState();

  //
  const [editArticleLoading, seEditArticleLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  // Use Effect functions
  useEffect(() => {
    if (article_slug) {
      axios
        .get('/api/c/article_details', {
          params: { slug: article_slug },
        })
        .then((res) => {
          setArticle_details(res.data.layer);
          setTitle(res.data.layer?.title);
          setDescription(res.data.layer?.content);
          setSlug(res.data.layer?.slug);
          setArticle_id(res.data.layer?.id);
        })
        .catch((error) => {
          setShowError(true);
          setErrorMessage(error.response?.data?.message);
        });
    } else {
      router.push('/c');
    }
  }, [router]);
  // pop up close
  const closePopUP = () => {
    setShowError(false);
    setErrorMessage('');
  };

  return (
    <>
      {showError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showError && (setShowError(false), setErrorMessage(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp message={errorMessage} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      <div className="flex  flex-col md:flex-row items-start  justify-between">
        <div className=" w-full  c-lg:w-[700px]     mx-auto   order-last md:order-first">
          <div className=" bg-white    grid grid-cols-1  gap-2 rounded-card  shadow-xl px-[20px]">
            <div
              contentEditable
              draggable={false}
              className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0  transition  duration-700
                    focus:ring-0 
                    text-5xl 
                     focus-visible:ring-0                 
                     focus-visible:outline-none
                      out                
                     cursor-text    
                     min-h-[48px] 
                      break-words
                               
                  "
              onBlur={(e) => {
                e.currentTarget.textContent.length > 0
                  ? setTitle(e.currentTarget.textContent)
                  : setTitle('Title');
              }}
              onFocus={(e) => {
                e.currentTarget.textContent === 'Title'
                  ? setTitle('')
                  : setTitle(title);
              }}
            >
              {title}
            </div>

            {description && (
              <Composer2
                contents={description ? description : ' '}
                setContents={setDescription}
                mainClass={' min-h-[70vh]'}
              />
            )}
          </div>
        </div>

        <div className="  flex-shrink-0 flex justify-end px-10 py-2  ml-auto ">
          <button
            className="text-white   w-[180px] h-12 rounded-button bg-primary  flex items-center justify-center "
            onClick={() => {
              edit_layer_click(
                title,
                slug,
                meta_title,
                meta_description,
                meta_keyword,
                description,
                account_id,
                article_id,
                setErrorMessage,
                setShowError,
                seEditArticleLoading,
                router,
                dispatch
              );
            }}
            disabled={editArticleLoading ? true : false}
          >
            {editArticleLoading && (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            {Layer_text?.layer.edit_layer}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditLayerForm;
