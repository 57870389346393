/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import tradly from 'tradly';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import axios from 'axios';
import { useRouter } from 'next/router';
// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const Banner = ({ banners }) => {
  const [appPromoBanners, setAppsPromoBanners] = useState(null);
  useEffect(() => {
    axios.get(`/api/banners`, { params: { medium: 'app' } }).then((res) => {
      setAppsPromoBanners(res.data.promo_banners);
    });
  }, []);

  const router = useRouter();
  const banner_click = (banner) => {
    if (banner?.type !== 'general') {
      if (banner.type == 'account' && banner.value) {
        router.push(`/a/${banner.value}?id=true`);
      } else if (banner.type == 'listing' && banner.value) {
        router.push(`/l/${banner.value}?id=true`);
      } else if (banner.type == 'external_link' && banner.value) {
        window.open(banner.value);
      }
    }
  };

  return (
    <>
      <div className=" hidden md:block w-full   h-auto mb-9 mx-auto">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
        >
          {banners?.map((banner, i) => {
            return (
              <SwiperSlide
                key={i}
                className=" w-full flex flex-col justify-center items-center mb-14"
              >
                <div
                  className=" w-full h-full aspect-w-13 aspect-h-3 relative rounded-image overflow-hidden"
                  onClick={() => banner_click(banner)}
                >
                  <img
                    src={banner.image_path}
                    alt="Banner Image"
                    className="h-full w-full object-cover object-left-top"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className=" block md:hidden w-full   h-auto mb-9 mx-auto">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
        >
          {appPromoBanners?.map((banner, i) => {
            return (
              <SwiperSlide
                key={i}
                className=" w-full flex flex-col justify-center items-center mb-14"
              >
                <div className=" w-full h-full aspect-w-16 aspect-h-9 relative rounded-image overflow-hidden">
                  <img
                    src={banner.image_path}
                    alt="Banner Image"
                    className="h-full w-full object-cover object-left-top"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default Banner;
