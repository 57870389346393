/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import icon from '../../../../assets/Images/Home/pexels-photo-789812 1.png';
import Image from 'next/image';
import AddVariantsForm from './AddVariantsForm';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../../store/feature/authSlice';
import tradly from 'tradly';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../../Shared/PopUp/PopUp';
import { storeSelector } from '../../../../store/feature/storeSlice';
import { configsSelector } from '../../../../store/feature/configsSlice';
import { stock_card_text } from '../../../Shared/Constant/TextConstant/addlistingConstant';
import axios from 'axios';
import { dynamic_translation_set } from '../../../../constant/Translation/translation_set';
import { Add_product_text } from '../../../../constant/Translation/AddProduct_text';
import AllIcons from '../../../Shared/Constant/Icons/AllIcons';
import AddMatrixVariantsForm from './AddMatrixVariantsForm';

const VariantsPart = ({
  variantsArray,
  setVariantsArray,
  currency,
  order_type,
  showVariantForm,
  setShowVariantForm,
}) => {
  const [variantsType, setVariantsType] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [button_title, setButton_title] = useState(
    Add_product_text.addvariant.add_variant
  );

  // effects
  useEffect(() => {
    axios.get('/api/variant').then((res) => {
      setVariantsType(res.data.variant_types);
    });

    return () => {
      setVariantsType(null);
    };
  }, []);

  const { currencies } = useSelector(storeSelector);

  const { genral_configs, MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);

  const [variantsObject, setVariantsObject] = useState({
    variant_values: null,
    images: null,
    title: null,
    description: null,
    list_price: null,
    offer_percent: 0,
    stock: 0,
    digitalProducts: null,
  });

  //
  const addVariantClick = () => {
    setVariantsArray((prev) => [...prev, variantsObject]);
    setShowVariantForm(false);
    clearFormState();
  };

  // onEditVariant
  const onEditVariant = (item) => {
    setVariantsObject({
      variant_values: item?.variant_values,
      images: item?.images,
      title: item?.title,
      description: item?.description,
      list_price: item?.list_price,
      offer_percent: item?.offer_percent,
      stock: item?.stock,
      digitalProducts: item?.digitalProducts,
    });
    setShowVariantForm(true);
    setButton_title(Add_product_text.addvariant.edit_variant);
  };

  //
  const closePopUP = () => {
    setShowError(false);
    setError_message('');
  };

  // clear form state
  const clearFormState = () => {
    setVariantsObject({
      variant_values: null,
      images: null,
      title: null,
      description: null,
      list_price: null,
      offer_percent: 0,
      stock: 0,
      digitalProducts: null,
    });
  };

  return (
    <>
      {showError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showError && (setShowError(false), setError_message(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp message={error_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      <div className="bg-white p-4 md:p-10   rounded-card   shadow-c-sm  grid grid-cols-1 gap-6 my-6">
        <h3 className=" text-start font-semibold   text-primary text-xl  ">
          {Add_product_text.addproduct.add_variant}
        </h3>
        {!variantsArray === null || variantsArray?.length > 0 ? (
          <div className="flex flex-col gap-6">
            {variantsArray.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className={
                    'w-full min-h-min relative rounded-button overflow-hidden  flex items-center  shadow-c-sm  border border-transparent cursor-pointer gap-2'
                  }
                >
                  <div className=" h-28 w-28 relative">
                    {item.images ? (
                      <Image
                        src={URL.createObjectURL(item.images)}
                        layout="fill"
                      />
                    ) : (
                      AllIcons.default_listing_icon
                    )}
                  </div>
                  <div className=" ml-6 w-3/6 md:w-4/6">
                    <p className=" text-sm text-primary font-normal">
                      {dynamic_translation_set(
                        Add_product_text.addvariant.variant_limit_text,
                        item.stock
                      )}
                    </p>
                    <p className="text-black font-semibold">{item.title}</p>
                    <p className=" flex items-center  ">
                      <span className=" text-default_gray font-normal  text-xs">
                        {currency
                          ? currencies.map((item) => {
                              if (item.id === currency) {
                                return item.code;
                              }
                            })
                          : currencies[0].code}
                      </span>
                      <span className=" ltr:ml-2 rtl:mr-2">
                        {item.list_price}{' '}
                      </span>
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      const filtered_items = variantsArray.filter(
                        (item, i) => i !== index
                      );
                      setVariantsArray([...filtered_items]);
                    }}
                  >
                    {AllIcons.delete_icon}
                  </button>
                  <button className=" " onClick={() => onEditVariant(item)}>
                    {AllIcons.pencil_icon}
                  </button>
                </div>
              );
            })}
          </div>
        ) : (
          <div className=" w-full  flex flex-col  md:flex-row justify-center items-center gap-3">
            <div
              className="   w-full max-w-[350px] bg-yellow-500    text-white   py-3 rounded-card  relative grid grid-cols-[20%,80%]"
              role="alert"
            >
              <div className="flex items-center justify-center">
                {AllIcons.notification}
              </div>
              <div>
                <span className="  ltr:ml-2 rtl:mr-2">
                  {Add_product_text.addvariant.no_variants_available_alert}
                </span>
              </div>
            </div>
            <button
              className="border  border-primary rounded-button text-primary text-center px-6 py-1 "
              onClick={() => setShowVariantForm(!showVariantForm)}
            >
              {Add_product_text.addvariant.add_variant}
            </button>
          </div>
        )}
        {!showVariantForm &&
          variantsArray !== null &&
          variantsArray?.length > 0 && (
            <div className="flex justify-center">
              <button
                className="border  border-primary rounded-button text-primary text-center px-6 py-1 mt-9"
                onClick={() => setShowVariantForm(true)}
              >
                {Add_product_text.addvariant.add_variant}
              </button>
            </div>
          )}
      </div>
      {showVariantForm && (
        <div className="  bg-white w-full min-h-[300px] mt-4 relative">
          <button
            className="absolute top  ltr:right-0 rtl:left-0 text-primary font-semibold text-xl mt-5 ltr:mr-5 rtl:ml-5"
            onClick={() => {
              setShowVariantForm(false);
              clearFormState();
            }}
          >
            {AllIcons.close_icon}
          </button>
          <AddMatrixVariantsForm
            variantsType={variantsType}
            variantsObject={variantsObject}
            setVariantsObject={setVariantsObject}
            addVariantClick={addVariantClick}
            setError_message={setError_message}
            setShowError={setShowError}
            MARKETPLACE_MODULES={MARKETPLACE_MODULES}
            btn_title={button_title}
            order_type={order_type}
          />
        </div>
      )}
    </>
  );
};

export default VariantsPart;
