import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { check_login } from '../../../constant/check_auth';
import { authSelector } from '../../../store/feature/authSlice';
import {
  getAllListings,
  listingLike,
} from '../../../store/feature/listingSlice';
import DescriptionPart from '../../ListingDetails/DescriptionPart/DescriptionPart';
import CustomImagePart from '../../ListingDetails/ImagePart/CustomImagePart';
import ImagePart from '../../ListingDetails/ImagePart/ImagePart';
import MainBox from '../../ListingDetails/MainBox/MainBox';
import MainBox3 from '../../ListingDetails/MainBox/MainBox3';
import ProductButtons from '../../ListingDetails/ProductButtons/ProductButtons';
import SimpleProductButtons from '../../ListingDetails/ProductButtons/SimpleProductButton';
import Variants from '../../ListingDetails/Variants/Variants';
import ButtonTypeVariant from '../../ListingDetails/Variants/ButtonTypeVariant';
import Variants2 from '../../ListingDetails/Variants/ButtonTypeVariant';

const SimpleListingsCard = ({
  all_listings_details,
  setShowError,
  setError_message,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [selectedVariant, setSelectedVariant] = useState(null);

  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id: id,
          isLiked: isLiked,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            getAllListings({
              prams: {
                page: 1,
                per_page: 10,
                status: 2,
                include: 'attributes',
              },
            })
          );
        }
      });
    }
  };

  return (
    <div className="flex flex-col gap-16">
      {all_listings_details?.map(({ listing }) => {
        return (
          <div
            key={listing.id}
            className="w-full grid grid-cols-1  md:grid-cols-2  gap-6"
          >
            <div>
              {listing?.variants.filter(
                (vr) => vr.id === selectedVariant?.id
              )[0]?.id === selectedVariant?.id ? (
                selectedVariant?.images.length > 0 ? (
                  <CustomImagePart images={selectedVariant?.images} />
                ) : (
                  <CustomImagePart images={listing?.images} />
                )
              ) : (
                <CustomImagePart images={listing?.images} />
              )}
            </div>
            <div className=" shadow-md p-4 rounded-card  ">
              <div>
                <MainBox3
                  listing_details={listing}
                  rating_data={listing?.rating_data}
                  like={like}
                  selectedVariant={selectedVariant}
                />
              </div>
              {listing?.variants?.length > 0 && (
                <div className="mt-6">
                  <ButtonTypeVariant
                    variants={listing.variants}
                    setSelectedVariant={setSelectedVariant}
                    selectedVariant={selectedVariant?.id}
                    listing_details={listing}
                  />
                </div>
              )}

              <div className="  w-full p-4 sm:p-0  bg-white  relative md:bg-transparent z-[60] sm:z-30 sm:mt-6   ">
                <SimpleProductButtons
                  listing_details={listing}
                  selectedVariant={selectedVariant?.id}
                  setError_message={setError_message}
                  setShowError={setShowError}
                />
              </div>
              {/* {listing?.description !== '' && (
                <div className="mt-6  ">
                  <DescriptionPart description={listing?.description} />
                </div>
              )} */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SimpleListingsCard;
