import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';

const Search_filter = () => {
  const [searchKey, setSearchKey] = useState(null);
  const router = useRouter();

  const handleKeypress = (isIconClick, e) => {
    if (isIconClick) {
      router.push({
        query: { ...router.query, search_key: searchKey },
      });
    } else {
      if (!searchKey?.replace(/\s/g, '').length <= 0) {
        if (e.key === 'Enter') {
          router.push({
            query: { ...router.query, search_key: searchKey },
          });
        }
      }
    }
  };

  useEffect(() => {
    if (router?.query?.search_key) {
      setSearchKey(router?.query?.search_key);
    }
  }, [0]);

  return (
    <div className="w-full relative">
      <input
        value={searchKey}
        onChange={(e) => setSearchKey(e.target.value)}
        onKeyPress={(e) => handleKeypress(false, e)}
        type="text"
        className="w-full border focus:border-gray-800 focus:ring-2 focus:ring-offset-2 focus:ring-primary   "
      ></input>
      <button
        className="absolute right-3 top-0 bottom-0 p-1  "
        onClick={() => handleKeypress(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
      {searchKey?.length > 0 && (
        <button
          className="absolute right-10 top-0 bottom-0 p-1  "
          onClick={() => {
            const queries = { ...router.query };
            delete queries.search_key;
            router.push({
              query: { ...queries },
            });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-primary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default Search_filter;
