/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import OutsideClickHandler from 'react-outside-click-handler';
import Modal from '../../../Shared/Modal/Modal';
import AddressForm from './AddressForm/AddressForm';
import tradly from 'tradly';
import { get_order_details } from '../../../../store/feature/store_orderSlice';
import { useRouter } from 'next/dist/client/router';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../../store/feature/authSlice';
import { useDispatch } from 'react-redux';
import CustomLoading from '../../../Shared/Loading/CustomLoading';
import axios from 'axios';
import { Orders_text } from '../../../../constant/Translation/OrdersText';
import { Address_text } from '../../../../constant/Translation/Address';

const AddressBox2 = ({ order_details }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [showShippingAddressForm, setShowShippingAddressForm] = useState(false);
  const { register, handleSubmit, setValue, control } = useForm();
  // account_shipment
  let account_shipment = order_details?.legacy
    ? undefined
    : order_details?.shipments?.filter(
        (shipment) => shipment?.account?.id == router.query.account_id
      )?.length > 0
    ? order_details?.shipments?.filter(
        (shipment) => shipment?.account?.id == router.query.account_id
      )[0]
    : undefined;

  // Onsubmit new address
  const onSubmit = (data) => {
    setIsLoading(true);
    const id = account_shipment?.pickup_address?.id ?? '';

    axios
      .post('/api/address/save_address', {
        id,
        addressData: {
          address: {
            ...Object.fromEntries(
              Object.entries(data).filter(([key, value]) => value !== '')
            ),
            type: 'pickup',
            current: true,
          },
        },
      })
      .then((res) => {
        axios
          .post('/api/shipments/update_shipment', {
            order_id: order_details.id,
            shipment_id: account_shipment.id,
            data: {
              operation: 'update_pickup_address',
              shipment: { pickup_address_id: res.data.address.id },
            },
          })
          .then((res) => {
            dispatch(
              get_order_details({
                id: order_details.id,
                bodyParam: { account_id: router.query.account_id },
              })
            );
            setShowShippingAddressForm(false);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  //Decide address
  const type = order_details?.shipping_method.type;
  let address;
  if (type === 'pickup') {
    address = order_details?.shipments[0]?.pickup_address ?? {};
  } else {
    address = account_shipment?.shipping_address;
  }

  return (
    <>
      {isLoading && <CustomLoading />}
      {showShippingAddressForm && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowShippingAddressForm(false);
            }}
          >
            <AddressForm
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              register={register}
              setShowShippingAddressForm={setShowShippingAddressForm}
              pickup_address={account_shipment?.pickup_address}
              setValue={setValue}
              control={control}
            />
          </OutsideClickHandler>
        </Modal>
      )}
      <div className="w-full h-min-[50px] bg-white  shadow-c-sm rounded-card   p-4   sm:p-7    py-5  border-opacity-40">
        <div className="flex  ">
          <p className=" text-lg text-black font-semibold   ">
            {order_details?.shipping_method.name}{' '}
            {Address_text.addresslist.address_title}
          </p>
        </div>
        <div className="mt-4">
          {account_shipment ? (
            Object?.keys(address)?.length > 0 ? (
              <>
                {address?.name && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold     w-24">
                      {Address_text.address.name} :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                      {address?.name}
                    </p>
                  </div>
                )}
                {address?.address_line_1 && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24">
                      {Address_text.address.address_one} :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                      {address?.address_line_1}
                    </p>
                  </div>
                )}
                {address?.address_line_2 && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24 ">
                      {Address_text.address.address_two} :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                      {address?.address_line_2}
                    </p>
                  </div>
                )}
                {address?.country && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24">
                      {Address_text.address.country} :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                      {address.country}
                    </p>
                  </div>
                )}
                {address?.post_code && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24">
                      {Address_text.address.zipcode} :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                      {address.post_code}
                    </p>
                  </div>
                )}
                {address?.formatted_address && (
                  <div className=" flex justify-start items-start py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24">
                      {Address_text.address.formatted_address} :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                      {address.formatted_address}
                    </p>
                  </div>
                )}
                {address?.phone_number && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24">
                      {Address_text.address.phone} :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                      {address.phone_number}
                    </p>
                  </div>
                )}
              </>
            ) : (
              Address_text.addresslist.no_address_found
            )
          ) : (
            Address_text.addresslist.address_not_applicable
          )}
        </div>
        {order_details?.shipping_method.type === 'pickup' &&
          account_shipment?.status !== 7 && (
            <div className="mt-4">
              <button
                className=" btn-primary-outline"
                onClick={() => {
                  setShowShippingAddressForm(true);
                }}
              >
                {Object.keys(address).length === 0
                  ? Address_text.address.add_pickup_address
                  : Address_text.address.edit_pickup_address}
              </button>
            </div>
          )}
      </div>
    </>
  );
};

export default AddressBox2;
