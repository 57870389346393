import React, { useEffect, useState } from 'react';
import { Cropper } from 'react-cropper';
import { General_text } from '../../../constant/Translation/General';
import PopupUI from '../PopUp/UI/PopupUI';
import { loading_icon } from '../Constant/Icons/AllIcons';

const CropImage = ({
  imageUpload,
  image_data,
  setImageData,
  imageFormOpen,
  setImageFormOpen,
}) => {
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });
  const [cropImageSrc, setCropImageSrc] = useState('');
  const [cropper, setCropper] = useState();
  const [croppingStart, setCroppingStart] = useState(false);

  useEffect(() => {
    if (image_data) {
      const imageReader = new FileReader();
      imageReader.readAsDataURL(image_data);
      imageReader.onloadend = () => {
        setCropImageSrc(imageReader.result);
      };
    }
  }, [image_data]);

  const getCropData = async (e) => {
    if (typeof cropper !== 'undefined') {
      setCroppingStart(true);
      const canvas = cropper.getCroppedCanvas();

      canvas.toBlob(
        (blob) => {
          blob['preview'] = URL.createObjectURL(blob);
          fetch(
            cropper.getCroppedCanvas().toDataURL(image_data.type, '0.9')
          ).then(async (res) => {
            imageUpload({
              id: blob.preview,
              path: blob.preview,
              size: blob.size,
              name: image_data.name,
              type: image_data.type,
              base64: await res.blob(),
            });
            setImageData(null);
            setCropImageSrc('');
            setCrop();
            e.stopPropagation();
            setImageFormOpen(false);
            setCroppingStart(false);
          });
        },
        image_data.type,
        '0.9'
      );
    }
  };

  const skip_cropping = (e) => {
    imageUpload({
      id: image_data.name + image_data.type,
      size: image_data.size,
      path: URL.createObjectURL(image_data),
      name: image_data.name,
      type: image_data.type,
      base64: image_data,
    });
    setImageData(null);
    setCropImageSrc('');
    setCrop();
    e.stopPropagation();
    setImageFormOpen(false);
  };

  return (
    <div className="min-h-[300px] ">
      <section className="min-h-[100px] ">
        <PopupUI
          left_button={{
            title: General_text.general.cancel,
            click: (e) => {
              e.stopPropagation();
              setImageFormOpen(false);
              setImageData(null);
              setCropImageSrc('');
            },
          }}
          right_button={{
            title: General_text.general.crop,
            click: (e) => {
              getCropData(e);
            },
            icon: croppingStart ? loading_icon : '',
          }}
        >
          <div className="  relative ">
            <Cropper
              style={{ height: 400 }}
              zoomTo={0}
              initialAspectRatio={1}
              aspectRatio={1}
              // preview=".img-preview"
              src={cropImageSrc}
              viewMode={0}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={true}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
            <button
              onClick={(e) => skip_cropping(e)}
              className="btn-primary-outline absolute bottom-2  right-2  bg-white border-white hover:bg-primary "
            >
              {General_text.general.skip_cropping}
            </button>
          </div>
        </PopupUI>
      </section>
    </div>
  );
};

export default CropImage;
