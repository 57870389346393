import React from 'react';
import { Menubar_text } from '../../../constant/Translation/Heading';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const HeroBanner = () => {
  return (
    <section className="pt-8 pb-10 md:pt-10 md:pb-16">
      <div className=" max-w-3xl text-center md:text-left  ">
        <h2 className="heading-h2 text-black font-bold pr-4">
          {TYPE_CONSTANT.GENERAL_CONFIGS?.header_text}
        </h2>
        <p className=" paragraph  md:font-medium  mt-4   text-black   ">
          {TYPE_CONSTANT.GENERAL_CONFIGS?.header_description}
        </p>

        <button className="btn-primary-fill px-4 py-2 mt-10">
          {Menubar_text.more.add_new_listing}
        </button>
      </div>
    </section>
  );
};

export default HeroBanner;
