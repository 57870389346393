import React from 'react';
import { Tab } from '@headlessui/react';
import AccountListings from './AccountListings';
import AboutSection from './AboutSection';
import { Account_text } from '../../../constant/Translation/Account';
import { about_icon, cart_icon } from '../../Shared/Constant/Icons/AllIcons';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AccountTab = ({
  account_details,
  setAccount_details,
  setIsDataLoading,
}) => {
  return (
    <Tab.Group as="div" className="mt-5 min-h-[50vh]">
      <Tab.List className=" relative w-full grid grid-cols-2 border-b-2  border-[#D2D2D2]">
        <Tab
          as="h2"
          className={({ selected }) =>
            classNames(
              '  w-full rounded-lg py-2.5 text-base font-medium leading-5  ',
              'ring-0 focus:outline-none flex flex-col md:flex-row items-center justify-center gap-1  ',
              selected
                ? 'relative  text-primary after:content-[""] after:h-1 after:w-full  after:block after:absolute after:-bottom-0.5 after:bg-primary after:rounded-card '
                : 'text-[#4F4F4F]   '
            )
          }
        >
          <span> {cart_icon}</span>
          <span> {Account_text.storedetail.product}</span>
        </Tab>
        <Tab
          as="h2"
          className={({ selected }) =>
            classNames(
              '  w-full rounded-lg py-2.5 text-base font-medium leading-5  ',
              'ring-0 focus:outline-none  flex flex-col md:flex-row items-center justify-center gap-1 ',
              selected
                ? 'relative  text-primary after:content-[""] after:h-1 after:w-full  after:block after:absolute after:-bottom-0.5 after:bg-primary after:rounded-card '
                : 'text-[#4F4F4F]   '
            )
          }
        >
          <span> {about_icon}</span>
          <span> {Account_text.storedetail.about}</span>
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <AccountListings
            account_details={account_details}
            setAccount_details={setAccount_details}
            setIsDataLoading={setIsDataLoading}
          />
        </Tab.Panel>
        <Tab.Panel>
          <AboutSection
            account_details={account_details}
            setAccount_details={setAccount_details}
            setIsDataLoading={setIsDataLoading}
          />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default AccountTab;
