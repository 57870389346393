import React, { useEffect } from 'react';

import KYCSteps from '../../components/KYC/KYCSteps';
import KYCMessage from '../../components/KYC/KYCMessage';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { KYCSelector, getKYCDetails } from '../../store/feature/KYC/kycSlice';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const KYCPageLayout = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const currency = router.query.code
    ? router.query.code
    : getCookie(`${TYPE_CONSTANT.DOMAIN}_default_currency`) ?? '';

  //
  useEffect(() => {
    dispatch(getKYCDetails({ currency: currency }));
  }, [router]);

  //
  const { kyc_details } = useSelector(KYCSelector);

  return (
    <div>
      {kyc_details?.mangopay_kyc_submission &&
        kyc_details?.mangopay_kyc_status_message && (
          <KYCMessage
            message={kyc_details?.mangopay_kyc_status_message}
            mangopay_kyc_status={kyc_details?.mangopay_kyc_status}
          />
        )}
      {/* <KYCForm /> */}
      {(!kyc_details?.mangopay_kyc_submission ||
        kyc_details?.mangopay_kyc_check_failed) && <KYCSteps />}
    </div>
  );
};

export default KYCPageLayout;
