import React from 'react';
import {
  gallery_icon,
  list_icon,
  map_icon,
} from '../Shared/Constant/Icons/AllIcons';
import { useAppDispatch, useAppSelector } from '../../hooks/Redux';
import {
  dynamicPageSelector,
  updateListingViewType,
} from '../../store/feature/Pages/DynamicPage/dynamicPageSlice';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const ListingsView = ({ styles }) => {
  const dispatch = useAppDispatch();
  const { listing_view_type } = useAppSelector(dynamicPageSelector);

  const type = [
    {
      value: 'map_view',
      icon: map_icon,
    },
    {
      value: 'gallery_view',
      icon: gallery_icon,
    },
  ];

  return (
    <div className="flex   " style={get_dynamic_styles(styles?.main_styles)}>
      {type.map((item) => {
        return (
          <button
            key={item.value}
            style={get_dynamic_styles(styles?.button_styles)}
            onClick={() => dispatch(updateListingViewType(item.value))}
            className={
              item.value !== listing_view_type &&
              '!text-black !bg-transparent !shadow'
            }
          >
            {item.icon}
          </button>
        );
      })}
    </div>
  );
};

export default ListingsView;
