/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import {
  addToCart,
  cartList,
  cartSelector,
  clearCartState,
} from '../../../store/feature/cartSlice';
import { authSelector } from '../../../store/feature/authSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../Shared/PopUp/PopUp';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import { listingDetails } from '../../../store/feature/listingSlice';
import { Listing_text } from '../../../constant/Translation/Listing';
import ModalBasic from '../../Shared/Modal/ModalBesic';
import { General_text } from '../../../constant/Translation/General';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import PopupUI from '../../Shared/PopUp/UI/PopupUI';
import axios from 'axios';

const ProductButtons2 = ({ listing_details, selectedVariant }) => {
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  const { login, auth_key } = useSelector(authSelector);
  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(cartSelector);
  const dispatch = useDispatch();
  const router = useRouter();
  const [isBuyNow, setIsBuyNow] = useState(false);

  const add_to_Cart = (_isBuyNow) => {
    if (
      TYPE_CONSTANT.LISTINGS_CONFIGS?.enable_stock &&
      listing_details?.stock == 0
    ) {
      setShowError(true);
      setError_message(Listing_text.product.out_of_stock);
      return false;
    }

    if (_isBuyNow) {
      const cartData = {
        cart: {
          listing_id: listing_details?.id,
          quantity: 1,
        },
      };
      if (selectedVariant !== null) {
        cartData.cart['variant_id'] = selectedVariant;
      }
      dispatch(addToCart({ authKey: auth_key, data: cartData })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'buynow',
            data: {
              name: listing_details?.title,
              slug: listing_details?.slug,
              price: listing_details?.offer_price.amount,
              stock: listing_details?.stock,
              category: {
                id: listing_details?.categories[0]?.id,
                name: listing_details?.categories[0]?.name,
              },
              image: listing_details?.images,
              url: window.location.href,
            },
          });
          setIsBuyNow(false);
          router.push('/checkout');
        } else {
          if (res.payload.code === 480) {
            setForm_open(true);
          } else {
            setShowError(true);
            setError_message(res.payload.message);
          }
        }
      });
    } else {
      const cartData = {
        cart: {
          listing_id: listing_details?.id,
          quantity: 1,
        },
      };
      if (selectedVariant !== null) {
        cartData.cart['variant_id'] = selectedVariant;
      }
      dispatch(addToCart({ authKey: auth_key, data: cartData })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'add_cart',
            data: {
              name: listing_details?.title,
              slug: listing_details?.slug,
              price: listing_details?.offer_price.amount,
              stock: listing_details?.stock,
              category: {
                id: listing_details?.categories[0]?.id,
                name: listing_details?.categories[0]?.name,
              },
              image: listing_details?.images,
              url: window.location.href,
            },
          });
          setIsBuyNow(false);
          dispatch(cartList({}));
          dispatch(
            listingDetails({
              ...router?.query,
            })
          );
        } else {
          if (res.payload.code === 480) {
            setForm_open(true);
          } else {
            setShowError(true);
            setError_message(res.payload.message);
          }
        }
      });
    }
  };
  const closePopUP = () => {
    setShowError(false);
    setError_message('');
    dispatch(clearCartState());
  };

  //
  const [form_open, setForm_open] = useState(false);
  const clear_cart = () => {
    axios
      .delete('/api/cart/delete_all_cart')
      .then((res) => {
        setForm_open(false);
        add_to_Cart(isBuyNow);
      })
      .catch((error) => {
        setForm_open(false);
      });
  };
  return (
    <>
      {(isError || showError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (isError || showError) && setShowError(false),
              setError_message(''),
              dispatch(clearCartState());
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={errorMessage || error_message}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {isFetching && <CustomLoading />}

      <div className="grid grid-cols-1 gap-3">
        <button
          className="   w-full py-3  flex justify-center items-center border border-primary rounded-full box-border  btn-primary-outline "
          id="add_to_cart"
          onClick={() => {
            setIsBuyNow(false);
            login
              ? listing_details?.in_cart
                ? router.push('/checkout')
                : listing_details?.variants.length > 0
                ? selectedVariant === null
                  ? (setShowError(true),
                    setError_message(Listing_text.product.variant_validation))
                  : add_to_Cart()
                : add_to_Cart()
              : router.push('/sign-in');
          }}
          type="button"
        >
          <span className=" text-base   font-semibold ml-3">
            {listing_details?.in_cart
              ? Listing_text.product.go_to_cart
              : Listing_text.product.add_to_cart}
          </span>
        </button>
        <button
          className="  w-full  py-3   rounded-full  flex justify-center items-center btn-primary-fill  "
          id="buy_now"
          // onClick={add_to_Cart}
          onClick={() => {
            setIsBuyNow(true);
            login
              ? listing_details?.variants.length > 0
                ? selectedVariant === null
                  ? (setShowError(true),
                    setError_message(Listing_text.product.variant_validation))
                  : add_to_Cart()
                : add_to_Cart()
              : router.push('/sign-in');
          }}
          type="button"
        >
          <span className=" text-base  font-semibold  ">
            {Listing_text.product.buy_now}
          </span>
        </button>
      </div>

      <ModalBasic
        title="Multi Seller"
        id="multi-seller-serror"
        modalOpen={form_open}
        setModalOpen={setForm_open}
        closeByOutsideClick={true}
      >
        <section className="min-h-[100px] ">
          <PopupUI
            left_button={{
              title: General_text.general.cancel,
              click: (e) => {
                e.stopPropagation();
                setForm_open(false);
              },
            }}
            right_button={{
              title: Listing_text.product.clear_cart,
              click: (e) => {
                clear_cart();
              },
            }}
          >
            <div className="flex flex-col  px-10   py-8  borderer-b border-gray-400">
              <p className="text-base text-center mb-3 ">
                {Listing_text.product.clear_cart_info}
              </p>
            </div>
          </PopupUI>
        </section>
      </ModalBasic>
    </>
  );
};

export default ProductButtons2;
