import React from 'react';
import ListingsCarouselSkeleton from './ListingsCarouselSkeleton';
import ListingListSkeleton from './ListingsListSkeleton';

const ListingDynamicSkeleton = ({
  listings_type,
  listing_card_type,
  per_page,
}) => {
  let decide_skeleton_ui;

  switch (listings_type) {
    case 'carousel':
      decide_skeleton_ui = (
        <ListingsCarouselSkeleton listing_card_type={listing_card_type} />
      );
      break;
    case 'list':
      decide_skeleton_ui = (
        <ListingListSkeleton
          per_page={per_page}
          listing_card_type={listing_card_type}
        />
      );
      break;

    default:
      decide_skeleton_ui = (
        <ListingListSkeleton
          per_page={per_page}
          listing_card_type={listing_card_type}
        />
      );
      break;
  }

  return <>{decide_skeleton_ui}</>;
};

export default ListingDynamicSkeleton;
