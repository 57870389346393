/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import {
  changeDateFormat,
  getThumbnailImage,
} from '../../../Shared/Constant/Constant';
import Link from 'next/link';
import { Orders_text } from '../../../../constant/Translation/OrdersText';
import moment from 'moment';
import { clock_icon, time_icon } from '../../../Shared/Constant/Icons/AllIcons';

const EventsSummary = ({ order_details }) => {
  const OrderDetails = order_details?.order_details;
  return (
    <div className=" w-full h-min-[300px] bg-white  shadow-c-sm rounded-card   p-4   sm:p-7   border-opacity-40  ">
      <div>
        {OrderDetails?.map((item, index) => {
          let listing_details = item?.listing;
          let listing_variant_details = item?.variant
            ? Object?.keys(item?.variant)?.length > 0
              ? item?.variant
              : undefined
            : undefined;

          let item_details = listing_variant_details
            ? listing_variant_details
            : listing_details;

          return (
            <Link
              key={Math.random()}
              href={
                item?.listing?.slug
                  ? `/l/${item?.listing?.slug}`
                  : `/l/${item?.listing?.id}-${item?.listing?.title.replace(
                      /[ /?,#=]+/g,
                      '-'
                    )}?id=true`
              }
            >
              <a
                className={[
                  'bg-white p-4 md:p-8  shadow-c-sm rounded-card border-opacity-40   flex  items-start  gap-4 md:gap-8',
                ].join(' ')}
              >
                {item_details?.images?.length > 0 && (
                  <div className="w-[70px] md:w-[120px] overflow-hidden">
                    <img
                      src={item_details?.images[0]}
                      className="h-full w-full]  object-cover object-left-top rounded-image "
                      alt="image"
                    />
                  </div>
                )}

                <div className="flex flex-col gap-2">
                  <h2 className="heading-h2">
                    {listing_details.title}{' '}
                    {listing_variant_details &&
                      listing_variant_details?.title &&
                      `(${listing_variant_details.title})`}
                  </h2>
                  <p>{order_details?.account?.name}</p>
                  <p className="flex items-center justify-start gap-2 flex-wrap ">
                    <span className="description-paragraph">
                      {/* {moment(item?.schedule_start_at).format('dddd, MMM DD')} */}
                      {changeDateFormat(
                        item?.schedule_start_at_epoch,
                        'dddd, MMM DD'
                      )}
                    </span>
                    <hr className="bg-gray-200  h-8 w-1 rounded" />
                    <span className="flex items-center justify-start gap-2">
                      <span>{clock_icon}</span>
                      <span className="description-paragraph">
                        {/* {moment(item?.schedule_start_at).format('h:mm a')} */}
                        {changeDateFormat(
                          item?.schedule_start_at_epoch,
                          'h:mm a'
                        )}
                      </span>
                      <span>-</span>
                      <span>
                        {changeDateFormat(
                          item?.schedule_end_at_epoch,
                          'h:mm a'
                        )}
                      </span>
                    </span>
                  </p>
                </div>
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default EventsSummary;
