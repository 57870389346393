import React, { useEffect } from 'react';
import { Add_product_text } from '../../../constant/Translation/AddProduct_text';
import { useDispatch, useSelector } from 'react-redux';
import {
  getListingTaxes,
  taxSelector,
} from '../../../store/feature/tax/taxSlice';
import { MultiSelect } from 'react-multi-select-component';
import { useAvailableBlockKeys } from '../../../hooks/useAvailableBlockKeys';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import SelectDropDown from '../../Shared/SelectDropDown';

const EditProductTaxes = ({ tax_ids, setTaxes }) => {
  const dispatch = useDispatch();
  const { tax_list } = useSelector(taxSelector);
  const add_listing_current_blocks = useAvailableBlockKeys(
    TYPE_CONSTANT.PAGES?.add_listing
  );

  useEffect(() => {
    dispatch(
      getListingTaxes({
        type: 'listing_tax',
      })
    );
  }, []);

  return add_listing_current_blocks?.includes('listing_taxes') &&
    tax_list?.length > 0 ? (
    <label className="block w-full">
      <span className="text-gray-700  text-lg">
        {Add_product_text.addproduct.listing_tax}
      </span>
      <SelectDropDown
        drop_down_values={tax_list?.map((value, index) => {
          return {
            value: value?.id,
            label: value.title,
          };
        })}
        setSelectedItem={(item) => {
          if (item) {
            setTaxes([item.value]);
          } else {
            setTaxes([]);
          }
        }}
        selectedItem={
          tax_ids?.length > 0
            ? tax_ids?.map((vl) => {
                return {
                  value: tax_list?.find((tl) => tl.id == vl)?.id,
                  label: tax_list?.find((tl) => tl.id == vl)?.title,
                };
              })[0]
            : null
        }
      />
    </label>
  ) : (
    <></>
  );
};

export default EditProductTaxes;
