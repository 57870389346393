/* eslint-disable react/prop-types */
import React from 'react';
import { Payment_text } from '../../constant/Translation/Payment';

const BillingDetailsForm = (props) => {
  return (
    <div className=" w-full mt-7 mb-7   rounded-card    ">
      <h2 className="text-xl text-primary text-center font-bold">
        {Payment_text.payment.pay_with_card_title}
      </h2>
      <div className="mt-8  w-full">
        <div className="grid grid-cols-1 gap-6">
          <label className="block">
            <span className="text-gray-700 after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px] ">
              {Payment_text.payment.name}
            </span>
            <input
              value={props.name}
              onChange={(e) => props.setName(e.target.value)}
              type="text"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
              placeholder=""
            />
          </label>
          <label className="block">
            <span className="text-gray-700 after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px]">
              {Payment_text.payment.email}
            </span>
            <input
              value={props.email}
              onChange={(e) => props.setEmail(e.target.value)}
              type="text"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
              placeholder="john@example.com"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">
              {Payment_text.payment.address}
            </span>
            <input
              value={props.address}
              onChange={(e) => props.setAddress(e.target.value)}
              type="text"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
              placeholder="Address"
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default BillingDetailsForm;
