import React, { useEffect, useMemo, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { listingSelector } from '../../store/feature/listingSlice';
import CustomLoading from '../Shared/Loading/CustomLoading';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const MapByListingsAttribute = ({ styles, attributes, data }) => {
  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  // listings from store:
  const { listings, total_records, page, isFetching } =
    useSelector(listingSelector);

  const selected_attributes = listings?.map((listing) =>
    listing.attributes?.find(
      (attributes) => attributes.id == data?.attribute_id
    )
  );

  const destinations = selected_attributes
    ?.map((attr) =>
      attr?.values?.map((item) => {
        if (item?.latitude && item?.longitude) {
          return {
            lat: item?.latitude,
            lng: item?.longitude,
          };
        } else if (item?.metadata?.latitude && item?.metadata?.longitude) {
          return {
            lat: item?.metadata?.latitude,
            lng: item?.metadata?.longitude,
          };
        }
      })
    )
    ?.flat();

  //
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:
      TYPE_CONSTANT.ADDRESS_CONFIGS?.google_map_api_key ??
      'AIzaSyBr3Q8BS3eMq4dA0u0wrkUtW_VWgYLbtB0',
  });

  const defaultCenter = { lat: 0, lng: 0 };

  return isLoaded ? (
    destinations?.length > 0 ? (
      <GoogleMap
        mapContainerStyle={get_dynamic_styles(styles?.main_styles)}
        center={destinations?.[0] ?? defaultCenter}
        zoom={7}
      >
        {destinations?.map((item, index) => (
          <Marker position={item} icon={data?.icon ?? undefined} />
        ))}
      </GoogleMap>
    ) : (
      <GoogleMap
        mapContainerStyle={get_dynamic_styles(styles?.main_styles)}
        center={defaultCenter}
        zoom={7}
      ></GoogleMap>
    )
  ) : (
    <CustomLoading />
  );
};

export default MapByListingsAttribute;
