import { useRouter } from 'next/router';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { slug as SlugChecker } from 'github-slugger';
import { formatBytes } from '../../../../constant/functions';
import { Add_product_text } from '../../../../constant/Translation/AddProduct_text';
import { General_text } from '../../../../constant/Translation/General';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import { digitalContentSelector } from '../../../../store/feature/digital content/digitalContentSlice';
import { myAccountListingDetails } from '../../../../store/feature/storeSlice';
import {
  delete_icon,
  file_icon,
} from '../../../Shared/Constant/Icons/AllIcons';
import Modal from '../../../Shared/Modal/Modal';
import PopUp from '../../../Shared/PopUp/PopUp';
import EditSuccess from './EditSuccess';
import { edit_digital_products } from './edit_digital_product';

const DigitalProduct = ({}) => {
  const router = useRouter();
  const productId = router.query.product_id;
  const accountID = router.query.account_id;
  const dispatch = useDispatch();

  const { main_listing_digital_content } = useSelector(digitalContentSelector);
  const [digitalProducts, setDigitalProducts] = useState({});

  useEffect(() => {
    setDigitalProducts(main_listing_digital_content);
  }, [main_listing_digital_content]);

  //
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  //destructure
  const { account_id, title, description, version_name, content, active } =
    digitalProducts;

  // File Upload part
  const handleFileClick = () => {
    document.getElementById('select-digital-product-file').click();
  };

  //

  const selectFile = (e) => {
    setDigitalProducts((prev) => ({
      ...prev,
      content:
        content?.length > 0
          ? [
              ...content,
              {
                upload_file: true,
                full_file: e.target.files[0],
                size: formatBytes(e.target.files[0].size),
                mime_type: e.target.files[0].type,
                file_name: e.target.files[0].name.replace(/\s/g, '-'),
              },
            ]
          : [
              {
                upload_file: true,
                full_file: e.target.files[0],
                size: formatBytes(e.target.files[0].size),
                mime_type: e.target.files[0].type,
                file_name: e.target.files[0].name.replace(/\s/g, '-'),
              },
            ],
    }));
  };

  // deleteContent
  const deleteContent = (item) => {
    setDigitalProducts((prev) => ({
      ...prev,
      content: content.filter(
        (it) => it.file_name != item.file_name && it.size != item.size
      ),
    }));
  };

  // closePopUP
  const closePopUP = () => {
    setShowError(false);
    setError_message('');
  };

  return (
    <div className="w-full bg-white shadow-c-sm p-5  min-h-min flex flex-col gap-4  rounded-card">
      {showError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showError && (setShowError(false), setError_message(''));
          }}
        >
          <div className=" fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp message={error_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {showSuccessMessage && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowSuccessMessage(false);
              dispatch(myAccountListingDetails({ slug: productId, id: true }));
            }}
          >
            <EditSuccess
              message={Add_product_text.adddigitalproduct.updated}
              setShowSuccessMessage={setShowSuccessMessage}
              dispatch={dispatch}
              productId={productId}
            />
          </OutsideClickHandler>
        </Modal>
      )}
      <h3 className=" font-semibold text-primary text-xl  text-start">
        {Add_product_text.adddigitalproduct.digital_product_title}
      </h3>

      {/* Upload digital product file  */}
      <div className="flex  flewx-wrap items-center gap-2 justify-start">
        <button
          className="btn-primary-outline"
          onClick={() => handleFileClick()}
        >
          {Add_product_text.adddigitalproduct.add_digital_product_btn_title}
        </button>

        <input
          type="file"
          value={''}
          id={'select-digital-product-file'}
          className="h-0  opacity-0  w-0"
          multiple={false}
          onChange={selectFile}
        />
      </div>
      {/* Contents list */}
      {content?.length > 0 && (
        <div className="flex flex-col gap-4 ">
          {content?.map((item) => {
            return (
              <div className="w-full flex items-center justify-start gap-3 rounded-card shadow-sm border border-primary p-2">
                <span className=" flex -none text-primary">{file_icon}</span>
                <p className=" flex-grow text-base text-gray-600 font-medium">
                  {item.file_name}
                </p>
                <button
                  className="text-primary hover:text-error  flex-none "
                  onClick={() => deleteContent(item)}
                >
                  {delete_icon}
                </button>
              </div>
            );
          })}
        </div>
      )}

      {/* Input fields */}
      <div className="flex flex-col gap-4">
        {TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content?.title !== false && (
          <label className="block">
            <span className="text-gray-700  text-lg">
              {Add_product_text.adddigitalproduct.title}
            </span>
            <input
              type="text"
              value={title}
              className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              onChange={(e) =>
                setDigitalProducts((prev) => ({
                  ...prev,
                  title: e.target.value,
                }))
              }
              placeholder=""
            />
          </label>
        )}
        {TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content?.version !== false && (
          <label className="block">
            <span className="text-gray-700  text-lg">
              {Add_product_text.adddigitalproduct.Version}
            </span>
            <input
              type="text"
              value={version_name}
              className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              onChange={(e) =>
                setDigitalProducts((prev) => ({
                  ...prev,
                  version_name: e.target.value,
                }))
              }
              placeholder=""
            />
          </label>
        )}
        {TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content?.description !==
          false && (
          <label className="block">
            <span className="text-gray-700  text-lg">
              {Add_product_text.adddigitalproduct.description}
            </span>

            <textarea
              value={description}
              className="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              rows="3"
              onChange={(e) =>
                setDigitalProducts((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            ></textarea>
          </label>
        )}
      </div>
      {/* Upoad digital product file  */}
      <div className="flex  flewx-wrap items-center gap-2 justify-center">
        <button
          className="btn-primary-fill gap-2"
          onClick={() =>
            edit_digital_products({
              digitalProductId: digitalProducts.id,
              listingId: productId,
              setShowError,
              setError_message,
              digitalProducts,
              setShowSuccessMessage,
              setEditLoading: setIsEditLoading,
              isPost: digitalProducts.id ? false : true,
            })
          }
          // disabled={isEditLoading}
        >
          {isEditLoading && (
            <svg
              className="animate-spin -ml-1  h-5 w-5  "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {Add_product_text.adddigitalproduct.update_digital_product_btn_title}
        </button>
      </div>
    </div>
  );
};

export default DigitalProduct;
