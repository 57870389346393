import axios from 'axios';

export const delete_pricing_rule = async ({
  rule_id,
  listing_id,
  onSuccess,
  setIsLoading,
}) => {
  setIsLoading(true);
  await axios
    .post('/api/pricing_rules/delete', {
      listing_id,
      rule_id,
    })
    .then((result) => {
      onSuccess();
      setIsLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
};

export const post_pricing_rule = async ({
  rule_id,
  listing_id,
  onSuccess,
  setIsLoading,
  data,
}) => {
  setIsLoading(true);
  await axios
    .post('/api/pricing_rules/post', {
      listing_id,
      rule_id,
      data: {
        listing_price_rule: Object.entries(data).reduce((acc, [key, value]) => {
          if (value !== null) {
            acc[key] = value;
          }

          return acc;
        }, {}),
      },
    })
    .then((result) => {
      onSuccess();
      setIsLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
};
