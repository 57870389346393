/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { useRouter } from 'next/router';
import React from 'react';
import LayerCard from '../Shared/Cards/LayerCard';
import LayerCard2 from '../Shared/Cards/LayerCard2';

const Layers2 = ({ articles, path }) => {
  const router = useRouter();

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        {articles?.map((item, index) => (
          <LayerCard2 item={item} path={path} key={index} />
        ))}
      </div>
    </>
  );
};

export default Layers2;
