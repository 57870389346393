import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import {
  cartList,
  cartSelector,
  checkout,
  clearCartState,
  EphemeralKey,
  getAddress,
  getStorageHubAddress,
  paymentIntent,
  paymentMethods,
  saveAddress,
  save_address,
  shippingMethods,
} from '../../store/feature/cartSlice';
import CartItemBox from '../../components/Cart/CartItemBox/CartItemBox';
import OrderSummary from '../../components/Cart/OrderSummary/OrderSummary';
import PaymentMethod from '../../components/Cart/PaymentMethods/PaymentMethod';
import ShippingMethod from '../../components/Cart/ShippingMethods/ShippingMethod';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import Modal from '../../components/Shared/Modal/Modal';
import OrderSuccess from '../../components/Cart/OrderSuccess/OrderSuccess';
import NoCartItem from '../../components/Cart/NoCartItem/NoCartItem';
import AddressForm from '../../components/Cart/AddressForm/AddressForm';
import ShippingAddresses from '../../components/Cart/ShippingAddresses/ShippingAddresses';
import StorageHubAddresses from '../../components/Cart/StorageHubAddress/StorageHubAddresses';
import CustomLoading from '../../components/Shared/Loading/CustomLoading';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Checkout_text } from '../../constant/Translation/Cart_text';
import { Address_text } from '../../constant/Translation/Address';
import { Shipping_text } from '../../constant/Translation/Shipping_text';
import { Payment_text } from '../../constant/Translation/Payment';
import { push_data_layer } from '../../constant/GTMDatalayer';
import ShipmentDetails from '../../components/Cart/ShipmentDetails/ShipmentDetails';
import { base_url } from '../../constant/url';
import {
  commissionsSelector,
  getCommissions,
} from '../../store/feature/Commissions/CommissionsSlice';
import { dynamic_translation_set } from '../../constant/Translation/translation_set';
import CartCommission from '../../components/Cart/CartCommission/CartCommission';
import ScheduleSelect from '../../components/Cart/SchedulePart/ScheduleSelect';
import { getDatesArray } from '../../components/Shared/Constant/Constant';
import axios from 'axios';
import { Listing_text } from '../../constant/Translation/Listing';

const CheckoutPageLayout = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  // States
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(null);

  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  const [showShippingAddressForm, setShowShippingAddressForm] = useState(false);
  const [selectShippingAddress, setSelectShippingAddress] = useState(null);
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [selectStorageHubAddress, setSelectStorageHubAddress] = useState(null);

  const { register, handleSubmit, setValue, control } = useForm();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [cart_commission, setCartCommission] = useState([]);

  // Schedule state
  let dates = getDatesArray();
  const [selectedDate, setSelectedDate] = useState(
    moment(dates[0]).format('YYYY-MM-DD')
  );

  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [scheduleArray, setScheduleArray] = useState(null);
  const [selectedScheduleTimeIndex, setSelectedScheduleTimeIndex] =
    useState(null);

  //
  const { auth_key } = useSelector(authSelector);
  const { order_reference, addresses, storage_hub_addresses } =
    useSelector(cartSelector);
  const { commissions } = useSelector(commissionsSelector);

  // cart details Effect
  useEffect(() => {
    const cart_list_params = {};
    if (shippingMethod !== null && shippingMethod) {
      cart_list_params.bodyParam = {
        shipping_method_id: shippingMethod?.id,
      };
    }

    dispatch(cartList(cart_list_params));

    if (
      TYPE_CONSTANT.ACCOUNTS_CONFIGS?.enable_shipping_methods_preference !==
        true &&
      TYPE_CONSTANT.MARKETPLACE_MODULES !== 2 &&
      TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables !== true
    ) {
      dispatch(shippingMethods({}));
    }

    dispatch(paymentMethods({}));
    dispatch(EphemeralKey({}));
    dispatch(
      getAddress({
        bodyParam: { type: 'shipping' },
      })
    );
    dispatch(
      getStorageHubAddress({
        bodyParam: { type: 'storage_hub' },
      })
    );

    dispatch(getCommissions({ bodyParam: { type: 'cart' } }));
  }, []);

  // States from selector
  const {
    cart,
    cart_details,
    shipments,
    payment_methods,
    shipping_methods,
    isError,
    errorMessage,
    isFetching,
    isCheckoutFetching,
    isSuccess,
  } = useSelector(cartSelector);

  // Use Effect
  useEffect(() => {
    if (TYPE_CONSTANT.MARKETPLACE_MODULES === 2) {
      if (
        cart_details &&
        cart_details[0]?.listing?.id &&
        selectedDate &&
        scheduleArray === null
      ) {
        axios
          .post('/api/schedules', {
            id: `${cart_details && cart_details[0]?.listing?.id}`,
            bodyParam: { days: 30, start_at: selectedDate },
          })
          .then((res) => {
            setScheduleArray(res.data.schedules_per_day);
          });
      }
    }
  }, [cart_details, selectedDate]);

  // Calling shipping method with preference
  useEffect(() => {
    if (
      TYPE_CONSTANT.ACCOUNTS_CONFIGS?.enable_shipping_methods_preference &&
      cart_details?.length > 0 &&
      TYPE_CONSTANT.MARKETPLACE_FLAVOURS !== 2 &&
      TYPE_CONSTANT.MARKETPLACE_MODULES !== 2 &&
      TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables !== true
    ) {
      dispatch(
        shippingMethods({
          type: 'account',
          account_id: cart_details && cart_details[0]?.listing?.account?.id,
        })
      );
    }
  }, [cart_details]);

  //

  useEffect(() => {
    if (payment_methods && paymentMethod == null) {
      setPaymentMethod(payment_methods[0]);
    }
    if (shipping_methods && shippingMethod == null) {
      setShippingMethod(shipping_methods[0]);
      dispatch(
        cartList({
          bodyParam: {
            shipping_method_id: shipping_methods[0]?.id,
          },
        })
      );
    }
    if (
      addresses &&
      selectShippingAddress == null &&
      shippingMethod?.type === 'delivery'
    ) {
      setSelectShippingAddress(addresses[0]);
    }
    if (
      storage_hub_addresses &&
      selectStorageHubAddress == null &&
      shippingMethod?.type === 'storage_hub'
    ) {
      setSelectStorageHubAddress(storage_hub_addresses[0]);
    }
  }, [
    payment_methods,
    shipping_methods,
    addresses,
    storage_hub_addresses,
    shippingMethod,
  ]);

  // Checkout func:
  const clickCheckOut = () => {
    if (
      TYPE_CONSTANT.MARKETPLACE_MODULES === 2 &&
      selectedScheduleTimeIndex === null
    ) {
      setShowError(true);
      setError_message(Listing_text.productschedule.select_date_time);
      return false;
    }

    if (
      shippingMethod === null &&
      TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables !== true &&
      TYPE_CONSTANT.MARKETPLACE_MODULES !== 2
    ) {
      setShowError(true);
      setError_message(Shipping_text.shipping.selec_shipping_method);
      return false;
    }
    if (shippingMethod?.type === 'delivery') {
      if (
        selectShippingAddress === null ||
        selectShippingAddress === undefined
      ) {
        setShowError(true);
        setError_message(Shipping_text.shipping.select_shipping_address);
        return false;
      }
    }
    if (shippingMethod?.type === 'storage_hub') {
      if (
        selectStorageHubAddress === null ||
        selectStorageHubAddress === undefined
      ) {
        setShowError(true);
        setError_message(Shipping_text.shipping.select_shipping_address);
        return false;
      }
    }
    if (paymentMethod === null) {
      setShowError(true);
      setError_message(Payment_text.payment.select_payment_option);
      return false;
    }

    if (
      commissions?.length > 0 &&
      commissions.filter(
        (commission) =>
          commission?.commission_data?.optional === false &&
          !cart_commission.some((cart_com) => cart_com.id === commission.id)
      )?.length > 0
    ) {
      let required_commission = commissions.filter(
        (commission) => commission?.commission_data?.optional === false
      )[0];
      setShowError(true);
      setError_message(
        dynamic_translation_set(
          Payment_text.payment.cart_commission_alert,
          required_commission?.title
        )
      );
      return false;
    }

    let checkout_data;
    if (shippingMethod?.type === 'delivery') {
      checkout_data = {
        order: {
          payment_method_id: paymentMethod.id,
          shipping_method_id: shippingMethod?.id,
          shipping_address_id: selectShippingAddress?.id,
        },
      };
    } else if (shippingMethod?.type === 'storage_hub') {
      checkout_data = {
        order: {
          payment_method_id: paymentMethod.id,
          shipping_method_id: shippingMethod?.id,
          shipping_address_id: selectStorageHubAddress.id,
        },
      };
    } else {
      checkout_data = {
        order: {
          payment_method_id: paymentMethod.id,
          shipping_method_id: shippingMethod?.id,
        },
      };
    }

    // event type
    if (TYPE_CONSTANT.MARKETPLACE_MODULES === 2) {
      checkout_data.order.type = 'events';
      checkout_data.order.schedule_start_at = `${scheduleArray[selectedDateIndex].day} ${scheduleArray[selectedDateIndex].schedules[selectedScheduleTimeIndex].start_time}:00`;
      checkout_data.order.schedule_end_at = `${scheduleArray[selectedDateIndex].day} ${scheduleArray[selectedDateIndex].schedules[selectedScheduleTimeIndex].end_time}:00`;
    }
    // digital type
    if (TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables) {
      checkout_data.order.type = 'digital';
    }

    //
    if (cart_commission?.length > 0) {
      checkout_data.order.cart_commission = cart_commission;
    }

    if (paymentMethod.channel === 'web') {
      dispatch(
        checkout({
          checkoutData: checkout_data,
        })
      ).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'checkout',
            data: {
              products: cart_details.map((item) => {
                return {
                  name: item?.listing.title,
                  slug: item?.listing.slug,
                  price: item?.listing.offer_price.amount,
                  stock: item?.listing.stock,
                  image: item?.listing.images,
                  quantity: item.quantity,
                  cart_id: item.id,
                };
              }),
              payment_method: paymentMethod.name,
              shipping_method: shippingMethod?.name,
              grand_total: cart.grand_total.formatted,
            },
          });
          window.open(
            `${base_url()}/v1/payments/web/paymentIntent?order_reference=${
              res.payload.order_reference
            }&auth_key=${auth_key}&payment_method_id=${
              paymentMethod.id
            }&agent=web`
          );
        }
      });
    } else {
      if (paymentMethod.type === 'stripe') {
        dispatch(
          checkout({
            checkoutData: checkout_data,
          })
        ).then((res) => {
          if (!res.payload.code) {
            dispatch(
              paymentIntent({
                sendData: {
                  order_reference: res.payload.order_reference,
                },
              })
            ).then((res) => {
              if (!res.payload.code) {
                push_data_layer({
                  event: 'checkout',
                  data: {
                    products: cart_details.map((item) => {
                      return {
                        name: item?.listing.title,
                        slug: item?.listing.slug,
                        price: item?.listing.offer_price.amount,
                        stock: item?.listing.stock,
                        image: item?.listing.images,
                        quantity: item.quantity,
                        cart_id: item.id,
                      };
                    }),
                    payment_method: paymentMethod.name,
                    shipping_method: shippingMethod?.name,
                    grand_total: cart.grand_total.formatted,
                  },
                });
                router.push('/payment');
              }
            });
          }
        });
      } else {
        dispatch(
          checkout({
            checkoutData: checkout_data,
          })
        ).then((res) => {
          if (!res.payload.code) {
            push_data_layer({
              event: 'checkout',
              data: {
                products: cart_details.map((item) => {
                  return {
                    name: item?.listing.title,
                    slug: item?.listing.slug,
                    price: item?.listing.offer_price.amount,
                    stock: item?.listing.stock,
                    image: item?.listing.images,
                    quantity: item.quantity,
                    cart_id: item.id,
                  };
                }),
                payment_method: paymentMethod.name,
                shipping_method: shippingMethod?.name,
                grand_total: cart.grand_total.formatted,
              },
            });
            setShowSuccessMessage(true);
          }
        });
      }
    }
  };

  // save address
  const onSubmit = (data) => {
    const id = !isNewAddress ? selectShippingAddress.id : '';
    dispatch(
      save_address({
        id,
        addressData: {
          address: {
            ...Object.fromEntries(
              Object.entries(data).filter(([key, value]) => value !== '')
            ),
            type: 'shipping',
          },
        },
      })
    ).then((res) => {
      if (!res.payload.code) {
        dispatch(
          getAddress({
            bodyParam: { type: 'shipping' },
          })
        );
        setShowShippingAddressForm(false);
      }
    });
  };

  const closePopUP = () => {
    dispatch(clearCartState());
    setShowError(false);
    setError_message('');
  };

  //fetch cart api calling base on commission effect
  useEffect(() => {
    if (cart_commission?.length > 0) {
      fetch_cart_list();
    }
  }, [cart_commission]);

  //fetch_cart_list
  const fetch_cart_list = ({}) => {
    const cart_list_params = {};
    if (shippingMethod !== null && shippingMethod) {
      cart_list_params.bodyParam = {
        shipping_method_id: shippingMethod?.id,
      };
    }
    if (cart_commission !== null && cart_commission?.length > 0) {
      cart_list_params.bodyParam.cart_commission =
        JSON.stringify(cart_commission);
    }

    dispatch(cartList(cart_list_params));
  };

  return (
    <>
      {isFetching && <CustomLoading />}
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearCartState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {showSuccessMessage && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowSuccessMessage(false);
              router.push('/');
            }}
          >
            <OrderSuccess />
          </OutsideClickHandler>
        </Modal>
      )}
      {showShippingAddressForm && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowShippingAddressForm(false);
            }}
          >
            <AddressForm
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              register={register}
              setShowShippingAddressForm={setShowShippingAddressForm}
              isNewAddress={isNewAddress}
              selectShippingAddress={selectShippingAddress}
              setValue={setValue}
              control={control}
            />
          </OutsideClickHandler>
        </Modal>
      )}

      {cart_details === null || cart_details?.length > 0 ? (
        <div className="  mx-auto w-full    sm:px-8 md:px-0 flex  flex-col justify-center lg:flex-row lg:justify-between    max-w-6xl lg:gap-9  ">
          <div className="  flex-grow">
            {/* May cart */}
            <div className="bg-[#FEFEFE] rounded-card   py-6    px-4 md:px-9">
              <p className="text-primary text-xl leading-6 font-medium mb-5">
                {Checkout_text.cart.header_title}
              </p>
              <CartItemBox
                cart={cart}
                cart_details={cart_details}
                fetch_cart_list={fetch_cart_list}
              />
            </div>

            {/* Schedules */}
            {cart_details && TYPE_CONSTANT.MARKETPLACE_MODULES === 2 && (
              <div className="mt-6">
                <ScheduleSelect
                  dates={dates}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  selectedDateIndex={selectedDateIndex}
                  setSelectedDateIndex={setSelectedDateIndex}
                  scheduleArray={scheduleArray}
                  selectedScheduleTimeIndex={selectedScheduleTimeIndex}
                  setSelectedScheduleTimeIndex={setSelectedScheduleTimeIndex}
                />
              </div>
            )}

            {/* Shipping methods */}
            {TYPE_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables !== true &&
              TYPE_CONSTANT.MARKETPLACE_MODULES !== 2 && (
                <div className="mt-6">
                  <ShippingMethod
                    shipping_methods={shipping_methods}
                    shippingMethod={shippingMethod}
                    setShippingMethod={setShippingMethod}
                  />
                </div>
              )}
            {/* delivery Address */}
            {shippingMethod?.type === 'delivery' && (
              <div className="mt-6  w-full min-h-[100px] bg-[#FEFEFE] rounded-card   py-6    px-4 md:px-9">
                <p className="text-primary text-xl leading-6 font-medium ">
                  {shippingMethod?.name} {Checkout_text.cart.address}
                </p>
                <div className="mt-6">
                  <ShippingAddresses
                    addresses={addresses}
                    selectShippingAddress={selectShippingAddress}
                    setSelectShippingAddress={setSelectShippingAddress}
                  />
                </div>
                <div className="mt-5 flex justify-start flex-wrap gap-5">
                  <button
                    className=" btn-primary-outline px-4 py-2 "
                    onClick={() => {
                      setShowShippingAddressForm(true), setIsNewAddress(true);
                    }}
                  >
                    {Checkout_text.cart.add_new_address}
                  </button>
                  {selectShippingAddress && (
                    <button
                      className="btn-primary-outline px-4 py-2 "
                      onClick={() => {
                        setShowShippingAddressForm(true),
                          setIsNewAddress(false);
                      }}
                    >
                      {Address_text.address.edit_address}
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* storage_hub */}
            {shippingMethod?.type === 'storage_hub' && (
              <div className="mt-6  w-full min-h-[100px] bg-[#FEFEFE] rounded-card   py-6    px-4 md:px-9">
                <p className="text-primary text-xl leading-6 font-medium ">
                  {shippingMethod?.name} {Checkout_text.cart.address}
                </p>
                <div className="mt-6">
                  <StorageHubAddresses
                    addresses={storage_hub_addresses}
                    selectStorageHubAddress={selectStorageHubAddress}
                    setSelectStorageHubAddress={setSelectStorageHubAddress}
                  />
                </div>
              </div>
            )}

            {/* Payment methods */}
            {payment_methods?.length > 1 && (
              <div className="mt-6">
                <PaymentMethod
                  payment_methods={payment_methods}
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                />
              </div>
            )}

            {/* Shipment Details */}
            {TYPE_CONSTANT.MARKETPLACE_MODULES === 1 && (
              <div className="mt-6">
                <ShipmentDetails
                  cart={cart}
                  cart_details={cart_details}
                  shipments={shipments}
                />
              </div>
            )}
          </div>
          <div className=" flex-none mt-6 lg:mt-0 w-full lg:w-[340px]">
            {/* cart commission */}
            {commissions?.length > 0 && (
              <CartCommission
                cart_commission={cart_commission}
                setCartCommission={setCartCommission}
                fetch_cart_list={fetch_cart_list}
              />
            )}

            {/* Order summary */}
            <OrderSummary
              cart={cart}
              cart_details={cart_details}
              shippingMethod={shippingMethod}
              cart_commission={cart_commission}
              setCartCommission={setCartCommission}
              fetch_cart_list={fetch_cart_list}
            />

            {/* final checkout button */}
            {cart && (
              <div className="flex justify-center  mt-6">
                <button
                  className=" w-5/6 bg-primary  rounded-button py-[12px] text-center text-base  text-white flex justify-center items-center font-semibold gap-3"
                  onClick={clickCheckOut}
                >
                  {isCheckoutFetching && (
                    <span>
                      <svg
                        className="animate-spin -ml-1   h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  )}
                  <span className=" ">{Shipping_text.shipping.checkout}</span>
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <NoCartItem />
        </div>
      )}
    </>
  );
};

export default CheckoutPageLayout;
