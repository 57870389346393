import React from 'react';
import { svgHeartIcon } from '../../Shared/Constant/Icons/Icons';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);
import tradly from 'tradly';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import ListingCard from '../../Shared/Cards/ListingCard';
import { listingLike } from '../../../store/feature/listingSlice';
import Link from 'next/link';
import { check_login } from '../../../constant/check_auth';
import axios from 'axios';
import { Listing_text } from '../../../constant/Translation/Listing';
import ListingCard6 from '../../Shared/Cards/ListingCard6';

const RelatedListingsList = ({ listing_details }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [related_listings, setRelated_listings] = useState(null);
  useEffect(() => {
    if (listing_details) {
      axios
        .get('/api/l/similar', {
          params: {
            page: 1,
            per_page: 30,
            id: listing_details?.id,
          },
        })
        .then((res) => {
          setRelated_listings(res.data.listings);
        });
    }
  }, [listing_details]);

  // Button Handle
  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id: id,
          isLiked: isLiked,
        })
      ).then((res) => {
        if (!res.payload.code) {
          axios
            .get('/api/l/similar', {
              params: {
                page: 1,
                per_page: 30,
                id: listing_details?.id,
              },
            })
            .then((res) => {
              setRelated_listings(res.data.listings);
            });
        }
      });
    }
  };

  return (
    <>
      {related_listings !== null ? (
        related_listings?.length > 0 && (
          <div className="w-full  min-h-min p-4 bg-[#FFFFFF] rounded-card     shadow-c-sm">
            <div className=" flex justify-between items-center">
              <h2 className="heading-h2">
                {Listing_text.product.similar_product_title}
              </h2>
              <Link
                href={{
                  pathname: `/l/similar/${listing_details?.id}`,
                }}
              >
                <a className=" btn-primary-fill">
                  {Listing_text.product.see_all}
                </a>
              </Link>
            </div>
            <div className="mt-4 ">
              <Swiper
                slidesPerView={'auto'}
                slidesPerGroup={1}
                spaceBetween={20}
                loop={false}
                navigation={false}
              >
                {related_listings?.map((item) => {
                  return (
                    <SwiperSlide
                      className=" w-48 h-72  shadow-sm   rounded-card  ltr:mr-4 overflow-hidden"
                      key={Math.random() * 3000000}
                      style={{
                        width: '260px',
                      }}
                    >
                      <ListingCard6 item={item} like={like} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        )
      ) : (
        <div className="border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card   p-4   w-full mx-auto overflow-hidden">
          <div className="flex  justify-between">
            <div className="h-8 bg-[#3B3269] bg-opacity-[20%] rounded-card   w-28"></div>
            <div className="h-8 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-28"></div>
          </div>
          <div className="animate-pulse mt-4 flex space-x-4">
            <Swiper
              slidesPerView={'auto'}
              slidesPerGroup={1}
              spaceBetween={16}
              loop={false}
              navigation={false}
              style={{ paddingBottom: '24px' }}
            >
              <SwiperSlide
                style={{
                  width: '192px',
                }}
              >
                <div className="w-48 h-55 bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  width: '192px',
                }}
              >
                <div className="w-48 h-55 bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  width: '192px',
                }}
              >
                <div className="w-48 h-55 bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  width: '192px',
                }}
              >
                <div className="w-48 h-55 bg-[#3B3269] bg-opacity-[20%] rounded-card   "></div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
};

export default RelatedListingsList;
