import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch, useSelector } from 'react-redux';
import { Listing_text } from '../../constant/Translation/Listing';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../constant/default_styles/HomeDefaultStyles';
import ListingDynamicSkeleton from '../Shared/skeletonUI/Listing/ListingDynamicSkeleton';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { removeKeysFromObject } from '../../utils/keyRemoveFromObject';
import {
  dynamicPageSelector,
  getDynamicPageSection,
} from '../../store/feature/Pages/DynamicPage/dynamicPageSlice';
import Warning from '../Shared/PopUp/Warning';
import PaginationTypeListings from './PaginationTypeListings';
import ScrollTypeListings from './ScrollTypeListing';
import ListingsView from './ListingsView';
import ListingsInMap from './ListingsInMap';

const AllListings = ({
  section_details,
  all_listings_data,
  styles,
  AllListingFilter,
}) => {
  const { fetch_by, extra_data } = section_details || {};
  const router = useRouter();
  const dispatch = useDispatch();

  //
  const { dynamic_page_sections, isSingleSectionFetching } =
    useSelector(dynamicPageSelector);

  //get more listings by page
  const moreListings = (current_page) => {
    dispatch(
      getDynamicPageSection({
        sections_data: [
          {
            ...section_details,
            fetch_by: {
              ...fetch_by,
              ...AllListingFilter,
              ...router.query,
              page: current_page,
            },
          },
        ],
      })
    );
  };

  // fetch listings base on category

  useEffect(() => {
    const filter_query_params = removeKeysFromObject({
      object_params: router.query,
      key_to_remove: ['draft', 'iframe', 'page_slug'],
    });

    //
    if (
      Object.keys(AllListingFilter)?.length > 0 ||
      Object.keys(filter_query_params)?.length > 0
    ) {
      dispatch(
        getDynamicPageSection({
          sections_data: [
            {
              ...section_details,
              extra_data: {
                ...section_details.extra_data,
                fetching_type: 'fetching_by_effect',
              },
              fetch_by: {
                ...fetch_by,
                ...AllListingFilter,
                ...filter_query_params,
              },
            },
          ],
        })
      );
    }
  }, [AllListingFilter]);

  //
  const [selected_type, setSelected_type] = useState('gallery_view');

  return (
    <>
      {all_listings_data !== null &&
      isSingleSectionFetching !== section_details?.block ? (
        <div
          className="w-full   "
          style={get_dynamic_styles(styles?.main_styles)}
        >
          <div
            className="w-full   "
            style={get_dynamic_styles_with_default_value(
              styles?.box_styles,
              HomeCardBoxDefaultStyles
            )}
          >
            {/* Linstings part */}
            {all_listings_data?.listings?.length > 0 ? (
              <>
                {/*Put the scroll bar always on the bottom*/}
                {extra_data?.pagination_type === 'scroll' && (
                  <ScrollTypeListings
                    all_listings_data={all_listings_data}
                    dynamic_page_sections={dynamic_page_sections}
                    section_details={section_details}
                    moreListings={moreListings}
                  />
                )}

                {/*  */}
                {extra_data?.pagination_type !== 'scroll' && (
                  <PaginationTypeListings
                    all_listings_data={all_listings_data}
                    dynamic_page_sections={dynamic_page_sections}
                    section_details={section_details}
                    moreListings={moreListings}
                    selected_type={selected_type}
                  />
                )}
              </>
            ) : (
              <Warning
                className={' max-w-sm px-2 mx-auto'}
                message={Listing_text.productlist.no_products_found}
              />
            )}
          </div>
        </div>
      ) : (
        <div style={get_dynamic_styles(styles?.main_styles)}>
          <div
            className="w-full   "
            style={get_dynamic_styles_with_default_value(
              styles?.box_styles,
              HomeCardBoxDefaultStyles
            )}
          >
            <ListingDynamicSkeleton
              listings_type={'list'}
              listing_card_type={
                TYPE_CONSTANT.STYLES_CONFIGS?.listing_card?.card_type?.type
              }
              per_page={fetch_by?.per_page}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AllListings;
