import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import LayerWIthTitle from './layerCards/LayerWIthTitle';
import LayerWithImage from './layerCards/LayerWithImage';
SwiperCore.use([Navigation, Pagination]);

const LayersCarousel = ({ layers, layers_extra_data }) => {
  const decide_layer_card = ({ item }) => {
    let decide_layer_card;
    switch (layers_extra_data?.card_type) {
      case 'card_with_title':
        decide_layer_card = <LayerWIthTitle item={item} />;
        break;
      case 'card_with_image':
        decide_layer_card = <LayerWithImage item={item} />;
        break;

      default:
        decide_layer_card = <LayerWIthTitle item={item} />;
        break;
    }

    return decide_layer_card;
  };
  return (
    <Swiper
      slidesPerView="auto"
      slidesPerGroup={1}
      spaceBetween={16}
      loop={false}
      navigation={false}
    >
      {layers?.map((item) => (
        <SwiperSlide
          className="max-w-[300px]  min-h-[159px]  pb-2  rounded-card   overflow-hidden"
          key={Math.random() * 3000000}
          style={{
            width:
              'calc(var(--listingCardMinWidth)*var(--listingCardPerRow)/var(--listingCardPerRow))',
            // minHeight: '210px',
            // paddingBottom: '5px',
          }}
        >
          <div className=" relative">
            {decide_layer_card({ item })}

            {/* <LayerWIthTitle item={item} /> */}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default LayersCarousel;
