//  bg-[#] rounded-card    p-2 flex flex-col  justify-between items-center border border-transparent  shadow-c-sm hover:border-primary hover:bg-primary_light  w-home_category_card  h-home_category_card cursor-pointer

export const CategoryCardDefaultStyles = {
  padding_left: '8px',
  padding_right: '8px',
  padding_top: '8px',
  padding_bottom: '8px',
  border_radius: '8px',
  // box_shadow: '0px 8px 28px rgba(0, 0, 0, 0.05)',
  height: '100px',
  width: '100px',
};

export const CategoryCardImagedDefaultStyles = {
  border_radius: '8px',
  box_shadow: '',
  height: '75%',
  width: '100%',
};

export const CategoryNameDefaultStyles = {
  margin_left: 'auto',
  margin_right: 'auto',
  padding_bottom: '16px',
  color: 'var(--primary_color)',
  text_size: '14px',
  font_weight: '500',
  text_align: 'center',
};
