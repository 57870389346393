/* eslint-disable @typescript-eslint/no-var-requires */
import axios from 'axios';
import { Layer_text } from '../../constant/Translation/LayerText';
import { slug as SlugChecker } from 'github-slugger';

export const add_layer_click = async (
  title,
  slug,
  meta_title,
  meta_description,
  meta_keyword,
  description,
  account_id,
  setErrorMessage,
  setShowError,
  setAddArticleLoading,
  router
) => {
  setAddArticleLoading(true);

  if (title?.replace(/\s/g, '').length <= 0) {
    setShowError(true);
    setErrorMessage(Layer_text?.layer.title_alert);
    setAddArticleLoading(false);
    return false;
  }
  if (description?.replace(/\s/g, '').length <= 0) {
    setShowError(true);
    setErrorMessage(Layer_text?.layer.desc_alert);
    setAddArticleLoading(false);
    return false;
  }

  try {
    axios
      .post('/api/c/article', {
        data: {
          layer: {
            title: title,
            content: description,
            type: 'post',
            //  tags: ['node'],
            slug: SlugChecker(title),
            meta_title: ' ',
            meta_description: ' ',
            meta_keyword: ' ',
            account_id: account_id,
            //  category_id: '1, 2',
          },
        },
      })
      .then((res) => {
        setAddArticleLoading(false);
        router.push('/c');
      })
      .catch((error) => {
        setShowError(true);
        setErrorMessage(error.response?.data?.message);
        setAddArticleLoading(false);
      });
  } catch (error) {
    setShowError(true);
    setErrorMessage(error.response?.data?.message);
    setAddArticleLoading(false);
  }
};
