/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  default_large_user_icon,
  default_user_icon,
  rating_icon,
} from '../../Shared/Constant/Icons/AllIcons';
import Image from 'next/image';
import Rating from './rating';
import {
  changeDateFormat,
  getThumbnailImage,
} from '../../Shared/Constant/Constant';
import ShowMoreText from 'react-show-more-text';
import tradly from 'tradly';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import axios from 'axios';
import {
  getListingReviews,
  listingDetails,
  listingSelector,
} from '../../../store/feature/listingSlice';
import { useRouter } from 'next/dist/client/router';
import { check_login } from '../../../constant/check_auth';
import { Listing_text } from '../../../constant/Translation/Listing';

const ReviewBox = ({}) => {
  const { auth_key, login } = useSelector(authSelector);
  const dispatch = useDispatch();
  const router = useRouter();

  const { listing_details, rating_data, reviews, my_review, review_page } =
    useSelector(listingSelector);

  const helpful_review = (id, status) => {
    if (check_login(router)) {
      axios
        .post('/api/review/like', {
          data: {
            review: {
              status: status == 0 ? 1 : 0,
            },
          },
          id: id,
        })
        .then((res) => {
          dispatch(
            listingDetails({
              ...router?.query,
            })
          );
          dispatch(
            getListingReviews({
              authKey: auth_key,
              params: {
                type: 'listings',
                id: listing_details?.id,
                page: review_page,
                per_page: 30,
              },
            })
          );
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  };

  const [show_moe_review, setShow_more_review] = useState(false);

  return (
    <div className=" bg-white rounded-card   w-full min-h-min p-4 shadow-c-sm">
      <h2 className="heading-h2">
        {Listing_text.product.reviews} {`(${rating_data?.review_count})`}
      </h2>
      <div className="mt-4">
        {/* My review */}
        {my_review && (
          <div
            className={[
              'min-h-[200px] p-4 rounded-card   shadow-c-md my-3',
            ].join(' ')}
          >
            <p className="description-paragraph  mb-2">
              {Listing_text.product.your_review}
            </p>
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 rounded-full overflow-hidden">
                {my_review.user.profile_pic ? (
                  <img
                    className="w-8 h-8 rounded-full overflow-hidden object-cover"
                    src={my_review.user.profile_pic}
                    width={32}
                    height={32}
                    alt="user image"
                  />
                ) : (
                  default_large_user_icon
                )}
              </div>
              <p className="description-paragraph ">
                {my_review.user.first_name}
              </p>
            </div>
            <div className="my-2 flex items-center gap-3">
              <Rating rating_value={Number(my_review.rating)} />
              <p className="description-caption ">
                {changeDateFormat(my_review.created_at, 'MMM DD,YYYY')}
              </p>
            </div>
            <div>
              <ShowMoreText
                /* Default options */
                lines={3}
                more="View More"
                less="View Less"
                anchorClass="text-lg text-primary  "
                expanded={false}
                truncatedEndingComponent={'... '}
              >
                {my_review.content}
              </ShowMoreText>
            </div>
            <div className=" mt-3 flex items-center justify-start flex-wrap gap-3  ">
              {my_review?.images.map((img, index) => {
                return (
                  <div
                    className="w-[100px] h-[100px] rounded-image overflow-hidden"
                    key={index}
                  >
                    <Image
                      src={img}
                      width={100}
                      height={100}
                      objectFit="cover"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* Others review */}
        {reviews.map((item, index, array) => {
          return (
            <div
              key={index}
              className={[
                'min-h-min p-4 rounded-card   shadow-c-md my-3',
                array.length > 1 && !show_moe_review && index + 1 !== 1
                  ? 'hidden'
                  : 'block',
              ].join(' ')}
            >
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full overflow-hidden">
                  {item.user.profile_pic ? (
                    <img
                      src={getThumbnailImage(item.user.profile_pic)}
                      alt="image"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    default_large_user_icon
                  )}
                </div>
                <p className="description-paragraph ">{item.user.first_name}</p>
              </div>
              <div className="my-2 flex items-center gap-3">
                <Rating rating_value={Number(item.rating)} />
                <p className="description-caption ">
                  {changeDateFormat(item.created_at, 'MMM DD,YYYY')}
                </p>
              </div>
              <div>
                <ShowMoreText
                  /* Default options */
                  lines={3}
                  more="View More"
                  less="View Less"
                  anchorClass="text-lg text-primary  "
                  expanded={false}
                  truncatedEndingComponent={'... '}
                >
                  {item.content}
                </ShowMoreText>
              </div>
              <div className=" mt-3 flex items-center justify-start flex-wrap gap-3  ">
                {item?.images.map((img, index) => {
                  return (
                    <div
                      className="w-24 h-24 rounded-image overflow-hidden"
                      key={index}
                    >
                      <img
                        src={img}
                        alt="image"
                        className=" w-full h-full object-cover"
                      />
                    </div>
                  );
                })}
              </div>
              <div className=" mt-2 px-2 flex items-center justify-end gap-3">
                <button
                  onClick={() => helpful_review(item.id, item.like_status)}
                >
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill={
                      item.like_status == 0
                        ? '#9B9B9B'
                        : 'var( --primary_color)'
                    }
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 12.7612H2.36364V5.10458H0V12.7612ZM13 5.74244C13 5.04059 12.4682 4.46634 11.8182 4.46634H8.08956L8.65093 1.55046L8.66865 1.34628C8.66865 1.08468 8.5682 0.842225 8.40865 0.669952L7.78229 0L3.89411 4.20474C3.67547 4.43444 3.54547 4.75347 3.54547 5.10439V11.4849C3.54547 12.1867 4.07729 12.761 4.72729 12.761H10.0455C10.5359 12.761 10.9555 12.442 11.1327 11.9826L12.9173 7.48432C12.9705 7.33757 13 7.18443 13 7.01854V5.79987L12.9941 5.79349L13 5.74244Z"
                    />
                  </svg>
                </button>
                <span className="description-caption ">{item.likes}</span>
              </div>
            </div>
          );
        })}
      </div>
      {reviews.length > 1 && (
        <div
          className={[
            ' w-full flex justify-center mt-6 mb-3',
            show_moe_review && 'hidden',
          ].join(' ')}
        >
          <button
            className=" w-4/6 text-center py-3 border border-primary text-primary text-lg rounded-button"
            onClick={() => setShow_more_review(true)}
          >
            {Listing_text.product.read_all_reviews}
          </button>
        </div>
      )}
    </div>
  );
};

export default ReviewBox;
