import Transition from '../utils/Transition';
import { useState, useRef, useEffect } from 'react';

function LocationSearch({
  locationSearchKey,
  setLocationSearchKey,
  place_holder,
  locations_list,
  handleKeypress,
  handelItemSelect,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <>
      <div className="relative  h-full w-full ">
        <input
          value={locationSearchKey ?? ''}
          ref={trigger}
          className="h-full w-full  bg-transparent px-4 placeholder-gray-400 outline-none border-none ring-0 focus:ring-0"
          type="text"
          placeholder={place_holder}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          onChange={(e) => setLocationSearchKey(e.target.value)}
          aria-haspopup="true"
          aria-expanded={dropdownOpen}
          onKeyPress={(e) => handleKeypress(e)}
        />

        <Transition
          className={` origin-top-right absolute z-[30] top-full  w-full bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1  right-0`}
          show={dropdownOpen}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div
            ref={dropdown}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
          >
            <ul className="flex flex-col gap-1 p-3">
              {locations_list?.map((item) => {
                return (
                  <li
                    className=" cursor-pointer p-2 text-base text-gray-600 hover:shadow-md transition-all duration-300 "
                    onClick={() => {
                      setDropdownOpen(false);
                      handelItemSelect(item);
                    }}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </Transition>
      </div>
    </>
  );
}

export default LocationSearch;
