import React from 'react';

import AddLayerForm from '../../components/Layers/AddLayerForm';

const AddPostPageLayout = () => {
  return (
    <>
      <div className=" flex justify-center">
        <div className=" w-full">
          <AddLayerForm />
        </div>
      </div>
    </>
  );
};

export default AddPostPageLayout;
