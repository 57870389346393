import React from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const CategoriesSkeleton = ({ styles }) => {
  return (
    <div
      className="  flex  flex-wrap  "
      style={get_dynamic_styles(styles?.categories_styles)}
    >
      <div className="w-24 h-24    bg-[#3B3269] bg-opacity-[20%] rounded-card   " />
      <div className="w-24 h-24    bg-[#3B3269] bg-opacity-[20%] rounded-card   " />
      <div className="w-24 h-24    bg-[#3B3269] bg-opacity-[20%] rounded-card   " />
      <div className="w-24 h-24    bg-[#3B3269] bg-opacity-[20%] rounded-card   " />
      <div className="w-24 h-24    bg-[#3B3269] bg-opacity-[20%] rounded-card   " />
      <div className="w-24 h-24    bg-[#3B3269] bg-opacity-[20%] rounded-card   " />
    </div>
  );
};

export default CategoriesSkeleton;
