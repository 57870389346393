/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import Link from 'next/link';

// Import Swiper styles
import 'swiper/css/bundle';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { configsSelector } from '../../../store/feature/configsSlice';
import { authSelector } from '../../../store/feature/authSlice';
import { listingLike } from '../../../store/feature/listingSlice';
import { homeCollections } from '../../../store/feature/homeSlice';

import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { Home_page_text } from '../../../constant/Translation/Home';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const Listings = ({ products }) => {
  // const { isSuccess } = useSelector(listingSelector);

  return (
    <div className="">
      <div className="flex justify-between items-center  ">
        <h2 className=" text-2xl text-black font-semibold">{products.title}</h2>
        <Link
          href={{
            pathname: '/l',
          }}
          passHref
        >
          <a className=" block text-base text-primary font-semibold cursor-pointer">
            {Home_page_text.home.view_all}
          </a>
        </Link>
      </div>
      <div className="grid grid-cols-1  sm:grid-cols-2 gap-5 mt-5">
        {products?.listings?.map((item, index) => {
          return (
            <Link
              href={
                item.slug
                  ? `/l/${item.slug}`
                  : `/l/${item.id}-${item.title.replace(
                      /[ /?,#=]+/g,
                      '-'
                    )}?id=true`
              }
              key={index}
            >
              <a className="  w-full   px-4  mb-1 flex  items-center justify-start border border-transparent hover:border-gray-300  rounded-image p-4 cursor-pointer gap-4">
                <div
                  className={
                    ' flex-none relative   h-listing_card_height  rounded-image overflow-hidden'
                  }
                  style={{ width: 'var(--listing_card_height)' }}
                >
                  <img
                    src={
                      item.images.length > 0 &&
                      getThumbnailImage(item.images[0])
                    }
                    alt="image"
                    className="  absolute  max-w-[100%]  top-[50%] ltr:left-[50%] rtl:-right-[50%]  -translate-x-1/2 -translate-y-1/2 rounded-image shadow-sm "
                  />
                </div>
                <div className=" flex-grow ">
                  <h4 className="  text-sm font-semibold">
                    {item.title.length > 18
                      ? item.title.substring(0, 18) + '..'
                      : item.title}
                  </h4>
                  {TYPE_CONSTANT.MARKETPLACE_FLAVOURS === 1 && (
                    <p className=" mt-2 mb-0 text-second text-sm   text-primary whitespace-pre-wrap">
                      By{' '}
                      {item?.account?.name.length > 20
                        ? item?.account?.name.substring(0, 18) + '..'
                        : item?.account?.name}
                    </p>
                  )}
                </div>
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Listings;
