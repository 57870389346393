import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { useRouter } from 'next/router';

import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';

import { useScreenSize } from '../../hooks/useScreenSize';
import Paragraph from '../../components/StaticBlocks/Paragraph';
import CustomCta from '../../components/StaticBlocks/CustomCta';
import EmptyBlock from '../../components/StaticBlocks/EmptyBlock';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../utils/ResponsiveDesign';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { clearErrorState } from '../../store/feature/Pages/TagListings/TagListingSlice';
import { TagListingsDefaultColumnStyles } from '../../constant/default_styles/TagListingsDefaultStyles';
import HeadingBlock from '../../components/StaticBlocks/HeadingBlock';
import DividerBlock from '../../components/StaticBlocks/DividerBlock';
import { layerSelector } from '../../store/feature/layerSlice';
import LayerImage from '../../components/LayerDetils.jsx/LayerImage';
import LayerDescription from '../../components/LayerDetils.jsx/LayerDescription';
import LayerTItle from '../../components/LayerDetils.jsx/LayerTItle';
import SingleLink from '../../components/StaticBlocks/SingleLink';
import Heading2Block from '../../components/StaticBlocks/Heading2Block';
import WideSearchBox from '../../components/SearchBox/WideSearchBox';
import DescriptionBlock from '../../components/StaticBlocks/DescriptionBlock ';
import ImageBlock from '../../components/StaticBlocks/ImageBlock';
import ImageDestinationBlock from '../../components/StaticBlocks/ImageDestinationBlock';
import FeaturedCard2 from '../../components/home/FeaturedCrad/FeaturedCard2';
import TitleDescriptionButton from '../../components/StaticBlocks/TitleDescriptionButton';
import VideoBlock from '../../components/StaticBlocks/VideoBlock';
import QuoteBlock from './QuoteBlock';
import TrustedBlock from './TrustedBlock';
import CardBlock from '../../components/StaticBlocks/CardBlock';
import CardBlockImageFirst from '../../components/StaticBlocks/CardBlockImageFirst';
import CarouselCardBlock from '../../components/StaticBlocks/CarouselCardBlock';
import AboutUs from '../../components/StaticBlocks/AboutUs';
import LeftImageFeaturedCard from '../../components/home/FeaturedCrad/LeftImageFeaturedCard';
import { layer_details_default_data } from '../../data/styles/LayerDetailsDefaultData';
import { common_blocks_cases } from './BlockDecide/CommonBlockCase';

const BlogDetailsPageLayout = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { width, height } = useScreenSize();

  //
  const { isError, errors, blog_details } = useSelector(layerSelector);

  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  // close pop up
  const closePopUP = () => {
    dispatch(clearErrorState());
    setShowError(false);
    setError_message('');
  };

  //select blocks
  const BLOG_DETAILS_PAGE = TYPE_CONSTANT.PAGES?.blog_details
    ? TYPE_CONSTANT.PAGES?.blog_details
    : layer_details_default_data;

  //  component_decide
  const component_decide = ({
    order_number,
    block,
    block_data_key,
    fetch_by,
    data,
    isStatic,
    extra_data,
    styles,
  }) => {
    const _styles = styles ?? data?.styles ?? {};
    const section = {
      order_number,
      block,
      block_data_key,
      fetch_by,
      data,
      isStatic,
      extra_data,
      styles,
    };
    switch (block) {
      case 'layer_title':
        return (
          data?.enabled && (
            <LayerTItle
              data={{ title: blog_details?.title }}
              styles={_styles}
            />
          )
        );
      case 'layer_image':
        return (
          data?.enabled && (
            <LayerImage
              data={{ image: blog_details?.cover_image_path }}
              styles={_styles}
            />
          )
        );
      case 'layer_description_block':
        return (
          data?.enabled && (
            <LayerDescription
              data={{
                description: blog_details?.content,
              }}
              styles={_styles}
            />
          )
        );
      case 'heading_title':
        return (
          data?.enabled && (
            <HeadingBlock
              data={data}
              styles={_styles}
              check_by={'{tag}'}
              dynamic_text_value={router?.query?.tag}
            />
          )
        );
      case 'divider_block':
        return <DividerBlock styles={_styles} />;

      case 'paragraph':
        return <Paragraph data={data} styles={styles} />;
      case 'cta':
        return <CustomCta data={data} styles={styles} />;

      case 'empty_block':
        return <EmptyBlock data={data} styles={styles} />;

      case 'single_link':
        return isEnabled && <SingleLink data={data} styles={styles} />;
      case 'heading2_block':
        return <Heading2Block data={data} styles={styles} />;

      case 'searchblock':
        return <WideSearchBox data={data} styles={styles} />;

      case 'description_block':
        return (
          data?.enabled && <DescriptionBlock data={data} styles={styles} />
        );
      case 'image_block':
        return data?.enabled && <ImageBlock data={data} styles={styles} />;
      case 'image_with_destination_block':
        return (
          data?.enabled && <ImageDestinationBlock data={data} styles={styles} />
        );
      case 'featured_card':
        return data?.enabled && <FeaturedCard2 data={data} styles={styles} />;
      case 'title_desc_button':
        return (
          data?.enabled && (
            <TitleDescriptionButton data={data} styles={styles} />
          )
        );

      case 'video_featured':
        return data?.enabled && <VideoBlock data={data} styles={styles} />;
      case 'quote_block':
        return data?.enabled && <QuoteBlock data={data} styles={styles} />;
      case 'trusted_block':
        return data?.enabled && <TrustedBlock data={data} styles={styles} />;

      case 'card_block':
        return <CardBlock data={data} styles={styles} />;
      case 'card_block_top_image':
        return <CardBlockImageFirst data={data} styles={styles} />;
      case 'carousel_card_block':
        return <CarouselCardBlock data={data} styles={styles} />;
      case 'about_us':
        return <AboutUs data={data} styles={styles} />;
      case 'left_image_featured_card':
        return <LeftImageFeaturedCard data={data} styles={styles} />;
      default:
        return common_blocks_cases({ section });
    }
  };

  return (
    <>
      {/* Error handler */}
      {(isError || showError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (isError || showError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearErrorState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="flex flex-col gap-2">
              {errors?.map((err) => {
                return <PopUp message={err?.msg} closePopUP={closePopUP} />;
              })}
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {/* Rows */}
      <div
        style={get_dynamic_styles(
          responsive_design_styles({
            width,
            parent_group: BLOG_DETAILS_PAGE?.styles,
            child_group_name: 'main_styles',
          })
        )}
      >
        <div
          className="flex flex-col  "
          style={get_dynamic_styles(
            responsive_design_styles({
              width,
              parent_group: BLOG_DETAILS_PAGE?.styles,
              child_group_name: 'box_styles',
            })
          )}
        >
          {BLOG_DETAILS_PAGE?.schema?.map((row) => {
            const {
              columns,
              current_editor_version = '',
              ...column_styles
            } = row || {};
            const row_styles = responsive_row_styles({
              parent_group: row,
              width,
            });
            return (
              <div
                className={``}
                style={{
                  ...get_dynamic_styles(row_styles),
                  display:
                    row_styles?.isShowInMobile === false ? 'none' : 'grid',
                  gap: row_styles?.gap_between_columns ?? '24px',
                  gridTemplateColumns:
                    width > 768
                      ? row_styles?.columns_box_percentage
                        ? row_styles?.columns_box_percentage
                        : `repeat(${row.columns?.length ?? 1}, minmax(0, 1fr))`
                      : row?.['768_row_styles']
                      ? row_styles?.columns_box_percentage
                      : '100%',
                }}
              >
                {/* Columns */}
                {columns?.map((column, index) => {
                  const current_column_style = responsive_column_styles({
                    parent_group: column_styles,
                    index,
                    width,
                  });

                  return (
                    <div
                      className="flex   "
                      style={{
                        ...get_dynamic_styles_with_default_value(
                          current_column_style,
                          TagListingsDefaultColumnStyles
                        ),
                      }}
                    >
                      {column?.map((item) => {
                        return (
                          component_decide({ ...item }) &&
                          component_decide({ ...item })
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BlogDetailsPageLayout;
