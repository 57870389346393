import { Sales_text } from '../../constant/Translation/Sales';

export const transaction_type = (type) => {
  switch (type) {
    case 1:
      return Sales_text.sales.sales_sales;
    case 2:
      return Sales_text.sales.sales_sales;
    case 5:
      return Sales_text.sales.commision_created;
    case 6:
      return Sales_text.sales.commision_canceled;
    case 7:
      return Sales_text.sales.transfer_created;
    case 8:
      return Sales_text.sales.transfer_canceled;
    case 9:
      return Sales_text.sales.payout;
    case 10:
      return Sales_text.sales.subscription_fee;
    case 11:
      return Sales_text.sales.processing_fee;
    case 12:
      return Sales_text.sales.processing_fee_canceled;
    default:
      return Sales_text.sales.no_payout_reversal;
  }
};
