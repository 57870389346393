import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { Menubar_text } from '../../../constant/Translation/Heading';
import { categorySelector } from '../../../store/feature/categorySlice';
import { useRouter } from 'next/router';
import { slug as SlugChecker } from 'github-slugger';

const Categories5 = () => {
  const router = useRouter();
  const { all_categories } = useSelector(categorySelector);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const width = window.innerWidth;
    var calc;
    if (all_categories?.length > 0) {
      if (all_categories.length < 9) {
        setCategories(all_categories);
      } else {
        var sliceLength;
        if (width < 1300) {
          sliceLength = 6;
        }
        if (width < 1000) {
          sliceLength = 5;
        }
        if (width < 900) {
          sliceLength = 4;
        }

        let updatedCategories = all_categories.slice(0, sliceLength || 9);
        let moreCategory = {
          id: 'more',
          name: Menubar_text.more.more,
          image_path: '',
          has_sub_category: true,
          link: 'all-categories',
        };
        updatedCategories.push(moreCategory);
        setCategories(updatedCategories);
      }
    }
  }, [all_categories]);

  return (
    <div>
      <div className="w-full  flex items-center ">
        {categories?.map((item) => {
          const query =
            item.id !== 'more'
              ? {
                  slug: item.slug
                    ? item.slug
                    : `${item?.id}-${SlugChecker(item.name)}`,
                }
              : '';

          return (
            <Link
              key={Math.random()}
              href={{
                pathname: `${item.name !== 'More' ? '/lc/[slug]' : '/lc'}`,
                query,
              }}
              passHref
            >
              <a className="block">
                <p
                  className={[
                    '  px-3 py-2  whitespace-nowrap  cursor-pointer transition duration-300 hover:text-primary ',
                    router?.query?.slug ===
                    (item.slug
                      ? item.slug
                      : `${item?.id}-${SlugChecker(item.name)}`)
                      ? 'text-primary'
                      : '',
                  ].join(' ')}
                >
                  {item.name}
                </p>
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Categories5;
