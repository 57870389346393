import React, { memo } from 'react';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const HomeHeading = ({ data }) => {
  return (
    <div
      className="  max-w-5xl mx-auto "
      style={{
        marginTop: data?.margin_top ?? '',
        marginBottom: data?.margin_bottom ?? '',
      }}
    >
      <h2 className="text-center text-4xl md:text-[100px]  font-bold   md:leading-[110px] ">
        {data?.header_text}
      </h2>
    </div>
  );
};

export default memo(HomeHeading);
