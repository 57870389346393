import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { useRouter } from 'next/router';
import axios from 'axios';
import Link from 'next/link';
import ReactPaginate from 'react-paginate';
import { Home_page_text } from '../../constant/Translation/Home';
import { getThumbnailImage } from '../../components/Shared/Constant/Constant';
import Latest_blogs from '../../components/home/BlogsWidget/Latest_blogs';
import { bannersSelector } from '../../store/feature/Home/bannerSlice';

const HomePageLayout = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [listings, setListings] = useState(null);
  const [page, setPage] = useState(1);
  const [total_records, setTotal_records] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const category_id = router?.query?.category_id;

  // //
  // useEffect(() => {
  //   dispatch(
  //     homeCollections({
  //       authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
  //     })
  //   );
  //   dispatch(
  //     AllPromoBanners({
  //       authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
  //       bodyParam: { medium: 'web' },
  //     })
  //   );
  // }, [auth_key, dispatch]);

  //
  const queries = router.query;

  // fetch listings
  useEffect(() => {
    const params = router?.query?.page
      ? {
          ...router.query,
          type: 'listings',
          status: 2,
          includes: 'attributes',
          per_page: 10,
        }
      : {
          ...router.query,
          page: 1,
          type: 'listings',
          status: 2,
          includes: 'attributes',
          per_page: 10,
        };
    axios
      .get('/api/l', {
        params: params,
      })
      .then((res) => {
        setListings(res.data.listings);
        setPage(res.data.page);
        setTotal_records(res.data.total_records);

        const total_page = Math.ceil(res.data.total_records / 10);
        if (Number(total_records) > 10) {
          setPageCount(total_page);
        }
      });
  }, [router]);

  //
  const moreListings = (data) => {
    router.push({
      query: {
        ...router.query,
        page: Number(data.selected) + 1,
      },
    });
  };

  // Data from store
  const { collections, categories } = useSelector(homeSelector);
  const { promo_banners } = useSelector(bannersSelector);

  const { general_configs } = useSelector(configsSelector);
  return (
    <>
      <div className="">
        <div className="pb-12 grid md:grid-cols-2 items-center">
          <div className="flex flex-col gap-4">
            <h2 className="text-[32px] text-black font-bold  ltr:pr-4 rtl:pl-4">
              {general_configs?.header_text}
            </h2>
            <p className=" mt-2 text-[16px] text-black font-normal w-5/6  ltr:pr-4 rtl:pl-4">
              {general_configs?.header_description}
            </p>
          </div>
          <div className=" relative   aspect-w-4 aspect-h-2  ">
            {promo_banners?.length > 0 && (
              <img
                src={promo_banners[0]?.image_path}
                className="  absolute  max-w-[100%]  top-[50%] ltr:left-[50%] rtl:-right-[50%]  -translate-x-1/2 -translate-y-1/2 rounded-image shadow-sm "
              />
            )}
          </div>
        </div>

        {/* Filters */}
        <div className="mb-6">
          {categories !== null && (
            <div className="border-b border-gray-300  ">
              <div className="flex w-full overflow-x-auto   md:flex-wrap  justify-start  font-medium  gap-4 ">
                <Link href="/" passHref>
                  <a
                    className={[
                      ' border-b  border-transparent hover:border-primary  whitespace-nowrap pb-2  transition duration-300',
                      !category_id && router.pathname === '/'
                        ? 'text-primary  whitespace-nowrap    border-primary   '
                        : 'text-gray-800 ',
                    ].join(' ')}
                  >
                    {Home_page_text.home.view_all}
                  </a>
                </Link>

                {categories.map((ct) => {
                  return (
                    <Link
                      href={{ path: `/`, query: { category_id: ct.id } }}
                      passHref
                    >
                      <a
                        className={[
                          ' border-b  border-transparent hover:border-primary  whitespace-nowrap  pb-2 transition duration-300',
                          category_id == ct.id
                            ? 'text-primary  whitespace-nowrap    border-primary   '
                            : 'text-gray-800 ',
                        ].join(' ')}
                      >
                        {ct.name}
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        {/* Listings*/}
        <div className="max-w-sm mx-auto md:max-w-none">
          {/* Listings  container */}
          <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
            {listings?.map((item) => {
              return (
                <Link
                  href={
                    item.slug
                      ? `/l/${item.slug}`
                      : `/l/${item.id}-${item.title.replace(
                          /[ /?,#=]+/g,
                          '-'
                        )}?id=true`
                  }
                >
                  <a>
                    <article className="flex flex-col h-full   rounded-md overflow-hidden  border border-[#eaeaea]   shadow-c-sm  hover:shadow-md p-6">
                      <header>
                        <div className=" aspect-w-3 aspect-h-2 block relative mb-6 overflow-hidden">
                          <img
                            className="absolute top-0 left-0  align-middle  rounded-image"
                            src={getThumbnailImage(item?.images[0])}
                            alt=""
                          />
                        </div>

                        <div className="mb-3 px-2">
                          <ul className="flex flex-wrap text-xs font-medium -m-1">
                            <li className="m-1">
                              <a className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out">
                                {item.list_price.formatted}
                              </a>
                            </li>
                          </ul>
                        </div>
                        <h3 className=" px-2 text-xl font-bold leading-snug tracking-tight mb-2">
                          {item.title}
                        </h3>
                      </header>
                      {item?.description && (
                        <p className="px-2 text-gray-600 flex-grow">
                          {item?.description.length > 60
                            ? item?.description.substring(0, 60) + '...'
                            : item?.description}
                        </p>
                      )}
                    </article>
                  </a>
                </Link>
              );
            })}
          </div>
        </div>

        <section>
          <div className="mt-6 flex justify-center">
            {listings && (
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
                onPageChange={(data) => moreListings(data)}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel={
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
                renderOnZeroPageCount={null}
                containerClassName=""
                className="relative z-0 inline-flex flex-wrap justify-center rounded-button shadow-sm -space-x-px "
                pageClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center text-sm font-medium"
                pageLinkClassName="px-4 py-2 border"
                previousClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                nextClassName="relative inline-flex items-center px-2 py-2   border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                breakLinkClassName="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                activeLinkClassName="z-10 bg-primary  border-primary text-white relative inline-flex items-center px-4 py-2 border text-md font-semibold"
                disabledLinkClassName=""
                prevPageRel="2"
                forcePage={page - 1}
              />
            )}
          </div>
        </section>

        {TYPE_CONSTANT.GENERAL_CONFIGS?.home_blogs_widget !== false && (
          <div className="max-w-sm mx-auto md:max-w-none">
            <Latest_blogs />
          </div>
        )}
      </div>
    </>
  );
};

export default HomePageLayout;
