import React from 'react';

import EditLayerForm from '../../components/Layers/EditLayerForm';

const EditPostPageLayout = () => {
  return (
    <>
      <div className=" flex justify-center">
        <EditLayerForm />
      </div>
    </>
  );
};

export default EditPostPageLayout;
