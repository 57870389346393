import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { Home_page_text } from '../../../constant/Translation/Home';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const Categories4 = ({ categories }) => {
  const router = useRouter();
  return (
    <div className=" w-full   pt-4   pb-6  flex flex-row  gap-3      overflow-x-auto   overflow-y-hidden    scrollbar-thin   scrollbar-track-gray-100  scrollbar-thumb-gray-300  max-w-[1140px] mx-auto px-4   ">
      <Link
        href={{
          pathname: '/',
        }}
      >
        <a
          className={[
            '      whitespace-nowrap font-normal text-sm btn-primary-outline px-2 py-1',
            !router.query?.category_id && '   btn-primary-fill  px-2 py-1',
          ].join(' ')}
        >
          {Home_page_text.home.view_all}
        </a>
      </Link>
      {categories?.map((ct) => {
        return (
          <Link
            href={{
              pathname: '/',
              query: {
                category_id: ct.id,
              },
            }}
          >
            <a
              className={[
                ' whitespace-nowrap   font-normal text-sm btn-primary-outline px-2 py-1',
                router.query?.category_id == ct.id &&
                  '   btn-primary-fill  px-2 py-1',
              ].join(' ')}
            >
              {ct.name}
            </a>
          </Link>
        );
      })}
    </div>
  );
};

export default Categories4;
