/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
import {
  changeDateFormat,
  getThumbnailImage,
} from '../../Shared/Constant/Constant';
import { configsSelector } from '../../../store/feature/configsSlice';
import { authSelector } from '../../../store/feature/authSlice';
import { listingLike } from '../../../store/feature/listingSlice';
import { homeCollections } from '../../../store/feature/homeSlice';

import favorite from '../../../assets/Images/Home/favourite@3x.png';
import heartIcon from '../../../assets/Images/Home/heartIcon@3x.png';
import ListingCard2 from '../../Shared/Cards/ListingCard2';
import { Home_page_text } from '../../../constant/Translation/Home';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const LatestListings = ({ products }) => {
  const { login, auth_key } = useSelector(authSelector);
  // const { isSuccess } = useSelector(listingSelector);
  const dispatch = useDispatch();
  const router = useRouter();

  const like = (id, isLiked) => {
    if (login) {
      dispatch(
        listingLike({
          id,
          isLiked,
          authKey: auth_key,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(homeCollections({ authKey: auth_key }));
        }
      });
    } else {
      router.push('/sign-in');
    }
  };

  return (
    <div className="mt-1">
      <div className="flex justify-between items-center my-5  ">
        <h2 className="heading-h2">{products.title}</h2>
        <Link
          href={{
            pathname: `/cp/${products.slug ? products.slug : products.id}`,
            query: { collection_name: products.title },
          }}
          passHref
        >
          <a className=" block text-base text-primary font-semibold cursor-pointer">
            {Home_page_text.home.view_all}
          </a>
        </Link>
      </div>
      <div className="grid  	grid-cols-2  sm:grid-cols-3  md:grid-cols-4 gap-5">
        {products?.listings.map((item, index, array) => {
          const total_rows = Math.ceil(array / 4);
          return (
            <div key={item.id} className={''}>
              <ListingCard2 item={item} like={like} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LatestListings;
