import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import PopUp from '../Shared/PopUp/PopUp';
import { add_layer_click } from './add_layer_press';
import { Layer_text } from '../../constant/Translation/LayerText';
import ReadingComposer from '../Shared/Composer/ReadingComposer';
import Composer2 from '../Shared/Composer/Composer2';

const AddLayerForm = () => {
  const [title, setTitle] = useState('Title');
  const [meta_title, setMetaTitle] = useState('');
  const [meta_keyword, setMetaKeyword] = useState('');
  const [slug, setSlug] = useState(' ');
  const [description, setDescription] = useState(``);
  const [meta_description, setMetaDescription] = useState(' ');

  //
  const [addArticleLoading, setAddArticleLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const dispatch = useDispatch();
  const router = useRouter();
  const account_id = router.query.account_id;

  // Use Effect functions

  // pop up close
  const closePopUP = () => {
    setShowError(false);
    setErrorMessage('');
  };

  // console.log(imagePath);

  return (
    <>
      {showError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showError && (setShowError(false), setErrorMessage(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp message={errorMessage} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      <div className="flex  flex-col md:flex-row items-start  justify-between">
        <div className=" w-full  c-lg:w-[700px]     mx-auto   order-last md:order-first">
          <div className=" bg-white    grid grid-cols-1  gap-2 rounded-card  shadow-xl px-[20px]">
            {/* <h3 className=" font-semibold text-[#121212] text-xl mb-4 text-center">
              Add New Article
            </h3> */}
            <label className="block">
              {/* <span className="text-gray-700">Title</span> */}
              <div
                contentEditable
                draggable={false}
                className="
                    mt-0
                    block
                    w-full
                    px-0.5 
                    border-0  transition  duration-700
                    focus:ring-0 
                    text-5xl 
                     focus-visible:ring-0                 
                     focus-visible:outline-none
                      out                
                     cursor-text    
                     min-h-[48px] 
                      break-words
                               
                  "
                onBlur={(e) => {
                  e.currentTarget.textContent.length > 0
                    ? setTitle(e.currentTarget.textContent)
                    : setTitle('Title');
                }}
                onFocus={(e) => {
                  e.currentTarget.textContent === 'Title'
                    ? setTitle('')
                    : setTitle(title);
                }}
              >
                {title}
              </div>
            </label>

            <Composer2
              contents={description}
              setContents={setDescription}
              isReadOnly={false}
              mainClass={' min-h-[70vh]'}
            />
          </div>
        </div>

        <div className="  flex-shrink-0 flex justify-end px-10 py-2  ml-auto ">
          <button
            className="text-white   w-[180px] h-12 rounded-button bg-primary  flex items-center justify-center "
            onClick={() => {
              add_layer_click(
                title,
                slug,
                meta_title,
                meta_description,
                meta_keyword,
                description,
                account_id,
                setErrorMessage,
                setShowError,
                setAddArticleLoading,
                router
              );
            }}
            disabled={addArticleLoading ? true : false}
          >
            {addArticleLoading && (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            {Layer_text?.layer.publish}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddLayerForm;
