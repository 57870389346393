import { group_hover_animation, hover_animation } from './HoverAnimation';

export const get_animation_classes = (animations, type) => {
  if (type == 'group') {
    return (
      animations &&
      Object?.keys(animations)?.reduce((previous, key) => {
        return animations[key]
          ? previous + group_hover_animation[key]
          : previous;
      }, '')
    );
  } else {
    return Object?.keys(animations)?.reduce((previous, key) => {
      return animations && animations[key]
        ? previous + hover_animation[key]
        : previous;
    }, '');
  }
};
