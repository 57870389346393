import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { push_data_layer } from '../../constant/GTMDatalayer';
import { Address_text } from '../../constant/Translation/Address';
import {
  addressSelector,
  clearErrorState,
  delete_address,
  getPickupAddress,
  getShippingAddress,
  save_address,
} from '../../store/feature/addressSlice';
import CustomLoading from '../Shared/Loading/CustomLoading';
import Modal from '../Shared/Modal/Modal';
import PopUp from '../Shared/PopUp/PopUp';
import AddressForm from './AddressForm';

const Edit_address_button = ({ address_details, _type }) => {
  const [address_form, setAddressForm] = useState(false);
  const { register, handleSubmit, setValue, control } = useForm();
  const [type, setType] = useState(_type);
  const dispatch = useDispatch();
  const { isFetching, isError, errorMessage } = useSelector(addressSelector);

  // save address
  const onSubmit = (data) => {
    dispatch(
      save_address({
        id: address_details?.id,
        addressData: {
          address: {
            ...Object.fromEntries(
              Object.entries(data).filter(([key, value]) => value !== '')
            ),
            type: type,
          },
        },
      })
    ).then((res) => {
      if (!res.payload.code) {
        push_data_layer({
          event: 'edit_address',
          data: {},
        });
        dispatch(
          getPickupAddress({
            bodyParam: { type: 'pickup' },
          })
        );
        dispatch(
          getShippingAddress({
            bodyParam: { type: 'shipping' },
          })
        );
        setAddressForm(false);
      }
    });
  };
  // delete address
  const delete_current_address = (data) => {
    dispatch(
      delete_address({
        id: address_details?.id,
      })
    ).then((res) => {
      if (!res.payload.code) {
        dispatch(
          getPickupAddress({
            bodyParam: { type: 'pickup' },
          })
        );
        dispatch(
          getShippingAddress({
            bodyParam: { type: 'shipping' },
          })
        );
        setAddressForm(false);
      }
    });
  };

  //
  const closePopUP = () => {
    dispatch(clearErrorState());
  };

  return (
    <>
      {isFetching && <CustomLoading />}
      {isError && (
        <OutsideClickHandler
          onOutsideClick={() => {
            isError && dispatch(clearErrorState());
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp message={errorMessage} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      <div className="w-full flex  justify-end">
        {address_form && (
          <Modal>
            <OutsideClickHandler
              onOutsideClick={() => {
                setAddressForm(false);
              }}
            >
              <AddressForm
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                register={register}
                setShowAddressForm={setAddressForm}
                isNewAddress={false}
                setValue={setValue}
                type={type}
                setType={setType}
                address_details={address_details}
                control={control}
              />
            </OutsideClickHandler>
          </Modal>
        )}
        <div className="flex items-center gap-2">
          <button
            onClick={() => setAddressForm(!address_form)}
            className="p-1.5 rounded-button bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary flex items-center gap-2  "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </button>
          <button
            onClick={() => delete_current_address()}
            className="p-1.5 rounded-button bg-primary border border-transparent text-white  transition duration-300 hover:bg-transparent hover:border-primary hover:text-primary flex items-center gap-2  "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default Edit_address_button;
