/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { useRouter } from 'next/dist/client/router';

import Link from 'next/link';
import { Listing_text } from '../../../constant/Translation/Listing';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import SidePan from '../../Shared/Modal/SidePan';

const CustomProductButton2 = ({}) => {
  const router = useRouter();
  const [sidePanOpen, setSidePanOpen] = useState(false);
  const link_open = (e) => {
    if (
      TYPE_CONSTANT?.LISTINGS_CONFIGS?.custom_button.action_type !== 'popup'
    ) {
      TYPE_CONSTANT?.LISTINGS_CONFIGS?.custom_button.action_type !== '_blank'
        ? router.push(TYPE_CONSTANT?.LISTINGS_CONFIGS?.custom_button?.link)
        : window.open(TYPE_CONSTANT?.LISTINGS_CONFIGS?.custom_button?.link);
    } else {
      e.stopPropagation();
      setSidePanOpen(true);
    }
  };

  return (
    <>
      <div className=" w-full flex justify-center items-center">
        <button
          className=" w-full text-center py-3 px-10 bg-primary rounded-button text-white"
          onClick={link_open}
        >
          {TYPE_CONSTANT?.LISTINGS_CONFIGS?.custom_button.title}
        </button>
      </div>
      {TYPE_CONSTANT?.LISTINGS_CONFIGS?.custom_button.action_type ===
        'popup' && (
        <SidePan
          id={`listing-details-sidepan`}
          sidePanOpen={sidePanOpen}
          setSidePanOpen={setSidePanOpen}
          modal_section_styles={
            'fixed  right-0 left-0 bottom-0  top-0 my-auto  z-[60] overflow-hidden flex items-center  justify-center     rounded-card transform '
          }
          modal_inside_section_styles="bg-white rounded shadow-lg overflow-auto   max-w-5xl w-full max-h-full  lg:scrollbar-thin   lg:scrollbar-track-transparent  lg:scrollbar-thumb-transparent"
          modal_width={'max-w-xl w-full'}
        >
          <div className=" relative  h-[80vh]  ">
            <iframe
              width="100%"
              height="100%"
              src={TYPE_CONSTANT?.LISTINGS_CONFIGS?.custom_button?.link}
              allowfullscreen
            ></iframe>
          </div>
        </SidePan>
      )}
    </>
  );
};

export default CustomProductButton2;
