/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import DescriptionPart from '../../components/ListingDetails/DescriptionPart/DescriptionPart';
import ImagePart from '../../components/ListingDetails/ImagePart/ImagePart';
import MainBox from '../../components/ListingDetails/MainBox/MainBox';
import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  clearListingState,
  getListingQNA,
  getListingReviews,
  listingDetails,
  listingLike,
  listingSelector,
} from '../../store/feature/listingSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import AttributeDetails from '../../components/ListingDetails/AttributeDetails/AttributeDetails';
import Head from 'next/head';
import AddressBox from '../../components/ListingDetails/AddressBox/AddressBox';
import Variants from '../../components/ListingDetails/Variants/Variants';
import ProductButtons from '../../components/ListingDetails/ProductButtons/ProductButtons';
import StoreNameBox from '../../components/ListingDetails/StoreNameBox/StoreNameBox';
import RatingBox from '../../components/ListingDetails/RatingBox/RatingBox';
import ReviewBox from '../../components/ListingDetails/ReviewBox/ReviewBox';
import ReactPaginate from 'react-paginate';
import RelatedListings from '../../components/ListingDetails/RelatedListing/RelatedListings';
import AccountListings from '../../components/ListingDetails/AccountListings/AccountListings';
import { check_login } from '../../components/../constant/check_auth';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import Edit_listing_button from '../../components/Shared/Buttons/Edit_listing_button';
import ShareButtons from '../../components/ListingDetails/ShareButtons/ShareButtons';
import Category from '../../components/ListingDetails/categories/Category';
import ShippingMethods from '../../components/ListingDetails/ShippingMethods/ShippingMethods';
import QNA_section from '../../components/ListingDetails/QNA/QNA_section';
import MainBox4 from '../../components/ListingDetails/MainBox/MainBox4';
import DescriptionPart2 from '../../components/ListingDetails/DescriptionPart/DescriptionPart2';
import RelatedListings2 from '../../components/ListingDetails/RelatedListing/RelatedListings2';
import ShareButtons2 from '../../components/ListingDetails/ShareButtons/ShareButtons2';
import Cookies from 'js-cookie';
import NotFound from '../../components/Shared/Default UI/NotFound';
import { Listing_text } from '../../constant/Translation/Listing';
import { slug as SlugChecker } from 'github-slugger';
import ReportButton from '../../components/ListingDetails/report/ReportButton';
import SuccessPopUp from '../../components/Shared/PopUp/Success';

const ListingDetailsPageLayout = ({}) => {
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  const [selectedVariant, setSelectedVariant] = useState(null);

  const router = useRouter();

  const dispatch = useDispatch();
  const { auth_key, login } = useSelector(authSelector);

  const {
    isSuccess,
    listing_details,
    rating_data,
    errorMessage,
    isError,
    reviews,
    my_review,
    review_page,
    review_total_records,
    qna,
    isShowSuccess,
    success_message,
  } = useSelector(listingSelector);

  //
  // useEffect(() => {
  //   if (router?.query.slug && listing_details === null) {
  //     dispatch(
  //       listingDetails({
  //         ...router?.query,
  //       })
  //     );
  //   }
  // }, [router?.query.slug]);

  useEffect(() => {
    if (listing_details) {
      dispatch(
        getListingReviews({
          authKey: auth_key,
          params: {
            type: 'listings',
            id: listing_details.id,
            page: 1,
          },
        })
      );
      dispatch(
        getListingQNA({
          params: {
            reference_type: 'listings_qa',
            reference_id: listing_details.id,
            page: 1,
            per_page: 10,
            parent: 0,
          },
        })
      );
    }
  }, [listing_details]);

  // Button Handle
  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id: id,
          isLiked: isLiked,
          authKey: auth_key,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            listingDetails({
              ...router?.query,
            })
          );
        }
      });
    }
  };

  const closePopUP = () => {
    dispatch(clearListingState());
    setShowError(false);
    setError_message('');
  };

  //
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    const totalpage = Math.ceil(review_total_records / 30);
    if (Number(review_total_records) > 30) {
      setPageCount(totalpage);
    }
  }, [review_total_records]);

  //more review click
  const moreReviews = (data) => {
    dispatch(
      getListingReviews({
        authKey: auth_key,
        params: {
          type: 'listings',
          id: listing_details.id,
          page: Number(data.selected) + 1,
          per_page: 30,
        },
      })
    );
  };

  // Hash anchro tag:
  React.useEffect(() => {
    const path = window.location.hash;
    if (path && path.includes('#') && listing_details) {
      setTimeout(() => {
        const id = path.replace('#', '');
        const el = window.document.getElementById(id);
        if (el) {
          const r = el.getBoundingClientRect();
          window.top.scroll({
            top: pageYOffset + r.top,
            behavior: 'smooth',
          });
        }
      }, 600);
    }
  }, [listing_details]);

  return (
    <div className={' w-full flex flex-col gap-page_default  '}>
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearListingState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {/* Success popup */}
      {isShowSuccess && (
        <OutsideClickHandler
          onOutsideClick={() => {
            isShowSuccess && dispatch(clearListingState());
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <SuccessPopUp message={success_message} closePopUP={closePopUP} />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) &&
        JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)) ==
          listing_details?.account_id &&
        listing_details && (
          <div className="flex items-center justify-end ">
            <Edit_listing_button listing_details={listing_details} />
          </div>
        )}

      {listing_details !== undefined ? (
        <>
          {' '}
          <div className="  flex flex-col  md:flex-row md:justify-center gap-page_default">
            <div className="  md:w-64 lg:w-72 h-auto  md:self-start md:shrink-0 md:order-1    md:sticky md:top-24  flex flex-col gap-page_default  ">
              <MainBox4 listing_details={listing_details} />
              {TYPE_CONSTANT.LISTINGS_CONFIGS?.listing_share_section !=
                false && (
                <div className="    ">
                  <ShareButtons2
                    listing_details={listing_details}
                    router={router}
                  />
                </div>
              )}
            </div>
            <div className="flex-grow flex flex-col gap-page_default ">
              {TYPE_CONSTANT.LISTINGS_CONFIGS?.show_description != false &&
                listing_details?.description !== '' && (
                  <div className=" ">
                    <DescriptionPart2
                      description={listing_details?.description}
                    />
                  </div>
                )}

              {TYPE_CONSTANT.LISTINGS_CONFIGS?.similar_listing_card !=
                false && (
                <div className="    ">
                  <RelatedListings2 listing_details={listing_details} />
                </div>
              )}
            </div>
          </div>
          {listing_details && (
            <div className="  flex items-center justify-between flex-wrap">
              <Breadcrumb
                lists={[
                  { name: 'Home', link: '/' },
                  { name: 'Categories', link: '/lc' },
                  {
                    isHide: listing_details?.categories[0]?.name ? false : true,
                    link: `/lc/${
                      listing_details?.categories[0]?.slug
                        ? listing_details?.categories[0]?.slug
                        : `${listing_details?.categories[0]?.id}-${SlugChecker(
                            listing_details?.categories[0]?.name
                          )}`
                    }`,
                  },
                  {
                    name: listing_details?.title,
                    link: '',
                  },
                ]}
              />
              <ReportButton listing_details={listing_details} />
            </div>
          )}
        </>
      ) : (
        <NotFound message={Listing_text.product.no_product_found} />
      )}
    </div>
  );
};

export default ListingDetailsPageLayout;
