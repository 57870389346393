import Link from 'next/link';
import React from 'react';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const FeaturedCard = ({ collection }) => {
  let listing = collection?.listings[0] || {};
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 rounded-card shadow-md overflow-hidden">
      <div className="p-5 md:px-24 md:py-10 flex flex-col gap-6">
        <h2 className="heading-h2">{collection.title}</h2>
        <p className=" paragraph text-[#474747]  font-light">
          {collection.description}
        </p>
        <div>
          <Link
            href={
              listing.slug
                ? `/l/${listing.slug}`
                : listing?.id
                ? `/l/${listing.id}-${listing?.title.replace(
                    /[ /?,#=]+/g,
                    '-'
                  )}?id=true`
                : '#'
            }
          >
            <a
              className="btn-primary-fill w-auto cursor-pointer"
              target={
                TYPE_CONSTANT?.GENERAL_CONFIGS?.featured_card?.link_opening ??
                '_blank'
              }
            >
              {TYPE_CONSTANT?.GENERAL_CONFIGS?.featured_card?.btn_title}
            </a>
          </Link>
        </div>
      </div>
      <div>
        <img
          src={TYPE_CONSTANT?.GENERAL_CONFIGS?.featured_card?.image}
          className="w-full h-full object-cover "
          alt="featured card"
        />
      </div>
    </div>
  );
};

export default FeaturedCard;
