import React from 'react';
import { Tab } from '@headlessui/react';
import AccountListings from './AccountListings';
import AboutSection from './AboutSection';
import { Account_text } from '../../../../constant/Translation/Account';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getStoreReviews } from '../../../../store/feature/storeSlice';
import axios from 'axios';
import { useState } from 'react';
import { about_icon, cart_icon } from '../../../Shared/Constant/Icons/AllIcons';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AccountTab = ({ account_details }) => {
  const [account_full_details, setAccountFull_details] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getStoreReviews({
        params: {
          type: 'accounts',
          id: account_details.id,
          page: 1,
          per_page: 30,
        },
      })
    );

    const path = account_details?.id
      ? `/api/a/${account_details?.slug}?id=${account_details?.id}`
      : `/api/a/${account_details?.slug}`;

    axios
      .get(path)
      .then((res) => {
        setAccountFull_details(res.data.account);
      })
      .catch((error) => {
        // Error_log('error', error.response);
      });
  }, [account_details]);

  return (
    <Tab.Group as="div" className="mt-5 min-h-[50vh]">
      <Tab.List className=" relative w-full grid grid-cols-2 border-b-2  border-[#D2D2D2]">
        <Tab
          as="h2"
          className={({ selected }) =>
            classNames(
              '  w-full rounded-lg py-2.5 text-base font-medium leading-5  flex flex-col md:flex-row items-center justify-center gap-3 ',
              'ring-0 focus:outline-none  ',
              selected
                ? 'relative  text-primary after:content-[""] after:h-1 after:w-full  after:block after:absolute after:-bottom-0.5 after:bg-primary after:rounded-card '
                : 'text-[#4F4F4F]   '
            )
          }
        >
          <span> {cart_icon}</span>
          <span> {Account_text.storedetail.product}</span>
        </Tab>
        <Tab
          as="h2"
          className={({ selected }) =>
            classNames(
              '  w-full rounded-lg py-2.5 text-base font-medium leading-5  ',
              'ring-0 focus:outline-none   flex flex-col md:flex-row items-center justify-center gap-3',
              selected
                ? 'relative  text-primary after:content-[""] after:h-1 after:w-full  after:block after:absolute after:-bottom-0.5 after:bg-primary after:rounded-card '
                : 'text-[#4F4F4F]   '
            )
          }
        >
          <span> {about_icon}</span>
          <span> {Account_text.storedetail.about}</span>
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <AccountListings account_details={account_details} />
        </Tab.Panel>
        <Tab.Panel>
          <AboutSection account_details={account_full_details} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default AccountTab;
