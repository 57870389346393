import { useRouter } from 'next/dist/client/router';
import React, { useEffect, useState } from 'react';
import ProfileLayout1 from '../common_layouts/ProfileLayout1';
import WalletDetails from '../../components/Wallet/WalletDetails';
import MultiWalletCurrencies from '../../components/Wallet/MultiCurrencies/MultiWalletCurrencies';
import { useAppDispatch, useAppSelector } from '../../hooks/Redux';
import { useScreenSize } from '../../hooks/useScreenSize';
import {
  dynamicPageSelector,
  getDynamicPageSections,
} from '../../store/feature/Pages/DynamicPage/dynamicPageSlice';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import {
  HomeBoxDefaultStyles,
  HomeDefaultColumnStyles,
  HomeMainDefaultStyles,
} from '../../constant/default_styles/HomeDefaultStyles';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../utils/ResponsiveDesign';
import {
  editor_versions_list,
  latest_editor_version,
} from '../../constant/Version';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { dynamic_page_component_decide } from './BlockDecide/DynamicPageBlockDecide';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const WalletPageLayout = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { width, height } = useScreenSize();

  //
  const { isError, errors, dynamic_page_sections } =
    useAppSelector(dynamicPageSelector);

  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  //select blocks
  const [BlocksList, setBlocksList] = useState(TYPE_CONSTANT.PAGES?.wallet);

  //

  const [AllListingFilter, setAllListingsFilter] = useState({});

  // data fetching
  useEffect(() => {
    if (BlocksList?.schema?.length > 0) {
      let all_items = BlocksList?.schema.map(
        ({ row_styles, columns, ...column_styles }) => {
          const all_columns_items = columns?.map((column) => column);
          const flattenedArray = all_columns_items.flat();
          // Remove empty inner arrays from the flattened array.
          const filteredArray = flattenedArray.filter(
            (element) => element.length !== 0
          );
          return filteredArray;
        }
      );
      const flattenedArray = all_items.flat();

      // Remove empty inner arrays from the flattened array.
      const filteredArray = flattenedArray.filter(
        (element) => element.length !== 0
      );

      dispatch(getDynamicPageSections({ sections_data: filteredArray }));
    }
  }, [BlocksList]);

  return (
    <div
      style={get_dynamic_styles_with_default_value(
        responsive_design_styles({
          width,
          parent_group: BlocksList?.styles,
          child_group_name: 'main_styles',
        }),
        HomeMainDefaultStyles
      )}
    >
      <div
        className="flex flex-col  "
        style={get_dynamic_styles_with_default_value(
          responsive_design_styles({
            width,
            parent_group: BlocksList?.styles,
            child_group_name: 'box_styles',
          }),
          HomeBoxDefaultStyles
        )}
      >
        {BlocksList?.schema?.map((row) => {
          const {
            columns,
            current_editor_version = '',
            ...column_styles
          } = row || {};
          const row_styles = responsive_row_styles({
            parent_group: row,
            width,
          });
          return (
            <div
              className={``}
              style={
                current_editor_version == latest_editor_version
                  ? {
                      display: row_styles?.display,
                      gridTemplateColumns: row_styles?.columns_box_percentage,
                      ...get_dynamic_styles(row_styles),
                    }
                  : {
                      ...get_dynamic_styles(row_styles),
                      display:
                        row_styles?.isShowInMobile === false ? 'none' : 'grid',
                      gap: row_styles?.gap_between_columns ?? '24px',
                      gridTemplateColumns:
                        width > 768
                          ? row_styles?.columns_box_percentage
                            ? row_styles?.columns_box_percentage
                            : `repeat(${
                                row.columns?.length ?? 1
                              }, minmax(0, 1fr))`
                          : row?.['768_row_styles']
                          ? row_styles?.columns_box_percentage
                          : '100%',
                    }
              }
            >
              {/* Columns */}
              {columns?.map((column, index) => {
                const current_column_style = responsive_column_styles({
                  parent_group: column_styles,
                  index,
                  width,
                });

                return (
                  <div
                    className="flex   "
                    style={{
                      ...get_dynamic_styles_with_default_value(
                        current_column_style,
                        HomeDefaultColumnStyles
                      ),
                    }}
                  >
                    {column?.map((item) => {
                      return (
                        dynamic_page_component_decide({
                          ...item,
                          dynamic_page_sections,
                          dynamic_page_sections,
                          AllListingFilter,
                          setAllListingsFilter,
                          screen_width: width,
                        }) &&
                        (editor_versions_list?.includes(
                          current_editor_version
                        ) ? (
                          <>
                            {dynamic_page_component_decide({
                              ...item,
                              dynamic_page_sections,
                              dynamic_page_sections,
                              AllListingFilter,
                              setAllListingsFilter,
                              screen_width: width,
                            })}
                          </>
                        ) : (
                          <div>
                            {dynamic_page_component_decide({
                              ...item,
                              dynamic_page_sections,
                              dynamic_page_sections,
                              AllListingFilter,
                              setAllListingsFilter,
                              screen_width: width,
                            })}
                          </div>
                        ))
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WalletPageLayout;
