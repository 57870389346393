import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import FeedbackForm from '../../components/Feedback/FeedbackForm';

const FeedbackPageLayout = () => {
  const router = useRouter();
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    axios
      .get('/api/feedback/categories')
      .then((res) => {
        setCategories(res.data.feedback_categories);
      })
      .catch((error) => {
        // Error_log('feedback_category', error);
      });
  }, [router]);

  return (
    <div>
      <FeedbackForm categories={categories} />
    </div>
  );
};

export default FeedbackPageLayout;
