/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import moreImage from '../../../assets/Images/Categories/Menu.png';
import { getThumbnailImage } from '../../Shared/Constant/Constant';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { slug as SlugChecker } from 'github-slugger';

const Categories2 = ({ categories }) => {
  return (
    <div className="relative">
      <div className=" w-full bg-primary ">
        <h2 className="max-w-[1400px] mx-auto px-5 flex justify-center h2 text-center p-5 text-white">
          {TYPE_CONSTANT?.GENERAL_CONFIGS?.header_text
            ? TYPE_CONSTANT?.GENERAL_CONFIGS?.header_text
            : 'Finds the best deals'}
        </h2>
      </div>
      <div className=" relative  h-auto mx-auto mt-0   ">
        <div className="absolute top-0 bg-primary h-[75%] md:h-[50%]    w-full -z-10"></div>

        <div className="max-w-[1400px] mx-auto md:py-4  flex  justify-center flex-wrap  gap-3   md:gap:10  lg:gap-14  ">
          {categories?.map((item, index) => {
            const query =
              item.name !== 'All Categories'
                ? {
                    slug: item.slug
                      ? item.slug
                      : `${item?.id}-${SlugChecker(item.name)}`,
                  }
                : '';

            return (
              index < 8 && (
                <Link
                  key={Math.random()}
                  href={{
                    pathname: `${
                      item.name !== 'All Categories' ? '/lc/[slug]' : '/lc'
                    }`,
                    query,
                  }}
                  passHref
                >
                  <a className=" w-[84px]  md:w-[94px]  lg:w-[114px]    hover:text-primary   cursor-pointer  group transition duration-300  ">
                    <div className="  relative my-2   ">
                      <img
                        className="  w-[54px] h-[54px] md:w-[94px]  md:h-[94px] lg:w-[114px]  lg:h-[114px]  rounded-full object-cover  object-left-top   bg-transparent shadow-sm  group-hover:shadow-md border-0 transition duration-300 ease-in-out scale-95 group-hover:scale-100   mx-auto"
                        src={getThumbnailImage(item.image_path)}
                        alt="category image"
                      />
                    </div>
                    <p className=" pb-1   text-base    md:font-semibold  border-b-1  border-transparent    text-center group-hover:border-primary py-1">
                      {item.name}
                    </p>
                  </a>
                </Link>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Categories2;
